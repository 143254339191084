<template>
	<div class="crm-message-card">
		<div class="crm-message-card-top">
			<div class="crm-message-content">
				<p>
					<strong class="username">{{ info.patient.name }}</strong>
					<span class="sex">{{ info.patient.sex_dictText }}</span> <span class="age">{{ info.patient.age }}岁</span><span class="date">{{ info.patient.createTime | formatDate }}</span>
				</p>
				<p>审批人：{{info.apply.auditBy}}</p>
				<p>驳回原因：{{info.apply.remarks}}</p>
			</div>
		</div>
		<div class="crm-message-card-bottom">
			<div class="crm-message-msg"><span class="dian"></span>患者的入院申请被驳回</div>
			<a class="crm-message-btn"
				@click="changeRouter">前往患者主页</a>
		</div>
	</div>
</template>
<script>
	import crmMixins from "@/utils/crm-mixins";
	export default {
		name: "msg401",
		props: ["item"],
		mixins: [crmMixins],
		data () {
			return {
				info: {
					patient: {},
					apply: {}
				}
			};
		},
		mounted () {
			this.info = JSON.parse(this.item.msgContent || "{msgContent:{}}");
		},
		methods: {
			changeRouter () {
				if (this.info.patient && this.info.patient.id) {
					this.$router.push(`/crm/profile/${this.info.patient.id}`);
					this.$emit("read", this.item.anntId);
				}
			}
		}
	};
</script>

<template>
	<div class="custom-form">
		<div class="form-label">{{ model.label }}</div>
		<div class="input-value input-upload">
			<van-uploader v-model="uploader" accept="image/*" :after-read="afterRead" @delete="removeImg" />
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
export default {
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: ""
				};
			}
		},
		value: {
			type: String | Number,
			default: ""
		}
	},
	data() {
		return {
			uploader: [],
			imgList: []
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			if (this.value) {
				this.uploader = this.value instanceof Array ? this.value : typeof this.value === "string" ? this.value.split(",") : [];
			} else {
				this.uploader = [];
			}
		},
		afterRead(file) {
			file.status = "uploading";
			file.message = "上传中...";
			api.upload(file, "survey").then(res => {
				if (res.success) {
					file.status = "done";
					file.message = "上传成功";
					this.imgList.push("http://crm.zfgeek.com/storage/" + res.message);
					this.changeValue();
				} else {
					file.status = "failed";
					file.message = "上传失败";
				}
			});
		},
		removeImg(file, detail) {
			this.uploader = [...this.uploader].splice(detail.index, 1);
			this.imgList = [...this.imgList].splice(detail.index, 1);
			this.changeValue();
		},
		changeValue() {
			this.$emit("input", this.imgList.join(","), this.model);
		}
	}
};
</script>

<style lang="less">
.input-upload {
	.van-uploader > .van-uploader__wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.van-uploader__upload,
	.van-uploader__preview-image {
		border-radius: 6px;
	}
}
</style>

<template>
	<div class="crm-report">
		<van-nav-bar @click-left="onClickLeft">
			<template #title><span class="bar-title"> {{ reportModel.patientName }} - 报告详情</span> </template>
			<template #left>
				<van-icon name="arrow-left" size="18" />
			</template>
		</van-nav-bar>
		<div class="crm-report-body">
			<div>
				<van-cell>
					<van-row class="crm-basic">
						<van-col class="crm-report-title" :span="24">基本信息</van-col>
						<van-col class="crm-report-text" :span="12">姓名：{{ reportModel.patientName }}</van-col>
						<van-col class="crm-report-text" :span="12">性别：{{ reportModel.patient.sex | convertDict(sex) }}</van-col>
						<van-col class="crm-report-text" :span="12">影像号：-------</van-col>
						<van-col class="crm-report-text" :span="12">检查时间：{{ reportModel.checkTime | formatDate("YYYY-MM-DD") }}</van-col>
						<van-col class="crm-report-text" :span="12">送检科室：{{ reportModel.department }}</van-col>
						<van-col class="crm-report-text" :span="12">病区：{{ reportModel.inpatientArea }}</van-col>
					</van-row>
					<van-row class="crm-basic">
						<van-col class="crm-report-title" :span="24">检查信息</van-col>
						<van-col class="crm-report-text" :span="24">临床诊断：-------</van-col>
						<van-col class="crm-report-text" :span="24">检查项目：-------</van-col>
						<van-col class="crm-report-text" :span="24">检查方法：-------</van-col>
					</van-row>
					<van-row class="crm-basic crm-basic-report">
						<van-col class="crm-report-title" :span="24">检查结果</van-col>
						<van-col class="crm-report-text" :span="24" v-for="(item, key) in reportModel.crmVwJcjgkList" :key="key">{{ item.xmjg }}</van-col>
					</van-row>
					<van-row class="crm-basic">
						<van-col class="crm-report-text" :span="24">报告医师：----------</van-col>
						<van-col class="crm-report-text crm-report-time" :span="24">报告时间：{{ reportModel.reportDate | formatDate("YYYY-MM-DD HH:mm") }}</van-col>
						<van-button class="mb-80 crm-report-btn" @click="getReport(reportModel)"  block>
                         查看影像

						</van-button>
					</van-row>
				</van-cell>
			</div>
		</div>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-report",
	mixins: [crmMixins],
	components: { SexIcon },
	data() {
		return {
			reportModel: {patient: {
					sex: '', // 或者其他默认值
				},},
		};
	},
	mounted() {
		this.reportModel = this.$route.params.params;
	},
	methods: {
		onClickLeft() {
			this.$router.go(-1);
		},

		getReport(reportModel){
			// window.location.href=`http://report.evo-health.cn:8090/tcloud/TCloudViewer.html?applyNos=${reportModel.reportId}&hospcode=${reportModel.hospitalId==='上海好望角医院'? '99999999902': '99999999903'}`
			window.open(`http://report.evo-health.cn:8090/tcloud/TCloudViewer.html?applyNos=${reportModel.reportId}&hospcode=${reportModel.hospitalId==='上海好望角医院'? '99999999902': '99999999903'}`, '_blank');

		},
	},
};
</script>

<style lang="less">
.crm-report-body {
	width: 100vw;
    height: 100vh;
    overflow: auto;
    padding-bottom: 10vh;
	.crm-report-title {
		font-weight: 600;
		font-size: 0.853rem;
		line-height: 1.8rem;
		text-align: left;
		margin-bottom: 0.2rem;
	}
	.crm-report-text {
		font-weight: 400;
		font-size: 0.747rem;
		line-height: 1.04rem;
		color: #333333;
		margin-bottom: 0.24rem;
	}
	.crm-basic {
		margin: 0.533rem;
		// padding: 0.748rem;
	}
	.crm-basic-report {
		.crm-report-text {
			margin-bottom: 0.54rem;
		}
	}
	.crm-report-btn {
		height: 2.56rem;
		background: #5f5aa3;
		border-radius: 0.23rem;
		color: #fff;
		font-size: 0.853rem;
		line-height: 1.067rem;
		white-space: nowrap;
	}
	.crm-report-time{
		margin-bottom: 1rem;
	}
}
.bar-title{
		font-weight: 600;
	}
</style>

<template>
	<div class="crm-bg crm-create">
		<div class="camera-card" v-if="step == 0">
			<a href="javascript:;" class="camera-btn" @click="open = !open">
				<span class="icon-camera"></span>
				拍照识别
			</a>
			<div class="camera-list" v-if="open">
				<a href="javascript:;" class="camera-sfz">
					<input type="file" name="file" accept=".png,.jpg,jpeg" @change="uploadSFZ" />
					<span class="icon-sfz"></span>
					身份证
				</a>
				<a href="javascript:;" class="camera-ybk">
					<input type="file" name="file" accept=".png,.jpg,jpeg" @change="uploadYBK" />
					<span class="icon-ybk"></span>
					医保卡
				</a>
			</div>
		</div>
		<van-form ref="vanFormRef1">
			<div class="crm-card" v-if="step == 0">
				<div class="crm-card-title">基础信息</div>
				<div class="crm-card-body">
					<van-field
						v-model="form.name"
						required
						name="username"
						@click-right-icon="dialogPatients(form.name)"
						label="姓名"
						placeholder="姓名"
						:rules="[{ required: true, message: '请填写姓名' }]"
					>
						<template #right-icon>
							<van-icon name="user-o" />

							<PatientsModal @data-sent="handleDataFromChild" :NameInfo="sameNameInfo" :showDialog="show"></PatientsModal> </template
					></van-field>
					<CRMSelect
						v-model="form.cardType"
						name="cardType"
						label="证件类型"
						:attr="{ required: true }"
						placeholder="选择证件类型"
						:columns="card_type"
						:rules="[{ required: true, message: '请选择证件类型' }]"
					/>
					<CRMSelect
						v-model="form.source"
						:deep="2"
						:all="addressInfo.all || []"
						name="source"
						label="患者来源"
						:attr="{ required: true }"
						placeholder="选择患者来源"
						:columns="addressInfo.tree || []"
						:rules="[{ required: true, message: '请选择患者来源' }]"
					/>
					<van-field
						v-if="form.cardType === '身份证'"
						v-model="form.idCard"
						name="idCard"
						label="身份证号"
						required
						placeholder="输入身份证号"
						maxlength="18"
						@blur="updateAge"
						:rules="[
							{ required: true, message: '请填写身份证号' },
							{ validator: RULES.idCard, message: '请填写正确的身份证号' },
						]"
					/>
					<van-field
						v-else
						v-model="form.idCard"
						name="idCard"
						label="证件号码"
						required
						placeholder="输入证件号码"
						:rules="[{ required: true, message: '请填写证件号码' }]"
					/>
					<CRMSelect
						v-model="form.sex"
						name="sex"
						label="性别"
						:attr="{ required: true }"
						placeholder="选择性别"
						:columns="sex"
						:rules="[{ required: true, message: '请选择性别' }]"
					/>
					<van-field
						v-model="form.age"
						type="digit"
						name="age"
						label="年龄"
						required
						maxlength="3"
						placeholder="请输入年龄"
						:rules="[{ required: true, message: '请填写年龄' }]"
					/>
				</div>
			</div>
			<div class="crm-card mb-80" v-if="step == 0">
				<div class="crm-card-body">
					<van-field
						v-model="form.cellphone"
						name="cellphone"
						type="tel"
						required
						label="联系电话"
						placeholder="请输入联系电话"
						:rules="[
							{ required: true, message: '请填写联系电话' },
							{ pattern: RULES.phone, message: '请输入正确的联系电话' },
						]"
					>
						<!-- <van-icon slot="right-icon" name="add-o" @click="addCellPhone" /> -->
					</van-field>
					<van-field
						v-model="cellphone[item]"
						type="tel"
						:name="`cellphone-${item}`"
						:label="`其他号码${index + 1}`"
						placeholder="请输入电话号"
						@blur="cellphoneBlur($event, item)"
						v-for="(item, index) in Object.keys(cellphone)"
						:key="index"
					>
						<van-icon slot="right-icon" name="minus" @click="subCellPhone(item)" />
					</van-field>
				</div>
			</div>
		</van-form>
		<van-button v-if="step == 0" class="crm-btn mb-80" :loading="loading" block @click="next">下一步<span class="btn-icon icon-next"></span></van-button>
		<van-form ref="vanFormRef2">
			<div class="crm-card mb-80" v-if="step == 1">
				<div class="crm-card-title">其他信息</div>
				<div class="crm-card-body">
					<van-field name="decision" required v-model="form.decision" label="决策人" :rules="[{ required: true, message: '请选择家庭决策人' }]">
						<template #input>
							<van-radio-group v-model="form.decision" direction="horizontal">
								<van-radio v-for="(item, index) in decision_type" :key="index" :name="item.value">{{ item.text }}</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<template v-if="form.decision == 2">
						<van-field
							v-model="form.decisionName"
							name="decisionName"
							type="text"
							label="决策人姓名"
							required
							placeholder="请输入决策人姓名"
							:rules="[{ required: true, message: '请填写决策人姓名' }]"
						/>
						<CRMSelect
							v-model="form.decisionRelation"
							name="decisionRelation"
							label="与患者关系"
							:attr="{ required: true }"
							placeholder="请选择决策人与患者的关系"
							:columns="patient_relation"
							:rules="[{ required: true, message: '请选择决策人与患者的关系' }]"
						/>
						<van-field
							v-model="form.decisionCellphone"
							name="decisionCellphone"
							type="tel"
							label="联系电话"
							required
							placeholder="请输入联系电话"
							:rules="[
								{ required: true, message: '请填写联系电话' },
								{ pattern: RULES.phone, message: '请输入正确的联系电话' },
							]"
						/>
					</template>
					<template v-else>
						<CRMSelect
							v-model="form.payType"
							name="payment"
							label="支付方式"
							:attr="{ required: true }"
							placeholder="选择支付方式"
							:columns="pay_type"
							:rules="[{ required: true, message: '请选择支付方式' }]"
						/>
						<van-field
							v-if="['bendi', 'yidi'].includes(form.payType)"
							v-model="form.medicalNo"
							name="medicalNo"
							type="text"
							label="医保卡号"
							placeholder="请输入医保卡号"
						/>
						<CRMSelect v-model="form.religion" name="religion" label="宗教信仰" placeholder="选择宗教信仰" :columns="religion" />
					</template>
				</div>
			</div>

			<div class="crm-card mb-80" v-if="step == 1&&form.decision == 2">
				<div class="crm-card-body">
					<CRMSelect
						v-model="form.payType"
						name="payType"
						label="支付方式"
						:attr="{ required: true }"
						placeholder="选择支付方式"
						:columns="pay_type"
						:rules="[{ required: true, message: '请选择支付方式' }]"
					/>
					<van-field
						v-if="['bendi', 'yidi'].includes(form.payType)"
						v-model="form.medicalNo"
						name="medicalNo"
						type="text"
						label="医保卡号"
						placeholder="请输入医保卡号"
					/>
					<CRMSelect v-model="form.religion" name="religion" label="宗教信仰" placeholder="选择宗教信仰" :columns="religion" />
				</div>
			</div>
		</van-form>
		<div class="crm-btn-patient" v-if="step == 1">
			<van-button class="crm-round-btn mb-32" style="margin-right: 0" :loading="loading" block @click="prev"
				><span class="btn-icon icon-prev"></span>上一步</van-button
			>
		</div>

		<div class="crm-btn-inline mb-32" v-if="step == 1">
			<van-button class="crm-btn" v-if="!!form.id" :loading="loading" block @click="save">保存</van-button>
			<van-button class="crm-round-btn" v-if="!form.id" :loading="loading" block @click="save"
				>仅建档<span class="btn-icon icon-check"></span
			></van-button>
			<van-button class="crm-btn" v-if="!form.id" :loading="loading" block @click="saveToApply"
				>建档并申请入院<span class="btn-icon icon-date"></span
			></van-button>
		</div>
	</div>
</template>

<script>
import PatientsModal from "@/views/CRM/Patient/PatientsModal.vue";
import CRMSelect from "@/components/CRM/Select/index.vue";
import api from "@/request/api";
import RULES from "@/constant/rules";
import { Notify } from "vant";
import crmMixins from "@/utils/crm-mixins";
import heic2any from "heic2any";
import { mapState } from "vuex";
import ImageCompressor from "@/utils/imageCompressor";
import request from "@/request/index.js";

export default {
	name: "create-patient",
	mixins: [crmMixins],
	components: { CRMSelect, PatientsModal },
	data() {
		return {
			show: false,
			step: 0,
			loading: false,
			open: false,
			RULES,
			file: null,
			userArr: [],
			form: {
				name: "",
				idCard: "",
				sex: "",
				age: "",
				cellphone: "",
				decision: "",
				decisionName: "",
				decisionRelation: "",
				decisionCellphone: "",
				payType: "",
				religion: "",
				source: "",
				sourceText: "",
				cardType: "",
			},
			cellphone: {},
			provinceList: [],
			patientFrom: {},
			sameNameInfo: [],
		};
	},
	computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),
	},
	watch: {
		form: {
			handler: function (a) {},
			deep: true,
		},
		"form.cellphone"(newVal) {
			this.form.cellphone = newVal.replace(/\s/g, "");
		},
		"form.decisionCellphone"(newVal) {
			this.form.decisionCellphone = newVal.replace(/\s/g, "");
		},

	},
	mounted() {
		const query = this.$route.query;
		console.log("query", query);
		const vm = this;

		if (query.id) {
			const vm = this;
			api.crmDecodeById(query.id).then((res) => {
				if (res.success) {
					vm.patientFrom = res.result;
					console.log("zjh", vm.patientFrom);
					this.form = { ...this.form, ...vm.patientFrom };
					if (!!vm.patientFrom.cellphone) {
						try {
							const phones = JSON.parse(vm.patientFrom.cellphone);
							phones.map((v, i) => {
								if (i === 0) {
									this.form.cellphone = v;
								} else {
									this.cellphone[i.toString()] = v;
								}
							});
							this.$forceUpdate();
						} catch (error) {}
					}
				}
			});
		}
		console.log("addressInfo", this.addressInfo);
	},
	methods: {
		handleDataFromChild(info) {
			for (let key in info) {
				if (this.form.hasOwnProperty(key)) {
					this.form[key] = info[key];
				}
			}
			console.log(info); // 输出：这是从子组件传递的数据
		},
		dialogPatients(name) {
			if (!name || name.trim() === "") {
				console.log("Name is empty, skipping further steps.");
				return;
			}

			api.listMobile({ name: name })
				.then((res) => {
					if (res.success) {
						this.sameNameInfo = res.result.records;
						this.show = !this.show;
					}
				})
				.catch((error) => {
					console.error("An error occurred:", error);
				});
		},
		cellphoneBlur(e, item) {
			this.$forceUpdate();
		},
		addCellPhone() {
			const len = Object.keys(this.cellphone).length;
			if (len >= 4) {
				this.$toast.fail("最多允许5个联系电话");
				return false;
			}
			this.cellphone[(len + 1).toString()] = "";
			this.$forceUpdate();
		},
		subCellPhone(key) {
			console.log(key);
			delete this.cellphone[key];
			this.$forceUpdate();
		},
		getAge(identityCard) {
			var len = (identityCard + "").length;
			if (len == 0) {
				return 0;
			} else {
				if (len != 15 && len != 18) {
					//身份证号码只能为15位或18位其它不合法
					return 0;
				}
			}
			var strBirthday = "";
			if (len == 18) {
				//处理18位的身份证号码从号码中得到生日和性别代码
				strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
			}
			if (len == 15) {
				strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
			}
			//时间字符串里，必须是“/”
			var birthDate = new Date(strBirthday);
			var nowDateTime = new Date();
			var age = nowDateTime.getFullYear() - birthDate.getFullYear();
			//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
			if (
				nowDateTime.getMonth() < birthDate.getMonth() ||
				(nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
			) {
				age--;
			}
			return age;
		},
		updateAge() {
			if (this.RULES.idCard(this.form.idCard)) {
				this.form.age = this.getAge(this.form.idCard);
				this.$forceUpdate();
			}
		},
		uploadSFZ(e) {
			const self = this;
			// var fd = new FormData();
			// fd.append("type", "idcard");
			// fd.append("file", e.target.files[0]);
			// this.runOCR(fd);
			this.showLoading();
			this.getJpgFile(e.target.files[0]).then((file) => {
				const options = {
					file: file,
					quality: 1,
					mimeType: "image/jpeg",

					minWidth: 500,
					minHeight: 500,
					loose: true,

					// 压缩前回调
					beforeCompress: function (result) {
						console.log("压缩之前图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
					},

					// 压缩成功回调
					success: function (result) {
						var fd = new FormData();

						console.log("转换后:" + result.size);
						fd.append("type", "idcard");
						fd.append("file", result);
						self.runOCR(fd);

						console.log("压缩之后图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
						console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
					},

					// 发生错误
					error: function (msg) {
						console.error(msg);
					},
				};
				new ImageCompressor(options);
			});
		},
		uploadYBK(e) {
			const self = this;
			// var fd = new FormData();
			// fd.append("type", "medical");
			// fd.append("file", e.target.files[0]);
			// this.runOCR(fd);
			this.showLoading();
			this.getJpgFile(e.target.files[0]).then((file) => {
				const options = {
					file: file,
					quality: 1,
					mimeType: "image/jpeg",

					minWidth: 500,
					minHeight: 500,
					loose: true,

					// 压缩前回调
					beforeCompress: function (result) {
						console.log("压缩之前图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
					},

					// 压缩成功回调
					success: function (result) {
						var fd = new FormData();
						fd.append("type", "medical");
						fd.append("file", result);
						self.runOCR(fd);

						console.log("压缩之后图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
						console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
					},

					// 发生错误
					// 发生错误
					error: function (msg) {
						console.error(msg);
					},
				};
				new ImageCompressor(options);
			});
		},
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},
		runOCR(fd) {
			this.open = false;
			this.showLoading("识别中...");
			api.crmOCR(fd).then((res) => {
				this.hideLoading();
				console.log(res);
				if (res.success) {
					if (res.result.name.length === 0) {
						Notify({ type: "danger", message: "ORC识别失败！" });
					} else {
						this.form.name = res.result.name;
						this.form.age = res.result.age;
						this.form.idCard = res.result.idCard;
						this.form.sex = res.result.sexCode;
					}
				} else {
					Notify({ type: "danger", message: "ORC识别失败！" });
				}
			});
		},
		prev() {
			this.step = 0;
		},
		next() {
			console.log(this.form);
			this.$refs.vanFormRef1
				.validate()
				.then(() => {
					this.step = 1;
				})
				.catch();
		},
		submit(next) {
			const fd = { ...this.form };
			if (fd.decision == 1) {
				fd.decisionName = "";
				fd.decisionRelation = "";
				fd.decisionCellphone = "";
			}

			if (Object.keys(this.cellphone).length > 0) {
				const phones = [fd.cellphone];
				Object.keys(this.cellphone).map((v) => {
					const p = this.cellphone[v];
					if (p.length > 0) {
						phones.push(p);
					}
				});
				fd.cellphone = JSON.stringify(phones);
			}
			this.showLoading();

			if (fd.id) {
				this.$dialog
					.confirm({
						title: "提示",
						message: "是否确认信息修改！",
					})
					.then(() => {
						api.crmPatientEdit(fd).then((res) => {
							this.hideLoading();
							if (res.success) {
								Notify({ type: "success", message: "患者修改成功！" });
								this.$router.replace("/crm/profile/" + fd.id);
							} else {
								Notify({ type: "danger", message: res.message });
							}
						});
					})
					.catch(() => {
						this.hideLoading();
					});
			} else {
				api.crmPatientAdd(fd)
					.then((res) => {
						this.hideLoading();
						if (res.success) {
							Notify({ type: "success", message: "患者创建成功！" });
							if (next) {
								this.$router.replace("/crm/create-apply/" + res.result.id);
							} else {
								this.$router.replace("/crm/home");
							}
						} else {
							const patientId = res.result.id;
							const userId = this.info.id;
							this.userArr.push(userId.replace(/\"/g, ""));
							const model = {
								patientId: patientId,
								userIdList: this.userArr,
							};
							const obj = { ...fd, id: res.result.id };
							//1.如果返回fromhis调用edit并且调用分配给当前用户的接口
							if (res.message == "fromHis") {
								//调用编辑接口
								api.crmPatientEdit(obj).then((res) => {
									this.hideLoading();
									if (res.success) {
										Notify({ type: "success", message: "患者修改成功！" });
										api.assignAndUpdateCreate(model).then((res) => {
											this.hideLoading();
											if (res.success) {
												Notify({ type: "success", message: "患者分配成功！" });
												//患者分配成功 回到主页
												if (next) {
													//患者建档并申请入院
													this.$router.replace("/crm/create-apply/" + model.patientId);
												} else {
													// this.$router.replace("/crm/profile/" + model.patientId);
													this.$router.replace("/crm/home");
												}
											} else {
												Notify({ type: "danger", message: res.message });
											}
										});
									} else {
										Notify({ type: "danger", message: res.message });
									}
								});
							} else {
								//2.如果不是fromhis弹出提示框，然后选择是否分配edit并且调用分配给其他用户的接口
								this.$dialog
									.confirm({
										title: "提示",
										message: "该患者已建档，是否需要管理该患者?",
									})
									.then(() => {
										api.CrmPatientAssign(model).then((res) => {
											this.hideLoading();
											if (res.success) {
												Notify({ type: "success", message: "患者分配成功！" });
												if (next) {
													//患者建档并申请入院
													this.$router.replace("/crm/create-apply/" + model.patientId);
												} else {
													// this.$router.replace("/crm/profile/" + model.patientId);
													this.$router.replace("/crm/home");
												}
											} else {
												Notify({ type: "danger", message: res.message });
											}
										});
									})
									.catch(() => {
										this.hideLoading();
									});
							}
							//结束
						}
					})
					.catch(() => {
						this.hideLoading();
					});
			}
		},
		save() {
			this.$refs.vanFormRef2
				.validate()
				.then(() => {
					this.submit();
				})
				.catch();
		},
		saveToApply() {
			this.$refs.vanFormRef2
				.validate()
				.then(() => {
					this.submit(true);
				})
				.catch();
		},
	},
};
</script>

<style lang="less">
.crm-create {
	padding: 32px;
	position: relative;
}
.camera-card {
	position: absolute;
	top: 60px;
	right: 0;
	width: 246px;
	z-index: 2;
	.camera-btn {
		width: 246px;
		height: 96px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		border-radius: 60px 0px 0px 60px;
		padding: 12px;
		box-sizing: border-box;
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		color: #5f5aa3;
	}
	.icon-camera {
		display: inline-block;
		width: 72px;
		height: 72px;
		background: url("~@/assets/crm/icon/icon-taking.png") no-repeat center center/contain;
		margin-right: 16px;
	}
	.camera-list {
		width: 246px;
		height: 156px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 42px 0px 0px 42px;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		.camera-sfz {
			border-bottom: 1px solid #fff;
		}
		.camera-sfz,
		.camera-ybk {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-weight: 400;
			font-size: 32px;
			line-height: 45px;
			color: #ffffff;
			position: relative;
		}
		.icon-sfz,
		.icon-ybk {
			display: inline-block;
			width: 72px;
			height: 72px;
			margin-right: 16px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50%;
		}
		.icon-sfz {
			background-image: url("~@/assets/crm/icon/icon-sfz.png");
		}
		.icon-ybk {
			background-image: url("~@/assets/crm/icon/icon-ybk.png");
		}
		input[type="file"] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
}
.van-toast--unclickable .van-dialog button {
	pointer-events: auto;
}
</style>

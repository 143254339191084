<template>
	<div class="crm-visitlist">
		<div class="crm-visitlist-body-scroll">
			<div class="showTitle"
				><p>共{{list.length}}条</p>
				<p @click="showPopup">筛选<van-icon name="brush-o" /></p
			></div>
			<van-pull-refresh v-model="visitScroll.refreshing" @refresh="onRefresh">
				<van-list v-model="visitScroll.loading" :finished="visitScroll.finished" finished-text="没有更多了" @load="OnLoadScroll">
					<van-cell v-for="(item, index) in list" :key="index">
						<p class="title-class">
							{{ item.createBy }} <van-tag color="#5f5aa3">{{ item.visitType_dictText }}</van-tag>
						</p>
						<p>
							<TextOverflow :text="`${item.content}`" :maxLines="3">
								<template v-slot:default="{ clickToggle, expanded }">
									<van-tag @click="clickToggle" class="expanded-btn">
										<van-icon color="#000" :name="expanded ? 'arrow-up' : 'arrow-down'" />
										<!-- {{ expanded ? "收起" : "展开" }} -->
									</van-tag>
								</template>
							</TextOverflow>
						</p>
						<p class="visit-time">探访时间：{{ item.visitTime }}</p>
					</van-cell>
				</van-list>
			</van-pull-refresh>
		</div>
		<van-popup  class="crm-vist-popup" v-model="show" >
			<van-row>
				<van-col class="popup-title">类型筛选</van-col>
			</van-row>
			<van-row class="popup-row">
				<van-col class="popup-col"  v-for="item in visitList" :key="item.value"
					><van-tag @click="select(item.value)" color="#5f5aa3" size="large" :plain="item.visitRadio" type="primary">{{
						item.title
					}}</van-tag></van-col
				>
			</van-row>
		<van-row class="popup_ok">	<van-button  color="#5f5aa3" size="small" @click="onSearch()">确定</van-button></van-row>
		</van-popup>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";
import TextOverflow from "@/views/CRM/Patient/TextOverflow.vue";
import request from '@/request'
export default {
	name: "crm-visitList",
	mixins: [crmMixins],
	components: { SexIcon, TextOverflow },
	props: {
		inWardFrom: Object,upData:Number,
	},
	data() {
		return {
			dayjs,
			visitScroll: {
                loading: false,
                finished: false,
                refreshing: false,
            },
			show: false,
			applyList: [],
			url: {
				list: "/crm-service/crm/crmVisit/groupByAll",
			},
			queryParams: {
				column: "visitTime",
				order: "desc",
				visitTypeQuery: -1,
				patientId: this.inWardFrom.patientId,
			},
			statusList: [],
			applyId: "",
			zjh: "",
			visitList: [
				{
					text: "全部",
					title: "全部",
					value: "-1",
					visitRadio: true,
				},
			],
		};
	},
	mounted() {
		// this.visit_type.map((item) => {
		// 	this.$set(item, "visitRadio", true);
		// });
		// this.visitList = this.visitList.concat(this.visit_type);this.loadPage();
	},	watch: {
    // 监听 myData 的变化
    upData(newVal, oldVal) {
      console.log('myData 改变了: ', newVal, '（之前是：', oldVal, '）');
	  if(newVal!=oldVal){
		this.refreshData();
	  }

    },
  },
	methods: {
		refreshData(a) {
			this.visit_type.map((item) => {
			this.$set(item, "visitRadio", true);
		});
		this.visitList = this.visitList.concat(this.visit_type);this.loadPage();
		},
		showPopup() {
			this.show = true;
			console.log("that.visitList", this.visitList);
		},
		select(value) {
			let that = this;
			that.visitList.map((i) => {
				if (value == i.value) {
					i.visitRadio = !i.visitRadio;
					that.queryParams.visitTypeQuery = i.value;
				} else {
					i.visitRadio = true;
				}
			});
		},
		onSearch() {
			this.show = false;
			this.OnLoadScroll();
		},
	 onRefresh ()  {
			this.visitScroll= {
                            loading: true,
                            finished: false,
                            refreshing: true,
                        }
     this.OnLoadScroll();
    },
		OnLoadScroll() {
            // this.showLoading('')
            return request.get(this.url.list, { params: { ...this.queryParams } }).then(res => {
                const result = res.result;
                if (res.success && (res.code === 200 || res.code === 0)) {
                    this.list = result
					this.visitScroll= {
                            loading: false,
                            finished: true,
                            refreshing: false,
                        }
                }else{
                    this.visitScroll.finished = true;
                    Notify({ type: "danger", message: res.message });
                }
                return res;
            })
        },
	},
};
</script>

<style lang="less">

.crm-vist-popup {
	.popup_ok{
	text-align: right;
	margin-top: 20px;
}
	padding: 80px 50px;
	width: 80vw;
	border-radius: 10px;
	.popup-title{
	font-size: 32px;
	font-weight: 600;
    line-height: 22px;
	margin-bottom: 20px;
}
}
.crm-visitlist .showTitle {
	// margin-top: 2px;
	background-color: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.533rem 0.1rem;
	p {
		color: #969799;
		text-align: left;
		font-weight: 400;
		font-size: 0.647rem;
		line-height: 1.04rem;
		margin-bottom: 0rem;
	}
}

.popup-row {
	.popup-col {
		width: 33%;
		padding-top: 40px;

		span {
			width: 100%;
			justify-content: center;
		}
		&:nth-child(3n + 1) {
			padding-right: 10px;
		}
		&:nth-child(3n + 2) {
			padding-left: 5px;
			padding-right: 5px;
		}

		&:nth-child(3n + 3) {
			padding-left: 10px;
		}
	}
}
.crm-visitlist {
	text-align: left;
	width: 100vw;
    height: 100vh;
    overflow: auto;
	padding-bottom: 6rem;
	.title-class {
	}
	.visit-time {
		margin-top: 4px;
		color: #969799;
		font-size: 12px;
		line-height: 18px;
	}
	.expanded-btn {
		background-color: #fff;
	}
}
</style>

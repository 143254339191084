<template>
	<div class="crm-home">
		<img src="~@/assets/crm/home-bg.png"
				 alt="" />
		<div class="crm-home-body">
			<div class="crm-home-top">
				<h1 class="apply-title">患者自建档</h1>
				<div class="crm-home-content">
					<vue-qr :text="qrData.url"
									:logoSrc="qrData.logo"
									:logoMargin="3"
									:logoCornerRadius="0"
									:size="500"></vue-qr>
				</div>
				<div class="apply-content">
					请向患者展示该二维码，患者扫描二维码可自行完善信息，患者完善信息后，您可以通过平台查看审核患者档案。您也可以通过下面复制按钮，分享给患者。
				</div>
				<van-cell class="crm-list"
									@click="handleList"
									is-link>自建档管理</van-cell>
				<van-button class="crm-round-btn"
										@click="copyLink"
										block>
					复制连接
				</van-button>
			</div>
		</div>
	</div>
</template>

<script>
import VueQr from "vue-qr";
import crmMIxins from "@/utils/crm-mixins";
import avatarImg from "@/assets/images/n-logo.png";
const URL = window.location.origin;
export default {
	mixins: [crmMIxins],
	components: {
		VueQr
	},
	data () {
		return {
			qrData: {
				url: URL,
				logo: avatarImg
			}
		};
	},
	watch: {
		staffInfo: {
			handler: function (val) {
				if (val && val.id) {
					this.qrData.url = `${URL}/archives?saleId=${val.id}`;
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted () { },
	methods: {
		handleList () {
			this.$router.push("/crm/archives");
		},
		copyLink () {
			const createInput = document.createElement("input");
			createInput.value = this.qrData.url;
			document.body.appendChild(createInput);
			createInput.select();
			document.execCommand("Copy");
			createInput.className = "createInput";
			createInput.style.display = "none";
			createInput.remove();
			this.$toast.success("复制成功!");
		}
	}
};
</script>

<style soped lang="less">
.apply-title {
	color: #ffffff;
}

.crm-home .crm-home-body {
	padding-bottom: 0;
}

.apply-content {
	font-size: 28px;
	text-indent: 2em;
	line-height: 50px;
	text-align: left;
	margin-bottom: 30px;
}

.crm-list {
	margin: 20px 0;
	color: #323233;
	font-weight: 600;
	font-weight: 400;
	font-size: 38px;
	line-height: 100px;
	background: #f7f8fa !important;
	border-radius: 99px;
	-webkit-transition: background 0.3s;
	transition: background 0.3s;
	display: flex;
	align-items: center;
}
</style>

<template>
	<div class="home-page">
		<div class="logo-icon"></div>
		<div class="logo-right"></div>
		<cover :survey="surveyInfo"
			@begin-test="beginTest" />
		<agreement :statement="statement"
			ref="coverRef"
			@agree="handlerAgree" />
	</div>
</template>

<script>
	import Cover from "@/components/Cover";
	import Agreement from "@/components/Agreement";
	import myMixins from "@/utils/mixins";
	import api from "@/request/api";
	// import { contentJson } from "./loacl";
	export default {
		name: "home-page",
		mixins: [myMixins],
		components: { Cover, Agreement },
		data () {
			return {
				hasAgree: false,
				resultSurveyInfo: {},
				surveyInfo: {},
				statement: {}
			};
		},
		mounted () {
			const surveyId = this.$route.query.id;
			if (!surveyId) {
				this.$router.replace("/survey/404");
				console.log("跳转到landing page， 因为没有问卷id");
				return false;
			}
			this.autoLogin().then(res => {
				const { users = {} } = res.result;
				let resultInfo = {};
				const userInfoKey = ["created_at", "birthYear", "height", "age", "username", "weight", "source", "sex", "hypertension", "diabetes"];
				this.constant.USER_INFO.forEach(key => {
					if ((users[key] != undefined || (users[key] && users[key].length != 0)) && userInfoKey.includes(key)) {
						if (key === "sex") {
							resultInfo.sex = users[key] === 1 ? "男" : "女";
						} else if (key === "age") {
							resultInfo.age = users.birthYear ? users.birthYear.split("-")[0] || 1990 : 1990;
						} else if (key === "height") {
							resultInfo.height = users.height || null;
						} else if (key === "weight") {
							resultInfo.weight = users.weight || null;
						} else {
							resultInfo[key] = users[key];
						}
					}
				});
				this.$store.dispatch("set_info", {
					age: null,
					height: null,
					weight: null,
					...resultInfo
				});
				this.$store.dispatch("reset_form");
				this.getSurvey();
			}).catch(e => {
				console.log(e)
			});
		},
		methods: {
			getSurvey () {
				const surveyId = this.$route.query.id;
				if (!surveyId) {
					this.$router.replace("/survey/404");
					console.log("跳转到landing page， 因为没有问卷id");
					return false;
				}
				api.querySurvey(surveyId).then(res => {
					if (res.success) {
						try {
							let contentJson = JSON.parse(res.result.contentJson);
							contentJson.title = res.result.title;
							contentJson.time = res.result.duration;
							contentJson.id = res.result.id;
							contentJson.total = res.result.joinCount;
							contentJson.result_function = res.result.answerJson;
							this.resultSurveyInfo = res.result;
							this.surveyInfo = contentJson;
							this.statement = contentJson.statement;

							if (contentJson.statement) {
								this.$refs.coverRef.showModal();
							}

							this.$store.dispatch("set_current_survey", { ...contentJson });
						} catch (error) {
							console.log("问卷信息设置失败", error);
						}
					} else {
						this.$router.replace("/404");
					}
				});
			},
			handlerAgree () {
				this.hasAgree = true;
			},
			beginTest () {
				if (this.hasAgree) {
					this.$router.push({
						path: "/survey/question"
					});
				} else {
					// this.$toast({
					// 	message: `检测前请先同意《隐私协议》及《用户条款》.`,
					// 	position: "bottom",
					// });
					this.$refs.coverRef.showModal();
				}
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");

	.home-page {
		width: 100vw;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
		// background: url("~@/assets/images/bg.png") no-repeat top left;
		background-color: @bg3;
		background-size: 100% 100%;
		position: relative;

		.van-dialog {
			width: 600px;
		}
	}

	.logo-icon {
		position: absolute;
		top: 70px;
		left: 70px;
		width: 115px;
		height: 93px;
		background: url("~@/assets/images/logo.png") no-repeat center center/contain;
	}

	.logo-right {
		position: absolute;
		top: 110px;
		right: 0;
		width: 233px;
		height: 151px;
		background: url("~@/assets/images/home-img.png") no-repeat center center/contain;
	}
</style>

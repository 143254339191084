<template>
	<div class="crm-menu">
		<!-- <van-tabbar v-model="active" @change="onChange" active-color="#333" inactive-color="#B0B0B0"> -->
		<van-tabbar-item v-for="(item, index) in footerMenuInfo" :key="index">
			<span>{{ item.name }}</span>
			<template #icon="props">
				<img v-if="item.component == 'icon.home'" :src="props.active ? icon.home.active : icon.home.inactive" />
				<img v-else-if="item.component == 'icon.patient'" :src="props.active ? icon.patient.active : icon.patient.inactive" />
				<img v-else-if="item.component == 'icon-message'" :src="props.active ? icon.message.active : icon.message.inactive" />
			</template>
		</van-tabbar-item>
		<!-- </van-tabbar> -->
	</div>
</template>

<script>
import api from "@/request/api";
import { mapState } from "vuex";
import { Dialog, Notify ,Toast} from "vant";
import request from "@/request";
export default {
	data() {
		return {
			footerMenuInfo: [],
			url: {
				authMenuList: "/crm-system/sys/mobileMenu/authMenuList",
			},
			show: false,
			active: 0,
			r: ["crm-home", "crm-message", "crm-patient", "crm-create-abnormal"],
			icon: {
				home: {
					inactive: require("@/assets/crm/menu/home.png"),
					active: require("@/assets/crm/menu/active-home.png"),
				},
				message: {
					inactive: require("@/assets/crm/menu/message.png"),
					active: require("@/assets/crm/menu/active-message.png"),
				},
				patient: {
					inactive: require("@/assets/crm/menu/patient.png"),
					active: require("@/assets/crm/menu/active-patient.png"),
				},
			},
		};
	},
	computed: {
		...mapState({
			newMenuInfo: (state) => state.menuInfo,
		}),
	},
	watch: {
		$route: function (a, b) {
			this.update(a);
		},
		newMenuInfo(newVal, oldVal) {
		this.getNewMenu(newVal)
    }
	},
	mounted() {
		// this.update(this.$route);
		// if (this.$store.getters.token) {
		// 	this.$store.dispatch("update_staff_info");
		// 	this.$store.dispatch("update_msg");
		// }
		this.getNewMenu(this.newMenuInfo)
	},
	methods: {
		getNewMenu(type){
			let vm = this;
	  if (type!=null) {
					const data = type.filter((item) => item.name == "底部菜单");
					vm.footerMenuInfo = data ? data[0].child : null;
				} else {
				}
		},
		update(a) {
			this.active = this.r.indexOf(a.name);
			if (this.active > -1) {
				this.show = true;
			} else {
				this.show = false;
			}
		},
		onChange(index) {
			const name = this.r[index];
			this.$router.push({ name });
		},
		// getMenuList() {
		// 	let vm = this;
		// 	request.get(this.url.authMenuList).then((res) => {
		// 		if (res.success) {
		// 			const data = res.result.filter((item) => item.name == "底部菜单");
		// 			vm.footerMenuInfo = data ? data[0].child : null;
		// 			if (res.result == null) {
		// 				Dialog.alert({ title: "提示", message: "当前无权限，请联系管理员添加权限", confirmButtonText: "退出系统" }).then((res) => {
		// 					localStorage.clear();
		// 			window.location.reload();
		// 				});
		// 			}
		// 		} else {

		// 			 Notify({ type: "danger", message: "接口请求时发生错误，请稍后再试。", duration: 4000 });
		// 		}
		// 	});
		// },
	},
};
</script>

<style lang="less">
.crm-bg {
	width: 100%;
	height: 100%;
	background-color: #f2f2f2;
	overflow: auto;
}
.crm-menu {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	align-items: center;

	.van-tabbar {
		height: 100px;
		background: #ffffff;
		box-shadow: 0px -4px 12px rgba(184, 181, 224, 0.25);
	}
	.van-tabbar-item__icon img {
		height: 32px;
		width: 32px;
	}
	.van-tabbar-item__text span {
		font-size: 20px;
	}
}
// next button
</style>

<template>
	<div class="home-page question-page">
		<question-header :tabs="tabs" :currentSurvey="currentSurvey" :parentActive="activeIndex" :pagesLength="pages.length - 1" @select="updateStep" />
		<div class="question-content">
			<div class="question-content-scroll">
				<template v-for="(item, index) in pages">
					<div v-if="index == activeIndex" :key="index" class="base-info">
						<component
							v-for="(child, childIndex) in item.questions"
							:key="`${childIndex}-${index}`"
							:is="`custom-${child.type}`"
							:model="child"
							@input="valueChange($event, child)"
							:value="fd[child.name]"
						/>
						<div class="tac next-btn">
							<span @click="prevPage" v-if="activeIndex > 0 && !!currentSurvey.canBack" class="jh-btn jh-prev">上一步</span>
							<span @click="nexPage" class="jh-btn">下一步</span>
						</div>
					</div>
				</template>
			</div>
		</div>
		<Result ref="ResultRef" />
	</div>
</template>

<script>
import { Notify } from "vant";
import questionHeader from "@/components/question-header/index.vue";
import Result from "@/components/Result";
import {
	customInput,
	customNumberSelect,
	customRadioImg,
	customRadio,
	customSelectScore,
	customGroup,
	customDate,
	customUpload
} from "@/components/form/index";
import api from "@/request/api";
import { mapState } from "vuex";
import { USER_INFO } from "@/constant";

export default {
	components: {
		customUpload,
		questionHeader,
		customInput,
		customNumberSelect,
		customRadioImg,
		customRadio,
		customSelectScore,
		customGroup,
		customDate,
		Result
	},
	data() {
		return {
			activeIndex: 0,
			tabs: [],
			columns: [],
			pages: [],
			fd: {}
		};
	},
	computed: {
		...mapState(["currentSurvey", "formValueData", "formValueScore", "userInfo", "source"])
	},
	mounted() {
		this.fd = { ...this.userInfo, ...this.formValueData };
		if (this.currentSurvey.tabs) {
			this.tabs = this.currentSurvey.tabs;
			this.pages = this.currentSurvey.pages;
		}
	},
	methods: {
		valueChange(value, item) {
			if (item.type === "group") return false;
			if (USER_INFO.includes(item.name)) {
				this.$store.dispatch("set_info", { ...this.userInfo, [item.name]: value });
			} else {
				this.$store.dispatch("set_info_item", { value, key: item.name || item.label });
				this.$store.dispatch("set_score_item", { value: item.score, key: item.name || item.label });
			}
		},
		updateStep(title) {
			const pageTitles = this.pages.map(v => v.title);
			const pageIndex = pageTitles.indexOf(title);
			this.activeIndex = pageIndex;

			const tab = this.$route.query.tab;
			if (tab === title) return false;
			this.$router.replace("/survey/question?tab=" + title);
		},
		deepToSimple(p) {
			const a = [];
			if (!(p instanceof Array)) return [];
			p.map(v => {
				if (!!v.children) {
					a.push(...this.deepToSimple(v.children));
				} else {
					a.push(v);
				}
			});
			return a;
		},
		isRequired() {
			const activeIndex = this.activeIndex;
			const fd = { ...this.fd };
			const pages = [...this.pages][activeIndex];
			let flag = false;
			this.deepToSimple(pages.questions).map(v => {
				if (!!v.required && (fd[v.name] == undefined || fd[v.name].length == 0)) {
					flag = true;
				}
			});
			return flag;
		},
		prevPage() {
			document.querySelector(".question-content-scroll").scrollTop = 0;
			this.activeIndex = this.activeIndex - 1;
			const t = this.$route.query.tab;
			const tab2 = this.pages[this.activeIndex].title;
			if (t === tab2) return false;
			this.$router.replace("/survey/question?tab=" + tab2);
		},
		nexPage() {
			this.fd = { ...this.userInfo, ...this.formValueData };
			if (this.isRequired()) {
				Notify({ type: "danger", message: "请完成内容填写" });
				return false;
			}
			if (this.activeIndex >= this.pages.length - 1) {
				// 最后一组题目
				if (this.currentSurvey.survey_type === 1 && this.currentSurvey.redirect_url) {
					this.submit();
				} else {
					this.$refs.ResultRef.showModal();
				}
			} else {
				document.querySelector(".question-content-scroll").scrollTop = 0;
				this.activeIndex = this.activeIndex + 1;
				const t = this.$route.query.tab;
				const tab2 = this.pages[this.activeIndex].title;
				if (t === tab2) return false;
				this.$router.replace("/survey/question?tab=" + tab2);
			}
		},
		async submit() {
			this.$toast.loading({
				message: "正在提交...",
				forbidClick: true,
				loadingType: "spinner"
			});
			const columns = [];
			let score = 0;
			const pages = this.currentSurvey.pages;
			(pages || []).map(v => {
				columns.push(...this.deepToSimple(v.questions));
			});
			this.columns = columns;
			const updateUser = {
				birthYear: this.userInfo.age ? this.userInfo.age + "-01-01 00:00:00" : null,
				height: this.formValueData.height,
				username: this.formValueData.username,
				weight: this.formValueData.weight,
				source: this.source,
				...this.userInfo,
				sex: isNaN(+this.userInfo.sex) ? (this.userInfo.sex === "男" ? 1 : 0) : this.userInfo.sex
			};
			for (let i in this.formValueScore) {
				score += this.formValueScore[i] || 0;
			}

			await api.updateUserInfo(updateUser);
			await api
				.submitAnswer({
					answers: JSON.stringify({
						...this.formValueData,
						...updateUser
					}),
					columns: JSON.stringify(this.columns.map(v => ({ label: v.label || v.title, name: v.name }))),
					questionId: this.currentSurvey.id,
					source: this.source
				})
				.then(res => {
					if (res.success) {
						this.$store.dispatch("set_score", {
							...this.formValueScore,
							...updateUser
						});
						this.$toast.success("提交成功");
						this.$router.push(this.currentSurvey.redirect_url + "?score=" + score);
					}
				});
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.question-page {
	box-sizing: border-box;
	color: #fff;

	.desc-title;

	.question-content {
		height: calc(100vh - 220px);
		max-width: 100%;
		margin: auto;
		overflow-x: hidden;
		overflow-y: hidden;
	}

	.question-content-scroll {
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.custom-form {
			margin-bottom: 60px;
		}
	}
}

.base-info {
	min-height: 100%;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	margin: 0 5%;
	overflow: hidden;
	padding-bottom: 150px;

	.question-item {
		overflow: hidden;
		margin: 50px 0 0 0;

		&.q-border {
			border-bottom: 1px solid @color5;
		}
	}

	.next-btn {
		position: absolute;
		left: 0;
		display: flex;
		justify-content: center;
		bottom: 48px;
		width: 100%;
	}
}
</style>

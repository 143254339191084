<template>
	<div class="result-page">
		<!-- <div class="result-card success"
			v-if="+archivesValueData.status === 3 || +archivesValueData.status === 4">
			<p class="card-title">已建档</p>
			<p class="card-tip">您的建档已完成，请在需要使用您的档案时通过您的有效证件查询。</p>
		</div>

		<div class="result-card danger"
			v-else-if="+archivesValueData.status === 2">
			<p class="card-title">驳回</p>
			<p class="card-tip">您的建档数据可能存在问题，请确认后修订：</p>
			<div class="card-tip">
				驳回原因：{{archivesValueData.remark}}
			</div>
		</div> -->

		<div class="result-card">
			<p class="card-title">线上登记提交成功，谢谢您的配合！</p>
			<!-- <p class="card-tip">线上登记提交成功，谢谢您的配合！</p> -->
			<!-- <div class="card-tip">
				谢谢您的配合！
			</div> -->
		</div>

		<div class="result-link">
			<van-cell title="我的登记历史"
								is-link
								:value="list.length ? `${list.length}条` : ''"
								@click="linkToList"> </van-cell>
		</div>

		<div class="info-card">
			<div class="info-item">
				<div class="item-label">患者姓名：</div>
				<div class="item-value">
					{{ archivesValueData.name }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">证件类型：</div>
				<div class="item-value">
					{{ cardTypeText }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">{{ archivesValueData.cardType === "身份证" ? "身份证" : "证件号码" }}：</div>
				<div class="item-value">
					{{ archivesValueData.idCard }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">居住地区：</div>
				<div class="item-value">
					{{ sourceText }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">性别：</div>
				<div class="item-value">
					{{ sexText }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">年龄：</div>
				<div class="item-value">
					{{ archivesValueData.age }}
				</div>
			</div>
			<template v-for="(item, index) in cellphone">
				<div class="info-item"
						 :key="item">
					<div class="item-label">{{ index === 0 ? '联系电话：' : `其他号码${index}：` }}</div>
					<div class="item-value">
						{{ item }}
					</div>
				</div>
			</template>
			<div class="info-item">
				<div class="item-label">就诊医院：</div>
				<div class="item-value">
					{{ archivesValueData.hospital }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">就诊科室：</div>
				<div class="item-value">
					{{ archivesValueData.dept }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">医生姓名：</div>
				<div class="item-value">
					{{ archivesValueData.consultant }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">
					<van-popover v-model="showPopover"
											 placement="right-end"
											 theme="dark"
											 trigger="click">
						<div class="decision-desc">当患者病情发生变化时，医疗团队会优先联系系统内所登记的决策人并征询确定后续治疗计划</div>
						<template #reference>
							决策人：
							<van-icon name="question-o" />
						</template>
					</van-popover>
				</div>
				<div class="item-value">
					{{ archivesValueData.decision == 2 ? "家属" : "本人" }}
				</div>
			</div>
			<template v-if="archivesValueData.decision == 2">
				<div class="info-item">
					<div class="item-label">决策人姓名：</div>
					<div class="item-value">
						{{ archivesValueData.decisionName }}
					</div>
				</div>
				<div class="info-item">
					<div class="item-label">与患者关系：</div>
					<div class="item-value">
						{{ decisionRelationText }}
					</div>
				</div>
				<div class="info-item">
					<div class="item-label">联系电话：</div>
					<div class="item-value">
						{{ archivesValueData.decisionCellphone }}
					</div>
				</div>
			</template>
			<div class="info-item">
				<div class="item-label">支付方式：</div>
				<div class="item-value">
					{{ payTypeText }}
				</div>
			</div>
			<!-- <div class="info-item"
				v-if="['bendi', 'yidi'].includes(archivesValueData.payType)">
				<div class="item-label">医保卡号：</div>
				<div class="item-value">
					{{ archivesValueData.medicalNo }}
				</div>
			</div> -->
			<div class="info-item">
				<div class="item-label">宗教信仰：</div>
				<div class="item-value">
					{{ religionText }}
				</div>
			</div>
		</div>
		<div class="submit-box">
			<van-button type="primary"
									color="#5f5aa3"
									@click="submit"
									block>
				{{ ![3, 4].includes(+archivesValueData.status) ? "修订登记信息" : "发起新的登记" }}
			</van-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import { sourceData, card_type, sex, decision_type, patient_relation, religion, pay_type } from "@/constant/archivesQuery";
export default {
	mixins: [crmMixins],
	data () {
		return {
			showPopover: false,
			list: [],
			errList: [],
			nowTime: dayjs().format("YYYY-MM-DD HH:mm")
		};
	},
	computed: {
		...mapGetters(["archivesSurvey", "archivesValueData", "userInfo"]),
		cellphone: function () {
			try {
				return this.archivesValueData.cellphone.length > 11 ? JSON.parse(this.archivesValueData.cellphone) : this.archivesValueData.cellphone
			} catch (error) {
				return this.archivesValueData.cellphone
			}
		},
		sourceText: function () {
			if (this.archivesValueData.source) {
				const item = sourceData.all.find(i => i.id === this.archivesValueData.source) || {};
				// const item = this.addressInfo.all.find(i => i.id === this.archivesValueData.source) || {};
				return item.name || "";
			} else {
				return "";
			}
		},
		sexText: function () {
			if (this.archivesValueData.sex) {
				const item = sex.find(i => i.value + '' === this.archivesValueData.sex + '') || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		payTypeText: function () {
			if (this.archivesValueData.payType) {
				const item = pay_type.find(i => i.value === this.archivesValueData.payType) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		religionText: function () {
			if (this.archivesValueData.religion) {
				const item = religion.find(i => i.value === this.archivesValueData.religion) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		cardTypeText: function () {
			if (this.archivesValueData.cardType) {
				const item = card_type.find(i => i.value === this.archivesValueData.cardType) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		decisionRelationText: function () {
			if (this.archivesValueData.decisionRelation) {
				const item = patient_relation.find(i => i.value === this.archivesValueData.decisionRelation) || {};
				return item.text || "";
			} else {
				return "";
			}
		}
	},
	created () {
		if (this.userInfo && Object.keys(this.userInfo).length) {
			this.init();
		} else {
			this.$router.push({
				path: "/archives",
				query: {
					...this.$route.query,
				}
			});
		}
	},
	methods: {
		init () {
			this.showLoading();
			api.listByUserId().then(resp => {
				this.list = resp.result || [];
				if (this.$route.query.archivesId) {
					const item = this.list.find(i => i.id == this.$route.query.archivesId)
					if (Object.keys(item)) {
						this.$store.dispatch("set_archives_value", {
							...item
						});
					} else {
						this.$router.push({
							path: "/archives/list",
							query: {
								...this.$route.query,
							}
						});
					}
				} else if (this.list.length) {
					this.$store.dispatch("set_archives_value", {
						...this.list[0]
					});
					this.$router.push({
						path: this.$route.path,
						query: {
							...this.$route.query,
							archivesId: this.list[0].id
						}
					});
				} else {
					this.$router.push({
						path: "/archives",
						query: {
							...this.$route.query,
							type: 'create'
						}
					});
				}
				this.hideLoading();
			})
				.finally(() => this.hideLoading());
		},
		sendForm () {
			const params = {
				...this.archivesValueData
			};
			delete params.submitDate;
			delete params.phone;
			delete params.token;
			api.epidemiologicalSave(params).then(() => {
				this.linkToList();
			});
		},
		linkToList () {
			this.$router.push({
				path: "/archives/list",
				query: {
					...this.$route.query
				}
			});
		},
		submit () {
			if ([3, 4].includes(+this.archivesValueData.status)) {
				const params = { ...this.$route.query }
				delete params.archivesId
				this.$router.push({
					path: "/archives",
					query: {
						...params,
						type: 'create'
					}
				});
			} else {
				this.$router.push({
					path: "/archives",
					query: {
						...this.$route.query,
						type: "edit"
					}
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
@import url("~@/variables.less");

.result-page {
	text-align: left;

	.result-link {
		margin: 20px;
		padding: 20px 0;

		>.van-cell {
			font-size: 26px;
			padding: 0;
		}
	}

	.result-card {
		// background-color: @bg5;
		margin: 30px 20px;
		padding: 20px;
		border-radius: 5px;
		color: @bg5;
		font-size: 26px;
		font-weight: 600;

		&.success {
			background-color: @color1;
		}

		&.danger {
			background-color: @danger;
		}

		.card-title {
			font-size: 32px;
			padding: 10px 0 20px;
			display: flex;
			align-items: center;
		}

		.card-tip {
			margin-bottom: 20px;

			.bold-text {
				line-height: 55px;
				display: flex;
				align-items: center;

				label {
					font-size: 28px;
					font-weight: 100;
					display: inline-block;
					width: 160px;
				}

				.bold-text-value {
					font-size: 36px;
					font-weight: 600;
					margin-right: 10px;

					&.effective {
						color: @success;
						font-weight: 800;
					}

					&.lose {
						color: @color4;
						font-weight: 800;
					}
				}

				>span {
					&.effective {
						color: @success;
					}

					&.lose {
						color: @color4;
					}
				}
			}
		}

		.card-option {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @font11;
			padding-bottom: 10px;

			.card-option-label {
				flex: auto;
			}

			.card-option-value {
				padding: 10px 20px;
				margin: 0 20px;
				background-color: @bg5;
				border-radius: 5px;
			}

			&:last-child {
				border: none;
			}
		}
	}

	.info-card {
		border-top: 4px dashed @bg5;
		padding: 30px 20px;
		font-size: 26px;

		.info-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;

			img {
				width: 30%;
				height: auto;
			}
		}
	}

	.submit-box {
		font-size: 26px;
		padding: 30px 30px 40px;
	}
}

.decision-desc {
	padding: 20px 10px;
	box-sizing: border-box;
	line-height: 36px;
	width: 300px;
}
</style>

<template>
	<div class="investigation-page">
		<div class="logo-icon"></div>
		<div class="logo-right"></div>
		<investigation-cover :survey="surveyInfo"
			@begin-test="beginTest" />
		<investigation-agreement :statement="statement"
			ref="coverRef"
			@agree="handlerAgree" />
	</div>
</template>

<script>
	import investigationCover from "./components/investigation-cover";
	import investigationAgreement from "./components/investigation-agreement";
	// import myMixins from "@/utils/mixins";
	import api from "@/request/api";
	import crmMixins from "@/utils/crm-mixins";
	import myMixins from "@/utils/mixins";
	import dayjs from "dayjs";
	import { mapGetters } from "vuex";
	import { investigationQuery } from "@/constant/investigationQuery.js"
	export default {
		name: "investigation-page",
		// mixins: [myMixins],
		mixins: [crmMixins, myMixins],
		components: {
			investigationCover,
			investigationAgreement
		},
		data () {
			return {
				hasAgree: false,
				resultSurveyInfo: {},
				surveyInfo: {},
				statement: {}
			};
		},
		computed: {
			...mapGetters(["userInfo"])
		},
		mounted () {
			document.title = "上海好望角医院入院流调表";
			const surveyId = this.$route.query.id;
			if (!surveyId) {
				this.$router.replace("/survey/404");
				console.log("跳转到landing page， 因为没有问卷id");
				return false;
			} else {
				this.autoLogin().then(() => {
					this.getSurvey();
				});
			}
		},
		methods: {
			init () {
				const effective = this.surveyInfo.effective || 24;
				this.showLoading();
				api.epidemiologicalIndex()
					.then(resp => {
						const LIST = (resp.result || []).reverse();
						if (LIST.length) {
							const item = LIST[0] || {};
							this.$store.dispatch("set_invest_value", { ...item });
							if (
								dayjs(item.submitDate)
									.add(effective, "hour")
									.valueOf() >= dayjs().valueOf()
							) {
								this.$router.push({
									path: "/investigation/result",
									query: {
										...this.$route.query
									}
								});
							} else {
								this.$dialog
									.confirm({
										title: "提示",
										confirmButtonText: "查看最新记录",
										cancelButtonText: "重新填写",
										message: "您有在有效期内容流行病史记录，您可以选择出示有效期内的记录或者重新填写。"
									})
									.then(() => {
										this.$router.push({
											path: "/investigation/result",
											query: {
												...this.$route.query
											}
										});
									})
									.catch(() => {
										this.$router.push({
											path: "/investigation/question",
											query: {
												...this.$route.query
											}
										});
									});
							}
						} else {
							this.$router.push({
								path: "/investigation/question",
								query: {
									...this.$route.query
								}
							});
						}
						this.hideLoading();
					})
					.finally(() => this.hideLoading());
			},
			getSurvey () {
				const surveyId = this.$route.query.id;
				if (!surveyId) {
					this.$router.replace("/survey/404");
					console.log("跳转到landing page， 因为没有问卷id");
					return false;
				} else {
					api.querySurvey(surveyId)
						.then(res => {
							if (res.success) {
								try {
									const contentJson = JSON.parse(res.result.contentJson);
									this.initQuery(contentJson);
									// this.initQuery(investigationQuery);
								} catch (error) {
									console.log("问卷信息设置失败", error);
								}
							} else {
								this.$router.replace("/404");
							}
						})
						.catch(() => {
							this.$store.dispatch("set_invest_survey", {});
						});
				}
			},
			initQuery (content) {
				let contentJson = {};
				contentJson.title = content.title;
				contentJson.duration = content.duration;
				contentJson.time = content.time;
				contentJson.id = content.id;
				contentJson.effective = content.effective;
				contentJson.total = content.joinCount;
				contentJson.statement = content.statement;
				contentJson.result_function = content.answerJson;
				contentJson.questions = content.questions;
				this.resultSurveyInfo = content;
				this.surveyInfo = content;
				this.statement = contentJson.statement;
				if (contentJson.statement) {
					this.$refs.coverRef.showModal();
				}
				this.$store.dispatch("set_invest_survey", { ...contentJson });
				// 已登陆用户直接进入
				if (this.userInfo && this.userInfo.phone) {
					this.init();
				}
			},
			handlerAgree () {
				if (this.userInfo && this.userInfo.phone) {
					this.init();
				} else {
					this.$store.dispatch("set_invest_value", {});
					this.hasAgree = true;
					this.$router.push({
						path: "/investigation/question",
						query: {
							...this.$route.query
						}
					});
				}
			},
			beginTest () {
				if (this.hasAgree) {
					if (this.userInfo && this.userInfo.phone) {
						this.init();
					} else {
						this.hasAgree = true;
						this.$router.push({
							path: "/investigation/question",
							query: {
								...this.$route.query
							}
						});
					}
				} else {
					this.$refs.coverRef.showModal();
				}
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");
	.investigation-page {
		width: 100vw;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
		background: url("~@/assets/images/bg.png") no-repeat top left;
		background-size: 100% 100%;
		position: relative;
		.van-dialog {
			width: 600px;
		}
	}
	.logo-icon {
		position: absolute;
		top: 70px;
		left: 70px;
		width: 115px;
		height: 93px;
		background: url("~@/assets/images/logo.png") no-repeat center center/contain;
	}
	.logo-right {
		position: absolute;
		top: 110px;
		right: 0;
		width: 233px;
		height: 151px;
		background: url("~@/assets/images/home-img.png") no-repeat center center/contain;
	}
</style>

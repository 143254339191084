<template>
	<div class="crm-create crm-bg">
		<van-form ref="formRef">
			<div class="crm-card">
				<div class="crm-card-title">病史详情</div>
				<div class="crm-card-body">
					<CRMDate
						v-model="form.docterTime"
						name="docterTime"
						label="就诊时间"
						:attr="{ required: true }"
						placeholder="请选择就诊时间"
						:rules="[{ required: true, message: '请选择就诊时间' }]"
					/>
					<CRMSelect
						v-model="form.hospital"
						name="hospital"
						label="医院"
						:attr="{ required: true }"
						placeholder="请选择医院"
						:columns="history_hospital"
						:rules="[{ required: true, message: '请选择医院' }]"
					/>
					<CRMSelect
						v-model="form.department"
						name="department"
						label="科室"
						:attr="{ required: true }"
						placeholder="请选择科室"
						:columns="history_department"
						:rules="[{ required: true, message: '请选择科室' }]"
					/>
					<CRMSelect
						v-model="form.picType"
						name="picType"
						label="病史类型"
						:attr="{ required: true }"
						placeholder="请选择病史类型"
						:columns="history_pic_type"
						:rules="[{ required: true, message: '请选择病史类型' }]"
					/>
					<CRMTextarea
						label1="备注"
						:rows="3"
						required
						:attr="{ maxlength: 1000, 'show-word-limit': true, required: true }"
						v-model="form.outRemarks"
						name="remarks"
						:rules="[{ required: true, message: '请填写备注' }, ,]"
						placeholder="请填写备注"
					/>
				</div>
			</div>
			<div class="crm-card mb-80 pb-28">
				<div class="crm-card-title">病史照片</div>
				<div class="crm-card-body">
					<div class="crm-upload">
						<!-- <van-uploader v-model="fileList" :after-read="afterRead" @delete="removeImg" /> -->
						<van-uploader multiple :max-count="6" v-model="fileList" :after-read="uploadSFZ" @delete="removeImg" />
					</div>
				</div>
			</div>
		</van-form>
		<van-button class="crm-btn mb-80" :loading="loading" block @click="submit">提交</van-button>
	</div>
</template>

<script>
import api from "@/request/api";
import TYPES from "@/constant/types";
import RULES from "@/constant/rules";
import CRMDate from "@/components/CRM/SelectDate";
import CRMTextarea from "@/components/CRM/Textarea";
import CRMSelect from "@/components/CRM/Select";
import { Notify } from "vant";
import crmMIxins from "@/utils/crm-mixins";
import ImageCompressor from "@/utils/imageCompressor";

export default {
	name: "crm-create-history",
	mixins: [crmMIxins],
	components: { CRMDate, CRMSelect, CRMTextarea },
	data() {
		return {
			loading: false,
			form: {
				docterTime: "",
				hospital: "",
				department: "",
				picType: "",
				pic: "",
				relationId: "",
				relationType: "",
			},
			fileList: [],
			imgList: [],
		};
	},
	mounted() {
		this.form.relationId = this.$route.params.id;
		this.form.relationType = this.$route.params.type;
	},
	methods: {
		uploadSFZ(files) {
			const self = this;
			// var fd = new FormData();
			// fd.append("type", "idcard");
			// fd.append("file", e.target.files[0]);
			// this.runUpload(fd);
			// this.showLoading();
			console.log("e", files);
			if (files.length) {
				for (const k in files) {
					console.log(k, files[k]);
					this.getJpgFile(files[k].file).then((file) => {
						const options = {
							file: file,
							quality: 1,
							mimeType: "image/jpeg",

							minWidth: 500,
							minHeight: 500,
							redressOrientation: true,
							loose: true,
							// 压缩前回调
							beforeCompress: function (result) {
								console.log("压缩之前图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
							},

							// 压缩成功回调
							success: function (result) {
								var fd = new FormData();
								console.log("转换后:" + result.size);
								// fd.append("type", "idcard");
								fd.append("file", result);
								// fd.append("file", file.file);
								fd.append("biz", self.form.relationId);
								console.log(result, "1");
								console.log("1fd", fd);
								console.log("fd.file", file);
								self.runUpload(fd);

								console.log("压缩之后图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
								console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
							},

							// 发生错误
							error: function (msg) {
								console.error(msg);
							},
						};
						new ImageCompressor(options);
					});
				}
			} else {
				console.log("k", files);
				this.getJpgFile(files.file).then((file) => {
					const options = {
						file: file,
						quality: 1,
						mimeType: "image/jpeg",

						minWidth: 500,
						minHeight: 500,
						loose: true,
						redressOrientation: true,

						// 压缩前回调
						beforeCompress: function (result) {
							console.log("压缩之前图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
						},

						// 压缩成功回调
						success: function (result) {
							var fd = new FormData();
							console.log("转换后:" + result.size);
							// fd.append("type", "idcard");
							fd.append("file", result);
							// fd.append("file", file.file);
							fd.append("biz", self.form.relationId);
							console.log(result, "1");
							console.log("1fd", fd);
							console.log("fd.file", file);
							self.runUpload(fd);

							console.log("压缩之后图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
							console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
						},

						// 发生错误
						error: function (msg) {
							console.error(msg);
						},
					};
					new ImageCompressor(options);
				});
			}
		},
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},
		runUpload(fd) {
			this.showLoading("上传中...");
			fd.status = "uploading";
			fd.message = "上传中...";
			console.log("f11d", fd);
			// this.showLoading("上传中...");
			api.upload(fd).then((res) => {
				this.hideLoading();

				if (res.success) {
					if (res.message) {
						fd.status = "done";
						fd.message = "上传成功";
						this.imgList.push(res.message);
						console.log(this.imgList);
					} else {
						Notify({ type: "danger", message: "图片上传失败！" });
					}
				} else {
					Notify({ type: "danger", message: "图片上传失败！" });
				}
			});
		},
		removeImg(file, detail) {
			// this.imgList = [...this.imgList].splice(detail.index, 1);
			this.imgList.splice(detail.index, 1);
		},
		// removeImg(file, detail) {
		// 	this.imgList = [...this.imgList].splice(detail.index, 1);
		// },
		afterRead(file) {
			console.log(file);
			file.status = "uploading";
			file.message = "上传中...";
			api.upload(file, this.form.relationId).then((res) => {
				if (res.success) {
					file.status = "done";
					file.message = "上传成功";
					this.imgList.push(res.message);
				} else {
					file.status = "failed";
					file.message = "上传失败";
				}
			});
		},
		submit() {
			this.$refs.formRef
				.validate()
				.then(() => {
					if (this.imgList.length == 0) {
						this.$toast.fail("请上传照片");
						return false;
					}
					this.form.pic = this.imgList.join(",");
					this.loading = true;
					if (this.form.id) {
						api.crmMedicalHistoryEdit(this.form).then((res) => {
							if (res.success) {
								this.loading = false;
								this.$router.back();
							} else {
								Notify({ type: "danger", message: "修改病史记录失败！" });
							}
						});
					} else {

						api.crmMedicalHistoryAdd(this.form).then((res) => {
							if (res.success) {
								this.loading = false;
								this.$router.back();
							} else {
								Notify({ type: "danger", message: "添加病史记录失败！" });
							}
						});
					}
				})
				.catch();
		},
	},
};
</script>

<style lang="less"></style>

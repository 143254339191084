import request from "./index.js";
import TYPES from "../constant/types.js";

const SURVEY_API = {
	/**
	 * 自动登录注册
	 * 如果有用户UUID则获取UUID对应用户TOKEN
	 * @param {Object} params = {uuid:''}
	 * @returns
	 */
	autoRegister: (params = {}) => {
		return request.get("/crm-questionnaire/web/config/info", { params }).then(res => {
			if (res.success) {
				localStorage.setItem(TYPES.UUID_KEY, res.result.uuid);
				localStorage.setItem(TYPES.TOKEN_KEY, res.result.token);
			}
			return res;
		});
	},

	/**
	 * 更新用户信息
	 * @param {Object } data = { "birthYear": "", "height": 0, "sex": 0, "username": "", "weight": 0 }
	 * @returns
	 */
	updateUserInfo: (data = {}) => {
		return request.put("/crm-questionnaire/web/users/update", data);
	},

	/**
	 * 提交问卷
	 * @param {Object} data = { "answers": "", "questionId": "" }
	 * @returns
	 */
	submitAnswer: (data = {}) => {
		return request.post("/crm-questionnaire/web/answerRecord/add", data);
	},
	/**
	 * 提交问卷计算后的结果
	 * @param {String} id 问卷id
	 * @param {Object} data 问卷结果
	 * @returns 
	 */
	submitAnswerRecord: (id, data = {}) => {
		return request.post('/crm-questionnaire/web/answerRecord/update/jsonContent?id=' + id, data);
	},

	/**
	 * 通过问卷ID获取问卷
	 * @param {String} id
	 * @returns
	 */
	querySurvey: id => {
		return request.get("/crm-questionnaire/web/questions/queryById?id=" + id);
	},

	// 手机号登录
	mobileLogin: data => {
		return request.post("/jeecg-healthhome/auth/mobile-login", data);
	},
	// 获取手机号验证码
	getMobileCode: data => {
		return request.post("/jeecg-healthhome/auth/get-mobile-code", data);
	},

	// ---------

	// 流调 录入数据
	epidemiologicalSave: data => {
		return request.post("/jeecg-healthhome/epidemiological/save", data);
	},
	// 流调 查询历史录入
	epidemiologicalIndex: data => {
		return request.get("/jeecg-healthhome/epidemiological/index");
	},

	// 上传图片(不用登录)
	uploadFile: (file, biz) => {
		const fd = new FormData();
		fd.append("file", file.file);
		fd.append("biz", biz);
		return request({
			url: "/jeecg-healthhome/healthhome-common/upload",
			method: "post",
			headers: {
				"Content-Type": "multipart/form-data"
			},
			data: fd
		});
	},

	// 基础信息
	patientStore: data => {
		return request.post("/jeecg-healthhome/archive/patient/store", data);
	},
	// 其他信息
	patientOtherStore: data => {
		return request.post("/jeecg-healthhome/archive/patient/other/store", data);
	},
	// 患者建档结果
	uploadInfo: data => {
		return request.post("/jeecg-healthhome/archive/patient/info", data);
	},
	// 患者建档结果
	listByUserId: () => {
		return request.get("/jeecg-healthhome/archive/patient/listByUserId");
	},
	// 患者建档结果
	listByInfo: () => {
		return request.get("/jeecg-healthhome/archive/patient/info");
	},
	// 患者建档结果
	patientEditById: data => {
		return request.post("/jeecg-healthhome/archive/patient/editById", data);
	},
	csmUpdate: data => {
		return request.post("/jeecg-healthhome/archive/patient/csm-update", data);
	},

	// 肖像授权保存
	portraitStore: data => {
		return request.post("/jeecg-healthhome/portrait/authorization/store", data);
	},

	// 微信授权
	wechatLogin: data => {
		return request.post("/jeecg-healthhome/auth/wechat-login", data);
	},

	// 我的答题记录-分页列表查询
	answerRecordList: data => {
		return request.get("/crm-questionnaire/web/answerRecord/list", {
			params: data
		});
	},
};

export default SURVEY_API;

<template>
	<div class="custom-form">
		<div class="form-label">{{ model.label }}</div>
		<div class="input-value">
			<input v-model="inputValue" @input="changeValue" :placeholder="model.placeholder" type="text" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: ""
				};
			}
		},
		value: [String, Number, null]
	},
	data() {
		return {
			inputValue: null
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			if (this.value) {
				this.inputValue = this.value;
			} else {
				this.inputValue = null;
			}
		},
		changeValue() {
			this.$emit("input", this.inputValue, this.model);
		}
	}
};
</script>

<style lang="less">
@import url("~@/variables.less");
@import url("~@/custom-form.less");

.input-value {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0 32px;
	input {
		background: none;
		border: none;
		box-shadow: none;
		border-bottom: 4px solid @font9;
		padding-bottom: 14px;
		text-align: center;
		display: block;
		width: 100%;
		min-width: 240px;
		font-size: 40px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		caret-color: #ffffff;
		line-height: 60px;
		&::placeholder {
			.placeholder-font;
			color: @font9;
			opacity: 0.7;
			text-align: center;
		}
		&:focus {
			border-bottom: 4px solid @font9;
		}
	}
}
</style>

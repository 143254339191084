<template>
	<div class="crm-page" :class="show ? 'has-menu' : ''">
		<router-view />
		<div class="crm-page-menu" v-if="show">
			<van-tabbar v-model="active" @change="onChange" active-color="#333" inactive-color="#B0B0B0">
				<Menu></Menu>
			</van-tabbar>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import { mapState } from "vuex";
import request from "@/request";
import Menu from "@/views/CRM/Menu/index"
export default {
	components: {Menu},
	data() {
		return {
			footerMenuInfo: [],
			show: false,
			active: 0,
			r: ["crm-home", "crm-message", "crm-patient", "crm-create-abnormal"],
			icon: {
				home: {
					inactive: require("@/assets/crm/menu/home.png"),
					active: require("@/assets/crm/menu/active-home.png"),
				},
				message: {
					inactive: require("@/assets/crm/menu/message.png"),
					active: require("@/assets/crm/menu/active-message.png"),
				},
				patient: {
					inactive: require("@/assets/crm/menu/patient.png"),
					active: require("@/assets/crm/menu/active-patient.png"),
				},
			},
		};
	},
	computed: {
		...mapState({
			token_value: (state) => state.token,
		}),
	},
	watch: {
		$route: function (a, b) {
			this.update(a);
		},
		token_value(newVal, oldVal) {
			this.$store.dispatch("get_menu_list");
    }
	},
	mounted() {
		this.update(this.$route);
		console.log("this.$store.getters.token",this.$store.getters.token);
		if (this.$store.getters.token) {
			this.$store.dispatch("get_menu_list");
			this.$store.dispatch("update_staff_info");
			this.$store.dispatch("update_msg");
		}
	},
	methods: {
		update(a) {
			this.active = this.r.indexOf(a.name);
			if (this.active > -1) {
				this.show = true;
			} else {
				this.show = false;
			}
		},
		onChange(index) {
			const name = this.r[index];
			this.$router.push({ name });
		},

	},
};
</script>

<style lang="less">
.crm-bg {
	width: 100%;
	height: 100%;
	background-color: #f2f2f2;
	overflow: auto;
}
.crm-page {
	width: 100%;
	height: 100%;
	overflow: hidden;
	&.has-menu {
		padding-bottom: 100px;
	}
}
.mb-80 {
	margin-bottom: 80px !important;
}
.mb-32 {
	margin-bottom: 32px !important;
}
.mb-24 {
	margin-bottom: 24px !important;
}
.pb-28 {
	padding-bottom: 28px !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.d-fl {
	float: left;
}
.d-fr {
	float: right;
}
// menu
.crm-page-menu {
	.van-tabbar {
		height: 100px;
		background: #ffffff;
		box-shadow: 0px -4px 12px rgba(184, 181, 224, 0.25);
	}
	.van-tabbar-item__icon img {
		height: 32px;
		width: 32px;
	}
	.van-tabbar-item__text span {
		font-size: 20px;
	}
}
// next button
button.crm-round-btn,
button.crm-btn {
	height: 96px;
	background: #5f5aa3;
	border-radius: 32px;
	color: #fff;
	font-size: 32px;
	line-height: 40px;
	white-space: nowrap;
	.van-button__text {
		display: flex;
		align-items: center;
	}
	.btn-icon {
		display: inline-block;
		width: 32px;
		height: 32px;
	}
	.icon-next {
		margin-left: 52px;
		background: url("~@/assets/crm/icon/icon-next.png") no-repeat center center/contain;
		&.icon-tran-180 {
			transform: rotate(180deg);
			margin-right: 52px;
			margin-left: 0;
		}
	}
	.icon-prev {
		margin-left: 52px;
		background: url("~@/assets/crm/icon/icon-prev.png") no-repeat center center/contain;
	}
	.icon-check {
		margin-left: 24px;
		background: url("~@/assets/crm/icon/icon-check.png") no-repeat center center/contain;
	}
	.icon-date {
		margin-left: 24px;
		background: url("~@/assets/crm/icon/icon-date.png") no-repeat center center/contain;
	}
}
.crm-btn-patient,
.crm-btn-inline {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.crm-round-btn {
		margin-right: 48px;
		background-color: #fff;
		border-color: #5f5aa3;
		color: #5f5aa3;
	}
}
.crm-btn-patient {
	.icon-prev {
		margin-left: 0 !important;
		margin-right: 32px;
	}
}
// form card
.crm-card {
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 32px;
	padding: 48px 28px;
	margin-bottom: 32px;
	&.pb-0 {
		padding-bottom: 0;
	}
	&.expend {
		.crm-card-arrow > .crm-card-arrow-icon {
			transform: rotate(180deg);
		}
	}
	&.collapse {
		.crm-card-info {
			margin-bottom: 0;
		}
	}
	.crm-card-title {
		font-weight: 600;
		font-size: 32px;
		line-height: 56px;
		color: #333333;
		text-align: left;
		padding: 0 20px;
		margin-bottom: 32px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.action-title {
			font-family: "PingFang HK";
			font-style: normal;
			font-weight: 400;
			font-size: 28px;
			line-height: 39px;
			text-align: right;
			color: #b0b0b0;
		}
		.crm-card-title__flex {
			display: flex;
			align-items: center;
		}
		.card-card-title-tag {
			margin-left: 10px;
			padding: 2px 10px;
			border-radius: 5px;
		}
	}
	.crm-card-cancel {
		padding: 0 32px;
		display: inline-block;
		font-style: normal;
		font-weight: 400;
		font-size: 28px;
		color: #5f5aa3;
		background: #ffffff;
		border: 2px solid #5f5aa3;
		box-sizing: border-box;
		border-radius: 10px;
	}
	.crm-card-body {
		.van-radio__label,
		input {
			font-size: 28px;
			line-height: 1.5;
		}
		.van-field__label {
			width: 160px;
			line-height: 1.5;
		}
		.crm-date-cell,
		.crm-select-cell,
		> .van-cell {
			background: #f2f2f2;
			border-radius: 12px;
			margin-bottom: 24px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.van-cell {
			padding: 20px 24px;
		}
		.van-cell__title {
			color: #333;
			font-size: 32px;
		}
		.van-field__body > input {
			font-size: 28px;
			color: #777;
		}
	}
	.crm-card-arrow {
		display: block;
		height: 80px;
		width: 100%;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.crm-card-arrow-icon {
			width: 48px;
			height: 48px;
			display: inline-block;
			background: url("~@/assets/crm/icon/icon-arrow-down.png") no-repeat center center/contain;
		}
	}
	.van-radio__icon .van-icon {
		background-color: #fff;
	}
	.van-radio__icon--checked .van-icon {
		background-color: #5f5aa3;
		border-color: #5f5aa3;
	}
	.crm-card-info {
		text-align: left;
		width: 100%;
		padding: 0 20px;
		margin-bottom: 50px;

		p {
			font-weight: 400;
			font-size: 28px;
			line-height: 39px;
			color: #333333;
			margin-bottom: 9px;
		}
		strong {
			display: inline-block;
			width: 220px;
			font-weight: 400;
		}
		&.well {
			font-weight: 400;
			font-size: 28px;
			line-height: 39px;
			color: #b0b0b0;
			background: #f2f2f2;
			border-radius: 12px;
			padding: 24px 20px;
		}
		.crm-tag {
			display: inline-block;
			font-weight: 400;
			font-size: 28px;
			line-height: 39px;
			color: #5f5aa3;
			padding: 12px 32px;
			margin-right: 32px;
			background: #ffffff;
			border: 2px solid #5f5aa3;
			box-sizing: border-box;
			border-radius: 12px;
		}
	}
}
.van-dialog__confirm,
.van-dialog__confirm:active {
	color: #5f5aa3 !important;
}
</style>

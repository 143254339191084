<template>
	<div class="crm-create crm-bg">
		<div class="crm-card">
			<div class="crm-card-title">病史详情</div>
			<div class="crm-card-body">
				<van-field v-model="record.docterTime" readonly name="docterTime" label="就诊时间" placeholder="就诊时间" />
				<van-field v-model="record.hospital" readonly name="hospital" label="医院" placeholder="医院" />
				<van-field v-model="record.department" readonly name="department" label="科室" placeholder="科室" />
				<CRMTextarea
						label1="备注"
						:rows="3"
						:attr="{ maxlength: 1000, 'show-word-limit': true ,required: true}"
						v-model="record.outRemarks"
						name="remarks"
						readonly
						:rules="[{ required: true, message: '请填写备注' }, ,]"
						placeholder="请填写备注"
					/>
			</div>
		</div>
		<div class="crm-card">
			<div class="crm-card-title">病史类型</div>
			<div class="crm-card-body">
				<ImgField :label="record.picType | convertDict(history_pic_type)" :options="record.pic | convertImg" />
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import ImgField from "@/components/CRM/ImgField";
import crmMixins from "@/utils/crm-mixins";
import CRMTextarea from "@/components/CRM/Textarea";
export default {
	name: "crm-history-detail",
	mixins: [crmMixins],
	components: { ImgField,CRMTextarea},
	data() {
		return {
			record: {},
			options: []
		};
	},
	mounted() {
		api.crmMedicalHistoryDetail(this.$route.params.id).then(res => {
			if (res.success) {
				this.record = res.result;
				this.options = res.result.pic.split(",").map(v => api.getImage(v));
			}
		});
	}
};
</script>

<style lang="less"></style>

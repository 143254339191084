export const getAge = identityCard => {
	let len = (identityCard + "").length;
	if (len == 0) {
		return 0;
	} else {
		if (len != 15 && len != 18) {
			//身份证号码只能为15位或18位其它不合法
			return 0;
		}
	}
	let strBirthday = "";
	if (len == 18) {
		//处理18位的身份证号码从号码中得到生日和性别代码
		strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
	}
	if (len == 15) {
		strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
	}
	//时间字符串里，必须是“/”
	let birthDate = new Date(strBirthday);
	let nowDateTime = new Date();
	let age = nowDateTime.getFullYear() - birthDate.getFullYear();
	//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
	if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};


export const getSex = identityCard => {
	let len = (identityCard + "").length;
	if (len == 0) {
		return '';
	} else {
		if (len != 15 && len != 18) {
			//身份证号码只能为15位或18位其它不合法
			return '';
		}
	}
	let strSex = "";
	if (len == 18) {
		//处理18位的身份证号码从号码中得到性别代码
		strSex = identityCard.substr(14, 3);
	}
	if (len == 15) {
		strSex = identityCard.Substring(12, 3);
	}
	return parseInt(strSex)%2 ? "1":"2";
};
<template>
	<div class="submit-list">
		<van-sticky>
			<div class="list-header">答题记录</div>
		</van-sticky>
		<van-list v-model="loading"
			:finished="finished"
			finished-text="没有更多了"
			@load="onLoad">
			<template v-for="(item, index) in list">
				<van-cell :key="index"
					@click="linkTo(item)"
					is-link>
					<template #title>
						{{ dayjs(item.answers.created_at).format("YYYY年MM月DD日 HH:mm") }}
					</template>
				</van-cell>
			</template>
			<template v-if="list.length === 0">
				<van-empty description="暂无记录" />
			</template>
		</van-list>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import dayjs from "dayjs";
	import api from "@/request/api";
	import crmMixins from "@/utils/crm-mixins";
	export default {
		mixins: [crmMixins],
		computed: {
			...mapState(["currentSurvey", "userInfo"])
		},
		data () {
			return {
				dayjs,
				finished: false,
				loading: false,
				page: {
					total: 0,
					pageNo: 0,
					pageSize: 10
				},
				list: [],
				columns: []
			};
		},
		created () {
			// this.init();
			this.getColumns();
		},
		methods: {
			deepToSimple (p) {
				const a = [];
				if (!(p instanceof Array)) return [];
				p.map(v => {
					if (!!v.children) {
						a.push(...this.deepToSimple(v.children));
					} else {
						a.push(v);
					}
				});
				return a;
			},
			getColumns () {
				const columns = [];
				const pages = this.currentSurvey.pages;
				(pages || []).map(v => {
					columns.push(...this.deepToSimple(v.questions));
				});
				this.columns = columns;
			},
			onLoad () {
				this.page.pageNo = this.page.pageNo + 1;
				this.init();
			},
			linkTo (item) {
				this.$store.dispatch("set_info", {
					...this.userInfo,
					...item.answers,
					created_at: item.answers.created_at,
					...item.scoreAnswers
				});
				this.$store.dispatch("set_score", {
					...item.answers,
					created_at: item.answers.created_at,
					...item.scoreAnswers
				});
				this.$router.push({ path: "/survey/result" });
			},
			init () {
				if (this.page.pageNo === 1) {
					this.list = [];
				}
				this.showLoading();
				this.loading = true;
				this.finished = false;
				api.answerRecordList({
					questionId: this.currentSurvey.id,
					id: this.currentSurvey.id,
					...this.page
				})
					.then(resp => {
						const userInfoKey = ["created_at", "birthYear", "height", "age", "username", "weight", "source", "sex", "hypertension", "diabetes"];
						const result = resp.result || {};
						const records = result.records || []
						let resultList = []
						records.forEach(item => {
							const scoreAnswers = {};
							for (const key in item.answers) {
								if (Object.hasOwnProperty.call(item.answers, key) && !userInfoKey.includes(key)) {
									const element = item.answers[key];
									const ITEM = this.columns.find(i => i.name === key);
									if (ITEM && ITEM.options) {
										const rItem = ITEM.options.find(i => i.value === element) || {}
										scoreAnswers[key] = rItem.score || 0;
									}
								}
							}
							item.scoreAnswers = scoreAnswers;
							resultList.push({ ...item })
						});
						this.list = [...this.list, ...resultList];
						this.page.total = result.total || 0;
						this.finished = this.list.length >= this.page.total || this.page.total === 0 || records.length === 0;
					}).catch((e) => {
						console.log(e)
						this.finished = true
					}).finally(() => {
						this.hideLoading();
						this.loading = false;
					});
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");

	.submit-list {
		text-align: left;

		.list-header {
			text-align: center;
			box-shadow: 0px 1px 8px 1px #e1dfdf;
			height: 100px;
			font-size: 32px;
			border-bottom: 2px solid @bg5;
			color: @bg5;
			line-height: 100px;
			background-color: #ffffff;
		}

		> .van-cell {
			font-size: 26px;
		}

		.van-cell__title {
			display: flex;
			align-content: center;

			.item-name {
				margin-left: 10px;
				font-size: 28px;
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>

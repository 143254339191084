<template>
	<div class="page-success">
		<div class="img-box">
			<img src="~@/assets/images/jh_success.png" alt="" />
      <template v-if="$store.getters.wjInfo && $store.getters.wjInfo.isAuth">
        <div class="info-box">
          <h1>签署成功</h1>
          <div>签署人：{{name}}，签署时间：{{created_at}}</div>
        </div>
      </template>
      <template v-else>
        <div class="info-box">
          <h1>提交成功</h1>
          <div v-html="successTip || '您的提交成功'"></div>
        </div>
      </template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment'

export default {
  data() {
    return {
      name: "",
      created_at: ""
    }
  },
	computed: {
		...mapGetters(["successTip"])
	},
  created() {
    this.name = this.$store.getters.wjSubmitData.name
    this.created_at = moment(this.$store.getters.wjSubmitData.created_at * 1000).format("YYYY-MM-DD")
  },
  methods: {

  }
};
</script>

<style lang="less">
.page-success {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.img-box {
		display: inline-block;
		position: relative;
		font-size: 28px;
		color: #999;
		font-weight: bold;
		h1 {
			font-size: 32px;
			color: #333;
			margin-bottom: 20px;
		}
		.info-box {
			width: 100%;
			left: 0;
			padding: 0 10% 20%;
			p {
				padding-bottom: 5px;
				font-weight: 400;
			}
		}
	}
	img {
		display: inline-block;
	}
}
</style>

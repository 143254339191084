<template>
	<div class="custom-form">
		<div class="form-label">{{ model.label }}</div>
		<div class="form-ruler-value">{{ inputValue }}</div>
		<div class="form-ruler" style="transform: translateX(-5%);">
			<cs-ruler
				@post-NumValue="changeValue"
				@scroll-end="endEvent"
				:maxNum="model.max"
				:minNum="model.min"
				:pointerColor="'#FDBC64'"
				:NowNum="rulerModel.default"
			></cs-ruler>
		</div>
	</div>
</template>

<script>
import ruler from "cs-ruler";
export default {
	components: {
		"cs-ruler": ruler.CsingRuler
	},
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					default: 1990,
					min: 1900,
					max: 2100
				};
			}
		},
		value: {
			type: [String, Number, null]
		}
	},
	data() {
		return {
			rulerModel: {
				label: "",
				default: 1990,
				min: 1900,
				max: 2100
			},
			inputValue: null,
			endEventFlag: false
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			this.rulerModel = { ...this.model };
			if (this.value) {
				this.inputValue = this.value;
				this.rulerModel.default = +this.value;
			} else {
				this.inputValue = this.rulerModel.default || null;
			}
		},
		endEvent(val) {
			this.endEventFlag = val;
		},
		changeValue(val) {
			if (this.endEventFlag) {
				this.inputValue = val;
				this.debounce(this.$emit("input", val, this.model), 1200);
			}
		},
		debounce(fn, delay) {
			let timer = null;
			return function() {
				if (timer) clearTimeout(timer);
				timer = setTimeout(fn, delay);
			};
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.form-ruler-value {
	.desc-title;
	padding: 10px 0;
}
</style>

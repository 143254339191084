<template>
	<div class="home-page analysis-page">
		<div class="page-content">
			<div class="analysis-info">
				<van-tag  type="primary" v-if="showInfo">结果</van-tag>
<div v-else>

	<van-tag type="primary" size="large" v-if="showUserInfo.age && showUserInfo.age < new Date().getFullYear() - 18">成人</van-tag>
				<van-tag type="primary" v-else>未成年</van-tag>
				<span class="info-item">
					{{ isNaN(+showUserInfo.sex) ? showUserInfo.sex : +showUserInfo.sex ? "男" : "女" }}
				</span>
				<span class="info-item">{{ new Date().getFullYear() - showUserInfo.age }}岁</span>
				<span class="info-item">{{ showUserInfo.height }}cm</span>
				<span class="info-item">{{ showUserInfo.weight }}kg</span>
</div>

			</div>

			<div class="analysis-card card-after">
				<div class="card-content">
					<div class="content-header">
						<span v-html="resultInfo.desc"></span>
					</div>
					<div class="content-tag">{{ resultInfo.label }}</div>
					<div class="content-label-tip">{{ resultInfo.labelTip }}</div>
					<template v-if="resultInfo.moreLabel">
						<van-divider />
						<div class="check-label" @click="checkLabel">
							<div class="content-tag-tip" v-html="resultInfo.moreLabel"></div>
							<van-icon name="arrow" />
						</div>
					</template>
					<div class="content-desc">{{ resultInfo.content }}</div>
				</div>
			</div>
		</div>

		<div class="submit-list" @click="handleClick">
			<span>
				答题记录
				<van-icon name="arrow" />
			</span>
			<span>
				{{ submitDate }}
			</span>
		</div>

		<div class="analysis-tabs icon-tip" v-if="resultInfo.tabs && resultInfo.tabs.length">
			<van-tabs v-model="activeTab" color="#4265ff">
				<van-tab v-for="(item, index) in resultInfo.tabs" :key="index" :name="index" :title-style="{ fontSize: '16px' }" :title="item.title">
					<div class="tab-content">
						<div v-if="item.customTag === 'dietTyerapy'">
							<p class="dietTyerapy-title">{{ resultInfo.dietTyerapyTitle }}</p>
							<p class="dietTyerapy-tip">{{ resultInfo.dietTyerapyTip }}</p>
						</div>
						<div class="tab-item-card" v-for="(child, key) in item.content" :key="`${key}-${index}`" :style="child.style || ''">
							<p :style="child.titleStyle || ''" class="item-card-title">{{ child.title }}</p>
							<div class="item-card-content">{{ child.content }}</div>
							<template v-if="child.images && child.images.length">
								<van-image
									width="50%"
									fit="contain"
									v-for="(img, imgKey) in child.images"
									:key="imgKey"
									@click="handleTongue([img])"
									:src="img"
								/>
							</template>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 挂号 -->
		<div class="register-card" v-if="resultInfo.registerLink">
			<van-divider />
			<p class="link-title">{{ resultInfo.registerLinkTitle }}</p>
			<a class="register-content" :href="resultInfo.registerLink.link">
				<van-image round width="50" height="50" :src="resultInfo.registerLink.img" />
				<div class="register-right">
					<p class="right-title">
						{{ resultInfo.registerLink.title }}
						<span>{{ resultInfo.registerLink.titleTip }}</span>
					</p>
					<p class="right-subTitle">
						{{ resultInfo.registerLink.subTitle }}
					</p>
					<p class="right-desc">{{ resultInfo.registerLink.desc }}</p>
				</div>
			</a>
		</div>

		<!-- 相关链接 -->
		<div class="foot-links" v-if="resultInfo.links && resultInfo.links.length > 0">
			<p class="link-title">{{ resultInfo.linksTitle }}</p>
			<a v-for="(img, index) in resultInfo.links" width="100%" :key="index" :href="img.link">
				<van-image width="100%" v-for="(img, index) in resultInfo.links" :key="index" :src="img.img" />
			</a>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { ImagePreview } from "vant";
import api from "@/request/api";
// import { resultFc } from "./result_function";
export default {
	computed: {
		...mapGetters(["userInfo", "formValueData", "formValueScore", "currentSurvey", "source"]),
		submitDate: function () {
			const localData = this.formValueScore || this.formValueData;
			return localData.created_at ? dayjs(localData.created_at).format("YYYY年MM月DD日 HH:mm") : dayjs().format("YYYY年MM月DD日 HH:mm");
		},
		showUserInfo: function () {

			return {
				...this.formValueScore,
			 	...this.userInfo,
			};
		},
		showInfo() {
      // 检查 age、sex 和 height 是否都是数字或者是否至少有一个是有效的数字
      return !(
        this.showUserInfo.age &&
       this.showUserInfo.sex &&
      this.showUserInfo.height &&  this.showUserInfo.weight
      )
	},
},
	data() {
		return {
			dividerStyle: {
				color: "#5C89FF",
				borderColor: "#5C89FF",
				padding: "0 16px",
			},
			configData: [],
			show: false,
			// showInfo:false,
			activeTab: 0,
			resultInfo: {
				currentRate: 0,
				label: "平和质",
				labelTip: "",
				desc: "多数人的体质特征不是典型的，兼夹体质为多，根据您的本次检测结果分析，主要体质结果如下：",
				content: "平和质是指阴阳平和，脏腑气血功能正常，先天禀赋良好，后天调养得当之人，对四时寒暑及地理环境适应能力强，患病少",
				date: "2022-02-14 10:11",
				result: "否",
				score: 6,
				risk: 6,
				maintain: "",
				tabs: [],
			},
		};
	},
	mounted() {
		this.computedResult.call(this, this);
	},
	methods: {
		resetHandler() {
			this.$router.replace("/");
		},
		checkLabel() {
			let activeTypeItem = this.configData.find((i) => i.type === this.resultInfo.moreLabelType) || {};
			let moreLabel = this.resultInfo.label;
			let moreLabelType = this.resultInfo.moreLabelType;
			let descMore = this.resultInfo.descMore;
			let desc = this.resultInfo.desc;
			// 切换主要体质兼容体质
			// 主要体质 this.resultInfo.label
			// 兼有体质 this.resultInfo.moreLabelType
			if (this.resultInfo.moreLabel.indexOf("兼有体质") > -1 && moreLabel) {
				// 当前展示的是主要体质
				this.resultInfo.moreLabel = '主要体质<span style="padding: 0 5px;color:#ff9917;display: inline-block;">' + moreLabel + "</span>";
				this.resultInfo.moreLabelType = moreLabel;
				this.resultInfo.descMore = desc;
				this.resultInfo.label = moreLabelType;
				this.resultInfo.desc = descMore;
			} else if (moreLabel) {
				// 当前展示的是兼有体质
				this.resultInfo.moreLabel = '兼有体质<span style="padding: 0 5px;color:#ff9917;display: inline-block;">' + moreLabel + "</span>";
				this.resultInfo.moreLabelType = moreLabel;
				this.resultInfo.descMore = desc;
				this.resultInfo.label = moreLabelType;
				this.resultInfo.desc = descMore;
			}
			this.resultInfo.tabs[0].content = activeTypeItem.zhengzhaung || [];
			this.resultInfo.tabs[1].content = activeTypeItem.imagesTitle || [];
			this.resultInfo.tabs[2].content = activeTypeItem.dietTyerapy || [];
			this.resultInfo.dietTyerapyTip = activeTypeItem.dietTyerapyTip || "";
		},
		computedResult(that) {
			eval(this.currentSurvey.result_function);
			this.sendResult();
			// resultFc(that);
		},
		sendResult() {
			let content = [];
			if (!!this.resultInfo.label) {
				content.push(this.resultInfo.label);
			}
			if (!!this.resultInfo.moreLabelType) {
				content.push(this.resultInfo.moreLabelType);
			}
			if (content.length > 0) {
				api.submitAnswerRecord(this.currentSurvey.id, {
					content: content.join(","),
				});
			}
		},
		handleTongue(images) {
			ImagePreview(images);
		},
		handleClick() {
			this.$router.push({
				path: "/survey-history",
			});
		},
	},
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");
.analysis-page {
	width: calc(100% + 8px);
	position: relative;
	box-sizing: border-box;
	color: @font4;
	text-align: left;
	overflow-x: hidden;
	font-size: 26px;
	.analysis-info {
		display: flex;
		justify-content: flex-start;
		background-color: @bg7;
		margin-top: 40px;
		border-radius: 20px 20px 0 0;
		padding: 20px;
		width: 100%;
		text-align: center;
		align-items: center;
		.info-item {
			display: inline-block;
			padding: 0 10px;
			color: #333333;
			font-size: 26px;
			.info-item-label {
				.desc-item;
				padding: 6px 0;
				color: @font6;
			}
			.info-item-value {
				.desc-title;
				font-size: 20px;
				color: @font6;
			}
		}
	}
	.page-content {
		padding: 0 40px;
	}

	.analysis-card {
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		background-color: @font9;
		margin-bottom: 20px;
		border-radius: 0 0 20px 20px;
		position: relative;
		&.tac {
			text-align: center;
		}
		.card-header {
			display: flex;
			justify-content: space-between;
			.card-date {
				.desc-item;
				padding: 9px;
				background-color: @bg1;
				color: @font9;
				border-radius: 10px;
				margin-bottom: 20px;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					border-top: 20px solid rgba(0, 0, 0, 0);
					border-right: 20px solid rgba(0, 0, 0, 0);
					border-bottom: 20px solid rgba(0, 0, 0, 0);
					border-left: 20px solid @bg1;
					left: 0;
					bottom: -10px;
					transform: rotate(360deg);
				}
			}
			.card-label {
				padding: 9px;
				.desc-item;
				color: @font6;
				img {
					width: 22px;
					vertical-align: inherit;
				}
			}
		}

		.card-content {
			.content-header {
				.desc;
				color: @font3;
				font-size: 28px;
				line-height: 46px;
			}
			.content-tag {
				margin: 30px 0 10px 0;
				text-align: center;
				color: @bg3;
				font-size: 50px;
				font-weight: 500;
			}
			.content-label-tip {
				text-align: center;
				margin-bottom: 40px;
				line-height: 40px;
			}
			.content-tag-tip span {
				display: inline-block;
				padding: 0 5px;
				color: @color3;
				font-size: 28px;
			}
			.content-desc {
				.desc;
				color: @font4;
				line-height: 46px;
			}
		}

		.analysis-card-img {
			width: 390px;
		}

		.chart-box {
			.chart-content {
				.chart-progress {
					width: 70%;
					height: 34px;
					background: #7b92fc;
					border-radius: 10px;
					border-radius: 50%;
					&::-webkit-progress-bar {
						background-color: #7b92fc;
						border-radius: 10px;
					}
					&::-webkit-progress-value {
						background: linear-gradient(270deg, #ff9302 0%, #fde959 100%);
						border-radius: 10px;
					}
				}
			}
		}

		.content-des {
			.desc-result;
			color: @font4;
		}
	}

	.analysis-tabs {
		box-sizing: border-box;
		width: 100%;
		background-color: @font9;
		border-radius: 30px 30px 0 0;
		min-height: 600px;
		position: relative;
		overflow: hidden;
		.item-card-content {
			font-size: 28px;
			margin: 10px 0;
		}
		.tab-content {
			padding: 0 40px 0;
			.tab-item-card {
				padding: 20px;
				margin: 10px 0;
				background-color: @font13;
				border-radius: 10px;
				font-size: 26px;
				.item-card-title {
					font-size: 28px;
					margin-bottom: 10px;
					color: @font1;
					font-weight: 800;
				}
			}
			.dietTyerapy-title {
				font-size: 30px;
				margin: 20px 0;
				color: @font1;
				position: relative;
				padding-left: 20px;
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 10px;
					bottom: 0;
					z-index: 2;
					display: inline-block;
					border-radius: 3px;
					background-color: @font7;
				}
			}
			.dietTyerapy-tip {
				margin: 40px 0;
				font-size: 28px;
				text-indent: 2em;
				line-height: 50px;
			}
		}
	}
	.next-btn {
		position: inherit;
		margin: 50px 0;
	}
}
.progress-circle {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	img {
		display: inline-block;
		margin: 0 auto;
	}
	.progress-circle-box {
		width: 310px;
		height: 310px;
		background-color: #f8fbff;
		border-radius: 50%;
		position: absolute;
		top: 10%;
	}
	.progress-circle-box-center {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.progress-circle-box-center-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 198px;
		height: 198px;
		background: #ffffff;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
		border-radius: 50%;
		font-weight: 500;
		color: #333333;
		font-size: 46px;
		letter-spacing: 3px;
	}
	.van-circle {
		position: absolute;
		transform: scale(1.78) rotate(-120deg);
	}
}
.submit-list {
	padding: 20px 40px;
	color: @font9;
	display: flex;
	justify-content: space-between;
}
.foot-links {
	padding: 20px 40px;
	background-color: #ffff;
	a {
		margin-top: 10px;
		display: block;
	}
}
.register-card {
	background-color: @font9;
	padding: 20px 40px;
	.register-content {
		color: @font1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: @font13;
		padding: 20px;
		border-radius: 10px;
		.register-right {
			padding-left: 30px;
			.right-title {
				.desc-big;
				color: @font1;
				font-weight: 800;
				> span {
					display: inline-block;
					color: @font1;
					font-weight: 400;
				}
			}
			.right-subTitle {
				.desc;
				color: @font1;
			}
			.right-desc {
				color: @font1;
			}
		}
	}
}
.check-label {
	display: flex;
	justify-content: space-between;
}
.link-title {
	margin: 0 0 20px 0;
	font-size: 28px;
	font-weight: 800;
	color: #000;
}
</style>

<style lang="less">
@import url("~@/variables.less");
.tab-content {
	.tongue {
		margin: 20px 0;
		background-color: @bg8;
		color: @font9;
		border-radius: 5px;
		.van-cell__right-icon {
			color: @font9;
		}
	}
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import TYPES from "@/constant/types.js";
import api from "@/request/api";
import {  Notify,Dialog } from "vant";
import wj from '@/store/wj'
const userInfoKey = ["created_at", "birthYear", "height", "age", "username", "weight", "source", "sex", "hypertension", "diabetes"];
Vue.use(Vuex);

let currentSurvey = {
	statement: {}
};
let userInfo = {
	height: 170,
	age: 1990,
	weight: 60
};
let staffInfo = {};
let formValueScore = {};
let formValueData = {};
let investSurvey = {};
let archivesSurvey = {};
let investValueData = {};
let archivesValueData = {};
let token = localStorage.getItem(TYPES.STAFF_TOKEN);
let wechatToken = localStorage.getItem(TYPES.WECHAT_STAFF_TOKEN);

try {
	currentSurvey = JSON.parse(localStorage.getItem(TYPES.CURRENT_SURVEY));
	investSurvey = JSON.parse(localStorage.getItem(TYPES.INVEST_SURVEY));
	archivesSurvey = JSON.parse(localStorage.getItem(TYPES.ARCHIVES_SURVEY));
	userInfo = JSON.parse(localStorage.getItem(TYPES.USER_INFO));
} catch (error) { }

try {
	staffInfo = JSON.parse(localStorage.getItem(TYPES.STAFF_INFO));
} catch (error) { }

try {
	formValueData = JSON.parse(localStorage.getItem(TYPES.FORM_VALUE));
	investValueData = JSON.parse(localStorage.getItem(TYPES.INVEST_VALUE));
	archivesValueData = JSON.parse(localStorage.getItem(TYPES.ARCHIVES_VALUE));
	formValueScore = JSON.parse(localStorage.getItem(TYPES.ARCHIVES_VALUE_SCORE));
} catch (error) { }

export default new Vuex.Store({
	modules: {
		wj
	},
	state: {
		userInfo: {
			height: 170,
			age: 1990,
			weight: 60,
			...userInfo
		},
		formValueData,
		investValueData,
		archivesValueData,
		formValueScore,
		currentSurvey,
		investSurvey,
		archivesSurvey,
		source: "h5",
		staffInfo,
		token,
		count: "",
		successTip: "",
		redirectUrl: "",
		wechatToken,
		menuInfo: null,
	},
	getters: {
		wjSubmitData: state => state.wj.submitData,
		wjInfo: state => state.wj.info,
		menuInfo: state => state.menuInfo,
		successTip: state => state.successTip,
		token: state => state.token,
		wechatToken: state => state.wechatToken,
		userInfo: state => state.userInfo,
		staffInfo: state => state.staffInfo,
		formValueData: state => state.formValueData,
		currentSurvey: state => state.currentSurvey,
		investSurvey: state => state.investSurvey,
		archivesSurvey: state => state.archivesSurvey,
		investValueData: state => state.investValueData,
		formValueScore: state => state.formValueScore,
		archivesValueData: state => state.archivesValueData,
		redirectUrl: state => state.redirectUrl
	},
	mutations: {
		SET_MENU_INFO(state, data) {
			state.menuInfo = data;
		  },
		  CLEAR_STORAGE_AND_RELOAD(state) {
			localStorage.clear();
			window.location.reload();
		  },
		SET_CURRENT_SURVEY (state, payload) {
			state.currentSurvey = payload;
			localStorage.setItem(TYPES.CURRENT_SURVEY, JSON.stringify(payload));
		},
		SET_USER_INFO (state, info) {
			let result = {}
			for (const key in info) {
				if (Object.hasOwnProperty.call(info, key) && userInfoKey.includes(key)) {
					result[key] = info[key];
				}
			}
			state.userInfo = {
				...result
			};
			localStorage.setItem(TYPES.USER_INFO, JSON.stringify(state.userInfo));
		},
		SET_INFO (state, info) {
			console.log(info);
			let result = {}
			for (const key in info) {
				if (Object.hasOwnProperty.call(info, key) && userInfoKey.includes(key)) {
					result[key] = info[key];

				}
			}
			state.userInfo = {
				...result
			};
			state.formValueData = {
				...state.userInfo,
				...info
			};
			localStorage.setItem(TYPES.FORM_VALUE, JSON.stringify(state.userInfo));
		},
		SET_FORM_DATA (state, info) {
			state.formValueData = {
				...info
			};
			localStorage.setItem(TYPES.FORM_VALUE, JSON.stringify(state.formValueData));
		},
		SET_INFO_ITEM (state, { key, value }) {
			state.formValueData = {
				...state.formValueData,
				...{ [key]: value }
			};
		},
		SET_SCORE_ITEM (state, { key, value }) {
			state.formValueScore = {
				...state.formValueScore,
				...{ [key]: value }
			};
			localStorage.setItem(TYPES.ARCHIVES_VALUE_SCORE, JSON.stringify(state.formValueScore));
		},
		SET_SCORE (state, info) {
			state.formValueScore = {
				...state.formValueScore,
				...info
			};
			localStorage.setItem(TYPES.ARCHIVES_VALUE_SCORE, JSON.stringify(state.formValueScore));
		},
		SET_TOKEN (state, token) {
			state.token = token;
			localStorage.setItem(TYPES.STAFF_TOKEN, token);
		},
		MSG_COUNT (state, count) {
			state.count = count;
		},
		// 流调
		SET_INVEST_SURVEY (state, payload) {
			state.investSurvey = payload;
			localStorage.setItem(TYPES.INVEST_SURVEY, JSON.stringify(payload));
		},
		SET_INVEST_DATA (state, info) {
			state.investValueData = {
				...info
			};
			localStorage.setItem(TYPES.INVEST_VALUE, JSON.stringify(state.investValueData));
		},
		// 建档
		SET_ARCHIVES_SURVEY (state, payload) {
			state.archivesSurvey = payload;
			localStorage.setItem(TYPES.ARCHIVES_SURVEY, JSON.stringify(payload));
		},
		SET_ARCHIVES_DATA (state, info) {
			state.archivesValueData = {
				...info
			};
			localStorage.setItem(TYPES.ARCHIVES_VALUE, JSON.stringify(info));
		},
		SET_SUCCESS_TIP (state, info) {
			state.successTip = info
		},
		SET_REDIRECT (state, url) {
			state.redirectUrl = url
		},
		SET_WECHAT_TOKEN (state, token) {
			state.wechatToken = token
		},
		RESET_FORM (state) {
			state.formValueScore = {};
			state.formValueData = {};
			localStorage.setItem(TYPES.FORM_VALUE, JSON.stringify({}))
			localStorage.setItem(TYPES.ARCHIVES_VALUE_SCORE, JSON.stringify({}));
		}
	},
	actions: {
		get_menu_list ({ commit, state }) {
			// 获取用户信息
			api.authMenuList()
				.then(res => {
					if (res.success) {
						commit('SET_MENU_INFO', res.result);
//当用户权限不足时 还需优化
					} else {
	// localStorage.clear();
						Dialog.confirm({
							title: '提示',
							message: '加载菜单遇到了小障碍',
							confirmButtonText:"退出系统",
							cancelButtonText:"重新请求"
						  })
							.then(() => {
								state.token = "";
							localStorage.clear();
							window.location.href = process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login'
							})
							.catch(() => {
								this.dispatch("get_menu_list");
							});

					}
				})
				.catch(e => {
					console.log(e.response);
				});
		},
		reset_form ({ commit }) {
			commit("RESET_FORM");
		},
		// 登录后重定向地址
		set_redirect_url ({ commit }, payload) {
			commit("SET_REDIRECT", payload);
		},
		// 登录后重定向地址
		set_wechat_token ({ commit }, payload) {
			commit("SET_REDIRECT", payload);
		},
		// 设置提交成功文案
		set_success_tip ({ commit }, payload) {
			commit("SET_SUCCESS_TIP", payload);
		},
		// 流调
		set_invest_survey ({ commit }, payload) {
			commit("SET_INVEST_SURVEY", payload);
		},
		set_invest_value ({ commit }, info) {
			commit("SET_INVEST_DATA", info);
		},
		// 建档
		set_archives ({ commit }, info) {
			commit("SET_ARCHIVES_SURVEY", info);
		},
		set_archives_value ({ commit }, info) {
			commit("SET_ARCHIVES_DATA", info);
		},
		// 问卷
		set_info_item ({ commit }, { key, value }) {
			commit("SET_INFO_ITEM", { key, value });
		},
		set_current_survey ({ commit }, payload) {
			commit("SET_CURRENT_SURVEY", payload);
		},
		// 设置用户详情
		set_user_info ({ commit }, payload) {
			commit("SET_USER_INFO", payload);
		},
		// 设置用户详情
		set_info ({ commit }, payload) {
			commit("SET_USER_INFO", payload);
		},
		set_score_item ({ commit }, { key, value }) {
			commit("SET_SCORE_ITEM", { key, value });
		},
		set_score ({ commit }, info) {
			commit("SET_SCORE", info);
		},
		set_token ({ commit }, token) {
			commit("SET_TOKEN", token);
		},
		update_staff_info ({ dispatch, state }) {
			// 获取用户信息
			api.crmGetUserInfo()
				.then(res => {
					if (res.success && res.code == 200) {
						state.staffInfo = res.result.userInfo;
						// 用户信息更新成功后需要更新字典
						dispatch("update_dict_info");
					} else {

						Notify({ type: 'danger', message: res.message , duration: 1000, });
						// localStorage.clear();
						Dialog.confirm({
							title: '提示',
							message: '获取用户信息失败',
							confirmButtonText:"退出系统",
							cancelButtonText:"重新请求"
						  })
							.then(() => {
								state.token = "";
								localStorage.clear();
								window.location.href = process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login'
							})
							.catch(() => {
								this.dispatch("update_staff_info");
							});

					}
				})
				.catch(e => {
					console.log(e.response);
				});
		},
		update_dict_info () {
			// 获取字典库
			api.ajaxGetAllDictItems(Object.keys(TYPES.dict)).then(res => {
				Vue.ls.set(TYPES.UI_CACHE_DB_DICT_DATA, res, 7 * 24 * 60 * 60 * 1000);
			});
			api.getCategoryDict("B08A*");
		},
		save_staff_info ({ dispatch, commit, state }, info) {
			state.staffInfo = info.userInfo;
			commit("SET_TOKEN", info.token);
			dispatch("update_dict_info");
		},
		update_msg ({ commit }) {
			api.getMyAnnouncementSendList({ readFlag: 0, pageNo: 1, pageSize: 1 }).then(res => {
				if (res.success) {
					const c = res.result.total > 100 ? "99+" : res.result.total;
					commit("MSG_COUNT", c);
				}
			});
		}
	}
});

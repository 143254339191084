<template>
	<div class="crm-patient crm-bg">
		<div class="crm-patient-search">
			<van-search v-model="queryParams.keyword" shape="round" background="transparent" placeholder="请输入用户名/手机号/身份证" @keydown.enter="search" />
		</div>
		<div class="crm-patient-body">
			<div class="crm-patient-body-scroll">
				<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->
						<div class="crm-message-card" :class="getClass(item)" v-for="(item, index) in list" :key="index">
							<div class="crm-message-card-top">
								<div class="crm-message-content">
									<van-row type="flex" justify="space-between">
										<van-col>
											<p>
												<strong class="username">{{ item.patientName }}</strong>
												<span class="sex"><SexIcon :sex="item.patientSex" /></span>
												<span class="age">{{ item.patientAge }}岁</span>
											</p>
										</van-col>
										<van-tag class="crm-message-tag" plain size="small" round :color="item.status | statusTag">{{
											item.status_dictText
										}}</van-tag>
									</van-row>
									<p>
										<van-row>
											<van-col :span="12">电话：{{ item.patientCellphone | arrPhone }}</van-col>

											<!-- <van-col :span="12">住院次数：{{ item.inHospitalTotal }}次</van-col> -->
											<van-col v-if="!!item.nsInDate" :span="12">入院时间：{{ item.nsInDate | formatDate("YYYY-MM-DD") }}</van-col>
											<van-col v-if="!item.nsInDate" :span="12">入院时间：{{ item.nsInDate }}</van-col>
											<van-col :span="24">身份证号：{{ item.idCard }}</van-col>
										</van-row>
									</p>
								</div>
							</div>

							<div class="card-button">
								<!-- <van-button @click="jumpPage(`/crm/suspected/${item.patientName}`)" style="border-radius: 10px" plain type="warning" size="mini"
									>疑似患者</van-button
								> -->
								<van-button @click="dialogTest(item)" style="border-radius: 10px" plain type="warning" size="mini">疑似患者</van-button>
								<van-button @click="jumpPage('/crm/create-detail/', item)" plain type="info" round size="mini">修改信息</van-button>
							</div>

							<div class="crm-message-card-bottom">
								<div class="crm-message-msg"></div>
								<div></div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
		<van-dialog v-model="show" title="相同姓名未匹配的患者信息" confirm-button-text="关闭" @confirm="submitHistory">
			<div class="patientInfo">
				<div v-if="applyList.length == 0" class="pb-16">
					<van-empty description="暂无任何入院申请"> </van-empty>
				</div>
				<div class="crm-card pb-0 mb-16 crm-ys" v-for="(item, index) in applyList" :key="index">
					<div class="crm-card-title">
						{{ item.patientName }}
						<span class="sex"><SexIcon :sex="item.patientSex" /></span>
						<span class="age">{{ item.patientAge }}岁</span>
						<span class="age"> <van-button @click="onMatch(item)" plain type="info" round size="mini">一键绑定</van-button> </span>
					</div>
					<div class="crm-card-body">
						<div class="crm-card-info">
							<p><strong>电话号码：</strong>{{ item.patientCellphone | arrPhone }}</p>
							<p><strong>入院时间：</strong>{{ item.inHosTime }}</p>
							<p><strong>身份证号码：</strong>{{ item.idCard }} &nbsp;<van-icon name="description" @click="doCopy(item.idCard)" /></p>
						</div>
					</div>
				</div>
			</div>
		</van-dialog>
		<!-- <van-popup v-model="show" closeable close-icon-position="top-left" position="bottom" round :style="{ height: '30%' }">{{item.patientName}}</van-popup> -->
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-patient",
	mixins: [crmMixins],
	components: { SexIcon },
	data() {
		return {
			show: false,
			applyList: [],
			url: {
				// list: api.crmInHospitalListURL
				list: "/crm-service/crm/crmInHospitalApply/inHospitalList",
			},
			queryParams: {
				column: "update_time",
				order: "desc",
				// macthed: 0,
				nsEndTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				status: "WAIT-IN",
			},
			statusList: [],
			applyId: "",
			zjh: "",
		};
	},
	mounted() {
		// this.loadPage();
	},
	methods: {
		//进入疑似患者页面后,绑定患者id如果 his匹配成功zjh，则不修改idcard，就是不调用edit编辑，也不允许编辑
		//异常患者id
		getPatientDetail(id) {
			let vm = this;
			api.crmDecodeById(id).then((res) => {
				if (res.success) {
					vm.patientFrom = res.result;
					vm.zjh = vm.patientFrom.zjh;
				}
			});
		},
		// 入院信息-得到患者入院申请id
		crmInHospitalApplyInfo(id) {
			let vm = this;
			api.crmInHospitalApplyDetail(id).then((res) => {
				if (res.success) {
					vm.applyId = res.result.id;
				}
			});
		},
		//一键绑定  传入疑似患者入院时间
		onMatch(ysFrom) {
			let vm = this;
			//将ysFrom.inHosTime传给患者
			//弹窗
			this.$dialog
				.confirm({
					title: "提示",
					message: "是否确认匹配用户信息",
				})
				.then(() => {
					const applyModel = {
						nsInDate: ysFrom.inHosTime,
						id: vm.applyId,
					};
					vm.patientFrom.idCard = ysFrom.idCard;

					//得到患者入院申请id
					if (!!vm.zjh) {
						//有zjh  只修改日期   把疑似患者的入院日期给患者拟入院日期
						//患者跟his绑定直接更新住院信息
						api.crmReMatch(applyModel).then((res) => {
							this.hideLoading();
							if (res.success) {
								Notify({ type: "success", message: "患者入院日期更新成功！" });
								this.show = false;
								this.loadPage();
							} else {
								Notify({ type: "danger", message: res.message });
							}
						});
					} else {
						//没有有zjh  先修改身份证号码再修改入院日期,把疑似患者的份证号码给患者份证号码,然后把疑似患者的入院日期给患者拟入院日期
						//获取疑似患者身份证号码,并复制给患者
						//患者未绑定的先更新患者
						api.crmPatientEdit(vm.patientFrom).then((res) => {
							this.hideLoading();
							if (res.success) {
								api.crmReMatch(applyModel).then((res) => {
									this.hideLoading();
									if (res.success) {
										Notify({ type: "success", message: "患者入院日期成功！" });
										this.show = false;
										this.loadPage();
										// this.$router.replace("/crm/create-abnormal");
									} else {
										Notify({ type: "danger", message: res.message });
									}
								});
								Notify({ type: "success", message: "患者基础信息更新成功！" });
							} else {
								Notify({ type: "danger", message: res.message });
							}
						});
					}
				})
				.catch(() => {
					// on cancel
				});
		},

		doCopy(idCard) {
			this.$copyText(idCard)
				.then(() => {
					this.$toast("复制成功");
					// Notify({ type: "success", message: "已成功复制到剪切板" });
				})
				.catch(() => {
					this.$toast.fail("复制失败");
				});
		},
		submitHistory() {
			// Notify({ type: "success", message: "关闭" });
		},
		dialogTest(hzFrom) {
			const model = {
				patientName: hzFrom.patientName,
				macthed: 0,
				status: "IN-HOSPITAL,OUT",
				hospitalId: hzFrom.id,
				nsInDate: hzFrom.nsInDate,
			};
			api.listWithoutPermission(model).then((res) => {
				if (res.success) {
					this.applyList = res.result.records;
				}
			});

			this.getPatientDetail(hzFrom.patientId);
			this.crmInHospitalApplyInfo(hzFrom.id);
			this.show = true;
		},

		getClass(item) {
			const group = item.groupTag;
			return `card-bg-` + group;
		},
		selectedStatus(item) {
			const index = this.statusList.indexOf(item.value);
			if (index > -1) {
				const status = [...this.statusList];
				status.splice(index, 1);
				this.statusList = [...status];
			} else {
				this.statusList.push(item.value);
			}
			this.queryParams.status = this.statusList.join(",");
			this.search();
		},
		search(info) {
			if (info === "all") {
				this.queryParams.status = "";
			}
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-patient {
	display: flex;
	flex-direction: column;
	.crm-patient-body {
		flex: 1;
		position: relative;
		overflow: hidden;
		.crm-patient-body-scroll {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			> .van-pull-refresh {
				height: 100%;
				min-height: 100%;
				padding: 32px;
				overflow-y: auto;
			}
		}
		.crm-message-content {
			padding-left: 0;
		}
	}
}
.crm-patient-search {
	padding: 32px;
	background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 78.43%);
	box-shadow: 0px 2px 12px rgba(95, 90, 163, 0.25);
	position: relative;
	z-index: 1;
	input {
		font-size: 28px;
	}
	.van-search {
		padding: 0;
		margin-bottom: 24px;
	}
	.van-search__content {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	}
	.van-cell {
		align-items: center;
	}
	.van-field__control {
		height: 64px;
		font-weight: 400;
		font-size: 28px;
		line-height: 1.5;
	}
}
.crm-patient-search-tag {
	display: flex;
	flex-direction: row;
	.crm-patient-tag__left {
		width: 100px;
		margin-right: 16px;
		.crm-patient-tag {
			margin: 0;
		}
	}
	.crm-patient-tag__right {
		flex: 1;
		overflow: hidden;
		position: relative;
	}
	.crm-patient-tag {
		white-space: nowrap;
		display: inline-block;
		font-weight: 400;
		font-size: 28px;
		padding: 12px 20px;
		border: 2px solid #8d85ff;
		box-sizing: border-box;
		border-radius: 12px;
		margin: 0 8px;
		&.active {
			background: #8d85ff;
			color: #fff;
		}
	}
	.crm-patient-tag__right-scroll {
		overflow-x: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 120%;
		width: 100%;
		white-space: nowrap;
	}
}
.card-bg-1 {
	background-color: rgba(255, 85, 0, 0.1);
}
.card-bg-2 {
	background-color: rgba(45, 183, 245, 0.1);
}
.card-bg-3 {
	background-color: rgba(135, 208, 104, 0.1);
}
.card-button {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-between;
}
.patientInfo {
	margin-top: 1rem;
	max-height: 50vh;
	overflow: auto;
}
.crm-ys {
	padding-bottom: 1.28rem !important;
}
</style>

<template>
	<div class="custom-form">
		<div class="form-label">{{ model.label }}</div>
		<div class="input-value">
			<input v-model="inputValue" @input="changeValue" :placeholder="model.placeholder" type="text" readonly @click="showPicker = true" />
		</div>
		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker v-bind="datePickerConfig" :value="dateValue" :type="type" :title="title" @confirm="confirm" @cancel="cancel" />
		</van-popup>
	</div>
</template>

<script>
import dayjs from "dayjs";
export default {
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: ""
				};
			}
		},
		type: {
			type: String,
			default: "date"
		},
		datePickerConfig: {
			type: Object,
			default() {
				return {};
			}
		},
		format: {
			type: String,
			default: "YYYY-MM-DD"
		},
		title: {
			type: String,
			default: ""
		},
		value: [String, Number, null]
	},
	data() {
		return {
			dateValue: new Date(),
			showPicker: false,
			inputValue: null
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			if (this.value) {
				this.inputValue = this.value;
			} else {
				this.inputValue = null;
			}
		},
		changeValue() {
			this.$emit("input", this.inputValue, this.model);
		},
		cancel() {
			this.showPicker = false;
		},
		confirm(e) {
			const v = dayjs(e).format(this.format);
            this.inputValue = v;
			this.cancel();
			this.changeValue();
            this.$emit("change", v);
		}
	}
};
</script>

<style scoped lang="less"></style>

<template>
	<div class="normal-form">
		<div class="form-label">
			<span v-if="model.required"
				class="label-required">*</span>
			<span class="label-index"
				v-if="showIndex">
				{{childIndex+1}}.
			</span>
			{{ model.label }}
		</div>
		<div :class="['input-value', , error ? 'error' : '']">
			<input v-model="inputValue"
				@input="changeValue"
				:placeholder="model.placeholder"
				type="number" />
		</div>
		<div class="error-tip"
			v-if="errorTip && error">{{ errorTip }}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			childIndex: Number,
			showIndex: Boolean,
			value: [Number, null]
		},
		data () {
			return {
				updateKey: 1,
				errorTip: "",
				error: false,
				residenceInput: "",
				inputValue: null
			};
		},
		mounted () {
			this.init();
		},
		methods: {
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = null;
				}
				this.updateKey++;
			},
			changeValue () {
				this.resetValidate();
				this.$emit("input", this.inputValue);
				this.$emit("change", this.inputValue);
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");
	.normal-form {
		.input-value {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0;
			input {
				background: none;
				border: none;
				box-shadow: none;
				border-bottom: 1px solid @font11;
				text-align: left;
				display: block;
				width: 100%;
				min-width: 240px;
				font-size: 32px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				line-height: 60px;
				color: @font1;
				caret-color: @font1;
				background-color: @bg6;
				padding: 10px;
				border-radius: 3px;
				&::placeholder {
					.placeholder-font;
					color: @font3;
					font-size: 26px;
					opacity: 0.7;
					text-align: left;
				}
				&:focus {
					border-bottom: 1px solid @font7;
				}
			}
		}
		.item-group {
			margin-bottom: 10px;
		}
	}
</style>

<template>
	<div class="crm-message-card">
		<div class="crm-message-card-top">
			<div class="crm-message-content">
				<p>
					<strong class="username">{{ info.patient.name }}</strong>
					<span class="sex">{{ info.patient.sex_dictText }}</span> <span class="age">{{ info.patient.age }}岁</span
					><span class="date">{{ info.patient.createTime | formatDate }}</span>
				</p>
				<p>病区：南一区</p>
				<p>拟入院时间：2022-01-23</p>
			</div>
		</div>
		<div class="crm-message-card-bottom">
			<div class="crm-message-msg"><span class="dian"></span>患者入院申请待审批</div>
			<a class="crm-message-btn">驳回</a>
		</div>
	</div>
</template>
<script>
import crmMixins from "@/utils/crm-mixins";
export default {
	name: "msg3",
	props: ["item"],
	mixins: [crmMixins],
	data() {
		return {
			info: {
				patient: {},
				apply: {}
			}
		};
	},
	mounted() {
		this.info = JSON.parse(this.item.msgContent || "{msgContent:{}}");
		console.log(this.info);
	}
};
</script>

<template>
	<div class="custom-form">
		<div class="form-label desc"
			v-if="model.label">{{ model.label }}</div>
		<div v-if="model.content"
			class="form-content"
			v-html="model.content"
			:style="model.style"></div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,


				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			value: [String, Number, null]
		},
		data () {
			return {
				inputValue: null
			};
		},
		created () {
			this.init();
		},
		methods: {
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = null;
				}
			},
			changeValue () {
				this.$emit("input", this.inputValue, this.model);
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");
	@import url("~@/custom-form.less");
	.form-label {
		&.desc {
			margin-bottom: 30px;
			.desc-title;
			color: @font1;
			font-size: 30px;
		}
	}
	.form-content {
		overflow: auto;
		border: 1px solid #e4e4e4;
		border-radius: 5px;
		max-height: 40vh;
		padding: 20px 10px;
		font-size: 26px;
	}
</style>

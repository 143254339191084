<template>
	<div class="crm-patient crm-bg">
		<div class="crm-patient-search">
			<div class="crm-search-group">
				<div class="crm-search-group-item">
					<CRMSelect v-model="queryParams.department" placeholder="请选择科室" :columns="history_department" @change="search" />
				</div>
				<div class="crm-search-group-item">
					<CRMSelectDate v-model="queryParams.docterTime" placeholder="日期" @change="search" :datePickerConfig="{ 'max-date': new Date() }" />
				</div>
			</div>
			<div class="crm-search-input">
				<CRMSelect v-model="queryParams.hospital" placeholder="请选择医院名称" :columns="history_hospital" @change="search" />
			</div>
		</div>
		<div class="crm-patient-body">
			<div class="crm-patient-body-scroll">
				<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->
						<div class="crm-message-card" @click="jumpPage('/crm/history-detail/' + item.id)" v-for="(item, index) in list" :key="index">
							<div class="crm-message-card-top">
								<div class="crm-message-content">
									<p>
										<strong class="username">医院：{{ item.hospital }}</strong
										><span class="date d-fr">就诊时间:{{item.createTime| formatDate("YYYY-MM-DD") }}</span>
									</p>
									<p>科室:{{ item.department }}</p>
								</div>
							</div>
							<div class="crm-message-card-bottom">
								<div class="crm-message-msg"></div>
								<div>
									<span class="crm-message-tag">类型{{ item.picType_dictText }}</span>
								</div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
			<div class="fixed-footer">
				<van-button class="crm-btn mb-80" :loading="loading" block @click="jumpPage(`/crm/create-history/${$route.params.id}/patient`)"
					>添加病史</van-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import TYPES from "@/constant/types";
import crmMixins from "@/utils/crm-mixins";
import CRMSelect from "@/components/CRM/Select";
import CRMSelectDate from "@/components/CRM/SelectDate";

export default {
	name: "crm-history-list",
	mixins: [crmMixins],
	components: { CRMSelect, CRMSelectDate },
	data() {
		return {
			loading: false,
			history_department: [{ text: "所有科室", value: "" }, ...api.getDictItemsFromCache(TYPES.dict.history_department)],
			history_hospital: [{ text: "所有医院", value: "" }, ...api.getDictItemsFromCache(TYPES.dict.history_hospital)],
			url: {
				// list: "/crm-service/crm/crmMedicalHistory/list"
				list: "/crm-service/crm/crmInHospitalApply/queryPatientAllMedicalHistory",
			},
		};
	},
	mounted() {
		console.log("this.$route.params", this.$route.params);
		this.queryParams.relationId = this.$route.params.id;
		this.queryParams.patientId = this.$route.params.id;
		this.queryParams.relationType = "patient";
	},
	methods: {
		search() {
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-search-group {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 60px;
	margin-bottom: 24px;
	padding: 0 20px;
	.crm-search-group-item {
		flex: 1;
		border-right: 2px solid rgba(176, 176, 176, 0.6);
		&:last-child {
			border-right: none;
		}
		input {
			display: block;
			width: 100%;
			font-size: 28px;
		}
	}
}
.crm-search-input {
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 60px;
	.van-cell {
		padding: 16px 40px;
		.icon-down {
			background-image: url("~@/assets/crm/icon/icon-search.png");
		}
	}
}
.fixed-footer {
	padding: 0 60px;
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
</style>

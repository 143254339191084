<template>
	<div class="crm-patient crm-inhospital crm-bg "  ref='aaa' >
		<div class="crm-patient-search crm-hos-search" v-show="!isHos">
			<van-search v-model="queryParams.keyword" shape="round" background="transparent" placeholder="请输入用户名/手机号/身份证" @keydown.enter="search" />
			<!-- start -->
			<van-dropdown-menu active-color="#000">
				<van-dropdown-item v-if="isShow" :title="totalValue" disabled />
				<van-dropdown-item v-if="isShow" @click="getInTime" class="in-time-item" title="入院时间" overlay="false">
					<template #title>
						<div class="indate" @click="getTopTime">
							<div class="indate-text">入院时间</div>
							<div class="indate-icon">
								<span :class="[!topValue ? 'top-icon' : 'top-icon icon-active']"></span>
								<span :class="[topValue ? 'bottom-icon' : 'bottom-icon icon-active']"></span>
							</div>
						</div>
					</template>
				</van-dropdown-item>
				<van-dropdown-item @opened="getOpen()" @closed="getClosed()" title="筛选" ref="selectRef">
					<van-cell class="dropdown-cell" title="患者状态">
						<div class="dropdown-item-tag">
							<div v-for="(item, index) in statusList" :key="index">
								<van-tag size="large" color="#5f5aa3" :plain="item.checked" type="primary" @click="getStatus(item, index)">{{
									item.name
								}}</van-tag>
							</div>
						</div>
					</van-cell>
					<van-cell class="dropdown-cell" title="所属院区">
						<div class="dropdown-item-tag">
							<div v-for="(item, index) in areaList" :key="index">
								<van-tag color="#5f5aa3" size="large" :plain="item.areaChecked" type="primary" @click="getArea(item, index)">{{
									item.text
								}}</van-tag>
							</div>
						</div>
					</van-cell>
					<van-cell class="crm-cell-ward dropdown-cell" center title="所属病区">
						<CRMSelect
							v-model="model.inWard"
							name="zzHospital"
							label1="所属病区"
							:attr="{ required: false }"
							placeholder="请选择所属病区"
							:columns="ns_wardList"
							:rules="[{ required: true, message: '请选择所属病区' }]"
						/>
					</van-cell>
					<div class="select-cell">
						<van-cell class="crm-cell dropdown-cell" center title="转诊医院">
							<CRMSelect
								v-model="model.zzHospital"
								name="zzHospital"
								label1="转诊医院"
								:attr="{ required: false }"
								placeholder="请选择转诊医院"
								:columns="history_hospital"
								:rules="[{ required: true, message: '请选择转诊医院' }]"
							/>
						</van-cell>
						<van-cell class="crm-cell dropdown-cell" center title="会诊医生">
							<van-field v-model="model.consultationDoctor" label1="会诊医生" placeholder="请输入会诊医生" />
						</van-cell>
					</div>
					<!-- <div>
    <data-picker
        class="date-input"
        width="100vw"
        height="30px"
        v-model="date"/>
</div> -->
					<!-- <van-cell class="crm-cell dropdown-cell" center title="入院日期">
	<data-picker
						v-model="model.docterTime"
						name="docterTime"
						label1="入院日期"
						:attr="{ required: true }"
						placeholder="请选择入院时间"
						startplaceholder="请选择开始时间"
						endplaceholder="请选择结束时间"
						:rules="[{ required: true, message: '请选择入院时间' }]"
					/></van-cell> -->
					<van-cell class="crm-cell-ward dropdown-cell" title="入院日期" :value="model.inHosTime" @click="inShow = true">
						<van-field v-model="model.inHosTime" label1="入院日期" placeholder="请输入入院时间" />
					</van-cell>
					<van-calendar @opened="opened(e)" :min-date="minDate" :max-date="maxDate" v-model="inShow" type="range" @confirm="inDateConfirm" />
					<van-cell class="crm-cell-ward dropdown-cell" title="出院日期" :value="model.outHosTime" @click="outShow = true">
						<van-field v-model="model.outHosTime" label1="出院日期" placeholder="请输入出院日期" />
					</van-cell>
					<van-calendar @opened="opened(e)" :min-date="minDate" :max-date="maxDate" v-model="outShow" type="range" @confirm="outDateConfirm" />

					<div class="dropdown-btn" style="padding: 5px 16px">
						<van-button type="danger" class="dropdown-btn-left-round" color="#ccc" block @click="onReset"> 重置 </van-button>
						<van-button type="info" class="dropdown-btn-right-round" color="#5f5aa3" block @click="onConfirm"> 完成 </van-button>
					</div>
				</van-dropdown-item>
			</van-dropdown-menu>
			<!-- end -->
			<div></div>
		</div>
		<div ref='wrapper' v-show="!isHos"  class="hos-list">
			<div ref='pullRefresh'>
				<van-pull-refresh ref='list' v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list  class="test" ref='item' v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->

						<div class="crm-message-card" :class="getClass(item)" v-for="(item, index) in list" :key="index">
							<!-- <div class="crm-message-card-top" @click="clickItem"> -->
								<div class="crm-message-card-top" @click="jumpPages('/crm/detail-hospital', item)">
								<div class="crm-message-content">
									<van-row type="flex" justify="space-between">
										<van-col>
											<p>
												<strong class="username">{{ item.patientName }}</strong>
												<span class="sex">
													<SexIcon :sex="item.patientSex" />
												</span>
												<span class="age">{{ item.patientAge }}岁</span>
											</p>
										</van-col>
										<van-tag class="" plain size="small" round :color="item.status | statusTag">{{ item.status_dictText }}</van-tag>
									</van-row>
									<p>
										<van-row>
											<!-- 病区+床位-->
											<van-col :span="12">{{ item.nsArea_dictText }}-{{ item.inWard }}-{{ item.inBed }}</van-col>
											<van-col :span="12">住院天数：{{ item.inDays }} <span v-if="item.inDays">天</span></van-col>
											<van-col :span="12"
												>入院日期：<span v-if="item.inHosTime == null"> - </span>
												<span v-else>
													{{ item.inHosTime | formatDate("YYYY-MM-DD") }}
												</span>
											</van-col>
											<van-col :span="12"
												>出院日期：<span v-if="item.outHosTime == null"> - </span>
												<span v-else>
													{{ item.outHosTime | formatDate("YYYY-MM-DD") }}
												</span>
											</van-col>
											<van-col :span="12"
												>转诊医院：<span>
													{{ item.zzHospital == "other" ? item.zzHospitalOther : item.zzHospital_dictText }}
												</span>
											</van-col>
											<van-col :span="12"
												>会诊医生：<span>
													{{ item.consultationDoctor }}
												</span>
											</van-col>
										</van-row>
									</p>
								</div>
							</div>
							<div class="crm-message-card-bottom">
								<div class="crm-message-msg"></div>
								<div></div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
		<HosFrom ref="hosRef" @ok="modalFormOk"></HosFrom>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import CRMSelect from "@/components/CRM/Select";
import DataPicker from "@/components/CRM/DataPicker";
import HosFrom from "@/views/CRM/InHospitalList/modules/hosFrom.vue";
export default {
	name: "crm-in-hospital",
	mixins: [crmMixins],
	components: { SexIcon, CRMSelect, DataPicker, HosFrom },
	data() {
		return {
			topValue: false,
			value: 0,
			model: {},
			ns_wardList: [],
			key_wardList: [],
			mh_wardList: [],
			ja_wardList: [],
			areaList: [
				{
					text: "全部",
					title: "全部",
					value: "all",
					areaChecked: true,
				},
			],
			minDate: new Date(2010, 0, 1),
			maxDate: new Date(2099, 0, 31),
			totalValue: "共0条",
			show: false,
			inShow: false,
			outShow: false,
			showCalendar: false,
			date: "",
			value: 0,
			switch1: false,
			switch2: false,
			totalOption: [{ text: "共200条", value: 1 }],
			dateOrderOption: [
				{ text: "入院时间", value: 0 },
				{ text: "新款商品", value: 1 },
				{ text: "活动商品", value: 2 },
			],
			//   end
			url: {
				list: api.crmInHospitalListURL,
			},
			queryParams: {
				column: "update_time",
				order: "desc",
				status: "IN-HOSPITAL,OUT",
			},
			statusList: [
				{
					id: 1,
					name: "全部",
					value: "IN-HOSPITAL,OUT",
					checked: true,
				},
				{
					id: 2,
					name: "住院中",
					value: "IN-HOSPITAL",
					checked: true,
				},
				{
					id: 3,
					name: "已出院",
					value: "OUT",
					checked: true,
				},
			],
			isShow: true,
			isHos: false,
			scrolla:9,
		};
	},
	activated() {
			console.log("aaaaaaaaaaaaa",this.$route.meta);
  const scrollTop = this.$route.meta.scrollTop;
  const $wrapper = document.querySelector('.crm-inhospital');
  if (scrollTop && $wrapper) {
    $wrapper.scrollTop = scrollTop;
    console.log("$wrapper.scrollTop ", $wrapper.scrollTop);
  }
},
	mounted() {
		this.crm_hospital_area.map((item) => {
			this.$set(item, "areaChecked", true);
		}),
			(this.areaList = this.areaList.concat(this.crm_hospital_area));
		this.ns_wardList = this.ns_ward;

		this.getdictward();
	},
	methods: {
		jumpPages(path, query) {
            this.$router.push({ path, query })
			localStorage.setItem("savedTabIndex", '住院详情');
        },
		// 获取病区数据字典
		// 获取病区数据字典
		getdictward() {
			api.dictItemList({
				dictId: "1515611450176110594",
				pageSize: 100,
				pageNo: 1,
			}).then((res) => {
				if (res.success) {
					const retainedItems = this.ns_wardList.filter((item) => {
						return !res.result.records.some((i) => {
							return i.status === 0 && i.itemValue === item.value;
						});
					});

					this.ns_wardList = retainedItems;

					this.ja_wardList = this.ns_wardList.filter((item) => {
						return res.result.records.some((i) => {
							return i.status === 1 && i.itemValue === item.value && i.itemKey === "静安院区";
						});
					});

					this.mh_wardList = this.ns_wardList.filter((item) => {
						return res.result.records.some((i) => {
							return i.status === 1 && i.itemValue === item.value && i.itemKey === "闵行院区";
						});
					});

					// 如果 key_wardList 应该是 ns_wardList 的一个引用或副本，可以直接赋值
					this.key_wardList = this.ns_wardList;

					console.log(this.ns_wardList);
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
		},
		getTopTime() {
			this.topValue = !this.topValue;
			this.model.column = "in_hos_time";
			if (!!this.topValue) {
				this.model.order = "asc";
				this.queryParams = Object.assign(this.queryParams, this.model);
				this.loadPage();
			} else if (!this.topValue) {
				this.model.order = "desc";
				this.queryParams = Object.assign(this.queryParams, this.model);
				this.loadPage();
			}
		},
		opened(e) {
			const scrollTop = 0;
		},
		getOpen(e) {
			this.isShow = false;
			const scrollTop = 0;
		},
		getClosed() {
			this.isShow = true;
		},
		getStatus(tag, i) {
			this.statusList.map((item) => {
				if (item.id == tag.id) {
					item.checked = !tag.checked;
				} else {
					item.checked = true;
				}
			});
			if (this.statusList[i].checked === true) {
				this.model.status = null;
			} else {
				this.model.status = tag.value;
			}
		},
		getArea(tag, i) {
			let that = this;
			this.areaList.map((item, index) => {
				if (index == i) {
					item.areaChecked = !tag.areaChecked;
				} else {
					item.areaChecked = true;
				}
			});
			if (this.areaList[i].areaChecked === true) {
				this.model.nsArea = null;
			} else {
				if (tag.value == "all") {
					this.model.nsArea = null;
					that.ns_wardList = that.key_wardList;
				} else if (tag.value == "haowangjiao") {
					this.model.nsArea = tag.value;
					that.ns_wardList = that.ja_wardList;
				} else if (tag.value == "shanghaijinkang") {
					this.model.nsArea = tag.value;
					that.ns_wardList = that.mh_wardList;
				}
			}
		},
		onReset() {
			this.statusList.map((item) => {
				item.checked = true;
			});
			this.areaList.map((item, index) => {
				item.areaChecked = true;
			});
			(this.model = {}), (this.model.column = " update_time");
			this.model.order = "desc";
			this.queryParams = this.model;
			this.loadPage();
			this.$refs.selectRef.toggle();
		},
		onConfirm() {
			delete this.model.outHosTime;
			delete this.model.inHosTime;
			this.queryParams = Object.assign(this.queryParams, this.model);
			this.loadPage();
			this.$refs.selectRef.toggle();
		},
		getInTime() {},
		formatDate(date) {
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		},
		outDateConfirm(date) {
			const [start, end] = date;
			this.outShow = false;
			this.model.outHosStartTime = this.formatDate(start);
			this.model.outHosEndTime = this.formatDate(end);
			this.model.outHosTime = `${this.formatDate(start)} - ${this.formatDate(end)}`;
		},
		inDateConfirm(date) {
			const [start, end] = date;
			this.inShow = false;
			this.model.startTime = this.formatDate(start);
			this.model.endTime = this.formatDate(end);
			this.model.inHosTime = `${this.formatDate(start)} - ${this.formatDate(end)}`;
		},
		getTotal() {
			document.getElementsByClassName("van-dropdown-menu__bar").createElement("div").innerHTML = "<b>qqqq</b>";
			return `totalValue`;
		},
		getClass(item) {
			const group = item.groupTag;
			this.totalValue = "共" + this.total + "条";
			return `card-bg-` + group;
		},
		selectedStatus(item) {
			const index = this.statusList.indexOf(item.value);
			if (index > -1) {
				const status = [...this.statusList];
				status.splice(index, 1);
				this.statusList = [...status];
			} else {
				this.statusList.push(item.value);
			}
			this.queryParams.status = this.statusList.join(",");
			this.search();
		},
		modalFormOk(is) {
			this.isHos = false;
		},
		jumpPop: function (record) {
			(this.isHos = true), this.$refs.hosRef.show(record, true);
		},
		clickItem(item) {
    let wrapperScrollTop = this.$refs.wrapper.scrollTop;
    let pullRefreshScrollTop = this.$refs.pullRefresh.scrollTop;
    let listScrollTop = this.$refs.list.scrollTop;
    let itemScrollTop = this.$refs.aaa.scrollTop;


},
		search(info) {
			this.queryParams.keyword = this.queryParams.keyword.trim();
			if (info === "all") {
				this.queryParams.status = "";
			}
			this.loadPage();
		},

	},
	beforeRouteLeave(to, from, next) {
		console.log('ceshi...=', to, from)
		if(to.name=='crm-detail-hospital'){
			const $wrapper = document.querySelector('.crm-inhospital'); // 列表的外层容器 注意找到滚动的盒子
			const scrollTop = $wrapper ? $wrapper.scrollTop : 0;
			from.meta.scrollTop = scrollTop;
		let itemScrollTop = this.$refs.aaa.scrollTop;
		}else{
			from.meta.scrollTop =0;
			console.log('ceshifrom0000', from.meta.scrollTop)
		}

			next();
		},
		beforeRouteEnter (to, from, next) {

	next();
},
};
</script>

<style lang="less">
.crm-inhospital {
	.crm-cell-ward .icon-down {
		display: none;
	}
	.hos-list{
		margin-top: 8rem;
	}
	.crm-hos-search{
		position: fixed;
		width: 100%;
		position: none;
	}
  overflow-x: hidden;

	.crm-cell-ward.dropdown-cell > .van-cell__value {
		border: 1px solid #ccc;
		border-radius: 10px;
		padding: 0 10px;

		.van-cell {
			padding: 0;
		}
	}

	.select-cell {
		display: flex;

		.dropdown-cell > .van-cell__value {
			border: 1px solid #ccc;
			border-radius: 10px;
			padding: 0 10px;
		}
	}

	.van-popup--top {
		top: -0.0625rem;
	}

	.dropdown-cell {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		align-content: flex-start;
		flex-wrap: nowrap;

		.van-cell__value {
			width: 100%;
		}

		.van-cell__title {
			margin-bottom: 10px;
		}

		&:after {
			border: none;
		}
	}

	.date-cell {
		&:after {
			border: none;
		}
	}

	.van-dropdown-menu {
		.van-dropdown-menu__bar {
			background-color: transparent;
			box-shadow: none;

			// height: 25px;
			.van-dropdown-menu__item:nth-of-type(2) {
				.van-dropdown-menu__title::after {
					border: none;
				}

				.van-dropdown-menu__title--active {
					color: #000;
				}
			}
		}
	}

	.indate {
		display: flex;
	}

	.indate-text {
		padding: 0 8px;
	}

	.indate-icon {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
	}

	.top-icon {
		width: 0;
		height: 0;
		border: 6px solid;
		border-color: transparent transparent #ccc #ccc;
		transform: rotate(135deg);
	}

	.bottom-icon {
		width: 0;
		height: 0;
		border: 6px solid;
		border-color: transparent transparent #ccc #ccc;
		transform: rotate(-45deg);
	}

	.icon-active {
		border-color: transparent transparent #5f5aa3 #5f5aa3;
	}
}

.crm-cell {
	.van-cell {
		padding: 0;
	}
}

.dropdown-btn {
	display: flex;
	justify-content: space-around;

	button {
		// flex: 0.4;
	}

	.dropdown-btn-left-round {
		border-radius: 1.6rem 0 0 1.6rem;
	}

	.dropdown-btn-right-round {
		border-radius: 0rem 1.6rem 1.6rem 0;
	}
}

.van-dropdown-item {
	.van-cell__title {
		text-align: left;
	}
}

.in-time-item {
	.van-dropdown-item {
		display: none;
	}
}

.dropdown-item-tag {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;
	flex-direction: row;
	justify-content: flex-start;

	div {
		padding-right: 25px;
	}
}

.crm-patient {
	display: flex;
	flex-direction: column;

	.crm-patient-body {
		flex: 1;
		position: relative;
		overflow: hidden;

		.crm-patient-body-scroll {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;

			> .van-pull-refresh {
				height: 100%;
				min-height: 100%;
				padding: 32px;
				overflow-y: auto;
			}
		}

		.crm-message-content {
			padding-left: 0;
		}
	}
}

.crm-patient-search {
	padding: 32px;
	background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 78.43%);
	box-shadow: 0px 2px 12px rgba(95, 90, 163, 0.25);
	position: relative;
	z-index: 1;

	input {
		font-size: 28px;
	}

	.van-search {
		padding: 0;
		margin-bottom: 24px;
	}

	.van-search__content {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	}

	.van-cell {
		align-items: center;
	}

	.van-field__control {
		height: 64px;
		font-weight: 400;
		font-size: 28px;
		line-height: 1.5;
	}
}

.crm-patient-search-tag {
	display: flex;
	flex-direction: row;

	.crm-patient-tag__left {
		width: 100px;
		margin-right: 16px;

		.crm-patient-tag {
			margin: 0;
		}
	}

	.crm-patient-tag__right {
		flex: 1;
		overflow: hidden;
		position: relative;
	}

	.crm-patient-tag {
		white-space: nowrap;
		display: inline-block;
		font-weight: 400;
		font-size: 28px;
		padding: 12px 20px;
		border: 2px solid #8d85ff;
		box-sizing: border-box;
		border-radius: 12px;
		margin: 0 8px;

		&.active {
			background: #8d85ff;
			color: #fff;
		}
	}

	.crm-patient-tag__right-scroll {
		overflow-x: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 120%;
		width: 100%;
		white-space: nowrap;
	}
}

.card-bg-1 {
	background-color: rgba(255, 85, 0, 0.1);
}

.card-bg-2 {
	background-color: rgba(45, 183, 245, 0.1);
}

.card-bg-3 {
	background-color: rgba(135, 208, 104, 0.1);
}
</style>

<template>
	<div class="crm-inhospital-detail crm-scroll">
		<!-- inWardFrom.patientName + activeName -->
		<van-nav-bar @click-left="onClickLeft">
			<template #title>
				<span class="bar-title" @click="synchronizeData">{{ inWardFrom.patientName + "-" + activeName }} <van-icon name="replay" /></span>
			</template>
			<template #left>
				<van-icon name="arrow-left" size="18" />
			</template>
		</van-nav-bar>
		<van-tabs v-model="activeName" @click="onClick">
			<van-tab v-for="(item, index) in isShowTab" :key="index" :title="item.name" :name="item.name" >
				<inWardDetail v-if="item.name == '住院详情'" :inWardFrom="inWardFrom"></inWardDetail>
				<JyList v-else-if="item.name == '检验报告'" :inWardFrom="inWardFrom"> </JyList>
				<JcList v-else-if="item.name == '检查报告'" :inWardFrom="inWardFrom"> </JcList>
				<visitList v-else-if="item.name == '探访记录'" :inWardFrom="inWardFrom"> </visitList>
				<DSList v-else-if="item.name == '电子病例'" :inWardFrom="inWardFrom"></DSList>
			</van-tab>
			<!-- <van-tab title="住院详情" name="住院详情" @click="onClick"><inWardDetail :inWardFrom="inWardFrom"></inWardDetail></van-tab>
			<van-tab title="检验报告" name="检验报告" @click="onClick"><JyList :inWardFrom="inWardFrom"> </JyList></van-tab>
			<van-tab title="检查报告" name="检查报告" @click="onClick"><JcList :inWardFrom="inWardFrom"> </JcList></van-tab>
			<van-tab title="探访记录" name="探访记录" @click="onClick"><visitList :inWardFrom="inWardFrom"> </visitList></van-tab> -->
		</van-tabs>
	</div>
</template>

<script>
import crmMIxins from "@/utils/crm-mixins.js";
import ImgField from "@/components/CRM/ImgField";
import SexIcon from "@/components/CRM/SexIcon";
import StatusIcon from "@/components/CRM/StatusIcon";
import CRMTextarea from "@/components/CRM/Textarea";
import inWardDetail from "@/views/CRM/InHospitalList/inWardDetail.vue";
import JyList from "@/views/CRM/InHospitalList/jyList.vue";
import DSList from "@/views/CRM/InHospitalList/DSList.vue";
import JcList from "@/views/CRM/InHospitalList/jcList.vue";
import visitList from "@/views/CRM/InHospitalList/visitList.vue";
import api from "@/request/api";
import request from "@/request";
import { Notify } from "vant";
import { format } from "path";
import VClamp from "vue-clamp";
import { mapState } from "vuex";

export default {
	mixins: [crmMIxins],
	// name: 'crm-detail-hospital',
	components: { visitList, DSList, ImgField, JcList, SexIcon, StatusIcon, VClamp, CRMTextarea, inWardDetail, JyList },
	data() {
		return {
			url: {
				authMenuList: "/crm-system/sys/mobileMenu/authMenuList",
			},
			activeName: "住院详情",
			inWardFrom: [],
			isShowTab: [],
		};
	},
	computed: {
		...mapState({
			newMenuInfo: (state) => state.menuInfo,
		}),
	},
	watch: {
    newMenuInfo(newVal, oldVal) {
		this.getNewMenu(newVal)
    },
	activeName(newVal, oldVal) {
	const tabName=	localStorage.getItem("savedTabIndex",);
	console.log("aaaa",newVal, oldVal,tabName);
	if(newVal!=tabName){
		sessionStorage.removeItem("detailPageNo");
sessionStorage.removeItem("listInfo");
console.log("eeeeee");
sessionStorage.removeItem("tabScrollTop");
	}
    }
  },

	mounted() {
		let that = this;
		this.inWardFrom = this.$route.query;
		const savedTabIndex = localStorage.getItem("savedTabIndex");
		if (savedTabIndex) {
			that.activeName = savedTabIndex;
		}
		// this.getMenuList();
		this.getNewMenu(this.newMenuInfo)
	},
	methods: {
		synchronizeData() {
			let vm = this;
			api.crmPatientHomeHis(vm.inWardFrom.idCard ).then((res) => {
				if (res.success) {
                    Notify({ type: "success", message: res.message });
                    vm.num=vm.num+1
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
		},
		getNewMenu(type){
			let vm = this;
			if (type!=null) {
					this.getUrl(type, "/crm/in-hospital");
					vm.isShowTab = this.getUrl(type, "/crm/in-hospital").child;

				} else {
					// Notify({ type: "danger", message: res.message });
				}

		},

		getUrl(array, url) {
			for (let i = 0; i < array.length; i++) {
				let obj = array[i];
				if (obj.url === url) {
					return obj;
				}
				if (obj.child) {
					let result = this.getUrl(obj.child, url);
					if (result) return result;
				}
			}
			return null;
		},

		getMenuList() {
			let vm = this;
			request.get(this.url.authMenuList).then((res) => {
				if (res.success) {
					this.getUrl(res.result, "/crm/in-hospital");
					vm.isShowTab = this.getUrl(res.result, "/crm/in-hospital").child;

				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
		},
		onClickLeft() {
			this.$router.go(-1);

sessionStorage.removeItem("detailPageNo");
sessionStorage.removeItem("listInfo");
sessionStorage.removeItem("tabScrollTop");
			// this.$router.push({ path: '/crm/in-hospital' })
		},
		onClick(name, title) {
			sessionStorage.removeItem("detailPageNo");
sessionStorage.removeItem("listInfo");
sessionStorage.removeItem("tabScrollTop");
			localStorage.setItem("savedTabIndex", this.activeName);
		},
	},
};
</script>

<style lang="less">
@import url("~@/variables.less");

.crm-inhospital-detail {
	background-color: #f2f2f2;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	.van-hairline--bottom:after {
		border-bottom-width: 0px;
	}
	.van-tabs__wrap {
		border-bottom: 1px #f2f2f2 solid;
		&:after {
			border-bottom-width: 1px;
		}
	}
	.bar-title {
		font-weight: 600;
	}
	p {
		text-align: left;
		font-weight: 400;
		font-size: 0.747rem;
		padding: 0.02rem 0.533rem;
		line-height: 1.04rem;
		color: #333333;
		margin-bottom: 0.24rem;
	}
}
</style>

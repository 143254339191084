import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Vant from 'vant';
import Storage from 'vue-ls'
import config from '@/config'
import './utils/rem';
import vueWatermark from 'vue-watermark-directive'
import VueClipboard from 'vue-clipboard2'
import * as constant from "./constant"

import "core-js/stable";
import "regenerator-runtime/runtime";
import "vant/lib/index.css"
import "./variables.less";
import "./style.less";
import "./registerServiceWorker";

const DEFAULT_CONFIG = {
	zIndex: 1000,
	font: '16px microsoft yahei',
	color: 'rgba(0,0,0,0.09)',
	content: '健恒医疗 CRM 系统',
	rotate: 20,
	width: 400,
	height: 150,
	repeat: true
  }

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.use(vueWatermark, DEFAULT_CONFIG)
Vue.use(Storage, config.storageOptions)
Vue.prototype.constant = constant;
Vue.use(VueClipboard)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");

<template>
	<div class="crm-bg crm-create">
		<van-form ref="vanFormRef1">
			<div class="crm-card" v-if="step == 0">
				<div class="crm-card-title">基础信息</div>
				<div class="crm-card-body">
					<van-field
						v-model="form.name"
						required
						name="username"
						label="姓名"
						placeholder="姓名"
						:rules="[{ required: true, message: '请填写姓名' }]"
					/>

					<!-- <van-field
						v-if="form.cardType === '身份证'"
						v-model="form.idCard"
						name="idCard"
						label="身份证号"
						required
						placeholder="输入身份证号"
						maxlength="18"
						@blur="updateAge"
						:rules="[
							{ required: true, message: '请填写身份证号' },
							{ validator: RULES.idCard, message: '请填写正确的身份证号' }
						]"
					/> -->
					<van-field
						:readonly="readonly"
						v-model="form.idCard"
						name="idCard"
						label="证件号码"
						required
						placeholder="输入证件号码"
						:rules="[{ required: true, message: '请填写证件号码' }]"
					/>
					<CRMSelect
						v-model="form.sex"
						name="sex"
						label="性别"
						:readonly="form.sex !== null"
						:attr="{ required: true }"
						placeholder="选择性别"
						:columns="sex"
						:rules="[{ required: true, message: '请选择性别' }]"
					/>
					<!-- <van-field
						readonly
						v-model="this.sex_dictText"
						type="digit"
						name="sex"
						label="性别"
						required
						maxlength="3"
						placeholder="请输入性别"
						:rules="[{ required: true, message: '请填写性别' }]"
					/> -->
					<van-field
						readonly
						v-model="form.age"
						type="digit"
						name="age"
						label="年龄"
						required
						maxlength="3"
						placeholder="请输入年龄"
						:rules="[{ required: true, message: '请填写年龄' }]"
					/>
				</div>
			</div>
		</van-form>
		<!-- 表2 -->
		<van-form ref="vanFormRef2">
			<div class="crm-card mb-80">
				<div class="crm-card-title">入院信息</div>
				<div class="crm-card-body">
					<DateTime
					v-model="applyList.nsInDate"
					name="nsInDate"
						label="入院日期"
						:attr="{ required: true }"
						placeholder="入院日期"
						:rules="[{ required: true, message: '请选择入院日期' }]"
					/>

					 <RYRadio
						v-model="applyList.zzInType"
						name="zzInType"
						label="入院方式"
						:options="in_hos_type"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择入院方式' }]"
					/>
					<RYRadio
						v-model="applyList.zzPlanInArea"
						name="zzPlanInArea"
						label="拟入院区"
						:options="crm_hospital_area"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择拟入院区' }]"
					/>
				</div>
			</div>
		</van-form>

		<div class="crm-btn-inline mb-32">
			<van-button class="crm-btn" v-if="!!form.id" :loading="loading" block @click="save">保存</van-button>
		</div>
	</div>
</template>

<script>
import CRMSelect from "@/components/CRM/Select/index.vue";
import api from "@/request/api";
import RULES from "@/constant/rules";
import { Notify } from "vant";
import crmMixins from "@/utils/crm-mixins";
import heic2any from "heic2any";
import { mapState } from "vuex";
// import CRMRadio from "@/components/CRM/Radio";
 import RYRadio from "@/components/CRM/ryRadio";
import CRMDate from "@/components/CRM/SelectDate";
import DateTime from "@/components/CRM/DataTime"
import CRMCheckbox from "@/components/CRM/Checkbox";
import CRMTextarea from "@/components/CRM/Textarea";
import ImageCompressor from "@/utils/imageCompressor";
import request from "@/request/index.js";

export default {
	name: "create-patient",
	mixins: [crmMixins],
	components: { CRMSelect, RYRadio ,CRMDate,DateTime},
	data() {
		return {
			step: 0,
			loading: false,
			open: false,
			RULES,
			readonly: false,
			sex_dictText:"",
			// 	format: {
			// 	type: String,
			// 	default: "YYYY-MM-DD HH:mm:ss"

			// },
			file: null,
			userArr: [],
			applyList: {
				zzPlanInArea: "",
				zzInType: "",
				//  inHosTime: this.format,
				nsInDate:"",
			},

			form: {
				name: "",
				idCard: "",
				sex: "",
				sex_dictText: "",
				age: "",
				cellphone: "",
				decision: "",
				decisionName: "",
				decisionRelation: "",
				decisionCellphone: "",
				payType: "",
				religion: "",
				source: "",
				sourceText: "",
			},
			cellphone: {},
			provinceList: [],
			patientFrom: {},
		};
	},
	computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),
	},
	watch: {
		form: {
			handler: function (a) {},
			deep: true,
		},
	},
	mounted() {
		let vm = this;
		const id = this.$route.query.patientId;
		const inId = this.$route.query.id;
		this.getPatientDetail(id);
		this.crmInHospitalApplyInfo(inId);
	},
	methods: {
		// showHide(item) {
		// 	item.is_show = !item.is_show;
		// this.$forceUpdate();
		// },
		// 入院信息
		crmInHospitalApplyInfo(id) {
			api.crmInHospitalApplyDetail(id).then((res) => {
				if (res.success) {
					this.applyList = res.result;
					console.log("this.applyList", this.applyList);
				}
			});
		},
		//异常患者id
		getPatientDetail(id) {
			let vm = this;
			api.crmDecodeById(id).then((res) => {
				if (res.success) {
					vm.patientFrom = res.result;
					if (this.patientFrom.id) {
						this.form = { ...this.form, ...this.patientFrom };
						if(this.form.sex =='1'){
							this.sex_dictText="男"
						}else{
							this.sex_dictText="女" ;
						}

						const zjh = this.form.zjh;
						if (!!zjh) {
							this.readonly = true;
							this.$toast({
								message: '请您修改入院日期',
								position: 'top',
								});
							// this.$toast('请您修改入院日期');
							// Notify({ type: "danger", message: "请您修改入院日期" });
						}else{
							this.$toast({
								message: '请您修改身份证号码以及入院日期',
								position: 'top',
								});
							// Notify({ type: "danger", message: "请您修改身份证号码以及入院日期" });
						}
						if (!!this.patientFrom.cellphone) {
							try {
								const phones = JSON.parse(this.patientFrom.cellphone);
								phones.map((v, i) => {
									if (i === 0) {
										this.form.cellphone = v;
									} else {
										this.cellphone[i.toString()] = v;
									}
								});
								this.$forceUpdate();
							} catch (error) {}
						}
					}
				}
			});
		},
		cellphoneBlur(e, item) {
			this.$forceUpdate();
		},
		addCellPhone() {
			const len = Object.keys(this.cellphone).length;
			if (len >= 4) {
				this.$toast.fail("最多允许5个联系电话");
				return false;
			}
			this.cellphone[(len + 1).toString()] = "";
			this.$forceUpdate();
		},
		subCellPhone(key) {
			console.log(key);
			delete this.cellphone[key];
			this.$forceUpdate();
		},
		getAge(identityCard) {
			var len = (identityCard + "").length;
			if (len == 0) {
				return 0;
			} else {
				if (len != 15 && len != 18) {
					//身份证号码只能为15位或18位其它不合法
					return 0;
				}
			}
			var strBirthday = "";
			if (len == 18) {
				//处理18位的身份证号码从号码中得到生日和性别代码
				strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
			}
			if (len == 15) {
				strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
			}
			//时间字符串里，必须是“/”
			var birthDate = new Date(strBirthday);
			var nowDateTime = new Date();
			var age = nowDateTime.getFullYear() - birthDate.getFullYear();
			//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
			if (
				nowDateTime.getMonth() < birthDate.getMonth() ||
				(nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
			) {
				age--;
			}
			return age;
		},
		updateAge() {
			if (this.RULES.idCard(this.form.idCard)) {
				this.form.age = this.getAge(this.form.idCard);
				this.$forceUpdate();
			}
		},

		prev() {
			this.step = 0;
		},
		next() {
			console.log(this.form);
			this.$refs.vanFormRef1
				.validate()
				.then(() => {
					this.step = 1;
				})
				.catch();
		},
		submit(next) {
			const fd = { ...this.form };
			const rycz = {
				nsInDate: this.applyList.nsInDate,
				id: this.applyList.id,
			};
			if (fd.decision == 1) {
				fd.decisionName = "";
				fd.decisionRelation = "";
				fd.decisionCellphone = "";
			}

			if (Object.keys(this.cellphone).length > 0) {
				const phones = [fd.cellphone];
				Object.keys(this.cellphone).map((v) => {
					const p = this.cellphone[v];
					if (p.length > 0) {
						phones.push(p);
					}
				});
				fd.cellphone = JSON.stringify(phones);
			}
			this.showLoading();

			if (fd.id) {
				this.$dialog
					.confirm({
						title: "提示",
						message: "是否确认信息修改！",
					})
					.then(() => {
							//start
							if (fd.zjh == null) {
								//患者未绑定的先更新患者
								api.crmPatientEdit(fd).then((res) => {
									this.hideLoading();
									if (res.success) {
										api.crmReMatch(rycz).then((res) => {
											if (res.success) {
												Notify({ type: "success", message: "患者入院日期成功！" });

												this.$router.replace("/crm/create-abnormal");
											} else {
												Notify({ type: "danger", message: res.message });
											}
										});
										Notify({ type: "success", message: "患者基础信息成功！" });
										this.$router.replace("/crm/create-abnormal");
										// this.$router.go(0)
									} else {
										Notify({ type: "danger", message: res.message });
									}
								});
							}else{
								//患者跟his绑定直接更新住院信息
								api.crmReMatch(rycz).then((res) => {
									this.hideLoading();
									if (res.success) {
										Notify({ type: "success", message: "患者入院日期成功！" });

										this.$router.replace("/crm/create-abnormal");
									} else {
										Notify({ type: "danger", message: res.message });
									}
								});
							}
							//end
					})
					.catch(() => {
						this.hideLoading();
					});
			} else {
				api.crmPatientAdd(fd)
					.then((res) => {
						this.hideLoading();
						if (res.success) {
							Notify({ type: "success", message: "患者创建成功！" });
							if (next) {
								this.$router.replace("/crm/create-apply/" + res.result.id);
							} else {
								this.$router.replace("/crm/home");
							}
						} else {
							const patientId = res.result.id;
							const userId = this.info.id;
							this.userArr.push(userId.replace(/\"/g, ""));
							const model = {
								patientId: patientId,
								userIdList: this.userArr,
							};
							const obj = { ...fd, id: res.result.id };
							//1.如果返回fromhis调用edit并且调用分配给当前用户的接口
							if (res.message == "fromHis") {
								//调用编辑接口
								api.crmPatientEdit(obj).then((res) => {
									this.hideLoading();
									if (res.success) {
										Notify({ type: "success", message: "患者修改成功！" });
										api.assignAndUpdateCreate(model).then((res) => {
											this.hideLoading();
											if (res.success) {
												Notify({ type: "success", message: "患者分配成功！" });
												//患者分配成功 回到主页
												if (next) {
													//患者建档并申请入院
													this.$router.replace("/crm/create-apply/" + model.patientId);
												} else {
													// this.$router.replace("/crm/profile/" + model.patientId);
													this.$router.replace("/crm/home");
												}
											} else {
												Notify({ type: "danger", message: res.message });
											}
										});
									} else {
										Notify({ type: "danger", message: res.message });
									}
								});
							} else {
								//2.如果不是fromhis弹出提示框，然后选择是否分配edit并且调用分配给其他用户的接口
								this.$dialog
									.confirm({
										title: "提示",
										message: "该患者已建档，是否需要管理该患者?",
									})
									.then(() => {
										api.CrmPatientAssign(model).then((res) => {
											this.hideLoading();
											if (res.success) {
												Notify({ type: "success", message: "患者分配成功！" });
												if (next) {
													//患者建档并申请入院
													this.$router.replace("/crm/create-apply/" + model.patientId);
												} else {
													// this.$router.replace("/crm/profile/" + model.patientId);
													this.$router.replace("/crm/home");
												}
											} else {
												Notify({ type: "danger", message: res.message });
											}
										});
									})
									.catch(() => {
										this.hideLoading();
									});
							}
							//结束
						}
					})
					.catch(() => {
						this.hideLoading();
					});
			}
		},
		// save() {

		// 			this.submit();

		// },
		save() {
			this.$refs.vanFormRef2
				.validate()
				.then(() => {
					this.submit();
				})
				.catch();
		},
		saveToApply() {
			this.$refs.vanFormRef2
				.validate()
				.then(() => {
					this.submit(true);
				})
				.catch();
		},
	},
};
</script>

<style lang="less">
.crm-create {
	padding: 32px;
	position: relative;
}
.camera-card {
	position: absolute;
	top: 60px;
	right: 0;
	width: 246px;
	z-index: 2;
	.camera-btn {
		width: 246px;
		height: 96px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		border-radius: 60px 0px 0px 60px;
		padding: 12px;
		box-sizing: border-box;
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		color: #5f5aa3;
	}
	.icon-camera {
		display: inline-block;
		width: 72px;
		height: 72px;
		background: url("~@/assets/crm/icon/icon-taking.png") no-repeat center center/contain;
		margin-right: 16px;
	}
	.camera-list {
		width: 246px;
		height: 156px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 42px 0px 0px 42px;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		.camera-sfz {
			border-bottom: 1px solid #fff;
		}
		.camera-sfz,
		.camera-ybk {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-weight: 400;
			font-size: 32px;
			line-height: 45px;
			color: #ffffff;
			position: relative;
		}
		.icon-sfz,
		.icon-ybk {
			display: inline-block;
			width: 72px;
			height: 72px;
			margin-right: 16px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50%;
		}
		.icon-sfz {
			background-image: url("~@/assets/crm/icon/icon-sfz.png");
		}
		.icon-ybk {
			background-image: url("~@/assets/crm/icon/icon-ybk.png");
		}
		input[type="file"] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
}
.van-toast--unclickable .van-dialog button {
	pointer-events: auto;
}
</style>

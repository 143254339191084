<template>
	<van-dialog v-model="show" class-name="custom-dialog" :showConfirmButton="false">
		<div class="kj-modal">
			<div class="result-modal-body">
				<div class="result-top">
					<span class="result-tpo-title">{{ title }}</span>
					<img src="~@/assets/images/modal-top.png" alt="" />
				</div>
				<div class="result-label">{{ label }}</div>
				<div class="result-info">
					<h1>
						{{ (results || []).map(v => v.label || "").join("、") }}
					</h1>
				</div>
				<div class="result-modal-desc">
					<!-- 本次得分<b>10</b>分 -->
				</div>
				<div class="result-modal-footer">
					<span class="kj-btn" @click="naxtPage">确定</span>
				</div>
			</div>
		</div>
	</van-dialog>
</template>

<script>
import { mapState } from "vuex";
import api from "@/request/api";
export default {
	data() {
		return {
			show: false,
			runFunction: null,
			columns: [],
			label: "",
			title: "检测结果",
			results: []
		};
	},
	computed: {
		...mapState(["formValueData", "formValueScore", "currentSurvey", "source", "userInfo"])
	},
	mounted() {
		// 计算结果
		const columns = [];
		const pages = this.currentSurvey.pages;
		(pages || []).map(v => {
			columns.push(...this.deepToSimple(v.questions));
		});
		this.columns = columns;

		if (this.currentSurvey.result_function) {
			this.runFunction = function(that) {
				eval(this.currentSurvey.result_function);
			};
		}
	},
	methods: {
		showModal() {
			this.show = true;
			if (!!this.runFunction) {
				this.runFunction(this);
			}
		},
		close() {
			this.show = false;
		},
		deepToSimple(p) {
			const a = [];
			if (!(p instanceof Array)) return [];
			p.map(v => {
				if (!!v.children) {
					a.push(...this.deepToSimple(v.children));
				} else {
					a.push(v);
				}
			});
			return a;
		},
		async naxtPage() {
			this.$toast.loading({
				message: "正在提交...",
				forbidClick: true,
				loadingType: "spinner"
			});

			await api.updateUserInfo({
				...this.userInfo,
				birthYear: this.formValueData.age ? this.formValueData.age + "-01-01 00:00:00" : null,
				height: this.formValueData.height,
				sex: this.formValueData.sex === "男" ? 1 : 0,
				username: this.formValueData.username,
				weight: this.formValueData.weight,
				source: this.source
			});
			await api
				.submitAnswer({
					answers: JSON.stringify(this.formValueData),
					columns: JSON.stringify(this.columns.map(v => ({ label: v.label || v.title, name: v.name }))),
					questionId: this.currentSurvey.id,
					source: this.source
				})
				.then(res => {
					if (res.success) {
						this.$toast.success("提交成功");
						this.$store.dispatch("set_info", {
							...this.userInfo,
							...this.formValueData,
							created_at: ""
						});
						this.$store.dispatch("set_score", {
							...this.userInfo,
							...this.formValueScore,
							created_at: ""
						});
						this.$router.push("/survey/success");
					}
				});
		}
	}
};
</script>

<style lang="less">
@import url("~@/variables.less");
.kj-modal {
	p {
		.desc;
		color: @font2;
		text-align: left;
	}
}
.result-modal-body {
	background: @font9;
	border-radius: 20px;
	.result-label {
		.desc-lable;
		color: @font2;
	}
	h1 {
		.btn-font;
		font-size: 36px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: @font6;
		line-height: 2;
		// padding: 36px 0 24px;
		display: inline-block;
		padding: 0 2px;
		word-break: keep-all;
	}
	a {
		display: inline-block;
	}
	.result-info {
		padding: 20px 50px;
	}
}
.result-top {
	position: relative;
	.result-tpo-title {
		position: absolute;
		font-size: 46px;
		font-family: Arial-BoldMT, Arial;
		font-weight: normal;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	img {
		display: inline-block;
		width: 100%;
	}
}
.result-modal-desc {
	.desc-title;
	color: @font2;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 40px 0;
}
.result-modal-footer {
	padding: 60px 0 70px;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	align-items: center;
	.kj-btn {
		width: 340px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90px;
		background: @bg3;
		border-radius: 45px;
		color: #fff;
	}
	.kj-close {
		font-size: 26px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		line-height: 39px;
		padding: 30px 0;
		letter-spacing: 1px;
	}
}
</style>

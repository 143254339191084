<template>
	<van-sticky>
		<div class="header-box">
			<div class="header-nav">
				<div class="header-bread">
					<div class="header-bread-list">
						<template v-for="(item, index) in tabs">
							<span class="pointer"
										:key="index"
										:class="{ active: index <= active }"
										@click="select(index)">{{ item }}
							</span>
							<van-icon name="arrow"
												:key="'icon-' + index"
												v-if="index !== tabs.length - 1" />
						</template>
					</div>
				</div>
			</div>
			<div class="header-progress">
				<span>进度</span>
				<progress :value="percent"
									max="100"></progress>
				<span class="progress-font">{{ percent }}%</span>
			</div>
		</div>
	</van-sticky>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	watch: {
		$route: {
			handler: function () {
				this.init();
			}
		},
		parentActive: {
			handler: function () {
				this.init();
			}
		}
	},
	props: {
		tabs: {
			type: Array,
			default: []
		},
		currentSurvey: {
			type: Object,
			default: {}
		},
		parentActive: {
			type: Number,
			default: 0
		},
		pagesLength: {
			type: Number,
			default: 1
		}
	},
	data () {
		return {
			active: 0,
			// percent: 30
		};
	},
	computed: {
		...mapGetters(['formValueData', 'userInfo']),
		questionlength: function () {
			const { pages = [] } = this.currentSurvey;
			let result = 0;
			pages.forEach(i => {
				if (i.questions.some(item => item.type === "group")) {
					i.questions.forEach(item => {
						result += item.children.length
					})
				} else {
					result += i.questions.length
				}
			});
			return result
		},
		percent: function () {
			let result = 0;
			const params = { ...this.formValueData, ...this.userInfo };

			let len = Math.max(this.questionlength, Object.keys(params).length)
			for (const key in params) {
				const item = params[key];
				if (item != undefined || (item && item.length > 0)) {
					result += 1
				}
			}
			return Math.min(Math.floor((result / len).toFixed(2) * 100), 100);
		}
	},
	created () {
		this.init();
	},
	methods: {
		init () {
			const index = this.tabs.findIndex(i => i === this.$route.query.tab);
			this.active = index > 0 ? index : 0;
			// this.percent = Math.floor((this.parentActive / this.pagesLength).toFixed(2) * 100);
		},
		select (index) {
			if (this.currentSurvey.canBack) {
				this.$emit("select", this.tabs[index], index);
				this.init();
			}
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.header-box {
	padding: 0 5%;
	overflow: hidden;
	height: 220px;

	.pointer {
		cursor: pointer;
	}

	.header-nav {
		padding: 60px 30px 30px;
		overflow: hidden;
		box-sizing: border-box;
	}

	.header-bread {
		color: @font9;
		overflow: hidden;
		height: 40px;

		.header-bread-list {
			display: flex;
			height: 60px;
			overflow-y: hidden;
			overflow-x: auto;

			>span {
				.desc-title;
				display: inline-block;
				color: @font7;
				white-space: nowrap;
				flex: 1;
			}

			>i {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;
				padding: 0 10px;
			}

			.active {
				color: @font9;
			}
		}
	}

	.header-progress {
		.desc;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		padding-bottom: 20px;

		>span {
			.desc;
			display: inline-block;
			color: @font9;
		}

		progress {
			width: 70%;
			height: 10px;
			background: #7b92fc;
			border-radius: 10px;
			transition: all 0.3s linear;
		}

		progress::-webkit-progress-bar {
			background-color: #7b92fc;
			border-radius: 10px;
		}

		progress::-webkit-progress-value {
			background: linear-gradient(270deg, #ff9302 0%, #fde959 100%);
			border-radius: 10px;
		}
	}

	.progress-font {
		display: inline-block;
		width: 90px;
	}
}
</style>

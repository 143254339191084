<template>
	<div class="crm-bg crm-create">
		<van-form ref="vanFormRef1">
			<div class="crm-card" v-if="step == 0">
				<div class="crm-card-title">医疗特别注意项</div>
				<div class="crm-card-body">
					<!-- <CRMSelect
						v-model="form.contentType"
						name="contentType"
						label="内容类型"
						:readonly="false"
						:attr="{ required: true }"
						placeholder="选择内容类型"
						:columns="content_type"
						required
						:rules="[{ required: true, message: '请选择内容类型' }]"
					/> -->
					<CRMSelect
						v-model="form.emergencyLevel"
						name="emergencyLevel"
						label="紧急程度"
						:readonly="false"
						:attr="{ required: true }"
						placeholder="选择紧急程度"
						:columns="emergency_level"
						:rules="[{ required: true, message: '请选择紧急程度' }]"
					/>

					<van-field
						v-if="form.contentType == '4'"
						v-model="form.contact"
						required
						name="contact"
						label1="最新联系方式"
						placeholder="最新联系方式"
						:rules="[
							{ required: true, message: '请填写联系电话' },
							{ pattern: RULES.phone, message: '请输入正确的联系电话' },
						]"
					/>
					<!-- <div class="crm-upload">
						<span class="crm-upload-title">照片：</span>
						<van-uploader v-model="fileList" :after-read="uploadSFZ" @delete="removeImg" />
					</div> -->
					<CRMTextarea
						label1="内容详细描述"
						:rows="4"
						:attr="{ maxlength: 1000, 'show-word-limit': true, required: true }"
						v-model="form.remarks"
						name="remarks"
						:rules="[{ required: true, message: '请填写内容详细描述' }, ,]"
						placeholder="内容详细描述"
					/>
					<div class="crm-upload" style="margin-top: 12px">
						<span class="crm-upload-title">照片：</span>
						<van-uploader multiple :max-count="6" v-model="fileList" :after-read="uploadSFZ" @delete="removeImg" />
					</div>
				</div>
			</div>

			<!-- <div class="crm-card">
				<div class="crm-card-title">照片信息</div>
				<div class="crm-card-body">
					<div class="crm-upload">
						<van-uploader v-model="fileList" :after-read="uploadSFZ" @delete="removeImg" />
					</div>
				</div>
			</div> -->
		</van-form>

		<van-form ref="vanFormRef2">
			<div class="crm-card">
				<div class="crm-card-body crm-card-left">
					<strong>说明：</strong>
					<span v-for="(item, index) in explain_type" :key="index"
						><span checked-color="#5f5aa3" :name="item.title">{{ item.title }}</span></span
					>
				</div>
			</div>
		</van-form>

		<div class="crm-btn-inline mb-32">
			<van-button class="crm-btn" :loading="loading" block @click="save">提交</van-button>
		</div>
	</div>
</template>

<script>
import CRMSelect from "@/components/CRM/Select/index.vue";
import api from "@/request/api";
import RULES from "@/constant/rules";
import { Notify } from "vant";
import crmMixins from "@/utils/crm-mixins";
import heic2any from "heic2any";
import { mapState } from "vuex";
// import CRMRadio from "@/components/CRM/Radio";
import RYRadio from "@/components/CRM/ryRadio";
import CRMDate from "@/components/CRM/SelectDate";
import DateTime from "@/components/CRM/DataTime";
import CRMCheckbox from "@/components/CRM/Checkbox";
import ImageCompressor from "@/utils/imageCompressor";
import request from "@/request/index.js";
import CRMTextarea from "@/components/CRM/Textarea";
import crmMIxins from "@/utils/crm-mixins";
import CRMRadio from "@/components/CRM/Radio";
export default {
	name: "create-patient",
	mixins: [crmMixins],
	components: { CRMSelect, RYRadio, CRMDate, DateTime, CRMTextarea },
	data() {
		return {
			step: 0,
			loading: false,
			RULES,
			readonly: false,
			sex_dictText: "",
			// 	format: {
			// 	type: String,
			// 	default: "YYYY-MM-DD HH:mm:ss"

			// },
			file: null,
			userArr: [],
			applyList: {
				zzPlanInArea: "",
				zzInType: "",
				//  inHosTime: this.format,
				nsInDate: "",
			},

			form: {
				contentType: "",
				emergencyLevel: "9",
				contact: "",
				images: "",
				remarks: "",
				patientId: "",
			},
			fileList: [],
			imgList: [],
			cellphone: {},
			provinceList: [],
			patientFrom: {},
		};
	},
	computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),
	},
	watch: {
		form: {
			handler: function (a) {},
			deep: true,
		},
	},
	mounted() {
		let vm = this;
		vm.form.patientId = this.$route.query.id;
		console.log("vm.from", vm.form);
	},
	methods: {
		uploadSFZ(files) {
			const self = this;
			// var fd = new FormData();
			// fd.append("type", "idcard");
			// fd.append("file", e.target.files[0]);
			// this.runUpload(fd);
			// this.showLoading();
			console.log("e", files);
			if (files.length) {
				for (const k in files) {
					console.log(k, files[k]);
					this.getJpgFile(files[k].file).then((file) => {
						const options = {
							file: file,
							quality: 1,
							mimeType: "image/jpeg",

							minWidth: 500,
							minHeight: 500,

							loose: true,

							// 压缩前回调
							beforeCompress: function (result) {
								console.log("压缩之前图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
							},

							// 压缩成功回调
							success: function (result) {
								var fd = new FormData();
								console.log("转换后:" + result.size);
								console.log("this.apply.patientId", self.form.patientId);
								// fd.append("type", "idcard");
								fd.append("file", result);
								// fd.append("file", file.file);
								fd.append("biz", self.form.patientId);
								console.log(result, "1");
								console.log("1fd", fd);
								console.log("fd.file", file);
								self.runUpload(fd);

								console.log("压缩之后图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
								console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
							},

							// 发生错误
							error: function (msg) {
								console.error(msg);
							},
						};
						new ImageCompressor(options);
					});
				}
			} else {
				console.log("k", files);
				this.getJpgFile(files.file).then((file) => {
					const options = {
						file: file,
						quality: 1,
						mimeType: "image/jpeg",

						minWidth: 500,
						minHeight: 500,
						loose: true,

						// 压缩前回调
						beforeCompress: function (result) {
							console.log("压缩之前图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
						},

						// 压缩成功回调
						success: function (result) {
							var fd = new FormData();
							console.log("转换后:" + result.size);
							console.log("this.apply.patientId", self.form.patientId);
							// fd.append("type", "idcard");
							fd.append("file", result);
							// fd.append("file", file.file);
							fd.append("biz", self.form.patientId);
							console.log(result, "1");
							console.log("1fd", fd);
							console.log("fd.file", file);
							self.runUpload(fd);

							console.log("压缩之后图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
							console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
						},

						// 发生错误
						error: function (msg) {
							console.error(msg);
						},
					};
					new ImageCompressor(options);
				});
			}
		},
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},
		runUpload(fd) {
			this.showLoading("上传中...");
			fd.status = "uploading";
			fd.message = "上传中...";
			console.log("f11d", fd);
			// this.showLoading("上传中...");
			api.upload(fd).then((res) => {
				this.hideLoading();
				console.log(res);
				if (res.success) {
					if (res.message) {
						fd.status = "done";
						fd.message = "上传成功";
						this.imgList.push(res.message);
					} else {
						Notify({ type: "danger", message: "图片上传失败！" });
					}
				} else {
					Notify({ type: "danger", message: "图片上传失败！" });
				}
			});
		},
		removeImg(file, detail) {
			//  this.imgList.splice(detail.index, 1);
			this.imgList.splice(detail.index,1)
			},
		afterRead(file) {
			file.status = "uploading";
			file.message = "上传中...";
			console.log("this.from.patientId", this.from.patientId);
			api.upload(file, this.from.patientId).then((res) => {
				if (res.success) {
					file.status = "done";
					file.message = "上传成功";
					this.imgList.push(res.message);
					console.log("this.imgList3",this.imgList);
				} else {
					file.status = "failed";
					file.message = "上传失败";
				}
			});
		},
		prev() {
			this.step = 0;
		},
		next() {
			console.log(this.form);
			this.$refs.vanFormRef1
				.validate()
				.then(() => {
					this.step = 1;
				})
				.catch();
		},
		submit(next) {
			this.$refs.vanFormRef1
				.validate()
				.then(() => {
					let vm = this;
					const fd = { ...this.form };
					fd.images = vm.imgList.join(",");
					fd.contentType != "4" ? (fd.contact = null) : fd.contact;
					this.showLoading();
					api.crmRemarksAdd(fd)
						.then((res) => {
							this.hideLoading();
							if (res.success) {
								Notify({ type: "success", message: "患者备注添加成功！" });
								this.$router.replace("/crm/profile/" + fd.patientId);
							} else {
								Notify({ type: "danger", message: res.message });
							}
						})
						.catch(() => {
							this.hideLoading();
						});
				})
				.catch();
		},
		save() {
			this.submit();
		},
		save() {
			let vm = this;
			//将ysFrom.inHosTime传给患者
			//弹窗
			this.$dialog
				.confirm({
					title: "提示",
					message: "请确认是否添加备注",
					confirmButtonText: "确认",
					cancelButtonText: "取消",
				})
				.then(() => {
					this.submit();
				})
				.catch(() => {});
		},
		saveToApply() {
			this.$refs.vanFormRef2
				.validate()
				.then(() => {
					this.submit(true);
				})
				.catch();
		},
	},
};
</script>

<style lang="less">
.crm-card-left {
	text-align: left;
}
.crm-upload {
	display: block;
	text-align: left;
	.van-uploader__preview-image,
	.van-uploader__upload,
	.van-uploader__preview {
		background: #f2f2f2;
		border-radius: 12px;
	}
	.van-uploader__preview-delete {
		border-radius: 16px;
		top: -6px;
		right: -6px;
		width: 32px;
		height: 32px;
		.van-uploader__preview-delete-icon {
			top: 0;
			right: 0;
		}
	}
}
.crm-create {
	padding: 32px;
	position: relative;
}
.camera-card {
	position: absolute;
	top: 60px;
	right: 0;
	width: 246px;
	z-index: 2;
	.camera-btn {
		width: 246px;
		height: 96px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		border-radius: 60px 0px 0px 60px;
		padding: 12px;
		box-sizing: border-box;
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		color: #5f5aa3;
	}
	.icon-camera {
		display: inline-block;
		width: 72px;
		height: 72px;
		background: url("~@/assets/crm/icon/icon-taking.png") no-repeat center center/contain;
		margin-right: 16px;
	}
	.camera-list {
		width: 246px;
		height: 156px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 42px 0px 0px 42px;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		.camera-sfz {
			border-bottom: 1px solid #fff;
		}
		.camera-sfz,
		.camera-ybk {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-weight: 400;
			font-size: 32px;
			line-height: 45px;
			color: #ffffff;
			position: relative;
		}
		.icon-sfz,
		.icon-ybk {
			display: inline-block;
			width: 72px;
			height: 72px;
			margin-right: 16px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50%;
		}
		.icon-sfz {
			background-image: url("~@/assets/crm/icon/icon-sfz.png");
		}
		.icon-ybk {
			background-image: url("~@/assets/crm/icon/icon-ybk.png");
		}
		input[type="file"] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
}
.van-toast--unclickable .van-dialog button {
	pointer-events: auto;
}
</style>

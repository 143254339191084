<template>
	<div class="custom-form">
		<div class="form-img-label">{{ model.label }}</div>
		<div class="form-img-option">
			<img class="option-item-img"
					 :src="imgMap[model.img]"
					 :alt="model.label" />
			<div :class="['option-item', inputValue === item.value ? 'active' : '']"
					 v-for="item in model.options"
					 @click="changeValue(item)"
					 :key="item.value">
				{{ item.label }}
				<img v-if="inputValue === item.value"
						 :src="IconChecked"
						 class="active-item" />
			</div>
		</div>
	</div>
</template>

<script>
import IconBig from "@/assets/images/icon-big.png";
import IconWig from "@/assets/images/icon-wbig.png";
import IconChecked from "@/assets/images/icon-checked.png";
export default {
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: "",
					options: []
				};
			}
		},
		value: [String, null]
	},
	data () {
		return {
			imgMap: {
				IconBig,
				IconWig
			},
			IconChecked,
			inputValue: null
		};
	},
	mounted () {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init () {
			if (this.value) {
				this.inputValue = this.value;
			} else {
				this.inputValue = null;
			}
		},
		changeValue (item) {
			this.inputValue = item.value;
			this.$emit("input", this.inputValue, this.model, item);
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.form-img-label {
	text-align: left;
}

.form-img-option {
	display: flex;
	margin: 36px 0 64px;
	justify-content: space-between;
	align-items: center;

	.option-item {
		position: relative;
		width: 150px;
		height: 91px;
		line-height: 91px;
		text-align: center;
		background: @bg5;
		border-radius: 8px;

		&.active {
			background-color: @bg4;
		}
	}

	.option-item-img {
		width: 188px;
	}

	.active-item {
		position: absolute;
		width: 48px;
		top: -10px;
		right: -10px;
	}
}
</style>

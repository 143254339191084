<template>
	<div class="question-page">
		<van-sticky>
			<div class="group-header">
				<a v-for="(item, index) in questions"
					:class="['group-title', active == item.type ? 'active' : '']"
					:key="index"
					@click="changeTab(item, index)"
					:href="`#${item.type}`">
					{{ item.title }}
				</a>
			</div>
		</van-sticky>
		<div class="question-box">
			<div v-for="(item, index) in questions"
				:key="index"
				class="question-group">
				<p class="question-group-title"
					:id="item.type">{{ item.title }}</p>
				<template v-for="(child, childIndex) in item.questions">
					<div class="question-item"
						v-if="!child.hide"
						:key="`${child.name}-${childIndex}-${index}`">
						<component :key="`${childIndex}-${updateKey}`"
							:is="`normal-${child.type}`"
							:model="child"
							:index="index"
							ref="questionItemRef"
							:childIndex="childIndex"
							:showIndex="!!item.showIndex"
							@input="valueChange($event, item, child)"
							:value="fd[child.name]" />
					</div>
				</template>
			</div>
			<div class="question-date">
				<div>
					<label>采集时间：</label>
					{{ nowTime }}
				</div>
				<div>
					<label>失效时间：</label>
					{{ loseEfficacy }}
				</div>
			</div>
			<div class="submit-box">
				<van-button type="primary"
					color="#5f5aa3"
					@click="submit"
					block>提交</van-button>
			</div>
		</div>
		<wj-login :model="fd"
			@success="submitForm"
			ref="WjLoginRef" />
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import dayjs from "dayjs";
	import api from "@/request/api";
	import WjLogin from "@/views/WJ/login.vue";
	import {
		normalDescText,
		normalInput,
		normalRadio,
		normalSelect,
		normalUpload,
		normalSign
	} from "@/components/form/index";
	import crmMixins from "@/utils/crm-mixins";
	export default {
		components: {
			normalInput,
			normalSelect,
			normalRadio,
			normalSign,
			normalUpload,
			WjLogin,
			normalDescText,
		},
		mixins: [crmMixins],
		data () {
			return {
				nowTime: dayjs().format("YYYY-MM-DD HH:mm"),
				fd: {
					family_auth_sign: "",
					family_accompany_sign: "",
					sign: "",
					hesuan_code: ""
				},
				updateKey: 1,
				active: ''
			};
		},
		computed: {
			...mapState(["investSurvey", "investValueData", "userInfo"]),
			questions: function () {
				return JSON.parse(JSON.stringify(this.investSurvey.questions || []));
			},
			loseEfficacy: function () {
				return dayjs().add(this.investSurvey.effective || 24, "hour")
					.format("YYYY-MM-DD HH:mm");
			}
		},
		mounted () {
			if (document.querySelector(".question-box")) {
				document.querySelector(".question-box").addEventListener("scroll", this.scrollListener);
			}
			if (this.investSurvey && this.investSurvey.questions.length) {
				this.active = this.questions[0] ? this.questions[0].type : '';
				if (this.userInfo && this.userInfo.phone) {
					this.fd.mobile = this.userInfo.phone || this.investValueData.mobile;
				}
				if (this.investValueData && Object.keys(this.investValueData).length) {
					this.fd.name = this.investValueData.name;
					this.fd.enter_reason = this.investValueData.enter_reason;
					this.fd.family_name = this.investValueData.family_name;
					this.fd.family_id_card = this.investValueData.family_id_card;
					this.fd.receptionist = this.investValueData.receptionist;
					this.fd.id_card = this.investValueData.id_card;
					this.fd.residence_address = this.investValueData.residence_address;
					this.fd.residential_address = this.investValueData.residential_address;
					// this.fd.sign = this.investValueData.sign;
					this.$nextTick(() => {
						this.investSurvey.questions.forEach(i => {
							if (i.questions && i.questions.length) {
								i.questions.forEach(child => {
									if (child.name === "receptionist") {
										child.hide = this.fd.enter_reason !== "预约接待";
									}
								});
							}
						});
					});
				}
				this.updateKey++;
			} else {
				this.$router.push({
					path: "/investigation",
					query: {
						...this.$route.query
					}
				});
			}
		},
		beforeDestroy () {
			if (document.querySelector(".question-box")) {
				document.querySelector(".question-box").removeEventListener("scroll", this.scrollListener);
			}
		},
		methods: {
			valueChange (value, val, ITEM) {
				if (val.type === "content") {
					const OPTION = (ITEM.option || []).find(i => i.value === value) || {};
					if (this.fd[val.type]) {
						const Index = this.fd[val.type].findIndex(i => i.name === ITEM.name);
						if (Index === -1) {
							this.fd[val.type].push({
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							});
						} else {
							this.$set(this.fd[val.type], Index, {
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							});
						}
					} else {
						this.fd[val.type] = [
							{
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							}
						];
					}
				} else {
					this.fd[ITEM.name] = value;
				}
				// 显隐规则
				if (ITEM.changeLink && ITEM.link) {
					this.investSurvey.questions.some(item => {
						const questionItem = item.questions.find(i => i.name === ITEM.link);
						// 自定义显隐规则
						if (ITEM.customHide && ITEM.customHide.length) {
							ITEM.customHide.forEach(child => {
								const Item = item.questions.find(i => i.name === child.filed);
								if (Item) {
									if (child.value == '*') {
										Item.hide = !(this.fd[ITEM.name] && this.fd[ITEM.name].length)
									} else if (Array.isArray(child.value)) {
										Item.hide = !child.value.includes(this.fd[ITEM.name])
									} else {
										Item.hide = (this.fd[ITEM.name] || '') != child.value
									}
								} else {
									return true;
								}
							})
						} else if (questionItem) {
							let flag = true;
							questionItem.showRule.forEach(i => {
								flag = flag && (this.fd[i.filed] === i.value || (this.fd[i.filed] && i.value == '*'));
							});
							questionItem.hide = !flag;
							// 清空隐藏字段值
							if (!flag) {
								this.fd[questionItem.name] = "";
							}
							return true;
						}
					});
				}
			},
			scrollListener (e) {
				this.questions.forEach(i => {
					const TOP = document.getElementById(i.type).offsetTop
					const scrollTop = e.target.scrollTop;
					if (scrollTop + 55 >= TOP) {
						this.active = i.type;
					}
				});

			},
			submit () {
				this.validate().then(val => {
					if (val) {
						this.$dialog
							.alert({
								title: "温馨提示",
								message: "请您务必如实填报，若故意隐瞒相关情况，造成后果，您将要承担相应法律责任，谢谢您的理解和配合！",
								messageAlign: "left"
							})
							.then(() => {
								if (this.userInfo && this.userInfo.phone) {
									this.submitForm();
								} else {
									// TODO 微信环境微信授权
									// this.$dialog.confirm({
									// 	title: '提示',
									// 	message: `您是否要验证，验证之后可以查询您的流行病学调查记录，且${this.investSurvey.effective}小时只能无需重新填写，直接出示有效期内的流行病学调查记录即可。`,
									// 	messageAlign: "left",
									// 	cancelButtonText: "直接提交",
									// 	confirmButtonText: "立即验证",
									// }).then(() => {
									this.$refs.WjLoginRef.show = true;
									this.$refs.WjLoginRef.params.mobile = this.fd.mobile;
									this.$refs.WjLoginRef.params.code = "";
									// }).catch(() => {
									// 	this.submitForm()
									// });
								}
							});
					} else {
						this.$toast.fail("必填项未完善或填写错误！");
					}
				});
			},
			// 校验
			validate () {
				return new Promise(resolve => {
					let flag = true;
					this.$refs.questionItemRef.forEach(item => {
						flag = item.validate() && flag;
					});
					resolve(flag);
				});
			},
			changeTab (item, index) {
				this.avtive = index;
			},
			submitForm () {
				// 提交数据
				api.epidemiologicalSave({
					...this.fd,
					expire_date: this.nowTime,
					status: this.fd.content.filter(i => i.danger > 0).length > 0 ? 0 : 1
				}).then(resp => {
					if (resp.success) {
						this.$store.dispatch("set_invest_value", {
							...this.fd,
							expire_date: this.expire_date,
							status: this.fd.content.filter(i => i.danger > 0).length > 0 ? 0 : 1
						});
						this.linkToResult();
					}
				});
			},
			linkToResult () {
				this.$router.push({
					path: "/investigation/result",
					query: {
						...this.$route.query
					}
				});
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	.question-page {
		text-align: left;
		.group-header {
			display: flex;
			justify-content: space-around;
			align-items: center;
			box-shadow: 0px 1px 8px 1px #e1dfdf;
			background-color: #ffffff;
			.group-title {
				width: 50%;
				height: 100px;
				line-height: 100px;
				font-size: 32px;
				text-align: center;
				border-bottom: 4px solid @font9;
				color: @font1;
				&.active {
					border-bottom: 4px solid @bg5;
					color: @bg5;
					font-weight: 700;
				}
			}
		}
		.question-box {
			height: calc(100vh - 120px);
			overflow: auto;
			.question-group-title {
				font-size: 32px;
				padding: 30px 20px 20px;
				font-weight: 600;
				color: @bg5;
			}
			.question-item {
				padding: 30px 30px 40px;
				border-bottom: 1px solid @font11;
				&:last-child {
					border: none;
				}
			}
			.question-group {
				padding: 0 0 20px 0;
				border-bottom: 4px dashed @bg5;
				.question-item {
					border: none;
				}
				&:first-child {
					padding: 0 0 60px 0;
				}
				&:last-child {
					border: none !important;
				}
			}
			.question-date {
				font-size: 26px;
				padding: 30px 30px 40px;
				> div {
					line-height: 30px;
					margin-top: 20px;
				}
			}
			.submit-box {
				font-size: 26px;
				padding: 30px 30px 40px;
			}
		}
	}
</style>

<style lang="less">
	@import url("~@/variables.less");
	.error-tip {
		float: left;
		color: @color4;
		font-size: 16px;
	}
	.normal-form .input-value.error .input-value-cell-body,
	.normal-form .input-value.error input {
		border-color: @color4;
	}
</style>

<template>
	<div class="jh-over">
		<h1 class="jh-over-h1">{{ survey.title }}</h1>
		<div class="jh-over-sub"
			v-show="!survey.hideInfo">
			<div class="jh-over-people">
				<span class="icon-people"></span>
				<strong>{{ survey.total }}</strong>人已测
			</div>
			<div class="jh-over-time">
				<span class="icon-time"></span>
				预计<strong>{{ survey.time }}</strong>分钟
			</div>
		</div>
		<div class="jh-over-description">
			<p class="jh-over-description-title">简介</p>
			<div v-html="survey.info"></div>
		</div>
		<div class="jh-over-footer">
			<div class="jh-over-info">
				{{ survey.description }}
			</div>
		</div>
		<div class="tac begin-btn">
			<a href="javascript:;"
				@click="beginTest"
				class="jh-btn">{{ survey.start_btn || "立即检测" }}</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "over-page",
		props: {
			survey: {
				type: Object,
				default: () => {
					return {};
				}
			}
		},
		mounted () {
			console.log(this.survey);
		},
		methods: {
			beginTest () {
				this.$emit("begin-test");
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");
	.jh-over {
		width: 100%;
		height: 100%;
		padding: 208px 70px 70px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		color: @font9;
	}
	.jh-over-h1 {
		.h1;
	}
	.jh-over-sub {
		display: flex;
		padding: 42px 0 94px 0;
		.jh-over-people {
			margin-right: 50px;
		}
		strong {
			display: inline-block;
			font-weight: bold;
			padding: 0 5px;
		}
	}
	.jh-over-footer {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
	}
	.jh-over-people,
	.jh-over-time {
		display: inline-flex;
		align-items: center;
		align-items: center;
		font-size: 26px;
		font-family: Arial-BoldMT, Arial;
		font-weight: normal;
		color: #ffffff;
	}
	.icon-people {
		display: inline-block;
		width: 30px;
		height: 27px;
		background: url("~@/assets/images/icon-people.png") no-repeat center center/contain;
		margin-right: 5px;
	}
	.icon-time {
		display: inline-block;
		width: 28px;
		height: 28px;
		background: url("~@/assets/images/icon-time.png") no-repeat center center/contain;
		margin-right: 5px;
	}
	.jh-over-info {
		.desc;
		text-align: left;
		opacity: 0.8;
	}
	.jh-over-description {
		text-align: left;
		margin-top: 30px;
		.desc;
		&-title {
			padding-bottom: 10px;
		}
		p {
			opacity: 0.8;
		}
	}
	.jh-btn {
		.btn;
		.btn-font;
		width: 610px;
		margin-top: 49px;
		cursor: pointer;
		&.disable {
			background: #7c95fe;
			border-radius: 44px;
			opacity: 0.6;
		}
		&.jh-prev {
			margin-right: 20px;
			background-color: transparent;
			border: 2px solid #fff;
			color: #fff;
		}
	}
	.begin-btn {
		display: flex;
		justify-content: center;
		width: 100%;
	}
</style>

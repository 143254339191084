<template>
	<van-dialog v-model="show"
							:title="title"
							@confirm="handleHide"
							@close="handleHide"
							:show-cancel-button="false">
		<div class="itemInfo">
			<van-row class="item-info">
				<van-col :span="24">患者姓名：{{ archivesValueData.name }}</van-col>
				<van-col :span="12">性别：{{ sexText }}</van-col>
				<van-col :span="12">年龄：{{ archivesValueData.age }}</van-col>
				<van-col v-for="(item, index) in cellphone"
								 :key="index"
								 :span="24">
					{{ index === 0 ? '联系电话：' : `其他号码${index}：` }}
					{{ item }}
				</van-col>
				<van-col :span="24">证件类型：{{ cardTypeText }}</van-col>
				<van-col :span="24">
					{{ archivesValueData.cardType === "身份证" ? "身份证" : "证件号码" }}：
					{{ archivesValueData.idCard }}
				</van-col>
				<van-col :span="24">居住地区：{{ sourceText }}</van-col>
				<van-col :span="24">就诊医院：{{ model.hospital }}</van-col>
				<van-col :span="24">就诊科室：{{ model.dept }}</van-col>
				<van-col :span="24">医生姓名：{{ model.consultant }}</van-col>
				<van-col :span="24">决策人：{{ archivesValueData.decision == 2 ? "家属" : "本人" }}</van-col>
				<template v-if="archivesValueData.decision == 2">
					<van-col :span="24">决策人姓名： {{ archivesValueData.decisionName }}</van-col>
					<van-col :span="24">与患者关系：{{ decisionRelationText }}</van-col>
					<van-col :span="24">联系电话：{{ archivesValueData.decisionCellphone }}</van-col>
				</template>
				<van-col :span="24">支付方式：{{ payTypeText }}</van-col>
				<van-col :span="24">宗教信仰：{{ religionText }}</van-col>
				<van-col :span="24">登记时间：{{ model.createTime | formatDate("YYYY-MM-DD") }}</van-col>
			</van-row>
		</div>
	</van-dialog>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import { sourceData, card_type, sex, decision_type, patient_relation, religion, pay_type } from "@/constant/archivesQuery";
export default {
	mixins: [crmMixins],
	props: {
		title: {
			type: String,
			default: "患者详情"
		},
		model: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	data () {
		return {
			archivesValueData: {},
			show: false
		};
	},
	mounted () {
		this.archivesValueData = this.model;
	},
	computed: {
		cellphone: function () {
			try {
				return this.archivesValueData.cellphone.length > 11 ? JSON.parse(this.archivesValueData.cellphone) : this.archivesValueData.cellphone
			} catch (error) {
				return this.archivesValueData.cellphone
			}
		},
		sourceText: function () {
			if (this.archivesValueData.source) {
				const item = sourceData.all.find(i => i.id === this.archivesValueData.source) || {};
				return item.name || "";
			} else {
				return "";
			}
		},
		sexText: function () {
			if (this.archivesValueData.sex) {
				const item = sex.find(i => i.value + '' === this.archivesValueData.sex + '') || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		payTypeText: function () {
			if (this.archivesValueData.payType) {
				const item = pay_type.find(i => i.value === this.archivesValueData.payType) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		religionText: function () {
			if (this.archivesValueData.religion) {
				const item = religion.find(i => i.value === this.archivesValueData.religion) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		cardTypeText: function () {
			if (this.archivesValueData.cardType) {
				const item = card_type.find(i => i.value === this.archivesValueData.cardType) || {};
				return item.text || "";
			} else {
				return "";
			}
		},
		decisionRelationText: function () {
			if (this.archivesValueData.decisionRelation) {
				const item = patient_relation.find(i => i.value === this.archivesValueData.decisionRelation) || {};
				return item.text || "";
			} else {
				return "";
			}
		}
	},
	methods: {
		handleShow (item) {
			this.show = true
			this.archivesValueData = item || this.model;
		},
		handleHide () {
			this.show = false
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.itemInfo {
	padding: 30px 20px;
	max-height: 60vh;
	overflow: auto;

	.item-info {
		text-align: left;
		font-size: 28px;
		padding: 10px 0;
		color: @font3;
	}
}
</style>

<template>
	<van-field :name="name" :label="label" class="crm-radio" :rules="rules" v-bind="attr">
		<template #input>
			<van-radio-group v-model="radio" disabled :direction="direction" @change="selected">
				<van-radio v-for="(item, index) in options" :key="index" :name="item.value || item.text">{{ item.text }}</van-radio>
			</van-radio-group>
		</template>
	</van-field>
</template>

<script>
export default {
	name: "crm-radio",
	props: {
		label: {
			type: String,
			default: ""
		},
		direction: {
			type: String,
			default: "horizontal" // horizontal | vertical
		},
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		name: {
			type: String,
			default: "name"
		},
		value: {
			type: String | Number,
			default: ""
		},
		rules: {
			type: Array,
			default() {
				return [];
			}
		},
		attr: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			radio: this.value
		};
	},
	watch: {
		value: function(a) {
			this.radio = a;
		}
	},
	methods: {
		selected(a) {
			this.$emit("input", a);
			this.$emit("change", a);
		}
	}
};
</script>

<style lang="less">
.crm-radio {
	.van-radio--vertical {
		margin-bottom: 9px;
		font-size: 28px;
		color: #777;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>

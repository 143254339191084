<template>
	<div class="crm-create crm-bg">
		<div class="crm-card">
			<div class="crm-card-title">
                <div class="crm-card-title__flex">联系人详情</div>
					<span class="crm-card-cancel"  @click="onDelete">删除</span>

                </div>
			<div class="crm-card-body">
				<van-field v-model="record.name" :readonly="disabled" name="name" label="姓名" placeholder="请填写姓名" />
				<van-field v-model="record.cellphone" :readonly="disabled" name="cellphone" label="电话" placeholder="请输入联系电话" />
				<CRMSelect
					v-model="record.relation"
					name="relation"
					label="关系"
					:readonly="disabled"
					:attr="{ required: true }"
					placeholder="请选择是否默认联系人"
					:columns="patient_relation"
					:rules="[{ required: true, message: '请选择与联系人关系' }]"
				/>
				<CRMSelect
					v-model="record.isDefault"
					name="isDefault"
					label="联系人"
					:readonly="disabled"
					:attr="{ required: true }"
					placeholder="请选择是否默认联系人"
					:columns="yn"
					:rules="[{ required: true, message: '请选择是否默认联系人' }]"
				/>
				<CRMTextarea
						label1="备注"
						:rows="3"
						required
                        :readonly="disabled"
						:attr="{ maxlength: 1000, 'show-word-limit': true ,required: true}"
						v-model="record.remark"
						name="remark"
						:rules="[{ required: true, message: '请填写备注' }, ,]"
						placeholder="备注"
					/>
            </div>
            <div class="fixed-footer">
				<van-button class="crm-btn mb-80" :loading="loading" block @click="toChange"
					>修改</van-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import ImgField from "@/components/CRM/ImgField";
import crmMixins from "@/utils/crm-mixins";
import CRMTextarea from "@/components/CRM/Textarea";
import { Dialog, Notify } from "vant";
import CRMSelect from "@/components/CRM/Select";

export default {
	name: "crm-history-detail",
	mixins: [crmMixins],
	components: { ImgField ,CRMTextarea,CRMSelect},
	data() {
		return {
            loading: false,
            disabled:true,
			record: {},
			options: []
		};
	},
	mounted() {
		api.crmContactDetail(this.$route.params.id).then(res => {
			if (res.success) {
				this.record = res.result;

			}
		});
	},
	watch: {

'record.cellphone'(newVal) {
this.record.cellphone = newVal.replace(/\s/g, '');

},
},
    methods: {
        onDelete(){
            let that=this;
            console.log("that.record",that.record)

            this.$dialog
					.confirm({
						title: "提示",
						message: "是否删除联系人！",
					})
					.then(() => {
                        api.crmContactDelete(that.record.id).then((res) => {
							if (res.success) {
								this.loading = false;
								this.$router.back();
							} else {
								Notify({ type: "danger", message: "删除联系人失败！" });
							}
						});
                    })
        },
        toChange() {
				Dialog.confirm({ title: "提示", message: "是否要修改联系人信息！", confirmButtonText: "确认" }).then(res => {
				this.jumpPage("/crm/edit-contact/" + this.record.id );
			});
		},
    },
};
</script>

<style lang="less"></style>

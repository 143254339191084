<template>
	<div class="crm-create crm-bg">
		<div class="crm-card">
			<div class="crm-card-title">联系人修改</div>
			<div class="crm-card-body">
				<van-field v-model="form.name" required name="name" label="姓名" placeholder="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
				<van-field
					v-model="form.cellphone"
					name="cellphone"
					type="tel"
					required
					label="电话"
					placeholder="请输入联系电话"
					:rules="[
						{ required: true, message: '请填写联系电话' },
						{ pattern: RULES.phone, message: '请输入正确的联系电话' },
					]"
				>
				</van-field>
				<CRMSelect
					v-model="form.relation"
					name="relation"
					label="关系"
					:attr="{ required: true }"
					placeholder="请选择是否默认联系人"
					:columns="patient_relation"
					:rules="[{ required: true, message: '请选择与联系人关系' }]"
				/>
				<CRMSelect
					v-model="form.isDefault"
					name="isDefault"
					label="联系人"
					:attr="{ required: true }"
					placeholder="请选择是否默认联系人"
					:columns="yn"
					:rules="[{ required: true, message: '请选择是否默认联系人' }]"
				/>
				<CRMTextarea
					label1="备注"
					:rows="3"
					required
					:attr="{ maxlength: 1000, 'show-word-limit': true, required: true }"
					v-model="form.remark"
					name="remark"
					:rules="[{ required: true, message: '请填写备注' }, ,]"
					placeholder="备注"
				/>
			</div>
			<div class="fixed-footer crm-btn-inline mb-32">
				<van-button class="crm-round-btn" :loading="loading" block @click="onSubmit">确定</van-button>
				<van-button class="crm-btn" :loading="loading" block @click="onCancel">取消</van-button>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import crmMixins from "@/utils/crm-mixins";
import CRMTextarea from "@/components/CRM/Textarea";
import RULES from "@/constant/rules";
import CRMSelect from "@/components/CRM/Select";
export default {
	name: "crm-history-detail",
	mixins: [crmMixins],
	components: { CRMSelect, CRMTextarea },
	data() {
		return {
			RULES,
			loading: false,
			disabled: true,
			form: {},
			options: [],
		};
	},
	//
	mounted() {
		api.crmContactDetail(this.$route.params.id).then((res) => {
			if (res.success) {
				this.form = res.result;
			}
		});
	},
	methods: {
        onCancel(){
            this.$router.replace("/crm/contact-detail/"+that.form.id);
        },
		onSubmit() {
            this.$dialog
					.confirm({
						title: "提示",
						message: "是否确认信息修改！",
					})
					.then(() => {

                        api.crmContactEdit(this.form).then((res) => {
				if (res.success) {
                    let that=this;
					console.log("res", res);
                    this.$router.replace("/crm/contact/"+that.form.patientId);
				} else {
					Notify({ type: "danger", message: res.message || "修改联系人失败！" });
				}
			});

                    })

		},
	},
};
</script>

<style lang="less"></style>

// 流调问卷
export const investigationQuery = {
	"title": "流行病学调查表",
	"info": "<div style='text-indent: 2em;'>上海好望角医院疫情期间进入院区人员流行病学调查表。</div>",
	"hideInfo": true,
	"id": 3,
	"time": 3,
	"joinCount": 3,
	"start_btn": "立即填写",
	"effective": 24,
	"result_function": "",
	"statement": {
		"title": "流行病学调查表",
		"content":
			"<div style='font-weight: 700;margin-bottom: 10px;'>温馨提示：本流调表有效期24小时，请于来院前24小时内填写，谢谢！</div><div>根据《中华人民共和国传染病防治法》和《上海市突发公共卫生事件应急预案》等相关法律法规要求,请您配合我们填写以下内容，医院负责保护您所填写的个人信息安全。请您务必如实填报，若故意隐瞒相关情况，造成后果，您将要承担相应法律责任，谢谢您的理解和配合！</div>"
	},
	"questions": [
		{
			"title": "入院流调",
			"type": "content",
			"questions": [
				{
					"label": "14天内您或您的家人是否有以下症状：发烧、咳嗽、胸闷、气促、腹泻?",
					"type": "radio",
					"name": "1",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				},
				{
					"label": "您14天内是否去过中高风险地区？",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "2",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				},

				{
					"label": "您是否处于隔离医学观察期（境外来沪未满14天）？",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "3",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				},
				{
					"label": "14天内您是否接触过来自外地或境外，或来自其他有病例报告社区的发热或有呼吸道症状的患者？",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "4",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				},
				{
					"label": "14天内是否接触过新冠肺炎确诊或疑似患者或已知无症状感染者？",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "5",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				},
				{
					"label": "聚集性发病：14天内您所在的小范围内，如家庭、办公室、学校班级等场所出现过2例及以上发热或呼吸道症状的病例？",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "6",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C",
							"danger": 1
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3",
							"danger": 0
						}
					]
				}
			]
		},
		{
			"title": "基本信息",
			"type": "baseinfo",
			"questions": [
				{
					"label": "患者姓名",
					"type": "input",
					"name": "name",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"placeholder": "请填写患者姓名"
				},
				{
					"label": "患者身份证号码",
					"type": "input",
					"required": true,
					"placeholder": "请填写患者身份证号码",
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						},
						{
							"regExp": "^([1-6][1-9]|50)\\d{4}(18|19|20)\\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$",
							"type": "regExp",
							"message": "请填写正确的身份证号码"
						}
					],
					"name": "id_card"
				},
				{
					"label": "家属的姓名(选填)",
					"type": "input",
					"name": "family_name",
					"rules": [],
					"placeholder": "请填写家属的姓名(选填)"
				},
				{
					"label": "家属身份证号码(选填)",
					"type": "input",
					"placeholder": "请填写家属身份证号码(选填)",
					"rules": [
						{
							"regExp": "^([1-6][1-9]|50)\\d{4}(18|19|20)\\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$",
							"type": "regExp",
							"message": "请填写正确的身份证号码"
						}
					],
					"name": "family_id_card"
				},
				{
					"label": "您的联系电话",
					"type": "input",
					"placeholder": "请填写您的联系电话",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						},
						{
							"regExp": "^1[3456789]\\d{9}$",
							"type": "regExp",
							"message": "请填写正确的手机号码"
						}
					],
					"name": "mobile"
				},
				{
					"label": "您进入院区的事由",
					"type": "select",
					"name": "enter_reason",
					"placeholder": "请选择您进入院区的事由",
					"changeLink": true,
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"link": "receptionist",
					"option": [
						{
							"label": "住院",
							"value": "住院"
						},
						{
							"label": "就诊",
							"value": "就诊"
						},
						{
							"label": "陪诊/陪护",
							"value": "陪诊/陪护"
						},
						{
							"label": "探视",
							"value": "探视"
						},
						{
							"label": "预约接待",
							"value": "预约接待"
						},
						{
							"label": "其他",
							"value": "其他"
						}
					]
				},
				{
					"label": "接待者",
					"type": "input",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"hide": true,
					"showRule": [
						{
							"filed": "enter_reason",
							"value": "预约接待"
						}
					],
					"placeholder": "请填写您的接待者",
					"name": "receptionist"
				},
				{
					"label": "您的户籍地",
					"type": "select",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"placeholder": "请填写您的户籍地",
					"name": "residence_address"
				},
				{
					"label": "您目前的居住地址",
					"placeholder": "请填写您目前的居住地址",
					"type": "input",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "residential_address"
				}
			]
		},
		{
			"title": "知情同意书",
			"type": "agree",
			"questions": [
				{
					"label": "请上传患者最新的健康码与核酸记录截图",
					"placeholder": "请上传患者最新的健康码与核酸记录截图",
					"type": "upload",
					"required": true,
					"limit": 2,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						},
						{
							"limit": 2,
							"type": "file-limit",
							"message": "请上传患者最新的健康码与核酸记录截图"
						}
					],
					"name": "health_code"
				},
				{
					"label": "请上传陪护家属最新的健康码与核酸记录截图",
					"placeholder": "请上传陪护家属最新的健康码与核酸记录截图",
					"type": "upload",
					"limit": 2,
					"name": "family_health_code"
				},
				{
					"label": "新冠疫情防控知情同意书",
					"type": "desc-text",
					"style": "max-height:30vh;",
					"content": "<div style='text-indent: 2em;'>尊敬的患者或患者的法定监护人、授权委托人：</div><div style='text-indent: 2em;'>您好！</div><div style='text-indent: 2em;'>为避免患者间交叉感染，降低院内感染发生率，确保医疗秩序平稳，我院现对近期来院患者及家属提出以下要求：</div><div style='text-indent: 2em;'>1.来院前的48小时内为预入院期。在此期间内，上级医院闭环转诊的患者及陪护家属需完成至少1次核酸检测；社会面来院的患者需完成至少1次核酸检测，陪护家属需完成至少2次核酸检测。检测结果为阴性者方可来院办理入院手续，入院时提供上述资料，纸质版或电子版均可。同时，患者及家属须如实告知院方既往新冠病毒感染史及治疗情况（若有）等院方需了解的全部信息。患者及陪护家属均承诺所提供资料真实、准确、完整且合法有效，否则承担全部责任。</div><div style='text-indent: 2em;'>2.来院当天至入院后24小时内为缓冲观察期。在此期间内，除必要的检验、术后换药与延续性治疗外，不进行常规检查和诊疗，紧急抢救情况除外；上级医院闭环转诊情形下可豁免观察期。</div><div style='text-indent: 2em;'>3.患者入院24小时后至患者出院为诊疗期。在此期间内，患者及陪护家属须每天进行1次核酸检测，直至出院。</div><div style='text-indent: 2em;'>4.入院时，请患者及陪护家属尽量备齐生活必需品，在院期间实行闭环管理，原则上不得离开院区，病房内外均须佩戴医用外科口罩。住院期间每位患者最多1名陪护家属，非必要不得更换，谢绝探视。</div><div style='text-indent: 2em;'>5.如果因为国家或市区街镇等新冠疫情防控有关政策医院被封控，不能保证及时出院。</div>"
				},
				{
					"label": "患者本人既往是否感染过新冠病毒",
					"type": "radio",
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "is_get_covid",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C"
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3"
						}
					]
				},
				{
					"label": "陪护家属既往是否感染过新冠病毒（选填）",
					"type": "radio",
					"name": "family_is_get_covid",
					"option": [
						{
							"label": "是",
							"value": "是",
							"color": "#F56C6C"
						},
						{
							"label": "否",
							"value": "否",
							"color": "#5f5aa3"
						}
					]
				},
				{
					"label": "",
					"type": "desc-text",
					"style": "border:none;",
					"content": "患者本人及陪护家属承诺以上情况的真实性、准确性及完整性，如有隐瞒，自行承担所有责任。"
				},
				{
					"label": "签名人与患者关系",
					"type": "select",
					"name": "family_auth_relation",
					"placeholder": "请选择签名人与患者关系",
					"changeLink": true,
					"link": "sign",
					"customHide": [
						{
							"filed": "family_auth_sign",
							"value": ["配偶", "子女", "父母", "配偶", "兄弟姐妹", "亲友", "朋友", "其他"]
						},
						{
							"filed": "sign",
							"value": "本人"
						}
					],
					"default": "本人",
					"option": [
						{
							"label": "本人",
							"value": "本人"
						},
						{
							"label": "配偶",
							"value": "配偶"
						},
						{
							"label": "子女",
							"value": "子女"
						},
						{
							"label": "父母",
							"value": "父母"
						},
						{
							"label": "兄弟姐妹",
							"value": "兄弟姐妹"
						},
						{
							"label": "亲友",
							"value": "亲友"
						},
						{
							"label": "朋友",
							"value": "朋友"
						},
						{
							"label": "其他",
							"value": "其他"
						}
					]
				},
				{
					"label": "签名",
					"placeholder": "",
					"type": "sign",
					"hide": false,
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "sign"
				},

				{
					"label": "患者授权亲属签名",
					"placeholder": "",
					"type": "sign",
					"hide": true,
					"required": true,
					"rules": [
						{
							"required": true,
							"type": "required",
							"message": "该项为必填项"
						}
					],
					"name": "family_auth_sign"
				},
				{
					"label": "陪护家属（选填）",
					"type": "select",
					"changeLink": true,
					"link": "family_accompany_sign",
					"name": "family_accompany_relation",
					"placeholder": "请选择陪护家属与患者关系",
					"option": [
						{
							"label": "",
							"value": ""
						},
						{
							"label": "配偶",
							"value": "配偶"
						},
						{
							"label": "子女",
							"value": "子女"
						},
						{
							"label": "父母",
							"value": "父母"
						},
						{
							"label": "兄弟姐妹",
							"value": "兄弟姐妹"
						},
						{
							"label": "亲友",
							"value": "亲友"
						},
						{
							"label": "朋友",
							"value": "朋友"
						},
						{
							"label": "其他",
							"value": "其他"
						}
					]
				},
				{
					"label": "陪护家属签名（选填）",
					"placeholder": "",
					"type": "sign",
					"hide": true,
					"showRule": [
						{
							"filed": "family_accompany_relation",
							"value": "*"
						}
					],
					"name": "family_accompany_sign"
				},
				{
					"label": "患者订餐",
					"type": "select",
					"name": "patient_ordering",
					"placeholder": "请选择患者订餐",
					"option": [
						{
							"label": "普食",
							"value": "普食"
						},
						{
							"label": "半流质",
							"value": "半流质"
						},
						{
							"label": "流质",
							"value": "流质"
						},
						{
							"label": "不需要",
							"value": "不需要"
						}
					]
				},
				{
					"label": "陪护订餐",
					"type": "select",
					"name": "family_ordering",
					"placeholder": "请选择陪护订餐",
					"option": [
						{
							"label": "普食",
							"value": "普食"
						},
						{
							"label": "不需要",
							"value": "不需要"
						}
					]
				}
			]
		}
	]
};

export const ProvinceCity = [
	{
		text: "上海市",
		children: [
			{ text: "黄浦区" },
			{ text: "徐汇区" },
			{ text: "长宁区" },
			{ text: "静安区" },
			{ text: "普陀区" },
			{ text: "虹口区" },
			{ text: "杨浦区" },
			{ text: "闵行区" },
			{ text: "宝山区" },
			{ text: "嘉定区" },
			{ text: "浦东新区" },
			{ text: "金山区" },
			{ text: "松江区" },
			{ text: "青浦区" },
			{ text: "奉贤区" },
			{ text: "崇明区" }
		]
	},
	{
		text: "浙江省",
		children: [
			{ text: "杭州市" },
			{ text: "宁波市" },
			{ text: "温州市" },
			{ text: "嘉兴市" },
			{ text: "湖州市" },
			{ text: "绍兴市" },
			{ text: "金华市" },
			{ text: "衢州市" },
			{ text: "舟山市" },
			{ text: "台州市" },
			{ text: "丽水市" }
		]
	},
	{
		text: "江苏省",
		children: [
			{ text: "南京市" },
			{ text: "无锡市" },
			{ text: "徐州市" },
			{ text: "常州市" },
			{ text: "苏州市" },
			{ text: "南通市" },
			{ text: "连云港市" },
			{ text: "淮安市" },
			{ text: "盐城市" },
			{ text: "扬州市" },
			{ text: "镇江市" },
			{ text: "泰州市" },
			{ text: "宿迁市" }
		]
	},
	{
		text: "安徽省",
		children: [
			{ text: "合肥市" },
			{ text: "芜湖市" },
			{ text: "蚌埠市" },
			{ text: "淮南市" },
			{ text: "马鞍山市" },
			{ text: "淮北市" },
			{ text: "铜陵市" },
			{ text: "安庆市" },
			{ text: "黄山市" },
			{ text: "滁州市" },
			{ text: "阜阳市" },
			{ text: "宿州市" },
			{ text: "六安市" },
			{ text: "亳州市" },
			{ text: "池州市" },
			{ text: "宣城市" }
		]
	},
	{
		text: "北京市",
		children: [
			{ text: "东城区" },
			{ text: "西城区" },
			{ text: "朝阳区" },
			{ text: "丰台区" },
			{ text: "石景山区" },
			{ text: "海淀区" },
			{ text: "门头沟区" },
			{ text: "房山区" },
			{ text: "通州区" },
			{ text: "顺义区" },
			{ text: "昌平区" },
			{ text: "大兴区" },
			{ text: "怀柔区" },
			{ text: "平谷区" },
			{ text: "密云区" },
			{ text: "延庆区" }
		]
	},
	{
		text: "天津市",
		children: [
			{ text: "和平区" },
			{ text: "河东区" },
			{ text: "河西区" },
			{ text: "南开区" },
			{ text: "河北区" },
			{ text: "红桥区" },
			{ text: "东丽区" },
			{ text: "西青区" },
			{ text: "津南区" },
			{ text: "北辰区" },
			{ text: "武清区" },
			{ text: "宝坻区" },
			{ text: "滨海新区" },
			{ text: "宁河区" },
			{ text: "静海区" },
			{ text: "蓟州区" }
		]
	},
	{
		text: "河北省",
		children: [
			{ text: "石家庄市" },
			{ text: "唐山市" },
			{ text: "秦皇岛市" },
			{ text: "邯郸市" },
			{ text: "邢台市" },
			{ text: "保定市" },
			{ text: "张家口市" },
			{ text: "承德市" },
			{ text: "沧州市" },
			{ text: "廊坊市" },
			{ text: "衡水市" }
		]
	},
	{
		text: "山西省",
		children: [
			{ text: "太原市" },
			{ text: "大同市" },
			{ text: "阳泉市" },
			{ text: "长治市" },
			{ text: "晋城市" },
			{ text: "朔州市" },
			{ text: "晋中市" },
			{ text: "运城市" },
			{ text: "忻州市" },
			{ text: "临汾市" },
			{ text: "吕梁市" }
		]
	},
	{
		text: "内蒙古自治区",
		children: [
			{ text: "呼和浩特市" },
			{ text: "包头市" },
			{ text: "乌海市" },
			{ text: "赤峰市" },
			{ text: "通辽市" },
			{ text: "鄂尔多斯市" },
			{ text: "呼伦贝尔市" },
			{ text: "巴彦淖尔市" },
			{ text: "乌兰察布市" },
			{ text: "兴安盟" },
			{ text: "锡林郭勒盟" },
			{ text: "阿拉善盟" }
		]
	},
	{
		text: "辽宁省",
		children: [
			{ text: "沈阳市" },
			{ text: "大连市" },
			{ text: "鞍山市" },
			{ text: "抚顺市" },
			{ text: "本溪市" },
			{ text: "丹东市" },
			{ text: "锦州市" },
			{ text: "营口市" },
			{ text: "阜新市" },
			{ text: "辽阳市" },
			{ text: "盘锦市" },
			{ text: "铁岭市" },
			{ text: "朝阳市" },
			{ text: "葫芦岛市" }
		]
	},
	{
		text: "吉林省",
		children: [
			{ text: "长春市" },
			{ text: "吉林市" },
			{ text: "四平市" },
			{ text: "辽源市" },
			{ text: "通化市" },
			{ text: "白山市" },
			{ text: "松原市" },
			{ text: "白城市" },
			{ text: "延边朝鲜族自治州" }
		]
	},
	{
		text: "黑龙江省",
		children: [
			{ text: "哈尔滨市" },
			{ text: "齐齐哈尔市" },
			{ text: "鸡西市" },
			{ text: "鹤岗市" },
			{ text: "双鸭山市" },
			{ text: "大庆市" },
			{ text: "伊春市" },
			{ text: "佳木斯市" },
			{ text: "七台河市" },
			{ text: "牡丹江市" },
			{ text: "黑河市" },
			{ text: "绥化市" },
			{ text: "大兴安岭地区" }
		]
	},
	{
		text: "福建省",
		children: [
			{ text: "福州市" },
			{ text: "厦门市" },
			{ text: "莆田市" },
			{ text: "三明市" },
			{ text: "泉州市" },
			{ text: "漳州市" },
			{ text: "南平市" },
			{ text: "龙岩市" },
			{ text: "宁德市" }
		]
	},
	{
		text: "江西省",
		children: [
			{ text: "南昌市" },
			{ text: "景德镇市" },
			{ text: "萍乡市" },
			{ text: "九江市" },
			{ text: "新余市" },
			{ text: "鹰潭市" },
			{ text: "赣州市" },
			{ text: "吉安市" },
			{ text: "宜春市" },
			{ text: "抚州市" },
			{ text: "上饶市" }
		]
	},
	{
		text: "山东省",
		children: [
			{ text: "济南市" },
			{ text: "青岛市" },
			{ text: "淄博市" },
			{ text: "枣庄市" },
			{ text: "东营市" },
			{ text: "烟台市" },
			{ text: "潍坊市" },
			{ text: "济宁市" },
			{ text: "泰安市" },
			{ text: "威海市" },
			{ text: "日照市" },
			{ text: "临沂市" },
			{ text: "德州市" },
			{ text: "聊城市" },
			{ text: "滨州市" },
			{ text: "菏泽市" }
		]
	},
	{
		text: "河南省",
		children: [
			{ text: "郑州市" },
			{ text: "开封市" },
			{ text: "洛阳市" },
			{ text: "平顶山市" },
			{ text: "安阳市" },
			{ text: "鹤壁市" },
			{ text: "新乡市" },
			{ text: "焦作市" },
			{ text: "濮阳市" },
			{ text: "许昌市" },
			{ text: "漯河市" },
			{ text: "三门峡市" },
			{ text: "南阳市" },
			{ text: "商丘市" },
			{ text: "信阳市" },
			{ text: "周口市" },
			{ text: "驻马店市" },
			{ text: "济源市" }
		]
	},
	{
		text: "湖北省",
		children: [
			{ text: "武汉市" },
			{ text: "黄石市" },
			{ text: "十堰市" },
			{ text: "宜昌市" },
			{ text: "襄阳市" },
			{ text: "鄂州市" },
			{ text: "荆门市" },
			{ text: "孝感市" },
			{ text: "荆州市" },
			{ text: "黄冈市" },
			{ text: "咸宁市" },
			{ text: "随州市" },
			{ text: "恩施土家族苗族自治州" },
			{ text: "仙桃市" },
			{ text: "潜江市" },
			{ text: "天门市" },
			{ text: "神农架林区" }
		]
	},
	{
		text: "湖南省",
		children: [
			{ text: "长沙市" },
			{ text: "株洲市" },
			{ text: "湘潭市" },
			{ text: "衡阳市" },
			{ text: "邵阳市" },
			{ text: "岳阳市" },
			{ text: "常德市" },
			{ text: "张家界市" },
			{ text: "益阳市" },
			{ text: "郴州市" },
			{ text: "永州市" },
			{ text: "怀化市" },
			{ text: "娄底市" },
			{ text: "湘西土家族苗族自治州" }
		]
	},
	{
		text: "广东省",
		children: [
			{ text: "广州市" },
			{ text: "韶关市" },
			{ text: "深圳市" },
			{ text: "珠海市" },
			{ text: "汕头市" },
			{ text: "佛山市" },
			{ text: "江门市" },
			{ text: "湛江市" },
			{ text: "茂名市" },
			{ text: "肇庆市" },
			{ text: "惠州市" },
			{ text: "梅州市" },
			{ text: "汕尾市" },
			{ text: "河源市" },
			{ text: "阳江市" },
			{ text: "清远市" },
			{ text: "东莞市" },
			{ text: "中山市" },
			{ text: "潮州市" },
			{ text: "揭阳市" },
			{ text: "云浮市" }
		]
	},
	{
		text: "广西壮族自治区",
		children: [
			{ text: "南宁市" },
			{ text: "柳州市" },
			{ text: "桂林市" },
			{ text: "梧州市" },
			{ text: "北海市" },
			{ text: "防城港市" },
			{ text: "钦州市" },
			{ text: "贵港市" },
			{ text: "玉林市" },
			{ text: "百色市" },
			{ text: "贺州市" },
			{ text: "河池市" },
			{ text: "来宾市" },
			{ text: "崇左市" }
		]
	},
	{
		text: "海南省",
		children: [
			{ text: "海口市" },
			{ text: "三亚市" },
			{ text: "三沙市" },
			{ text: "儋州市" },
			{ text: "五指山市" },
			{ text: "琼海市" },
			{ text: "文昌市" },
			{ text: "万宁市" },
			{ text: "东方市" },
			{ text: "定安县" },
			{ text: "屯昌县" },
			{ text: "澄迈县" },
			{ text: "临高县" },
			{ text: "白沙黎族自治县" },
			{ text: "昌江黎族自治县" },
			{ text: "乐东黎族自治县" },
			{ text: "陵水黎族自治县" },
			{ text: "保亭黎族苗族自治县" },
			{ text: "琼中黎族苗族自治县" }
		]
	},
	{
		text: "重庆市",
		children: [
			{ text: "万州区" },
			{ text: "涪陵区" },
			{ text: "渝中区" },
			{ text: "大渡口区" },
			{ text: "江北区" },
			{ text: "沙坪坝区" },
			{ text: "九龙坡区" },
			{ text: "南岸区" },
			{ text: "北碚区" },
			{ text: "綦江区" },
			{ text: "大足区" },
			{ text: "渝北区" },
			{ text: "巴南区" },
			{ text: "黔江区" },
			{ text: "长寿区" },
			{ text: "江津区" },
			{ text: "合川区" },
			{ text: "永川区" },
			{ text: "南川区" },
			{ text: "璧山区" },
			{ text: "铜梁区" },
			{ text: "潼南区" },
			{ text: "荣昌区" },
			{ text: "开州区" },
			{ text: "梁平区" },
			{ text: "武隆区" },
			{ text: "城口县" },
			{ text: "丰都县" },
			{ text: "垫江县" },
			{ text: "忠县" },
			{ text: "云阳县" },
			{ text: "奉节县" },
			{ text: "巫山县" },
			{ text: "巫溪县" },
			{ text: "石柱土家族自治县" },
			{ text: "秀山土家族苗族自治县" },
			{ text: "酉阳土家族苗族自治县" },
			{ text: "彭水苗族土家族自治县" }
		]
	},
	{
		text: "四川省",
		children: [
			{ text: "成都市" },
			{ text: "自贡市" },
			{ text: "攀枝花市" },
			{ text: "泸州市" },
			{ text: "德阳市" },
			{ text: "绵阳市" },
			{ text: "广元市" },
			{ text: "遂宁市" },
			{ text: "内江市" },
			{ text: "乐山市" },
			{ text: "南充市" },
			{ text: "眉山市" },
			{ text: "宜宾市" },
			{ text: "广安市" },
			{ text: "达州市" },
			{ text: "雅安市" },
			{ text: "巴中市" },
			{ text: "资阳市" },
			{ text: "阿坝藏族羌族自治州" },
			{ text: "甘孜藏族自治州" },
			{ text: "凉山彝族自治州" }
		]
	},
	{
		text: "贵州省",
		children: [
			{ text: "贵阳市" },
			{ text: "六盘水市" },
			{ text: "遵义市" },
			{ text: "安顺市" },
			{ text: "毕节市" },
			{ text: "铜仁市" },
			{ text: "黔西南布依族苗族自治州" },
			{ text: "黔东南苗族侗族自治州" },
			{ text: "黔南布依族苗族自治州" }
		]
	},
	{
		text: "云南省",
		children: [
			{ text: "昆明市" },
			{ text: "曲靖市" },
			{ text: "玉溪市" },
			{ text: "保山市" },
			{ text: "昭通市" },
			{ text: "丽江市" },
			{ text: "普洱市" },
			{ text: "临沧市" },
			{ text: "楚雄彝族自治州" },
			{ text: "红河哈尼族彝族自治州" },
			{ text: "文山壮族苗族自治州" },
			{ text: "西双版纳傣族自治州" },
			{ text: "大理白族自治州" },
			{ text: "德宏傣族景颇族自治州" },
			{ text: "怒江傈僳族自治州" },
			{ text: "迪庆藏族自治州" }
		]
	},
	{
		text: "西藏自治区",
		children: [
			{ text: "拉萨市" },
			{ text: "日喀则市" },
			{ text: "昌都市" },
			{ text: "林芝市" },
			{ text: "山南市" },
			{ text: "那曲市" },
			{ text: "阿里地区" }
		]
	},
	{
		text: "陕西省",
		children: [
			{ text: "西安市" },
			{ text: "铜川市" },
			{ text: "宝鸡市" },
			{ text: "咸阳市" },
			{ text: "渭南市" },
			{ text: "延安市" },
			{ text: "汉中市" },
			{ text: "榆林市" },
			{ text: "安康市" },
			{ text: "商洛市" }
		]
	},
	{
		text: "甘肃省",
		children: [
			{ text: "兰州市" },
			{ text: "嘉峪关市" },
			{ text: "金昌市" },
			{ text: "白银市" },
			{ text: "天水市" },
			{ text: "武威市" },
			{ text: "张掖市" },
			{ text: "平凉市" },
			{ text: "酒泉市" },
			{ text: "庆阳市" },
			{ text: "定西市" },
			{ text: "陇南市" },
			{ text: "临夏回族自治州" },
			{ text: "甘南藏族自治州" }
		]
	},
	{
		text: "青海省",
		children: [
			{ text: "西宁市" },
			{ text: "海东市" },
			{ text: "海北藏族自治州" },
			{ text: "黄南藏族自治州" },
			{ text: "海南藏族自治州" },
			{ text: "果洛藏族自治州" },
			{ text: "玉树藏族自治州" },
			{ text: "海西蒙古族藏族自治州" }
		]
	},
	{ text: "宁夏回族自治区", children: [{ text: "银川市" }, { text: "石嘴山市" }, { text: "吴忠市" }, { text: "固原市" }, { text: "中卫市" }] },
	{
		text: "新疆维吾尔自治区",
		children: [
			{ text: "乌鲁木齐市" },
			{ text: "克拉玛依市" },
			{ text: "吐鲁番市" },
			{ text: "哈密市" },
			{ text: "昌吉回族自治州" },
			{ text: "博尔塔拉蒙古自治州" },
			{ text: "巴音郭楞蒙古自治州" },
			{ text: "阿克苏地区" },
			{ text: "克孜勒苏柯尔克孜自治州" },
			{ text: "喀什地区" },
			{ text: "和田地区" },
			{ text: "伊犁哈萨克自治州" },
			{ text: "塔城地区" },
			{ text: "阿勒泰地区" },
			{ text: "石河子市" },
			{ text: "阿拉尔市" },
			{ text: "图木舒克市" },
			{ text: "五家渠市" },
			{ text: "北屯市" },
			{ text: "铁门关市" },
			{ text: "双河市" },
			{ text: "可克达拉市" },
			{ text: "昆玉市" },
			{ text: "胡杨河市" },
			{ text: "新星市" }
		]
	}
];

<template>
	<div class="crm-jclist">
		<div><div class="crm-jclist-body-scroll">
			<div  class="showTitle"
				><p>共{{list.length}}条</p>
				<p @click="showPopup">筛选<van-icon name="brush-o" /></p
			></div>
			<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
				<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
					<van-cell
						:title="item.checkType | convertDict(jc_type)"
						:label="dayjs(item.reportDate).format('YYYY-MM-DD HH:mm')"
						@click="jumpParamsPages('crm-jcreport-hospital', item,list)"
						is-link
						v-for="(item, index) in list"
						:key="index"
					></van-cell>
				</van-list>
			</van-pull-refresh>
		</div>
		<van-popup  class="crm-jc-popup" v-model="show" >
			<van-row>
				<van-col class="popup-title">类型筛选</van-col>
			</van-row>
			<div class="popup-row">
				<div class="popup-col"  v-for="item in jcList" :key="item.value"
					><van-tag @click="select(item.value)" color="#5f5aa3" size="large" :plain="item.jcRadio" type="primary">{{
						item.title
					}}</van-tag></div
				>
			</div>
		<van-row class="popup_ok">	<van-button  color="#5f5aa3" size="small" @click="onSearch()">确定</van-button></van-row>
		</van-popup></div>
		<div></div>
	</div>

</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import JyReportDetail from "@/views/CRM/InHospitalList/JyReportDetail.vue";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-jcList",
	mixins: [crmMixins],
	components: { SexIcon ,JyReportDetail},
	props: {
		inWardFrom: Object,
		upData:Number,
	},
	data() {
		return {
			dayjs,
			show: false,
			applyList: [],
			url: {
				list: "/crm-service/crm/crmCmsJcreportlist/list",
			},
			queryParams: {
				column: "report_date",
				order: "desc",
				certId: this.inWardFrom.idCard,
				patientName: this.inWardFrom.patientName,
			},

			statusList: [],
			applyId: "",
			zjh: "",
			jcList:[
				{
					text: "全部",
					title: "全部",
					value: null,
					jcRadio: true,
				},
			],
			isScrollTop:0,num:0,
		};
	},
	mounted() {
		this.$nextTick(() => {

const listInfo=	sessionStorage.getItem("listInfo")
const tabScrollTop=	sessionStorage.getItem("tabScrollTop")
if(!!listInfo&&listInfo.length!=0){
this.list=JSON.parse(listInfo);
const $wrapper = document.querySelector('.crm-jclist');

$wrapper.scrollTop = tabScrollTop;
}
})
	},
	watch: {
    // 监听 myData 的变化
    upData(newVal, oldVal) {
      // 当 myData 变化时，这个函数会被调用
      // newVal 是 myData 的新值，oldVal 是旧值
      console.log('myData 改变了: ', newVal, '（之前是：', oldVal, '）');
      // 在这里执行你需要的逻辑
	  if(newVal!=oldVal){
		this.refreshData();
	  }

    },
	list(newVal, oldVal) {

if(newVal!=oldVal&&this.num<2){
  const tabScrollTop=sessionStorage.getItem("tabScrollTop")
  const $wrapper = document.querySelector('.crm-jclist');
  $wrapper.scrollTop = tabScrollTop;
  this.num=this.num+1
//   sessionStorage.removeItem("detailPageNo");
// sessionStorage.removeItem("listInfo");
// sessionStorage.removeItem("tabScrollTop");

}
}
  },
	methods: {
		jumpParamsPages(path, params,listInfo) {

const $wrapper = document.querySelector('.crm-jclist');
console.log(path,params,$wrapper);
const detailPageNo=Math.ceil(listInfo.length / 15);
sessionStorage.setItem('detailPageNo',detailPageNo)
sessionStorage.setItem("listInfo",JSON.stringify(listInfo))
sessionStorage.setItem("tabScrollTop",$wrapper.scrollTop)
this.$router.push({name: path,params: {params:params}});
},
		refreshData(a) {
			this.jc_type.map((item) => {
			this.$set(item, "jcRadio", true);
		});
		this.jcList = this.jcList.concat(this.jc_type);
		this.loadPage();
		},
		showPopup() {
			this.show = true;
		},
		select(value) {
			let that = this;
			console.log("		that.jcList",		that.jcList);
			that.jcList.map((i) => {
				if (value == i.value) {
					i.jcRadio = !i.jcRadio;
					that.queryParams.checkType = i.value;
				} else {
					i.jcRadio = true;
				}
			});
		},
		onSearch() {
			this.show = false;debugger
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-jclist {
	text-align: left;
	width: 100vw;
    height: 100vh;
    overflow: auto;
	padding-bottom: 6rem;
	.showTitle {
	// margin-top: 2px;
	background-color: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.533rem 0.1rem;
	p {
		color: #969799;
		text-align: left;
		font-weight: 400;
		font-size: 0.647rem;
		line-height: 1.04rem;
		margin-bottom: 0rem;
	}
}
}

.crm-jc-popup {
	.popup_ok{
	text-align: right;
	margin-top: 30px;
}
	padding: 80px 50px;
	width: 80vw;
	border-radius: 10px;
	.popup-title{
	font-size: 32px;
	font-weight: 600;
    line-height: 22px;
	margin-bottom: 20px;
}
}
.crm-jclist .showTitle {
	// margin-top: 2px;
	background-color: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.533rem 0.1rem;
	p {
		color: #969799;
		text-align: left;
		font-weight: 400;
		font-size: 0.647rem;
		line-height: 1.04rem;
		margin-bottom: 0rem;
	}
}
.crm-jclist  .popup-row{
	display: flex;
	flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: space-around;
	.popup-col {
		width: 33%;
		padding-top: 40px;

		span {
			width: 100%;
			justify-content: center;
		}
		&:nth-child(3n + 1) {
			padding-right: 10px;
		}
		&:nth-child(3n + 2) {
			padding-left: 5px;
			padding-right: 5px;
		}

		&:nth-child(3n + 3) {
			padding-left: 10px;
		}
	}
}
</style>

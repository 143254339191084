<template>
	<div class="investigation-page">
		<div class="logo-icon"></div>
		<div class="logo-right"></div>
		<archives-cover :survey="surveyInfo" @begin-test="beginTest" />
		<archives-agreement :statement="statement" ref="coverRef" @agree="handlerAgree" />
	</div>
</template>

<script>
import archivesCover from "./components/archives-cover";
import archivesAgreement from "./components/archives-agreement";
// import myMixins from "@/utils/mixins";
import api from "@/request/api";
import crmMixins from "@/utils/crm-mixins";
import myMixins from "@/utils/mixins";
import { mapGetters } from "vuex";
import { archivesQuery } from "@/constant/archivesQuery";
export default {
	name: "archives-page",
	// mixins: [myMixins],
	mixins: [crmMixins, myMixins],
	components: {
		archivesCover,
		archivesAgreement
	},
	data() {
		return {
			hasAgree: false,
			resultSurveyInfo: {},
			surveyInfo: {},
			statement: {}
		};
	},
	computed: {
		...mapGetters(["userInfo"])
	},
	mounted() {
		document.title = "上海好望角医院线上登记";
		const saleId = this.$route.query.saleId;
		if (!saleId) {
			this.$router.replace("/survey/404");
			console.log("跳转到landing page， 因为没有问卷id");
			return false;
		} else {
			this.autoLogin().then(() => {
				// this.getSurvey();
				this.initQuery(archivesQuery);
			});
		}
	},
	methods: {
		init() {
			this.showLoading();
			api.listByUserId()
				.then(resp => {
					const item = (resp.result || [])[0] || {};
					this.$store.dispatch("set_archives_value", { ...item });
					if ((resp.result || []).some(i => i.status)) {
						this.$dialog
							.confirm({
								title: "提示",
								messageAlign: "left",
								confirmButtonText: "我的建档记录",
								cancelButtonText: "立即建档",
								message: "查询到您已存在建档记录，您可以选择创建新的建档也可以查看您之前的建档记录。"
							})
							.then(() => {
								this.$router.push({
									path: "/archives/list",
									query: {
										...this.$route.query
									}
								});
							})
							.catch(() => {
								const params = { ...this.$route.query };
								delete params.type;
								delete params.archivesId;
								this.$router.push({
									path: "/archives/question",
									query: params
								});
							});
					} else {
						this.$router.push({
							path: "/archives/question",
							query: {
								...this.$route.query
							}
						});
					}
					this.hideLoading();
				})
				.finally(() => this.hideLoading());
		},
		getSurvey() {
			const surveyId = this.$route.query.id;
			const saleId = this.$route.query.saleId;
			if (!surveyId) {
				this.$router.replace("/survey/404");
				console.log("跳转到landing page， 因为没有问卷id");
				return false;
			} else {
				api.querySurvey(surveyId)
					.then(res => {
						if (res.success) {
							try {
								const contentJson = JSON.parse(res.result.contentJson);
								this.initQuery(contentJson);
							} catch (error) {
								console.log("问卷信息设置失败", error);
							}
						} else {
							this.$router.replace("/404");
						}
					})
					.catch(() => {
						this.$store.dispatch("set_archives", {});
					});
			}
		},
		initQuery(content) {
			let contentJson = {};
			contentJson.title = content.title;
			contentJson.duration = content.duration;
			contentJson.time = content.time;
			contentJson.id = content.id;
			contentJson.effective = content.effective;
			contentJson.total = content.joinCount;
			contentJson.statement = content.statement;
			contentJson.result_function = content.answerJson;
			contentJson.questions = content.questions;
			this.resultSurveyInfo = content;
			this.surveyInfo = content;
			this.statement = contentJson.statement;
			if (contentJson.statement) {
				this.$refs.coverRef.showModal();
			}
			this.$store.dispatch("set_archives", { ...contentJson });
		},
		handlerAgree() {
			if (this.userInfo && this.userInfo.phone) {
				this.init();
			} else {
				this.$store.dispatch("set_archives_value", {});
				this.hasAgree = true;
				this.$router.push({
					path: "/archives/question",
					query: {
						...this.$route.query
					}
				});
			}
		},
		beginTest() {
			if (this.hasAgree) {
				this.handlerAgree();
			} else {
				this.$refs.coverRef.showModal();
			}
		}
	}
};
</script>

<style lang="less">
@import url("~@/variables.less");
.investigation-page {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	background: url("~@/assets/images/bg.png") no-repeat top left;
	background-size: 100% 100%;
	position: relative;
	.van-dialog {
		width: 600px;
	}
}
.logo-icon {
	position: absolute;
	top: 70px;
	left: 70px;
	width: 115px;
	height: 93px;
	background: url("~@/assets/images/logo.png") no-repeat center center/contain;
}
.logo-right {
	position: absolute;
	top: 110px;
	right: 0;
	width: 233px;
	height: 151px;
	background: url("~@/assets/images/home-img.png") no-repeat center center/contain;
}
</style>

<template>
	<div class="normal-form">
		<div class="form-label"
			v-if="model.label">
			<span v-if="model.required"
				class="label-required">*</span>
			<span class="label-index"
				v-if="showIndex">
				{{childIndex+1}}.
			</span>
			{{ model.label }}
		</div>
		<div :class="['input-value',error?'error':'']">
			<div class="input-value-cell-body">
				<input type="text"
					readonly
					@click="handleClick"
					:value="inputValue"
					:placeholder="model.placeholder||`请选择${model.label}`" />
				<span class="icon-down"></span>
			</div>
			<van-popup v-model="showPicker"
				position="bottom">
				<van-picker show-toolbar
					:columns="columns"
					ref="vanPickerRef"
					:visible-item-count="columns.length > 10 ? 10 : 4"
					@confirm="onConfirm"
					@cancel="showPicker = false" />
			</van-popup>
		</div>
		<div class="error-tip"
			v-if="errorTip && error">{{errorTip}}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	import { ProvinceCity } from "@/constant/investigationQuery"
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			childIndex: Number,
			showIndex: Boolean,
			value: [String, Number, Array, null]
		},
		computed: {
			columns: function () {
				if (this.model.option && this.model.option.length) {
					return this.model.option.map(i => i.label)
				} else if (this.model.name === 'residence_address') {
					return ProvinceCity
				} else {
					return []
				}
			}
		},
		data () {
			return {
				errorTip: "",
				error: false,
				showPicker: false,
				inputValue: null
			};
		},
		mounted () {
			this.init();
		},
		methods: {
			onConfirm (text, index) {
				this.resetValidate();
				this.inputValue = text;
				this.$emit("input", this.model.option ? this.model.option[index].value : (Array.isArray(text) ? text.join(',') : text));
				this.$emit("change", this.model.option ? this.model.option[index].value : (Array.isArray(text) ? text.join(',') : text));
				this.showPicker = false;
			},
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = this.model.default || null;
					if (this.model.default) {
						this.changeValue()
					}
				}
			},
			changeValue () {
				this.resetValidate();
				this.$emit("input", Array.isArray(this.inputValue) ? this.inputValue.join(',') : this.inputValue, this.model);
			},
			handleClick () {
				this.resetValidate();
				this.showPicker = true;
				this.$nextTick(() => {
					if (this.model.name === 'residence_address') {
						if (!Array.isArray(this.inputValue) && this.inputValue) {
							this.$refs.vanPickerRef.setValues(this.inputValue.split(','))
						}
					}
				})
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");
	.normal-form {
		.input-value {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0;
			.input-value-cell-body {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid @font11;
				padding-left: 0;
				padding-right: 0;
				background-color: @bg6;
				padding: 0 10px;
				border-radius: 3px;
				&:focus {
					border-bottom: 1px solid @font7;
				}
			}
			.icon-down {
				display: inline-block;
				width: 32px;
				height: 32px;
				background: url("~@/assets/crm/icon/icon-down.png") no-repeat center center/contain;
			}
			input {
				background: none;
				border: none;
				box-shadow: none;
				padding: 10px;
				text-align: left;
				display: block;
				width: 100%;
				min-width: 240px;
				font-size: 32px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				line-height: 60px;
				color: @font1;
				caret-color: @font1;
				&::placeholder {
					.placeholder-font;
					color: @font3;
					font-size: 26px;
					opacity: 0.7;
					text-align: left;
				}
			}
		}
	}
</style>

<template>
	<div class="crm-hos-detail">
		<div class="crm-inhospital-nav">
			<div class="crm-inhospital-nav-text">
				<p>{{ hospitalFrom.patientName }}</p>
				<p>
					{{ hospitalFrom.patientSex_dictText }}&nbsp;{{ hospitalFrom.patientAge + "岁" }}&nbsp;住院次数：{{
						hospitalFrom.inHosSeq == null ? "-" : hospitalFrom.inHosSeq
					}}/{{ hospitalFrom.inHospitalTotal }}
				</p>
				<p>诊断:{{ hospitalFrom.zhenDuan }}</p>
			</div>
			<div class="crm-inhospital-nav-icon">
				<img v-if="hospitalFrom.status === 'IN-HOSPITAL'" src="@/assets/crm/icon/detail-icon-zyz.png" alt="住院中" />
				<img v-else-if="hospitalFrom.status === 'OUT'" src="@/assets/crm/icon/detail-icon-out.png" alt="已出院" />
				<img v-else src="@/assets/crm/icon/detail-icon-other.png" alt="患者状态" />
				<span>{{ hospitalFrom.status_dictText }}</span>
			</div>
		</div>

		<div class="crm-inhospital-main">
			<div class="crm-information">
				<p><strong>住院信息</strong></p>
				<p><span style="width: 5.867rem">所在院区:</span>{{ hospitalFrom.nsArea_dictText }}</p>
				<p>所在病区:{{ hospitalFrom.inWard_dictText }}</p>
				<p>住院号:{{ hospitalFrom.blh }}</p>
				<p>床号:{{ hospitalFrom.inBed }}</p>

				<p>
					入院日期:

					<span v-if="hospitalFrom.inHosTime == null"> - </span>
					<span v-else>
						{{ hospitalFrom.inHosTime | formatDate("YYYY-MM-DD") }}
					</span>
				</p>
				<p>
					出院日期:<span v-if="hospitalFrom.outHosTime == null"> - </span>
					<span v-else>
						{{ hospitalFrom.outHosTime | formatDate("YYYY-MM-DD") }}
					</span>
				</p>
				<p>管床医生:{{ hospitalFrom.inNurse }}</p>
				<p>医保类型:{{ hospitalFrom.ybdm_dictText }}</p>
			</div>
			<div class="crm-information crm-zz">
				<p><strong>转诊信息</strong></p>
				<p>转诊医院:{{ hospitalFrom.zzHospital == "other" ? hospitalFrom.zzHospitalOther : hospitalFrom.zzHospital_dictText }}</p>
				<p>转诊科室:{{ hospitalFrom.zzDepartment == "other" ? hospitalFrom.zzDepartmentOther : hospitalFrom.zzDepartment }}</p>
				<p>会诊医生:{{ hospitalFrom.consultationDoctor }}</p>
			</div>
			<div class="crm-information crm-zz">
				<p><strong>院后信息</strong></p>
				<p>出院方式:{{ hospitalFrom.outType_dictText }}</p>
				<p>治疗周期:{{ hospitalFrom.treatmentCycle_dictText }}</p>
				<p>是否为新周期:{{ hospitalFrom.isNewTreatmentCycle | convertDict(yn_type) }}</p>
				<p v-if="hospitalFrom.nextHospitalTime">下次来院时间:{{ hospitalFrom.nextHospitalTime| formatDate("YYYY-MM-DD") }}</p>
				<p v-else>下次来院时间:</p>
			</div>
			<div class="crm-information crm-zz">
				<p><strong>其他信息</strong></p>
				<p>建档人:{{ hospitalFrom.patientCreateBy }}</p>
				<p>申请人:{{ hospitalFrom.createBy }}</p>
				<p>审核人:{{ hospitalFrom.auditBy }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import crmMIxins from "@/utils/crm-mixins.js";
import ImgField from "@/components/CRM/ImgField";
import SexIcon from "@/components/CRM/SexIcon";
import StatusIcon from "@/components/CRM/StatusIcon";
import CRMTextarea from "@/components/CRM/Textarea";
import api from "@/request/api";
import { Notify } from "vant";
import { format } from "path";
import VClamp from "vue-clamp";

export default {
	mixins: [crmMIxins],
	components: { ImgField, SexIcon, StatusIcon, VClamp, CRMTextarea },
	props: {
		inWardFrom: Object,	upData:Number,
	},
	data() {
		return {
			hospitalFrom: this.inWardFrom,
		};
	},
	mounted() {
		// this.hospitalFrom = this.$route.query;
	},
	methods: {},
};
</script>

<style lang="less">
@import url("~@/variables.less");
.icon-text-color {
	color: pink;
}
.crm-hos-detail {
	background-color: #f2f2f2;
	width: 100vw;
    height: 100vh;
    overflow: auto;
	padding-bottom: 6rem;
	p {
		text-align: left;
		font-weight: 400;
		font-size: 0.747rem;
		padding: 0.02rem 0.533rem;
		line-height: 1.04rem;
		color: #333333;
		margin-bottom: 0.24rem;
	}
	.crm-inhospital-nav {
		margin: 0.533rem;
		padding: 0.748rem;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		.crm-inhospital-nav-text {
			// padding: 0 .533rem;
			p:first-child {
				font-weight: 600;
				font-size: 0.853rem;
				line-height: 1.2rem;
				text-align: left;
			}
			p:last-child {
				padding-bottom: 0;
			}
		}
		.crm-inhospital-nav-icon {
			display: flex;
			flex-direction: column;
			img {
				width: 3.2rem;
				object-fit: cover;
			}
			span {
				font-weight: 600;
				font-size: 0.78rem;
				color: #5f5aa3;
			}
		}
	}
	.crm-inhospital-main {
		margin: 0.533rem;
		// padding: 1.7rem;
		.crm-information {
			background-color: #fff;
			padding: 0.748rem;
			p:first-child {
				font-size: 0.8rem;
			}
		}
		.crm-zz {
			margin: 0.625rem 0;
		}
	}
}
</style>

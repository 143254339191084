<template>
	<div class="wechat-login">
		<div>微信授权登录</div>
		<pre>
			{{ JSON.stringify(loginResp, null, 2) }}
		</pre
		>
	</div>
</template>

<script>
import api from "@/request/api";
import { mapGetters } from "vuex";
import wechatMixins from "@/utils/wechat-mixins";
export default {
	computed: {
		...mapGetters(["redirectUrl", "wechatToken"])
	},
	mixins: [wechatMixins],
	data() {
		return {
			loginResp: {
				s: 12,
				data: {
					a: 12
				}
			}
		};
	},
	created() {
		const code = this.$route.query.code;
		console.log("code", code);
		if (code) {
			api.wechatLogin({ code })
				.then(res => {
					console.log(res);
					// if (res.data.code == 1) {
					// 	let data = res.data.result_data;
					// 	// 校验获取到的用户信息是否为空（code只能用一次）
					// 	if (!flag) {
					// 		// 存储微信授权用户信息
					// 		sessionStorage.setItem("wxInfo", JSON.stringify(data));
					// 		// 获取初次访问的URL地址
					// 		let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
					// 		// 重新打开指定页面
					// 		this.$router.replace(redirectUrl);
					// 	} else {
					// 		if (sessionStorage.getItem("wxInfo")) {
					// 			let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
					// 			this.$router.replace(redirectUrl);
					// 		}
					// 	}
					// } else {
					// 	console.log("用户授权失败：", res.data.message);
					// }
					this.$store.dispatch("set_wechat_token", window.location.href);
				})
				.catch(err => {
					console.log(err);
					// sessionStorage.setItem("isLogin", true);
				});
		}
	},
	methods: {
		// 获取地址栏参数
		getQueryString(n) {
			let r,
				reg = new RegExp("(^|&)" + n + "=([^&]*)(&|$)");
			r = window.location.search.substr(1).match(reg);
			return r ? decodeURI(r[2]) : null;
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/crm_variables.less");
.form-box {
	margin: 30px 0;
}
.submit-box {
	display: flex;
	justify-content: space-around;
	margin: 30px 0;
}
</style>

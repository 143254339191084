<template>
	<div class="crm-textarea-cell">
		<div class="crm-textarea-title" v-if="label.length > 0">
			<span class="font-red" v-if="isRequired">*</span>
			{{ label }}
			<slot name="label" />
		</div>
		<div class="crm-textarea-body">
			<van-field
				class="crm-textarea-item"
				@input="onChange"
				:readonly="readonly"
				v-model="textarea"
				:rows="rows"
				autosize
				type="textarea"
				:placeholder="placeholder"
				:rules="rules"
				v-bind="attr"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "crm-textarea",
	props: {
		readonly: {
			type: Boolean,
			default: false
		},
		value: {
			type: String | Number,
			default: ""
		},
		rows: {
			type: Number,
			default: 2
		},
		autosize: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		attr: {
			type: Object,
			default: () => {
				return {};
			}
		},
		rules: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			textarea: this.value
		};
	},
	watch: {
		value: function(a) {
			this.textarea = a;
		}
	},
	methods: {
		onChange(value) {
			this.$emit("input", value);
		}
	}
};
</script>

<style lang="less">
.crm-textarea-cell {
	text-align: left;
	.crm-textarea-title {
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		padding: 0 16px;
		margin-bottom: 24px;
	}
	.crm-textarea-item {
		background: #f2f2f2;
		border-radius: 12px;
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		color: #b0b0b0;
		padding: 24px 20px;
	}

	.font-red {
		color: #ff5656;
		padding-right: 10px;
		font-size: 50px;
		line-height: 28px;
		vertical-align: bottom;
	}
}
</style>

<template>
	<div class="sex-icon">
		<img v-if="sex == '1'" src="@/assets/crm/icon/icon-male.png" alt="" />
		<img v-else src="@/assets/crm/icon/icon-female.png" alt="" />
	</div>
</template>
<script>
export default {
	name: "sex-icon",
	props: {
		sex: {
			type: String,
			default: "male"
		}
	}
};
</script>
<style lang="less" scoped>
.sex-icon {
	width: 32px;
	background-color: #fff;
	border-radius: 8px;
	padding: 4px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
		display: inline-block;
	}
}
</style>

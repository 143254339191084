import api from '@/request/api'
import TYPES from '@/constant/types.js'

export default {
    data () {
        return {
            width: window.innerWidth
        }
    },
    methods: {
        async autoLogin () {
            const uuid = localStorage.getItem(TYPES.UUID_KEY);
            const token = localStorage.getItem(TYPES.TOKEN_KEY);
            if (!uuid && !token) {
                return api.autoRegister()
            } else if (uuid) {
                return api.autoRegister({ uuid })
            }
        }
    }
}
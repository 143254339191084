<template>
	<div class="question-page">
		<van-form @submit="submit">
			<div class="question-box">
				<div class="question-group">
					<p class="question-group-title"
						 id="baseinfo">基本信息</p>
					<div class="question-group-body">
						<van-field v-model="fd.name"
											 required
											 name="username"
											 label="患者姓名"
											 placeholder="患者姓名"
											 :rules="[{ required: true, message: '请填写患者姓名' }]" />
						<CRMSelect v-model="fd.cardType"
											 name="cardType"
											 label="证件类型"
											 :attr="{ required: true }"
											 placeholder="选择证件类型"
											 :columns="card_type"
											 :rules="[{ required: true, message: '请选择证件类型' }]" />
						<van-field v-if="fd.cardType === '身份证'"
											 v-model="fd.idCard"
											 name="idCard"
											 label="身份证号"
											 required
											 placeholder="输入身份证号"
											 maxlength="18"
											 @blur="updateAge"
											 :rules="[
											 	{ required: true, message: '请填写身份证号' },
											 	{ validator: RULES.idCard, message: '请填写正确的身份证号' }
											 ]" />
						<van-field v-else
											 v-model="fd.idCard"
											 name="idCard"
											 label="证件号码"
											 required
											 placeholder="输入证件号码"
											 :rules="[{ required: true, message: '请填写证件号码' }]" />
						<CRMSelect v-model="fd.source"
											 :deep="2"
											 name="source"
											 label="居住地区"
											 :attr="{ required: true }"
											 placeholder="选择居住地区"
											 :columns="sourceData.tree"
											 :all="sourceData.all"
											 :rules="[{ required: true, message: '请选择居住地区' }]" />
						<CRMSelect v-model="fd.sex"
											 name="sex"
											 label="性别"
											 :attr="{ required: true }"
											 placeholder="选择性别"
											 :columns="sex"
											 :rules="[{ required: true, message: '请选择性别' }]" />
						<van-field v-model="fd.age"
											 type="digit"
											 name="age"
											 label="年龄"
											 required
											 maxlength="3"
											 placeholder="请输入年龄"
											 :rules="[{ required: true, message: '请填写年龄' }]" />
					</div>
					<div class="crm-card-body">
						<van-field v-model="fd.cellphone"
											 name="cellphone"
											 type="tel"
											 required
											 label="联系电话"
											 placeholder="请输入联系电话"
											 :rules="[
											 	{ required: true, message: '请填写联系电话' },
											 	{ pattern: RULES.phone, message: '请输入正确的联系电话' }
											 ]">
							<!-- <van-icon slot="right-icon"
												name="add-o"
												@click="addCellPhone" /> -->
						</van-field>
						<van-field v-model="cellphone[item]"
											 type="tel"
											 :name="`cellphone-${item}`"
											 :label="`其他号码${index + 1}`"
											 placeholder="请输入电话号"
											 @blur="cellphoneBlur($event, item)"
											 v-for="(item, index) in Object.keys(cellphone)"
											 :key="index">
							<van-icon slot="right-icon"
												name="minus"
												@click="subCellPhone(item)" />
						</van-field>
					</div>
				</div>
				<div class="question-group">
					<p class="question-group-title"
						 id="content">其他信息</p>
					<div class="question-group-body">
						<van-field v-model="fd.hospital"
											 required
											 name="hospital"
											 label="就诊医院"
											 placeholder="请填写您目前就诊的医院"
											 :rules="[{ required: true, message: '请填写您目前就诊的医院' }]" />
						<van-field v-model="fd.dept"
											 required
											 name="dept"
											 label="就诊科室"
											 placeholder="请填写您目前就诊的科室"
											 :rules="[{ required: true, message: '请填写您目前就诊的科室' }]" />
						<van-field v-model="fd.consultant"
											 required
											 name="consultant"
											 label="医生姓名"
											 placeholder="请填写您目前的医生姓名"
											 :rules="[{ required: true, message: '请填写您目前的医生姓名' }]" />


						<van-field name="decision"
											 required
											 v-model="fd.decision"
											 :rules="[{ required: true, message: '请选择家庭决策人' }]">
							<template slot="label">
								<van-popover v-model="showPopover"
														 placement="right-end"
														 theme="dark"
														 trigger="click">
									<div class="decision-desc">当患者病情发生变化时，医疗团队会优先联系系统内所登记的决策人并征询确定后续治疗计划</div>
									<template #reference>
										<div>
											决策人
											<van-icon name="question-o" />
										</div>
									</template>
								</van-popover>
							</template>
							<template #input>
								<van-radio-group v-model="fd.decision"
																 direction="horizontal">
									<van-radio v-for="(item, index) in decision_type"
														 :key="index"
														 :name="item.value">{{ item.text }}</van-radio>
								</van-radio-group>
							</template>
						</van-field>
						<template v-if="fd.decision == 2">
							<van-field v-model="fd.decisionName"
												 name="decisionName"
												 type="text"
												 label="决策人姓名"
												 required
												 placeholder="请输入决策人姓名"
												 :rules="[{ required: true, message: '请填写决策人姓名' }]" />
							<CRMSelect v-model="fd.decisionRelation"
												 name="decisionRelation"
												 label="与患者关系"
												 :attr="{ required: true }"
												 placeholder="请选择决策人与患者的关系"
												 :columns="patient_relation"
												 :rules="[{ required: true, message: '请选择决策人与患者的关系' }]" />
							<van-field v-model="fd.decisionCellphone"
												 name="decisionCellphone"
												 type="tel"
												 label="联系电话"
												 required
												 placeholder="请输入联系电话"
												 :rules="[
												 	{ required: true, message: '请填写联系电话' },
												 	{ pattern: RULES.phone, message: '请输入正确的联系电话' }
												 ]" />
						</template>
					</div>
					<div class="crm-card-body">
						<CRMSelect v-model="fd.payType"
											 name="payment"
											 label="支付方式"
											 :attr="{ required: true }"
											 placeholder="选择支付方式"
											 :columns="pay_type"
											 :rules="[{ required: true, message: '请选择支付方式' }]" />
						<CRMSelect v-model="fd.religion"
											 name="religion"
											 label="宗教信仰"
											 placeholder="选择宗教信仰"
											 :columns="religion" />
					</div>
				</div>
				<div class="submit-box">
					<van-button type="primary"
											color="#5f5aa3"
											native-type="submit"
											block>{{ $route.query.type === "edit" ? "修改" : "提交" }}</van-button>
				</div>
			</div>
		</van-form>
	</div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import api from "@/request/api";
import { normalInput, normalRadio, normalSelect, normalSign } from "@/components/form/index";
import CRMSelect from "@/components/CRM/Select/index.vue";
import { getAge, getSex } from "@/utils/tools";
import RULES from "@/constant/rules";
import { archivesQuery, sourceData, card_type, sex, decision_type, patient_relation, religion, pay_type } from "@/constant/archivesQuery";
import crmMixins from "@/utils/crm-mixins";
export default {
	mixins: [crmMixins],
	components: {
		normalInput,
		normalSelect,
		normalRadio,
		normalSign,
		CRMSelect
	},
	data () {
		return {
			showPopover: false,
			fd: {
				name: "",
				dept: "",
				hospital: "",
				consultant: "",
				idCard: "",
				sex: "",
				age: "",
				cellphone: "",
				decision: "1",
				decisionName: "",
				decisionRelation: "",
				decisionCellphone: "",
				payType: "",
				religion: "",
				source: "",
				sourceText: ""
			},
			updateKey: 1,
			cellphone: {},
			avtive: 0,
			RULES,
			sourceData,
			card_type,
			sex,
			decision_type,
			patient_relation,
			religion,
			pay_type
		};
	},
	created () {
		const archivesId = this.$route.query.archivesId;
		if (archivesId) {
			this.init(archivesId);
		} else {
			this.$router.back(-1);
			return false;
		}
	},
	methods: {
		init (id) {
			this.showLoading();
			api.cmsInfo({ id })
				.then(resp => {
					const result = resp.result || {};
					this.fd.name = result.name;
					this.fd.dept = result.dept;
					this.fd.hospital = result.hospital;
					this.fd.consultant = result.consultant;
					this.fd.cardType = result.cardType;
					this.fd.idCard = result.idCard;
					this.fd.sex = result.sex + "";
					this.fd.age = result.age;
					this.fd.source = result.source;
					this.fd.decision = result.decision;
					this.fd.saleUserId = result.saleUserId;
					this.fd.decisionName = result.decisionName;
					this.fd.decisionRelation = result.decisionRelation;
					this.fd.decisionCellphone = result.decisionCellphone;
					this.fd.medicalNo = result.medicalNo;
					this.fd.payType = result.payType;
					this.fd.religion = result.religion;
					const phones = result.cellphone.length > 11 ? JSON.parse(result.cellphone) : result.cellphone;
					try {
						phones.map((v, i) => {
							if (i === 0) {
								this.fd.cellphone = v;
							} else {
								this.cellphone[i.toString()] = v;
							}
						});
						this.$forceUpdate();
					} catch (error) { }
					this.hideLoading();
				})
				.finally(() => this.hideLoading());
		},
		cellphoneBlur (e, item) {
			this.$forceUpdate();
		},
		subCellPhone (key) {
			delete this.cellphone[key];
			this.$forceUpdate();
		},
		valueChange (value, val, ITEM) {
			if (val.type === "content") {
				const OPTION = (ITEM.option || []).find(i => i.value === value) || {};
				if (this.fd[val.type]) {
					const Index = this.fd[val.type].findIndex(i => i.name === ITEM.name);
					if (Index === -1) {
						this.fd[val.type].push({
							danger: OPTION.danger || 0,
							name: ITEM.name,
							value: value,
							label: ITEM.label
						});
					} else {
						this.$set(this.fd[val.type], Index, {
							danger: OPTION.danger || 0,
							name: ITEM.name,
							value: value,
							label: ITEM.label
						});
					}
				} else {
					this.fd[val.type] = [
						{
							danger: OPTION.danger || 0,
							name: ITEM.name,
							value: value,
							label: ITEM.label
						}
					];
				}
			} else {
				this.fd[ITEM.name] = value;
			}
		},
		addCellPhone () {
			const len = Object.keys(this.cellphone).length;
			if (len >= 4) {
				this.$toast.fail("最多允许5个联系电话");
				return false;
			}
			this.cellphone[(len + 1).toString()] = "";
			this.$forceUpdate();
		},
		updateAge () {
			if (this.RULES.idCard(this.fd.idCard)) {
				this.fd.age = getAge(this.fd.idCard);
				this.fd.sex = getSex(this.fd.idCard);
				this.$forceUpdate();
			}
		},
		submit (values) {
			// 提交数据
			this.showLoading("");
			let cellphone = [this.fd.cellphone];
			if (Object.keys(this.cellphone).length > 0) {
				Object.keys(this.cellphone).map(v => {
					const p = this.cellphone[v];
					if (p.length > 0) {
						cellphone.push(p);
					}
				});
			}
			api.csmUpdate({
				...this.fd,
				id: this.$route.query.archivesId,
				cellphone: JSON.stringify(cellphone),
				status: 1
			}).then(resp => {
				if (resp.success) {
					this.$router.push("/crm/archives");
				}
			})
				.finally(() => this.hideLoading());
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");

.question-page {
	text-align: left;
	font-size: 26px;

	.group-header {
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-shadow: 0px 1px 8px 1px #e1dfdf;

		.group-title {
			width: 50%;
			height: 100px;
			line-height: 100px;
			font-size: 32px;
			text-align: center;
			border-bottom: 4px solid @font9;
			color: @font1;

			&.active {
				border-bottom: 4px solid @bg5;
				color: @bg5;
				font-weight: 700;
			}
		}
	}

	.question-box {
		height: calc(100vh - 120px);
		padding: 20px;
		overflow: auto;

		.crm-card-body {
			margin-top: 20px;
		}

		.question-group-title {
			font-size: 32px;
			padding: 30px 20px 20px;
			font-weight: 600;
			color: @bg5;
		}

		.question-item {
			padding: 30px 30px 40px;
			border-bottom: 1px solid @font11;

			&:last-child {
				border: none;
			}
		}

		.question-group {
			padding: 0 0 20px 0;
			border: none;

			.question-item {
				border: none;
			}

			&:first-child {
				padding: 0 0 60px 0;
				border-bottom: 4px dashed @bg5;
			}
		}

		.question-date {
			font-size: 26px;
			padding: 30px 30px 40px;

			>div {
				line-height: 30px;
				margin-top: 20px;
			}
		}

		.submit-box {
			font-size: 26px;
			padding: 30px 30px 40px;
		}
	}
}

.decision-desc {
	padding: 20px 10px;
	box-sizing: border-box;
	line-height: 36px;
	width: 300px;
}
</style>

<style lang="less">
@import url("~@/variables.less");

.error-tip {
	float: left;
	color: @color4;
	font-size: 16px;
}

.normal-form .input-value.error .input-value-cell-body,
.normal-form .input-value.error input {
	border-color: @color4;
}

.question-page .van-radio__icon--checked .van-icon {
	background-color: @bg5;
	border-color: @bg5;
}

.question-page {
	input {
		font-size: 26px;

		&::placeholder {
			color: #d9dadc;
		}
	}
}
</style>

import Investigation from "@/views/WJ/investigation/index.vue";
import InvestigationQuestion from "@/views/WJ/investigation/question.vue";
import InvestigationResult from "@/views/WJ/investigation/result.vue";
import InvestigationList from "@/views/WJ/investigation/history-list.vue";

const InvestigationRouter = [
    {
        path: "/investigation",
        name: "investigation",
        mate: {
            title: "流行病学调查表",
            roles: ['*']
        },
        component: Investigation,
    },
    {
        path: "/investigation/question",
        name: "investigation-question",
        mate: {
            title: "流行病学调查表",
            roles: ['*']
        },
        component: InvestigationQuestion,
    },
    {
        path: "/investigation/result",
        name: "investigation-result",
        mate: {
            title: "流行病学调查表",
            roles: ['*']
        },
        component: InvestigationResult,
    },
    {
        path: "/investigation/list",
        name: "investigation-list",
        mate: {
            title: "流行病学调查表",
            roles: ['*']
        },
        component: InvestigationList,
    }
]

export default InvestigationRouter;
<template>
	<div class="crm-home">
		<img src="~@/assets/crm/home-bg.png" alt="" />
		<div class="crm-home-body">
			<div class="crm-home-top">
				<template v-if="staffInfo">
					<div class="crm-home-avatar">
						<!-- <van-icon name="more" size="18" color="#ee0a24" class="crm_home_icon" /> -->
						<div class="crm-home-photo" @click="showPopup">
							<img :src="staffInfo.avatar | avatar" alt="" />
						</div>
					</div>

					<h1>{{ staffInfo.username }}</h1>
					<p>{{ staffInfo.realname }}</p>
				</template>
				<div class="crm-home-content">
					<van-grid :border="false" class="crm-home-box" clickable :column-num="3">
						<van-grid-item
							v-for="(item, index) in menuInfo"
							:key="index"
							class="crm-home-border-right"
							icon="home-o"
							:text="`${item.name}`"
							@click="jumpPage(`${item.url}`)"
						>
							<template slot="icon">
								<span :class="`crm-home-icon ${item.component}`"></span>
							</template>
						</van-grid-item>
						<!-- <van-grid-item class="crm-home-border-right" icon="home-o" text="住院管理" @click="jumpPage('/crm/in-hospital')">
							<template slot="icon">
								<span class="crm-home-icon icon-inhospital"></span>
							</template>
						</van-grid-item> -->
						<!-- <van-grid-item class="crm-home-border-right" icon="home-o" text="患者建档" @click="jumpPage('/crm/create-patient')">
							<template slot="icon">
								<span class="crm-home-icon icon-create"></span>
							</template>
						</van-grid-item>
						<van-grid-item class="crm-home-border-right" icon="search" text="入院申请" @click="jumpPage('/crm/patient')"
							><template slot="icon">
								<span class="crm-home-icon icon-apply"></span>
							</template>
						</van-grid-item>
						<van-grid-item
							class="crm-home-border-right crm-home-border-bottom"
							icon="home-o"
							text="患者自建档"
							@click="jumpPage('/crm/patient-apply')"
							><template slot="icon">
								<span class="crm-home-icon icon-patient-create"></span>
							</template>
						</van-grid-item>
						<van-grid-item
							class="crm-home-border-right crm-home-border-bottom crm-home-border-top"
							icon="search"
							text="异常入院"
							@click="jumpPage('/crm/create-abnormal')"
							><template slot="icon">
								<span class="crm-home-icon icon-abnormal-admission"></span>
							</template>
						</van-grid-item>
						<van-grid-item
							class="crm-home-border-right crm-home-border-bottom crm-home-border-top"
							icon="search"
							text="修改密码"
							@click="jumpPage('/crm/personal-page')"
							><template slot="icon">
								<span class="crm-home-icon icon-personal-data"></span>
							</template>
						</van-grid-item> -->

						<van-grid-item class="crm-home-border-right crm-home-border-bottom crm-home-border-top" icon="search" text="退出系统" @click="logout"
							><template slot="icon">
								<span class="crm-home-icon icon-logout"></span>
							</template>
						</van-grid-item>
					</van-grid>
				</div>
			</div>
			<Version />
		</div>
		<van-popup v-model="show" round closeable position="bottom" :style="{ height: '50%' }">
			<div class="crm-upload crm-upload-pupup">
				<div class="crm-upload-nav">
					<span class="crm-upload-img">点击选择头像</span>

					<van-uploader :max-count="1" v-model="fileList" :after-read="uploadTX" @delete="removeImg" />
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import crmMIxins from "@/utils/crm-mixins";
import Version from "@/components/Version/index.vue";
import api from "@/request/api";
import request from "@/request";
import ImageCompressor from "@/utils/imageCompressor";
import { Dialog, Notify, Toast } from "vant";
import { mapState } from "vuex";
export default {
	mixins: [crmMIxins],
	data() {
		return {
			show: false,
			fileList: [],
			imgList: [],
			// aaa: [],
			menuInfo: [],
			url: {
				authMenuList: "/crm-system/sys/mobileMenu/authMenuList",
			},
		};
	},
	computed: {
		...mapState({
			newMenuInfo: (state) => state.menuInfo,
		}),
	},
	watch: {
    // 当 menuInfo 发生变化时，这个 watch 会被触发
    newMenuInfo(newVal, oldVal) {
		this.getNewMenu(newVal)
    }
  },
	mounted() {
		// if(this.newMenuInfo){
			this.getNewMenu(this.newMenuInfo)
		// }

	},
	methods: {
		getNewMenu(type){
			let vm = this;
	  if (type!=null) {
					const data = type.filter((item) => item.name == "主菜单");
					vm.menuInfo = data ? data[0].child : null;
				} else {
				}
		},
		uploadTX(file) {
			const self = this;
			console.log("e", file);
			this.getJpgFile(file.file).then((file) => {
				const options = {
					file: file,
					quality: 1,
					mimeType: "image/jpeg",

					minWidth: 500,
					minHeight: 500,
					convertSize: Infinity,
					loose: true,
					redressOrientation: true,

					// 压缩前回调
					beforeCompress: function (result) {
						console.log("压缩之前图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
					},

					// 压缩成功回调
					success: function (result) {
						var fd = new FormData();
						console.log("转换后:" + result.size);
						console.log("this.apply.patientId", self.staffInfo.id);
						// fd.append("type", "idcard");
						fd.append("file", result);
						// fd.append("file", file.file);
						fd.append("biz", self.staffInfo.id);
						console.log(result, "1");
						console.log("1fd", fd);
						console.log("fd.file", file);
						self.runUpload(fd);

						console.log("压缩之后图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
						console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
					},

					// 发生错误
					error: function (msg) {
						console.error(msg);
					},
				};
				new ImageCompressor(options);
			});
		},
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},
		runUpload(fd) {
			let that = this;
			fd.status = "uploading";
			fd.message = "上传中...";
			console.log("fd", fd);
			this.open = false;
			// this.showLoading("上传中...");
			api.upload(fd).then((res) => {
				this.hideLoading();
				console.log(res);
				if (res.success) {
					fd.status = "done";
					fd.message = "上传成功";
					this.imgList.push(res.message);
					that.submitPersonal();
				} else {
					fd.status = "failed";
					fd.message = "上传失败";
				}
			});
		},
		removeImg(file, detail) {
			// this.imgList = [...this.imgList].splice(detail.index, 1);
			this.imgList.splice(detail.index, 1);
		},
		showPopup() {
			this.show = true;
		},
		logout() {
			Dialog.alert({
				title: "提示",
				showConfirmButton: true,
				showCancelButton: true,
				message: "您确定要退出系统吗？",
				confirmButtonText: "退出系统",
				cancelButtonText: "取消",
			})
				.then(() => {
					localStorage.clear();
				window.location.href = process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login'
				})
				.catch(() => {
				});
		},
		submitPersonal() {
			const obj = {};
			obj.avatar = this.imgList.join(",");
			obj.id = this.staffInfo.id;
			api.crmEditUserInfo(obj).then((res) => {
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.getUserInfo();
				} else {
					Notify({ type: "danger", message: res.message });
				}
			});
		},
		getUserInfo() {
			let vm = this;
			this.showLoading();
			api.crmGetUserInfo()
				.then((res) => {
					this.hideLoading();
					if (res.success) {
						vm.staffInfo.avatar = res.result.userInfo.avatar;
						vm.imgList = [];
						vm.show = false;
					} else {
						Notify({ type: "danger", message: res.message });
					}
				})
				.catch(() => {
					this.hideLoading();
				});
		},
		// getMenuList() {
		// 	let vm = this;
		// 	console.log("aaa", this.aaa);
		// 	request.get(this.url.authMenuList).then((res) => {
		// 		if (res.success) {
		// 			const data = res.result.filter((item) => item.name == "主菜单");
		// 			vm.menuInfo = data ? data[0].child : null;

		// 			if (res.result == null) {
		// 				Dialog.alert({ title: "提示", message: "当前无权限，请联系管理员添加权限", confirmButtonText: "退出系统" }).then((res) => {
		// 					localStorage.clear();
		// 			window.location.reload();
		// 				});
		// 			}
		// 		} else {
		// 			// 					Toast.loading({
		// 			//   message: '加载1111中...',
		// 			//   forbidClick: true,
		// 			// });

		// 			Notify({ type: "danger", message: "接口请求时发生错误，请稍后再试。", duration: 2000 });
		// 		}
		// 	});
		// },
	},
	components: { Version },
};
</script>

<style lang="less">
.crm-upload-nav {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.crm-upload-img {
	text-align: center;
	font-size: initial;
	font-weight: 700;
	margin-bottom: 20px;
}

.crm-home {
	width: 100%;
	height: 100%;
	overflow: auto;
	.crm-home-body {
		margin-top: -60px;
		background: url("~@/assets/crm/box-bg.png") no-repeat top center;
		background-size: 100% auto;
		padding: 100px 40px;
		position: relative;
	}
	.crm-home-top {
		margin-top: -200px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h1 {
			margin-top: 40px;
			font-weight: 600;
			font-size: 40px;
			line-height: 56px;
			margin-block: 12px;
		}
		p {
			font-weight: 400;
			font-size: 36px;
			line-height: 50px;
		}
	}
	.crm-home-photo {
		width: 198px;
		height: 198px;
		border-radius: 50%;
		overflow: hidden;
		border: 5px solid #fff;
		background-color: #fff;
		color: #333;
		img {
			display: inline-block;
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
}
.crm-home-content {
	padding-top: 34px;
	width: 100%;

	.crm-home-box {
		display: flex;
		align-items: center;
		// justify-content: space-between;
		width: 100%;
		// height: 180px;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.15);
		border-radius: 24px;
		margin-bottom: 48px;
		padding: 48px 32px;
		box-sizing: border-box;
	}
	.crm-home-icon {
		display: inline-block;
		width: 84px;
		height: 84px;
		&.icon-create {
			background: url("~@/assets/crm/icon/icon-create.png") no-repeat center center/contain;
		}
		&.icon-inhospital {
			background: url("~@/assets/crm/icon/icon-inhospital.png") no-repeat center center/contain;
		}
		&.icon-apply {
			background: url("~@/assets/crm/icon/icon-apply.png") no-repeat center center/contain;
		}
		&.icon-logout {
			background: url("~@/assets/crm/icon/icon-logout.png") no-repeat center center/contain;
		}
		&.icon-patient-create {
			background: url("~@/assets/crm/icon/icon-patient-create.png") no-repeat center center/contain;
		}
		&.icon-abnormal-admission {
			background: url("~@/assets/crm/icon/icon-abnormal-admission.png") no-repeat center center/contain;
		}
		&.icon-personal-data {
			background: url("~@/assets/crm/icon/icon-password.png") no-repeat center center/contain;
		}
	}
	.crm-home-font {
		flex: 1;
		font-weight: 400;
		font-size: 38px;
		text-align: left;
		color: #333;
	}
	.crm-home-arrow {
		display: inline-block;
		width: 30px;
		height: 48px;
		background: url("~@/assets/crm/icon/icon-arrow-right.png") no-repeat center center/contain;
	}
}
.crm-home-avatar {
	position: relative;
	.crm_home_icon {
		position: absolute;
		right: 0;
	}
}
.crm-upload-pupup {
	display: inline-grid !important;
	height: 100%;
	width: 100%;
	margin-bottom: 0px !important;
	.van-uploader {
		padding-left: 0px !important;
		// height: inherit;
		margin: auto;
		.van-uploader__upload {
			width: 300px;
			height: 300px;
		}
	}
	.van-uploader__preview-image {
		display: block;
		width: 300px;
		height: 300px;
		overflow: hidden;
	}
}
</style>

<template>
	<div class="crm-patient crm-bg">
		<div class="crm-patient-search">
			<van-search v-model="queryParams.search" shape="round" background="transparent" placeholder="请输入用户名/手机号/身份证" @keydown.enter="search" />
			<div class="crm-patient-search-tag">
				<div class="crm-patient-tag__left">
					<span
						class="crm-patient-tag"
						:class="statusList.length == 0 || statusList.length === archives_status.length ? 'active' : ''"
						@click="search('all')"
					>
						全部
					</span>
				</div>
				<div class="crm-patient-tag__right">
					<div class="crm-patient-tag__right-scroll">
						<span
							class="crm-patient-tag"
							:class="statusList.indexOf(item.value) > -1 ? 'active' : ''"
							@click="selectedStatus(item)"
							v-for="(item, index) in archives_status"
							:key="index"
						>
							{{ item.text }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="crm-patient-body">
			<div class="crm-patient-body-scroll">
				<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->
						<div class="crm-message-card" :class="getClass(item)" v-for="(item, index) in list" :key="index">
							<div class="crm-message-card-top">
								<div class="crm-message-content">
									<van-row type="flex" @click="handleInfo(item)" justify="space-between">
										<van-col>
											<p>
												<strong class="username">{{ item.name }}</strong>
												<span class="sex">
													<SexIcon :sex="item.sex + ''" />
												</span>
												<span class="age">{{ item.age }}岁</span>
											</p>
										</van-col>
										<van-tag class="crm-message-tag" plain size="small" round :color="item.status | statusTag">{{
											status_dictText(item)
										}}</van-tag>
									</van-row>
									<p>
										<van-row>
											<van-col :span="12">电话：{{ item.cellphone | arrPhone }}</van-col>
											<van-col :span="12">登记时间：{{ item.createTime | formatDate("YYYY-MM-DD") }}</van-col>
											<van-col :span="12">居住地区：{{ sourceText(item) }}</van-col>
											<van-col :span="12">医生姓名：{{ item.consultant }}</van-col>
											<van-col :span="24">就诊医院：{{ item.hospital }}</van-col>
										</van-row>
									</p>
								</div>
							</div>
							<div class="crm-message-card-bottom">
								<div class="crm-message-msg">
									<a class="crm-message-btn icon-delete" @click="handleDelete(item)" v-if="+item.status < 3">
										<van-icon name="delete-o" size="12" />
									</a>
									<a class="crm-message-btn icon-edit" @click="handleEdit(item)" v-if="+item.status < 3">
										<van-icon name="edit" size="12" />
									</a>
									<!-- <a class="crm-message-btn reject"
										 @click="handleReject(item)"
										 v-if="+item.status === 1">驳回</a> -->
									<a class="crm-message-btn create" @click="handleCreate(item)" v-if="+item.status === 1">确认</a>
									<a
										class="crm-message-btn apply"
										@click="handleApply(item)"
										v-if="(+item.status === 3 || +item.status === 4) && item.cmsUserId"
										>入院申请</a
									>
								</div>
								<div></div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
		<van-dialog v-model="rejectShow" :beforeClose="rejectSubmit" title="驳回原因" show-cancel-button>
			<van-form ref="rejectForm">
				<van-field
					v-model="submitData.remark"
					name="remark"
					label-width="0"
					type="textarea"
					placeholder="驳回原因"
					:rules="[{ required: true, message: '请填写驳回原因' }]"
				/>
			</van-form>
		</van-dialog>
		<ItemInfo ref="ItemInfoRef" :model="scopeInfo" :title="ItemTitle" />
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import ItemInfo from "./ItemInfo.vue";
import { sourceData } from "@/constant/archivesQuery";
export default {
	name: "crm-patient",
	mixins: [crmMixins],
	components: {
		SexIcon,
		ItemInfo,
	},
	data() {
		return {
			rejectShow: false,
			submitData: {
				remark: "",
			},
			scopeInfo: {},
			ItemTitle: "患者详情",
			url: {
				list: "/jeecg-healthhome/archive/patient/csm-list",
			},
			queryParams: {
				column: "update_time",
				order: "desc",
			},
			archives_status: [
				{ value: "1", text: "待确认", title: "待确认" },
				{ value: "2", text: "驳回", title: "驳回" },
				{ value: "3", text: "已建档", title: "已建档" },
				{ value: "4", text: "已存在", title: "已存在" },
			],
			statusList: [],
		};
	},
	filters: {
		statusTag: (val) => {
			const STATUS_COLORS = {
				1: "#9D73FC", // 待审核
				2: "#F19963", // 审核不通过
				3: "#6481FC", // 审核通过（已建档）
				4: "#70CA51", // 已存在
			};
			return STATUS_COLORS[val] || "#8d85ff";
		},
	},
	mounted() {},
	methods: {
		handleInfo(item) {
			this.scopeInfo = item;
			this.ItemTitle = `【${item.name}】详细信息`;
			this.$refs.ItemInfoRef.handleShow(item);
		},
		sourceText(item) {
			if (item.source) {
				const result = sourceData.all.find((i) => i.id === item.source) || {};
				return result.name || "";
			} else {
				return "";
			}
		},
		handleDelete(item) {
			this.$dialog.confirm({
				title: "删除登记记录",
				messageAlign: "left",
				message: `确认删除【${item.name}】在${dayjs(item.createTime).format("YYYY-MM-DD")}提交的登记信息？`,
				confirmButtonText: "删除",
				beforeClose: (action, done) => {
					if (action === "confirm") {
						api.cmsDeleteArchive({
							id: item.id,
						}).then((resp) => {
							if (resp.success) {
								done();
								this.list = this.list.filter((i) => i.id !== item.id);
							}
						});
					} else {
						done();
					}
				},
			});
		},
		handleEdit(item) {
			this.$router.push({
				path: "/crm/archives-edit",
				query: {
					archivesId: item.id,
				},
			});
		},
		handleReject(item) {
			this.scopeInfo = item;
			this.submitData.remark = "";
			this.rejectShow = true;
		},
		rejectSubmit(action, done) {
			if (action === "confirm") {
				this.$refs.rejectForm
					.validate("remark")
					.then(() => {
						api.cmsUpdate({
							id: this.scopeInfo.id,
							status: 2,
							...this.submitData,
						}).then((resp) => {
							if (resp.success) {
								done();
								this.list = [];
								this.$nextTick(() => {
									this.loadPage(1);
								});
							}
						});
					})
					.catch(() => done(false));
			} else {
				done();
			}
		},
		handleCreate(item) {
			this.$dialog.confirm({
				title: "提示",
				message: "是否确认将该患者加入【我的患者】？",
				beforeClose: (action, done) => {
					if (action === "confirm") {
						api.cmsUpdate({
							id: item.id,
							status: 3,
						})
							.then((resp) => {
								if (resp.success) {
									this.list = [];
									this.$nextTick(() => {
										this.loadPage(1);
									});
									done();
								} else {
									this.$toast.fail(resp.message || "加入【我的患者】失败");
								}
							})
							.catch(() => done(false));
					} else {
						done();
					}
				},
			});
		},
		handleApply(item) {
			if (item.cmsUserId) {
				this.$dialog.confirm({
					title: "提示",
					message: "是否确认为该患者发起入院申请？",
					beforeClose: (action, done) => {
						if (action === "confirm") {
							done();
							this.$router.push(`/crm/create-apply/${item.cmsUserId}`);
						} else {
							done();
						}
					},
				});
			} else {
				this.$dialog.confirm({
					title: "提示",
					message: "是否确认将该患者加入【我的患者】？",
					beforeClose: (action, done) => {
						if (action === "confirm") {
							this.showLoading("");
							api.cmsUpdate({
								id: item.id,
								status: 3,
							})
								.then((resp) => {
									if (resp.success && resp.result.cmsUserId) {
										done();
										this.$router.push(`/crm/create-apply/${resp.result.cmsUserId}`);
									} else {
										if (!resp.success) {
											this.$toast.fail(resp.message || "加入【我的患者】失败");
										}
										this.list = [];
										this.$nextTick(() => {
											this.loadPage(1);
										});
										done();
									}
								})
								.finally(() => this.hideLoading(""));
						} else {
							done();
						}
					},
				});
			}
		},
		getClass(item) {
			const group = item.groupTag;
			return `card-bg-` + group;
		},
		status_dictText(item) {
			switch (+item.status) {
				case 1:
					return "待确认";
				case 2:
					return "驳回";
				case 3:
					return "已建档";
				case 4:
					return "已存在";
				default:
					return "待确认";
			}
		},
		selectedStatus(item) {
			const index = this.statusList.indexOf(item.value);
			if (index > -1) {
				this.statusList = [];
			} else {
				this.statusList = [item.value];
			}
			this.queryParams.status = this.statusList.join(",");
			this.search();
		},
		search(info) {
			if (info === "all") {
				this.queryParams.status = "";
			}
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-patient {
	display: flex;
	flex-direction: column;

	.crm-patient-body {
		flex: 1;
		position: relative;
		overflow: hidden;

		.crm-patient-body-scroll {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;

			> .van-pull-refresh {
				height: 100%;
				min-height: 100%;
				padding: 32px;
				overflow-y: auto;
			}
		}

		.crm-message-content {
			padding-left: 0;
		}
	}
}

.crm-patient-search {
	padding: 32px;
	background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 78.43%);
	box-shadow: 0px 2px 12px rgba(95, 90, 163, 0.25);
	position: relative;
	z-index: 1;

	input {
		font-size: 28px;
	}

	.van-search {
		padding: 0;
		margin-bottom: 24px;
	}

	.van-search__content {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	}

	.van-cell {
		align-items: center;
	}

	.van-field__control {
		height: 64px;
		font-weight: 400;
		font-size: 28px;
		line-height: 1.5;
	}
}

.crm-patient-search-tag {
	display: flex;
	flex-direction: row;

	.crm-patient-tag__left {
		width: 100px;
		margin-right: 16px;

		.crm-patient-tag {
			margin: 0;
		}
	}

	.crm-patient-tag__right {
		flex: 1;
		overflow: hidden;
		position: relative;
	}

	.crm-patient-tag {
		white-space: nowrap;
		display: inline-block;
		font-weight: 400;
		font-size: 28px;
		padding: 12px 20px;
		border: 2px solid #8d85ff;
		box-sizing: border-box;
		border-radius: 12px;
		margin: 0 8px;

		&.active {
			background: #8d85ff;
			color: #fff;
		}
	}

	.crm-patient-tag__right-scroll {
		overflow-x: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 120%;
		width: 100%;
		white-space: nowrap;
	}
}

.card-bg-1 {
	background-color: rgba(255, 85, 0, 0.1);
}

.card-bg-2 {
	background-color: rgba(45, 183, 245, 0.1);
}

.card-bg-3 {
	background-color: rgba(135, 208, 104, 0.1);
}

.crm-patient-body .crm-message-card-bottom {
	justify-content: flex-end;

	.crm-message-msg {
		.crm-message-btn {
			display: inline-block;
			padding: 10px 16px;
			margin: 0 10px;
			color: #fff;
			background: linear-gradient(180deg, #ff8bb5 0%, #f16991 60.94%, #e3466c 100%);
			border-radius: 10px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.icon-edit {
				background: linear-gradient(180deg, #6481fc 0%, #6481fc 60.94%, #6481fc 100%);
			}

			&.reject {
				background: linear-gradient(180deg, #ff8bb5 0%, #f16991 60.94%, #e3466c 100%);
			}

			&.apply {
				background: linear-gradient(180deg, #70ca51 0%, #70ca51 60.94%, #70ca51 100%);
			}

			&.create {
				background: linear-gradient(180deg, #8d85ff 0%, #8d85ff 60.94%, #8d85ff 100%);
			}
		}
	}
}
</style>

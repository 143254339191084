import Home from "@/views/WJ/Home/Home.vue";
import Question from "@/views/WJ/question/index.vue";
import SubmitList from "@/views/WJ/question/submit-list.vue";
import AnalysisPage from "@/views/WJ/question/modules/analysis-page.vue";
import CompletedPage from '@/views/WJ/question/modules/completed-page.vue'
import Page404 from "@/views/WJ/Info/404.vue";
import SuccessPage from "@/views/WJ/Info/success.vue";
import WechatLogin from "@/views/WJ/wechat-login.vue";

const SurveyRouter = [
	{
		path: "/wechatLogin",
		name: "wechatLogin",
		mate: {
			title: "微信登录",
			roles: ["*"]
		},
		component: WechatLogin
	},
	{
		path: "/survey",
		name: "Home",
		mate: {
			title: "问卷调查",
			roles: ["*"]
		},
		component: Home
	},
	{
		path: "/survey/question",
		name: "question",
		mate: {
			title: "问卷调查",
			roles: ["*"]
		},
		component: Question
	},
	{
		path: "/survey-history",
		name: "result-history",
		mate: {
			title: "提交记录",
			roles: ["*"]
		},
		component: SubmitList
	},
	{
		path: "/survey/result",
		name: "result",
		mate: {
			title: "检查结果",
			roles: ["*"]
		},
		component: AnalysisPage
	},
	{
		path: "/survey/completed",
		name: "completed",
		mate: {
			title: "检查结果",
			roles: ["*"]
		},
		component: CompletedPage
	},
	{
		path: "/survey/404",
		name: "404",
		mate: {
			title: "问卷调查",
			roles: ["*"]
		},
		component: Page404
	},
	{
		path: "/survey/success",
		name: "success",
		mate: {
			title: "问卷调查",
			roles: ["*"]
		},
		component: SuccessPage
	}
];

export default SurveyRouter;

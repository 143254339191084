import CRMPage from "@/views/CRM/index.vue"
import Login from "@/views/CRM/Login/index.vue"
import PLogin from '@/views/CRM/Login/pIndex.vue';
import Home from "@/views/CRM/Home/index.vue"
import Message from "@/views/CRM/Message/index.vue"
import Patient from "@/views/CRM/Patient/index.vue"
import PatientProfile from "@/views/CRM/Patient/profile.vue";
import CreatePatient from '@/views/CRM/Patient/create.vue';
import PatientApply from '@/views/CRM/Patient/apply.vue';
import ArchivesList from '@/views/CRM/Archives/List.vue';
import ArchivesEdit from '@/views/CRM/Archives/Edit.vue';
import CreateApply from '@/views/CRM/Apply/create.vue';
import ApplyDetail from '@/views/CRM/Apply/detail.vue';
import History from '@/views/CRM/History/index.vue';
import HistoryDetail from '@/views/CRM/History/detail.vue';
import CreateHistory from '@/views/CRM/History/create.vue';
import Contact from '@/views/CRM/Contact/index.vue';
import CreateContact from '@/views/CRM/Contact/create.vue';
import EditContact from '@/views/CRM/Contact/edit.vue';
import DetailContact from '@/views/CRM/Contact/detail.vue';
import CreateAbnormal from '@/views/CRM/Abnormal/List.vue';
import SuspectedPatient from '@/views/CRM/Abnormal/SuspectedPatient.vue';
import CreateAbnormalDetail from '@/views/CRM/Abnormal/detail.vue'
import MedicalRemarks from '@/views/CRM/Patient/addRemarks.vue';
import PersonalPage from '@/views/CRM/Personal/index.vue';
import LoginPassword from '@/views/CRM/Personal/password.vue'
import InHospitalList from '@/views/CRM/InHospitalList/index.vue';
import InHospitalDetail from '@/views/CRM/InHospitalList/detail.vue';
import JcReportDetail from '@/views/CRM/InHospitalList/JcReportDetail.vue';
import JyReportDetail from '@/views/CRM/InHospitalList/JyReportDetail.vue';
import DSReportDetail from '@/views/CRM/InHospitalList/DSReportDetail.vue';
import { KeepAlive } from "vue";
const CRMRouter = [
    {
        path: '/crm',
        name: 'crm',
        redirect: '/crm/home',
        component: CRMPage,
        children: [
            {
                path: '/crm/login',
                name: 'crm-login',
                meta: { title: '登录', roles: ['*'] },
                component: Login
            },
            {
                path: '/crm/pLogin',
                name: 'crm-pLogin',
                meta: { title: '登录', roles: ['*'] },
                component: PLogin
            },
            {
                path: '/crm/home',
                name: 'crm-home',
                meta: { title: '首页', roles: ['GAGL'] },
                component: Home
            },
            {
                path: '/crm/message',
                name: 'crm-message',
                meta: { title: '消息提醒', roles: ['GAGL'] },
                component: Message
            },
            {
                path: '/crm/patient',
                name: 'crm-patient',
                meta: { title: '患者管理', roles: ['GAGL'] },
                component: Patient
            },
            {
                path: '/crm/patient-apply',
                name: 'patient-apply',
                meta: { title: '患者自建档', roles: ['GAGL'] },
                component: PatientApply
            },
            {
                path: '/crm/archives',
                name: 'archives',
                meta: { title: '自建档管理', roles: ['GAGL'] },
                component: ArchivesList
            },
            {
                path: '/crm/archives-edit',
                name: 'archives-edit',
                meta: { title: '自建档修改', roles: ['GAGL'] },
                component: ArchivesEdit
            },
            //异常入院
            {
                path: '/crm/create-abnormal',
                name: 'crm-create-abnormal',
                meta: { title: '异常入院', roles: ['GAGL'] },
                component: CreateAbnormal
            },
            //修改患者信息
            {
                path: '/crm/create-detail',
                name: 'crm-create-detail',
                meta: { title: '异常入院患者档案修改', roles: ['GAGL'] },
                component: CreateAbnormalDetail
            },
            {
                path: '/crm/personal-page',
                name: 'crm-personal-page',
                meta: { title: '修改密码', roles: ['GAGL'] },
                component: PersonalPage,
            },
            {
                path: '/crm/login-password',
                name: 'crm-login-password',
                meta: { title: '修改密码', roles: ['GAGL'] },
                component: LoginPassword,
            },
            {
                path: '/crm/create-remarks',
                name: 'crm-medical-remarks',
                meta: { title: '添加备注', roles: ['GAGL'] },
                component: MedicalRemarks
            },
            {
                path: '/crm/create-patient',
                name: 'crm-create-patient',
                meta: { title: '患者建档', roles: ['GAGL'] },
                component: CreatePatient
            }, {
                path: '/crm/in-hospital',
                name: 'crm-in-hospital',
                // component: resolve => require(['@/views/CRM/InHospitalList/index.vue'], resolve),
                meta: { title: 'A', roles: ['GAGL'],keepAlive: true,
                    scrollTop: 0, },
                component: InHospitalList
            },
            {
                path: '/crm/detail-hospital',
                name: 'crm-detail-hospital',
                meta: {
                    title: 'B', roles: ['GAGL'],keepAlive: false,
                    scrollTop: 0
                },
                component: InHospitalDetail
            },
            {
                path: '/crm/jcreport-hospital',
                name: 'crm-jcreport-hospital',
                meta: { title: 'C', roles: ['GAGL'] ,keepAlive: false,
                    scrollTop: 0},
                component: JcReportDetail
            },
            {
                path: '/crm/jyreport-hospital',
                name: 'crm-jyreport-hospital',
                meta: { title: '检验报告详情页', roles: ['GAGL'] },
                component: JyReportDetail
            },
            {
                path: '/crm/dsreport-hospital',
                name: 'crm-dsreport-hospital',
                meta: { title: '电子病历详情页', roles: ['GAGL'] },
                component: DSReportDetail
            },
            {
                path: '/crm/edit-patient',
                name: 'crm-edit-patient',
                meta: { title: '患者档案修改', roles: ['GAGL'] },
                component: CreatePatient
            },
            {
                path: '/crm/create-apply/:id',
                name: 'crm-create-apply',
                meta: { title: '入院申请', roles: ['GAGL'] },
                component: CreateApply
            },
            {
                path: '/crm/edit-apply/:id',
                name: 'crm-create-apply',
                meta: { title: '修改入院申请', roles: ['GAGL'] },
                component: CreateApply
            },
            {
                path: '/crm/detail-apply/:id',
                name: 'crm-detail-apply',
                meta: { title: '入院详情', roles: ['GAGL'] },
                component: ApplyDetail
            },
            {
                path: '/crm/profile/:id',
                name: 'crm-profile-page',
                meta: { title: '患者主页', roles: ['GAGL'] },
                component: PatientProfile
            },
            {
                path: '/crm/suspected/:patientName',
                name: 'crm-suspected-patient',
                meta: { title: '疑似患者', roles: ['GAGL'] },
                component: SuspectedPatient
            },

            {
                path: '/crm/history/:id',
                name: 'crm-history',
                meta: { title: '病史列表', roles: ['GAGL'] },
                component: History
            },
            {
                path: '/crm/contact/:id',
                name: 'crm-contact',
                meta: { title: '联系人', roles: ['GAGL'] },
                component: Contact
            },
            {
                path: '/crm/create-contact/:id/',
                name: 'crm-contact-add',
                meta: { title: '添加联系人', roles: ['GAGL'] },
                component: CreateContact
            },
            {
                path: '/crm/edit-contact/:id/',
                name: 'crm-contact-edit',
                meta: { title: '编辑联系人', roles: ['GAGL'] },
                component: EditContact
            },
            {
                path: '/crm/contact-detail/:id',
                name: 'crm-contact-detail',
                meta: { title: '联系人详情', roles: ['GAGL'] },
                component: DetailContact
            },
            {
                path: '/crm/create-history/:id/:type',
                name: 'crm-history-detail',
                meta: { title: '添加病史', roles: ['GAGL'] },
                component: CreateHistory
            },
            {
                path: '/crm/history-detail/:id',
                name: 'crm-history-detail',
                meta: { title: '病史详情', roles: ['GAGL'] },
                component: HistoryDetail
            },
        ]
    }
]
export default CRMRouter;

import RULES from "@/constant/rules";

export default {
	methods: {
		// 重置校验
		resetValidate () {
			this.errorTip = '';
			this.error = false;
		},
		// 校验
		validate () {
			let flag = true
			const rules = this.model.rules || [];
			this.errorTip = '';
			this.error = false;
			rules.some(i => {
				console.log(this.inputValue, i)
				if (i.type === 'required') {
					if (Array.isArray(this.inputValue)) {
						if (this.inputValue.length == 0) {
							this.errorTip = i.message;
							this.error = true;
							flag = false
							return true
						}
					} else if (!this.inputValue && this.inputValue !== 0) {
						this.errorTip = i.message;
						this.error = true;
						flag = false
						return true
					}
				} else if (i.type === 'regExp' && i.regExp && this.inputValue) {
					if (!(new RegExp(i.regExp).test(this.inputValue))) {
						// 身份证校验
						if (this.model && (this.model.name === 'id_card' || this.model.name === 'family_id_card')) {
							if (!RULES.idCard(this.inputValue)) {
								this.errorTip = i.message;
								this.error = true;
								flag = false
								return true
							}
						} else {
							this.errorTip = i.message;
							this.error = true;
							flag = false
							return true
						}
					}
				} else if (i.type == 'file-limit') {
					if (Array.isArray(this.inputValue) && this.inputValue.length < i.limit) {
						this.errorTip = i.message;
						this.error = true;
						flag = false
						return true
					} else if (this.inputValue && this.inputValue.splice(',').length < i.limit) {
						this.errorTip = i.message;
						this.error = true;
						flag = false
						return true
					}
				}
			});
			return flag
		}
	}
}
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import TYPES from '@/constant/types.js'
import {Notify ,Dialog} from "vant";
import CRMRouter from './crm';
import SurveyRouter from "./survey";
import ArchivesRouter from "./archives";
import InvestigationRouter from "./investigation";
import CommonFormRouter from "./commonForm";

import EmptyPage from '@/views/emptyPage.vue'


const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
let token = localStorage.getItem(TYPES.STAFF_TOKEN)
const routes = [
	{
		path: "/",
		name: "Empty",
		mate: {
			title: "健恒医疗"
		},
		component: EmptyPage,
	},
	...SurveyRouter,
	...ArchivesRouter,
	...InvestigationRouter,
	...CommonFormRouter,
	...CRMRouter
];

const router = new VueRouter({
	mode: "history",
	base: process.env.VUE_APP_ASSET_BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	// 这样的情况需要做权限校验
	if (to.fullPath.indexOf('/crm/') === 0 && !!to.meta && (to.meta.roles || []).length > 0) {
		if (to.name === 'crm-login' && !!token) {
			next('/crm')
		}
		if (!store.getters.token && to.name != 'crm-login' && to.name != 'crm-pLogin') {

			  Dialog.alert({
				title: "提示",
				showConfirmButton: true,
				showCancelButton: true,
				message: `您的登录状态已过期或token无效，这可能是由于长时间未操作或系统安全要求所致。\n如果您不重新登录，部分功能可能无法正常使用，甚至可能遇到页面错误`,
				confirmButtonText: "重新登录",
				cancelButtonText: "取消",
				messageAlign:'left'
			})
				.then(() => {
					localStorage.clear();
				// window.location.href = '/crm/login'

				next(process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login')
				})
				.catch(() => {
					next();
				});

			// next(process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login')
		} else {
			next();
		}
		if (from.meta.keepAlive) {
			if(to.name=='crm-detail-hospital'){
				const $wrapper = document.querySelector('.crm-inhospital'); // 列表的外层容器 注意找到滚动的盒子
		const scrollTop = $wrapper ? $wrapper.scrollTop : 0;
				from.meta.scrollTop = scrollTop;
			}else{
				from.meta.scrollTop =0;

			}

		}
	} else {
		if (from.meta.keepAlive) {
			const $wrapper = document.querySelector('.testa'); // 列表的外层容器 注意找到滚动的盒子
			const scrollTop = $wrapper ? $wrapper.scrollTop : 0;
			console.log('scrollTop=', scrollTop)
			from.meta.scrollTop = scrollTop;
		  }
		next();
	}
})

export default router;

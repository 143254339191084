import Archives from "@/views/WJ/archives/index.vue";
import ArchivesQuestion from "@/views/WJ/archives/question.vue";
import ArchivesResult from "@/views/WJ/archives/result.vue";
import ArchivesList from "@/views/WJ/archives/history-list.vue";

const ArchivesRouter = [
    {
        path: "/archives",
        name: "Archives",
        mate: {
            title: "建档"
        },
       component: ArchivesQuestion,
    },
    // {
    //     path: "/archives/question",
    //     name: "archives-question",
    //     mate: {
    //         title: "患者建档"
    //     },
    //     component: ArchivesQuestion,
    // },
    {
        path: "/archives/result",
        name: "archives-result",
        mate: {
            title: "患者建档"
        },
        component: ArchivesResult,
    },
    {
        path: "/archives/list",
        name: "archives-list",
        mate: {
            title: "患者建档记录"
        },
        component: ArchivesList,
    }
]

export default ArchivesRouter;
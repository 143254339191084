<template>
	<div class="empty-page">
		<img src="~@/assets/images/logo.png" alt="" />
	</div>
</template>

<script>
export default {
	name: "empty"
};
</script>

<style lang="less">
.empty-page {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: url("~@/assets/images/bg.png") no-repeat center center/cover;
	img {
		display: inline-block;
		width: 40%;
		height: auto;
	}
}
</style>

<template>
	<div class="crm-bg crm-apply">
		<van-form ref="formRef1">
			<div class="crm-card mb-80" >
				<div class="crm-card-title">
					<div class="crm-card-title__flex">修改密码</div>
				</div>
				<div class="crm-card-body">

					<!-- <div class="crm-upload">
						<span class="crm-upload-title">头像：</span>
						<div class="crm-card-body" :readonly="editType==true">
				<ImgField  label=" " :options="personalFrom.avatar| convertImg" />
			</div>
			<van-uploader  :max-count="1"  v-model="fileList" :after-read="uploadTX" @delete="removeImg"/>

					</div> -->
					<van-field
						v-model="personalFrom.oldpassword"

						:rules="[{ required: false, message: '密码由8位数字、大小写字母和特殊符号组成!' }]"
						name="oldpassword"
						label="原密码"
						type="password"
						placeholder="原密码"
					/>
					<van-field
					type="password"
						v-model="personalFrom.password"
						required
						:rules="[{ required: true,pattern, message: '密码由8位数字、大小写字母和特殊符号组成!' }]"
						name="password"
						label="新密码"
						placeholder="请输入密码!"
					/>

                    <van-field
					required
					type="password"
						v-model="personalFrom.confirmpassword"
						:rules="[{ required: true,validator, message: '两次输入的密码不一样！' }]"
						name="confirmpassword"
						label="确认密码"
						@focus="onChange"
						placeholder="请再次输入新密码!"
					/>
				</div>
			</div>
		</van-form>
		<van-button v-if="editType==false" class="crm-btn mb-80" :loading="loading" block @click="editPersonal">
			<span>修改</span>
		</van-button>
		<van-button  v-if="editType==true"   class="crm-btn mb-80" :loading="loading" block @click="submitPersonal">
			<span>提交</span>
		</van-button>

	</div>
</template>

<script>
import api from "@/request/api";
import crmMIxins from "@/utils/crm-mixins";
import ImageCompressor from "@/utils/imageCompressor";
import CRMSelect from "@/components/CRM/Select";
import CRMRadio from "@/components/CRM/Radio";
import CRMDate from "@/components/CRM/SelectDate";
import CRMCheckbox from "@/components/CRM/Checkbox";
import ImgField from "@/components/CRM/ImgField";
import CRMTextarea from "@/components/CRM/Textarea";
import RULES from "@/constant/rules";
import { mapState } from "vuex";
import { Dialog, Notify } from "vant";

const gen = () => {
	return {
		zzHospital: "",
		zzHospitalOther: "",
		zzDepartment: "",
		zzDepartmentOther: "",
		consultationDoctor: "",
		zzInType: "",
		zzPlanInArea: "",
		zzBedReq: "",
	};
};

export default {
	name: "crm-personal-create",
	mixins: [crmMIxins],
	components: { CRMSelect, ImgField,CRMRadio, CRMDate, CRMCheckbox, CRMTextarea },
	data() {
		return {
			RULES,
			editType:true,
			remarksShow: false,
			loading: false,
			know_list: [],
			show: false,
			edit: true,
			personalFrom: {
				oldpassword:"",
				username: "",
				realname: "",
				avatar: "",
				birthday: "",
				// sex: "",
				email: "",
				phone: "",
				telephone: "",
				zzDepartment: "",
				zzBedReq: "",
				remarks: "",
				consultationDoctor: "",
				password: "",
				confirmpassword: "",
			},
			pattern:/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
			history: {
				pic: "",
				picType: "",
				outRemarks: "",
				withGuan: [],
			},
			remarksList: [],
			fileList: [],
			imgList: [],
			picList: [],
			outRemarks: {},
			picType: [],
			loadingBtn: false,
			showOldInfo: false,
			oldApplyInfo: gen(),
		};
	},
    computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),

	},
	mounted() {
		this.getRemarks();
	},
	methods: {
		uploadTX(file) {
			const self = this;
			console.log("e",file);
			this.getJpgFile(file.file).then((file) => {
				const options = {
					file: file,
					quality: 1,
					mimeType: "image/jpeg",
					maxWidth: 2000,
					maxHeight: 2000,
					width: 1000,
					height: 1000,
					minWidth: 500,
					minHeight: 500,
					convertSize: Infinity,
					loose: true,
					redressOrientation: true,

					// 压缩前回调
					beforeCompress: function (result) {
						console.log("压缩之前图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
					},

					// 压缩成功回调
					success: function (result) {
						var fd = new FormData();
						console.log("转换后:" + result.size);
						console.log("this.apply.patientId",self.info.id);
						// fd.append("type", "idcard");
						fd.append("file", result);
						// fd.append("file", file.file);
        fd.append('biz', self.info.id);
						console.log(result,"1");
						console.log("1fd",fd);
						console.log("fd.file",file);
						self.runUpload(fd);

						console.log("压缩之后图片尺寸大小: ", result.size);
						console.log("mime 类型: ", result.type);
						console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
					},

					// 发生错误
					error: function (msg) {
						console.error(msg);
					},
				};
				new ImageCompressor(options);
			});
		},
		runUpload(fd) {
			fd.status = "uploading";
			fd.message = "上传中...";
			console.log("fd",fd);
			this.open = false;
			// this.showLoading("上传中...");
			api.upload(fd).then((res) => {
				this.hideLoading();
				console.log(res);
				if (res.success) {
					fd.status = "done";
					fd.message = "上传成功";
					this.imgList.push(res.message);
				} else {
					fd.status = "failed";
					fd.message = "上传失败";
				}
			});
		},
		removeImg(file, detail) {
			this.imgList = [...this.imgList].splice(detail.index, 1);
		},
		getRemarks() {
			let vm = this;
			this.showLoading();
			api.crmGetUserInfo()
				.then((res) => {
					this.hideLoading();
					if (res.success) {
                        vm.personalFrom=res.result.userInfo;
					} else {
						Notify({ type: "danger", message: res.message });
					}
				})
				.catch(() => {
					this.hideLoading();
				});
		},

		onChange(){
this.edit=/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/.test(this.personalFrom.password);
		},
	 //   此处使用函数校验
	 validator(val) {
		return this.personalFrom.password===this.personalFrom.confirmpassword;
    },
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},

		//sfz
		afterRead(file) {
			console.log(file);
			file.status = "uploading";
			file.message = "上传中...";
			api.upload(file, this.info.id).then(res => {
				if (res.success) {
					file.status = "done";
					file.message = "上传成功";
					this.imgList.push(res.message);
				} else {
					file.status = "failed";
					file.message = "上传失败";
				}
			});
		},
		// editPersonal(){

		// 	Dialog.confirm({ title: "提示", message: "修改信息时，只允许修改头像以及密码！", confirmButtonText: "知晓" }).then(res => {
		// 		this.editType=true;});
		// },
		submitPersonal() {
			let that=this;
			that.personalFrom.avatar = that.imgList.join(",");
			that.$refs.formRef1.validate().then((res) => {
				if(that.personalFrom.confirmpassword&&that.personalFrom.password&&that.personalFrom.password===that.personalFrom.confirmpassword){
					const passwordFrom={};
					passwordFrom.oldpassword=that.personalFrom.oldpassword;
					passwordFrom.username=that.personalFrom.username;
					passwordFrom.password=that.personalFrom.password;
					passwordFrom.confirmpassword=that.personalFrom.confirmpassword;
					api.changePassword(passwordFrom).then((res) => {
				if (res.success) {
					Notify({ type: "success", message: res.message });
					const obj={...that.personalFrom};
					delete obj.password;
					delete obj.oldpassword;
					delete obj.confirmpassword;
					console.log("obj",obj);
					api.crmEditUserInfo(obj).then((res) => {
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.$router.back(-2);

				}else{
					Notify({ type: "danger", message: res.message });
				}
			});

				}else{
					Notify({ type: "danger", message: res.message });
				}
			});

					console.log("测试验证");
				}else{

				}

			});
		},
	},
};
</script>

<style lang="less">
.img-item__img img{
	object-fit: cover;
}
.crm-apply {
	padding: 32px;
	.van-checkbox__label {
		font-size: 28px;
	}
}
.crm-upload {
	display: block;
	text-align: left;
	background: #f2f2f2;
	border-radius: 15px;
	padding: 20px 25px 0px 25px;
	margin-bottom: 20px;
	.crm-upload-title {
		color: #333;
		font-size: 32px;
	}
	.van-uploader {
		padding-left: 100px;
	}
	.van-uploader__preview-image,
	.van-uploader__upload {
		background: #fff;
	}
	.van-uploader__preview {
		background: #f2f2f2;
		border-radius: 12px;
	}
	.van-uploader__preview-delete {
		border-radius: 16px;
		top: -6px;
		right: -6px;
		width: 32px;
		height: 32px;
		.van-uploader__preview-delete-icon {
			top: 0;
			right: 0;
		}
	}
}
.user-know-box {
	padding: 20px 20px 20px 30px;
	p {
		padding: 10px;
	}
}
.jh-cell-group {
	padding: 10px 20px;
	.van-cell__title {
		text-align: left;
		font-weight: bold;
	}
}
</style>

import { mapState } from "vuex"
import request from '@/request'
import dayjs from 'dayjs'
import api from '@/request/api';
import TYPES from '@/constant/types';
import avatarImg from '@/assets/crm/user.jpg';
import { Notify } from "vant";

export default {
    data() {
        return {
            inHospital_time:api.getDictItemsFromCache(TYPES.dict.inHospital_time),
            diagnosis_category: api.getDictItemsFromCache(TYPES.dict.diagnosis_category),
            in_hospital_status: api.getDictItemsFromCache(TYPES.dict.in_hospital_status),
            ns_nurse: api.getDictItemsFromCache(TYPES.dict.ns_nurse),
            ns_dirver: api.getDictItemsFromCache(TYPES.dict.ns_dirver),
            ns_car: api.getDictItemsFromCache(TYPES.dict.ns_car),
            ns_bed: api.getDictItemsFromCache(TYPES.dict.ns_bed),
            ns_ward: api.getDictItemsFromCache(TYPES.dict.ns_ward),
            history_guan: api.getDictItemsFromCache(TYPES.dict.history_guan),
            history_pic_type: api.getDictItemsFromCache(TYPES.dict.history_pic_type),
            history_department: api.getDictItemsFromCache(TYPES.dict.history_department),
            yn: api.getDictItemsFromCache(TYPES.dict.yn),
            history_hospital: api.getDictItemsFromCache(TYPES.dict.history_hospital),
            yn_type: api.getDictItemsFromCache(TYPES.dict.yn_type),
            patient_status: api.getDictItemsFromCache(TYPES.dict.patient_status),
            decision_type: api.getDictItemsFromCache(TYPES.dict.decision_type),
            religion: api.getDictItemsFromCache(TYPES.dict.religion),
            pay_type: api.getDictItemsFromCache(TYPES.dict.pay_type),
            patient_relation: api.getDictItemsFromCache(TYPES.dict.patient_relation),
            crm_hospital_area: api.getDictItemsFromCache(TYPES.dict.crm_hospital_area),
            sex: api.getDictItemsFromCache(TYPES.dict.sex),
            bed_req: api.getDictItemsFromCache(TYPES.dict.bed_req),
            in_hos_type: api.getDictItemsFromCache(TYPES.dict.in_hos_type),
            treatment_category: api.getDictItemsFromCache(TYPES.dict.treatment_category),
            card_type: api.getDictItemsFromCache(TYPES.dict.card_type),
            apply_type: api.getDictItemsFromCache(TYPES.dict.apply_type),
            to_user_know: api.getDictItemsFromCache(TYPES.dict.to_user_know),
            addressInfo: api.getCategoryDictItemsFromCache("B08A*"),
            //add
            remarks_show: api.getDictItemsFromCache(TYPES.dict.remarks_show),
            explain_type: api.getDictItemsFromCache(TYPES.dict.explain_type),
            content_type:api.getDictItemsFromCache(TYPES.dict.content_type),
            emergency_level:api.getDictItemsFromCache(TYPES.dict.emergency_level),
            remarks_exe_status:api.getDictItemsFromCache(TYPES.dict.remarks_exe_status),
            jy_type: api.getDictItemsFromCache(TYPES.dict.jy_type),
            jc_type: api.getDictItemsFromCache(TYPES.dict.jc_type),
            visit_type: api.getDictItemsFromCache(TYPES.dict.visit_type),
            url: {
                list: ''
            },
            queryParams: {},
            pages: {
                pageNo: 0,
                pageSize: 15
            },
            list: [],
            total:'',
            scroll: {
                loading: false,
                finished: false,
                refreshing: false,
            }
        }
    },
    computed: {
        ...mapState(['staffInfo'])
    },
    filters: {
        statusTag: (val) => {
            const STATUS_COLORS = {
                "FIRST": '#95ABFC',
                "CREATED": '#21C4BA',
                "WAIT-AUDIT": '#9D73FC', // 待患服审核
                "REJECT": '#F19963', // 入院驳回
                "WAIT-IN": '#C26B93', // 待入院
                "CANCEL-IN": '#F19963', // 取消入院
                "PRE-OUT": '#5aedc9', // 预出院
                "WAIT-OUT": '#BCE892', // 待出院
                "OUT": '#70CA51', // 已出院
                "IN-HOSPITAL": '#6481FC' // 已入院
            }
            return STATUS_COLORS[val] || '#8d85ff';
        },
        formatDate: (val, type) => {
            return dayjs(val).format(type || 'YYYY-MM-DD');
        },
        arrPhone: (val) => {

            if (!!val && val.indexOf('[') === 0) {
                return JSON.parse(val)[0];
            }
            return val;
        },
        encryption: (val, start = 3, end = 4) => {
            const reg = new RegExp(`^(.{${start}})(?:\\w+)(.{${end}})$`);
            return val.replace(reg, "\$1****\$2");
        },
        convertImg: (val) => {
            if (!val) return [];
            return val.split(',').map(v => api.getImage(v))
        },
        convertDict: (val, dictOption) => {
            const dictItem = dictOption;
            if (!!dictItem) {
                const current = dictItem.filter(v => v.value == val);
                if (current.length == 1) {
                    return current[0].text;
                }
                return val;
            }
            return val;
        },
        convertOptionDict: (options, val) => {
            const arr = (val || '').split(',')
            const v = [];

            options.map(item => {
                if (arr.includes(item.value)) {
                    v.push(item)
                }
            })

            return v;
        },
        tagColor: val => {
            return TYPES.COLORS[val] || '#5f5aa3'
        },
        avatar: val => {
            if (!!val && val.indexOf('http') == -1) {
                return '/crm-system/sys/common/static/' + val;
            }
            return val || avatarImg;
        }
    },
    methods: {
        loadScroll() {
            if (this.scroll.refreshing) {
                this.list = []
                this.scroll.refreshing = false;
            }
            const detailPageNo=sessionStorage.getItem('detailPageNo')
            if(!!detailPageNo){
                this.loadPage(Number(detailPageNo) + 1)
            }else{
                this.loadPage(this.pages.pageNo + 1)
            }

        },
        refreshScroll() {
            this.scroll.finished = false;
            this.scroll.loading = true;
            this.pages.pageNo = 0;
            this.pages.pages = undefined;
            this.list = [];
            sessionStorage.removeItem('detailPageNo')
            console.log("scroll",scroll);
            this.loadScroll();
        },
        showLoading(msg, duration = 0) {
            const cfg = {
                duration: duration,
                message: msg || "请求中...",
                forbidClick: true,
                loadingType: "spinner"
            }
            this.$toast.loading(cfg);
        },
        hideLoading() {
            this.$toast.clear();
        },
        jumpPage(path, query) {
            this.$router.push({ path, query })
        },
        jumpParamsPage(path, params) {
            console.log(path,params);
            this.$router.push({name: path,params: {params:params}});
        },
        loadPage(pageNo) {
            if (pageNo != undefined && this.pages.pages != undefined && pageNo > this.pages.pages) {

                this.scroll = {
                    loading: false,
                    finished: true,
                    refreshing: false,
                }
                return false;
            }
            if (pageNo == undefined) {
                this.pages.pageNo = 1;
                this.list = [];
            } else {
                this.pages.pageNo = pageNo
            }
            this.showLoading('')
            return request.get(this.url.list, { params: { ...this.queryParams, ...this.pages } }).then(res => {
                const result = res.result;
                this.hideLoading();
                this.scroll.loading = false;
                if (res.success && (res.code === 200 || res.code === 0)) {
                    this.list = this.list.concat(result.records||result)
                    this.total=result.total
                    this.pages = {
                        pageNo: result.current,
                        pageSize: result.size,
                        pages: result.pages
                    }
                    if (result.current >= result.pages) {
                        this.scroll.finished = true;
                    }
                    if (res.result.records&&res.result.records.length == 0) {
                        this.scroll = {
                            loading: false,
                            finished: true,
                            refreshing: false,
                        }
                    }
                }else{
                    this.scroll.finished = true;
                    Notify({ type: "danger", message: res.message });
                }
                return res;
            })
        },
    }
}

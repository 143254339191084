<template>
	<div >
		<van-dialog v-model="show" title="患者病史信息" confirm-button-text="关闭" @confirm="submitMedical">
			<div class="patientInfo">
				<div v-if="recordList.length == 0" class="pb-16">
					<van-empty description="暂无患者病史"> </van-empty>
				</div>
				<div class="crm-card pb-0 mb-16 crm-ys" v-for="(item, index) in recordList" :key="index">
					<div class="crm-card-title">
						{{ item.name }}
						<span class="sex"><SexIcon :sex="item.patientSex" /></span>
						<span class="age">{{ item.patientAge }}岁</span>
						<span class="age"> <van-button @click="onMatch(item)" plain type="info" round size="mini">一键导入</van-button> </span>
					</div>
					<div class="crm-card-body">
						<div class="crm-card-info">
							<p><strong>电话号码：</strong>{{ item.patientCellphone | arrPhone }}</p>
							<p><strong> 就诊时间：</strong>{{ item.docterTime }}</p>
							<p><strong>身份证号码：</strong>{{ item.idcard }} </p>
						</div>
					</div>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import crmMIxins from "@/utils/crm-mixins.js";
import ImgField from "@/components/CRM/ImgField";
import SexIcon from "@/components/CRM/SexIcon";
import StatusIcon from "@/components/CRM/StatusIcon";
import api from "@/request/api";
export default {
	mixins: [crmMIxins],
	components: { ImgField, SexIcon, StatusIcon },
	data() {
		return {
			patient: {},
			recordList: [],
			show: false,
		};
	},
	mounted() {
		// const patientName = this.$route.params.idCard;
		// this.getRecordDetail(this.$route.params.idCard);
	},
	methods: {
		submitMedical(){

		},
		showHide(item) {
			item.is_show = !item.is_show;
			this.$forceUpdate();
		},
		showHide(idCard) {
			this.show = !this.show;
			this.getRecordDetail(idCard);
		},
		getRecordDetail(idcard) {
			api.crmMedicalRecordList({idcard:idcard}).then((res) => {
				if (res.success) {
					this.recordList = res.result.records;
				}
			});
		},
		onMatch(item){
this.$emit('ok',item)
		},
	},
};
</script>

<style lang="less">
.ys-card-title {
	font-weight: 600;
	font-size: 1.067rem;
	line-height: 1.493rem;
	color: #333333;
	text-align: center;
	padding: 0 0.533rem;
	margin-bottom: 0.853rem;
}
.crm-profile {
	width: 100%;
	height: 100%;
	background: url("~@/assets/crm/profile-bg.png") no-repeat center top/contain;
	background-color: #fff;
	overflow: auto;
	&.card-bg-1 {
		background-color: #ffefe8;
	}
	&.card-bg-2 {
		background-color: #ecf8fe;
	}
	&.card-bg-3 {
		background-color: #f4fbf0;
	}
}
.crm-profile-body {
	background: #ffffff;
	padding: 96px 32px;
	border-radius: 0px 0px 0px 0px;
	.crm-profile-body__top {
		padding: 0 32px 64px;
	}
	&.card-bg-1 {
		background-color: #ffefe8;
	}
	&.card-bg-2 {
		background-color: #ecf8fe;
	}
	&.card-bg-3 {
		background-color: #f4fbf0;
	}
}
.crm-profile-header {
	display: flex;
	flex-direction: row;
	padding: 80px;
	color: #fff;
	text-align: left;
	position: relative;
	h1 {
		font-size: 40px;
		font-weight: 600;
		margin-bottom: 24px;
	}
	p {
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		margin-bottom: 7px;
		strong {
			display: inline-block;
			width: 200px;
			font-weight: 600;
			font-size: 32px;
			line-height: 45px;
			margin-right: 24px;
		}
	}
	.crm-profile-header__left {
		width: 100%;
	}
	.crm-profile-header__right {
		position: absolute;
		right: 40px;
		top: 100px;
		display: inline-block;
		width: 198px;
		height: 198px;
		border-radius: 50%;
		overflow: hidden;
		img {
			display: inline-block;
			width: 100%;
			height: auto;
		}
	}
	.crm-profile-edit {
		position: absolute;
		bottom: 18px;
		right: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 240px;
		height: 56px;
		border-radius: 30px 0 0 30px;
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		font-weight: 400;
		font-size: 28px;
		line-height: 39px;
		text-align: right;
		color: #5f5aa3;
		padding-right: 18px;
		padding-left: 10px;
	}
	.edit-icon {
		display: inline-block;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: url("~@/assets/crm/icon/icon-edit.png") no-repeat center center/contain;
	}
}
.profile-card {
	width: 100%;
	height: 218px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	&.history {
		background-image: url("~@/assets/crm/icon/icon-history.png");
	}
	&.apply {
		background-image: url("~@/assets/crm/icon/icon-to-apply.png");
	}
}
</style>

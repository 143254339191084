<template>
	<div class="normal-form normal-radio">
		<div class="form-label">
			<span v-if="model.required"
				class="label-required">*</span>
			<span v-if="showIndex">
				{{childIndex+1}}.
			</span>
			{{ model.label }}
		</div>
		<div class="form-option left">
			<van-radio-group v-model="inputValue">
				<van-row>
					<van-col v-for="item in model.option"
						:key="item.value"
						:span="parseInt(24/(model.span||1))">
						<van-radio class="form-option-block-item"
							checked-color="#5f5aa3"
							@click="changeValue(item)"
							:name="item.value">{{item.label}}</van-radio>
					</van-col>
				</van-row>
			</van-radio-group>
		</div>
		<div class="error-tip"
			v-if="errorTip&&error">{{errorTip}}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: "",
						option: []
					};
				}
			},
			childIndex: Number,
			showIndex: Boolean,
			value: [String, Number, null]
		},
		data () {
			return {
				error: false,
				errorTip: "",
				inputValue: null
			};
		},
		computed: {
			comColor: function () {
				const ITEM = this.model.option.find(i => i.value === this.inputValue) || {}
				return ITEM.color || '#5f5aa3'
			}
		},
		created () {
			this.init();
		},
		methods: {
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = null;
					this.inputValue = this.model.default || null;
					if (this.model.default) {
						this.changeValue({ value: this.model.default })
					}
				}
			},
			handSelect (item) {
				this.resetValidate();
				this.inputValue = item.value;
			},
			changeValue (item) {
				this.resetValidate();
				this.inputValue = item.value;
				this.$emit("input", this.inputValue);
				this.$emit("change", this.inputValue);
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");
	.form-option-block-item {
		margin: 10px 0;
		text-align: left;
	}
	.label-index {
		display: inline-block;
		background-color: @bg5;
		border-radius: 10px;
		padding: 2px 5px;
		vertical-align: middle;
		color: @font9;
		font-size: 26px;
		letter-spacing: 2px;
	}
	.form-option {
		display: flex;
		justify-content: space-around;
		&.left {
			justify-content: flex-start;
			padding-left: 40px;
			display: block;
		}

		.option-item {
			position: relative;
			opacity: 0.6;
			width: 200px;
			text-align: center;
			height: 60px;
			line-height: 60px;
			background-color: @font12;
			border-radius: 60px;
			font-size: 32px;
			&.active {
				opacity: 1;
				color: @font9;
			}
			.option-item-img {
				width: 230px;
			}
			.active-item {
				position: absolute;
				width: 48px;
				right: 19px;
			}
		}
	}
	.custom-radio {
		padding-top: 50px;
	}
</style>

<template>
	<div class="crm-checkbox-cell">
		<div class="crm-checkbox-title" v-if="label.length > 0"><span class="font-red" v-if="isRequired">*</span>{{ label }}</div>
		<div class="crm-checkbox-body">
			<van-checkbox-group v-bind="attr" :name="name" v-model="checkbox" :direction="direction" @change="onChange" :rules="rules">
				<van-checkbox :disabled="readonly" class="crm-checkbox-item" v-for="(item, index) in options" :key="index" :name="item.value || item.text">
					{{ item.text }}
				</van-checkbox>
			</van-checkbox-group>
		</div>
	</div>
</template>

<script>
export default {
	name: "crm-checkbox",
	props: {
		value: {
			type: Array,
			default() {
				return [];
			}
		},
		direction: {
			type: String,
			default: "horizontal"
		},
		label: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		isRequired: {
			type: Boolean,
			default: false
		},
		rules: {
			type: Array,
			default() {
				return [];
			}
		},
		readonly: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ""
		},
		attr: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			checkbox: this.value
		};
	},
	watch: {
		value: function(a) {
			this.checkbox = a;
		}
	},
	mounted() {
		console.log(this.value, "123");
	},
	methods: {
		onChange(item) {
			if (this.readonly) return false;
			this.$emit("input", item);
			this.$emit("change", item);
		}
	}
};
</script>

<style lang="less">
.crm-checkbox-cell {
	text-align: left;
	.crm-checkbox-title {
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		padding: 0 16px;
		margin-bottom: 12px;
	}
	.crm-checkbox-item {
		display: inline-block;
		margin: 12px;
		background: #ffffff;

		border: 2px solid #5f5aa3;

		box-sizing: border-box;
		border-radius: 12px;
		padding: 12px 32px;
		.van-checkbox__icon {
			display: none;
		}
		.van-checkbox__label {
			color: #5f5aa3;
			margin: 0;
			font-size: 28px;
		}
	}
	.crm-checkbox-item[aria-checked="true"] {
		background: #5f5aa3;
		border: 2px solid #5f5aa3;
		.van-checkbox__label {
			color: #fff;
		}
	}
	.font-red {
		color: #ff5656;
		padding-right: 10px;
		font-size: 50px;
		line-height: 28px;
		vertical-align: bottom;
	}
}
</style>

<template>
	<div class="img-field-box">
		<label>{{ label }}</label>
		<div class="img-list">
			<div class="img-item" v-for="(item, index) in options" :key="index" @click="open(index)">
				<van-image :src="item" class="img-item__img">
					<template v-slot:loading>
						<van-loading type="spinner" size="20" />
					</template>
				</van-image>
			</div>
		</div>
	</div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
	name: "crm-img-field",
	props: {
		label: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			show: false,
			index: 0
		};
	},
	methods: {
		open(index) {
			ImagePreview({
				images: this.options,
				startPosition: index,
				closeable: true
			});
		}
	}
};
</script>

<style lang="less">
.img-field-box {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	> label {
		width: 150px;
		font-weight: 400;
		font-size: 32px;
		line-height: 45px;
		color: #777777;
	}
	.img-list {
		flex: 1;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-left: 48px;
	}
	.img-item {
		display: inline-block;
		width: 176px;
		height: 182px;
		background: #f2f2f2;
		border-radius: 12px;
		margin-right: 24px;
		overflow: hidden;
		margin-bottom: 24px;
	}
	.img-item__img {
		width: 100%;
		height: 100%;
	}
}
</style>

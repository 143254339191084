<template>
	<div class="crm-date-cell">
		<van-field readonly clickable name="picker" :value="value" :label="label" :placeholder="placeholder" @click="open" :rules="rules" v-bind="attr">
			<template #input>
				<div class="crm-select-cell-body">
					<input type="text" readonly :value="value" :placeholder="placeholder" />
					<span class="icon-down"></span>
				</div>
			</template>
		</van-field>
		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker v-bind="datePickerConfig" :value="dateValue" :type="type" :title="title" @confirm="confirm" @cancel="cancel" />
		</van-popup>
	</div>
</template>

<script>
import dayjs from "dayjs";
export default {
	name: "crm-select-date",
	props: {
		name: {
			type: String,
			default: "picker"
		},
		value: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "date" //date time year-month month-day datehour
		},
		format: {
			type: String,
			default: "YYYY-MM-DD HH:mm:ss"
		},
		title: {
			type: String,
			default: ""
		},
		rules: {
			type: Array,
			default() {
				return [];
			}
		},
		datePickerConfig: {
			type: Object,
			default() {
				return {};
			}
		},
		readonly: {
			type: Boolean,
			default: false
		},
		attr: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			showPicker: false,
			dateValue: new Date()
		};
	},
	methods: {
		open() {
			if (!this.readonly) {
				this.showPicker = true;
			}
		},
		cancel() {
			this.showPicker = false;
		},
		confirm(e) {
			const v = dayjs(e).format(this.format);
			this.$emit("input", v);
			this.$emit("change", v);
			this.cancel();
		}
	}
};
</script>

<style lang="less">
.crm-date-cell {
	.van-cell {
		background-color: transparent;
	}
	.crm-select-cell-body {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		font-size: 28px;
	}
	.icon-down {
		display: inline-block;
		width: 32px;
		height: 32px;
		background: url("~@/assets/crm/icon/icon-date-black.png") no-repeat center center/contain;
	}
}
</style>

<template>
	<div class="custom-form select-score">
		<div class="form-select-label">{{ model.title }}</div>
		<div class="form-select-option">
			<div
				:class="['select-item', inputValue === item.value ? 'active-item' : '']"
				v-for="item in model.options"
				@click="changeValue(item)"
				:key="item.value"
			>
				{{ item.label }}
				<div class="select-item-score" v-if="item.show_score">
					<span>/</span>
					<span class="score">{{ item.score }}</span>
					<span>分</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: "",
					options: []
				};
			}
		},
		value: [String, Number, null]
	},
	data() {
		return {
			inputValue: null
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if (this.value) {
				this.inputValue = this.value;
			} else {
				this.inputValue = null;
			}
		},
		changeValue(item) {
			this.inputValue = item.value;
			if (!!item.score && item.show_score) {
				this.$toast({
					message: `+${item.score}分`,
					className: "custom-score",
					duration: 1000
				});
			}

			this.$emit("input", this.inputValue, this.model, item);
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");
.select-score {
	margin: 0 0 40px;
	padding: 50px;
	background-color: @font9;
	border-radius: 20px;
	color: @font1;
	width: 100%;
	.form-select-label {
		.btn-font;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: @font1;
		line-height: 44px;
		text-align: left;
	}
	.select-item {
		margin: 20px 0;
		height: 84px;
		line-height: 84px;
		background: @font10;
		border-radius: 42px;
		font-weight: 500;
		position: relative;
		&.active-item {
			background-color: @bg1;
			color: @font9;
			.select-item-score {
				color: @font9;
				.score {
					color: @font9;
				}
			}
		}
		.select-item-score {
			position: absolute;
			top: 0;
			right: 43px;
			color: @font5;
			.score {
				display: inline-block;
				padding: 0 5px;
				font-weight: 500;
				color: @font1;
			}
		}
	}
}
</style>

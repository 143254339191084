<template>
	<div class="normal-sign normal-form">
		<van-dialog v-model="show"
			title="签名"
			@confirm="handleSubmit"
			show-cancel-button>
			<div class="page sign-page container"
				v-if="show"
				id="container">
				<div class="content">
					<div class="sign-wrap"
						id="signWrap">
						<canvas class="canvas"
							id="canvas"
							canvas-id="canvas"></canvas>
					</div>
				</div>
				<div class='con-btn'>
					<img class="reload-btn"
						@click="handleClear"
						:src="IconReload">
				</div>
			</div>
		</van-dialog>
		<div class="form-label">
			<span v-if="model.required"
				class="label-required">*</span>
			{{ model.label }}
		</div>
		<div class="input-value"
			:style="`justify-content: ${inputValue?'space-between':'center'};`">
			<img :src="inputValue"
				v-if="inputValue">
			<img class="sigin-icon"
				@click="tiggerShow"
				v-else
				:src="IconSign" />
			<div class="btn-edit"
				@click="tiggerShow"
				v-if="inputValue">修改签名</div>
		</div>
		<div class="error-tip"
			v-if="errorTip && error">{{errorTip}}</div>
	</div>

</template>

<script>
	import IconSign from "@/assets/images/sign.png";
	import IconReload from "@/assets/images/reload.png";
	import validateMIxins from "@/utils/validate-mixins.js";
	/* eslint-disable */
	export default {
		mixins: [validateMIxins],
		data () {
			return {
				errorTip: "",
				error: false,
				IconReload,
				IconSign,
				inputValue: null,
				show: false,
				offsetLeft: 0,
				offsetTop: 0,
				context: null,
				canvas: null,
				mousePressed: false, //是否在绘制中
			}
		},
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			value: [String, Number, null]
		},
		methods: {
			tiggerShow () {
				this.resetValidate();
				this.show = true
				setTimeout(() => {
					this.context = document.getElementById('canvas').getContext('2d')
					this.canvas = document.getElementById('canvas')
					const container = document.getElementById('container')
					this.offsetLeft = this.canvas.getBoundingClientRect().left - this.canvas.offsetLeft
					this.offsetTop = this.canvas.getBoundingClientRect().top - this.canvas.offsetTop
					this.canvas.width = container.clientWidth
					this.canvas.height = container.clientHeight
					this.init()
				}, 100)
			},
			init () {
				this.canvas.addEventListener("touchstart", this.touchstart, false);
				this.canvas.addEventListener("touchmove", this.touchmove, false);
				this.canvas.addEventListener("touchend", this.touchend, false);
				// 鼠标
				this.canvas.onmousedown = (event) => {
					this.mousePressed = true;
					this.Draw(
						this.context,
						event.pageX - this.offsetLeft,
						event.pageY - this.offsetTop,
						false
					);
				};

				this.canvas.onmousemove = (event) => {
					if (this.mousePressed) {
						this.Draw(
							this.context,
							event.pageX - this.offsetLeft,
							event.pageY - this.offsetTop,
							true
						);
					}
				};
				this.canvas.onmouseup = () => {
					this.mousePressed = false;
				};
			},
			Draw (ctx, x, y, isDown) {
				if (isDown) {
					ctx.beginPath();
					ctx.strokeStyle = "#000"; // 颜色
					ctx.lineWidth = 3; // 线宽
					ctx.lineJoin = "round";
					ctx.lineMax = 10; // 设置画笔最大线宽
					ctx.lineMin = 3; // 设置画笔最小线宽
					ctx.linePressure = 1.2; // 设置画笔笔触压力
					ctx.smoothness = 30; // 设置画笔笔触大小变化的平滑度
					ctx.moveTo(this.lastX, this.lastY);
					ctx.lineTo(x, y);
					ctx.closePath();
					ctx.stroke();
				}
				this.lastX = x;
				this.lastY = y;
			},
			handleSubmit () {
				this.resetValidate();
				if (this.isCanvasBlank(this.canvas)) {
					this.$toast('请完善您的签名');
					return
				}
				this.inputValue = this.canvas.toDataURL("image/png")
				this.$emit("input", this.inputValue, this.model);
			},
			isCanvasBlank (canvas) {
				var blank = document.createElement("canvas"); // 系统获取一个空canvas对象
				blank.width = canvas.width;
				blank.height = canvas.height;
				return canvas.toDataURL() == blank.toDataURL(); // 比较值相等则为空
			},
			handleClear () {
				this.context.setTransform(1, 0, 0, 1, 0, 0);
				this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
			},
			touchstart (event) {
				if (event.targetTouches.length == 1) {
					event.preventDefault(); // 阻止浏览器默认事件，重要
					const { pageX, pageY } = event.targetTouches[0];
					this.mousePressed = true;
					this.Draw(this.context, pageX - this.offsetLeft, pageY - this.offsetTop, false);
				}
			},
			touchmove (event) {
				if (event.targetTouches.length == 1) {
					event.preventDefault(); // 阻止浏览器默认事件，重要
					const { pageX, pageY } = event.targetTouches[0];
					if (this.mousePressed) {
						this.Draw(this.context, pageX - this.offsetLeft, pageY - this.offsetTop, true);
					}
				}
			},
			touchend (event) {
				if (event.targetTouches.length == 1) {
					event.preventDefault(); // 阻止浏览器默认事件，防止手写的时候拖动屏幕，重要
				}
				this.mousePressed = false;
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");

	.normal-sign {
		.input-value {
			background-color: @bg6;
			height: 200px;
			border-radius: 3px;
			align-items: center;
			justify-content: space-between;
			img {
				height: 100%;
				width: auto;
			}
			.btn-edit {
				font-size: 26px;
				float: right;
			}
		}
	}
	.container {
		position: relative;
		width: 100%;
		height: 40vh;
		padding: 20px 10px;
		box-sizing: border-box;
		overflow: hidden;
		border-radius: 3px;
		.content {
			border: 2px solid @font11;
			width: 100%;
			height: 100%;
		}
	}
	.con-btn {
		position: absolute;
		top: 30px;
		right: 30px;
		.reload-btn {
			width: 50px;
			height: auto;
		}
	}
	.con-btn > button {
		margin: 10px;
		padding: 5px 10px;
		border: none;
	}
	.submit-btn {
		background: #24c123;
		color: white;
	}
	canvas {
		display: block;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		flex: 1;
		margin-right: 16px;
		margin: 5rpx;
		background: #ffffff;
	}
</style>
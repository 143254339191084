<template>
	<div class="normal-form normal-radio">
		<div class="form-label">
			<span v-if="model.required" class="label-required">*</span>
			<span class="label-index" v-if="showIndex"> {{ childIndex + 1 }} </span>
			{{ model.label }}
		</div>
		<div :class="['input-value', , error ? 'error' : '']">
			<input v-model="inputValue" @input="changeValue" :placeholder="model.placeholder" readonly @click="show = true" type="text" />
		</div>
		<div class="error-tip" v-if="errorTip && error">{{ errorTip }}</div>
		<van-calendar v-model="show" @confirm="onConfirm" color="#5f5aa3" :formatter="formatter" />
	</div>
</template>

<script>
import validateMIxins from "@/utils/validate-mixins.js";
import dayjs from "dayjs";
export default {
	mixins: [validateMIxins],
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					label: "",
					placeholder: ""
				};
			}
		},
		childIndex: Number,
		showIndex: Boolean,
		value: [String, Number, null]
	},
	data() {
		return {
			show: false,
			error: false,
			errorTip: "",
			format: "YYYY-MM-DD",
			inputValue: null
		};
	},
	computed: {
		comColor: function() {
			const ITEM = this.model.option.find(i => i.value === this.inputValue) || {};
			return ITEM.color || "#5f5aa3";
		}
	},
	created() {
		this.init();
	},
	methods: {
		formatter(day) {
			const options = this.model.options;
			const weekName = dayjs(day.date)
				.format("dddd")
				.toLocaleLowerCase();
			if (options && options.disabled) {
				if (options.disabled.indexOf(weekName) > -1) {
					day.type = "disabled";
				}
			}
			if (options && options.disabledDay) {
				(options.disabledDay || []).map(v => {
					const rule = v;
					if (rule.length === 3) {
						const match = rule[0] === "<" ? "isBefore" : "isAfter";
						if (dayjs(day.date)[match](dayjs().add(~~rule[1], rule[2] || "day"))) {
							day.type = "disabled";
						}
					}
				});
			}
			return day;
		},
		onConfirm(date) {
			this.show = false;
			this.changeValue({ value: dayjs(date).format(this.format) });
		},
		init() {
			if (this.value) {
				this.inputValue = this.value;
			} else {
				this.inputValue = null;
			}
		},
		handSelect(item) {
			this.resetValidate();
			this.inputValue = item.value;
		},
		changeValue(item) {
			this.resetValidate();
			this.inputValue = item.value;
			this.$emit("input", this.inputValue);
			this.$emit("change", this.inputValue);
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");
@import url("~@/normal-form.less");
@import url("~@/variables.less");
@import url("~@/normal-form.less");
.normal-form {
	.input-value {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 0;
		input {
			background: none;
			border: none;
			box-shadow: none;
			border-bottom: 1px solid @font11;
			text-align: left;
			display: block;
			width: 100%;
			min-width: 240px;
			font-size: 32px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			line-height: 60px;
			color: @font1;
			caret-color: @font1;
			background-color: @bg6;
			padding: 10px;
			border-radius: 3px;
			&::placeholder {
				.placeholder-font;
				color: @font3;
				font-size: 26px;
				opacity: 0.7;
				text-align: left;
			}
			&:focus {
				border-bottom: 1px solid @font7;
			}
		}
	}
	.item-group {
		margin-bottom: 10px;
	}
}
</style>

<template>
	<div class="jh-version">健恒医疗Ver: 1.1.5</div>
</template>
<script>
import pkg from "../../../package.json";
export default {
	name: "version",
	data() {
		return {
			ver: pkg.version
		};
	}
};
</script>
<style lang="less">
.jh-version {
	color: #999;
	letter-spacing: 2px;
	display: block;
	width: 100%;
	text-align: center;
	padding: 20px;
}
</style>

const wj = {
	state: {
		info: {},
		submitData: {}
	},
	mutations: {
		SET_INFO (state, data) {
			state.info = data
		},
		SET_SUBMIT_DATA (state, data) {
			state.submitData = data
		}
	},
	actions: {
		setInfo ({ commit }, info) {
			commit('SET_INFO', info)
		},
		setSubmitData ({ commit }, data) {
			commit('SET_SUBMIT_DATA', data)
		}
	}
}

export default wj

<template>
	<div class="crm-create crm-bg">
		<van-form ref="formRef">
			<div class="crm-card">
				<div class="crm-card-title">新增联系人</div>
				<div class="crm-card-body">
					<van-field
						v-model="form.name"
						required
						name="name"
						label="姓名"
						placeholder="姓名"
						:rules="[{ required: true, message: '请填写姓名' }]"
					/>
					<van-field
						v-model="form.cellphone"
						name="cellphone"
						type="tel"
						required
						label="电话"
						placeholder="请输入联系电话"
						:rules="[
							{ required: true, message: '请填写联系电话' },
							{ pattern: RULES.phone, message: '请输入正确的联系电话' },
						]"
					>
					</van-field>
                    <CRMSelect
						v-model="form.relation"
						name="relation"
						label="关系"
						:attr="{ required: true }"
						placeholder="请选择是否默认联系人"
						:columns="patient_relation"
						:rules="[{ required: true, message: '请选择与联系人关系' }]"
					/>
					<CRMSelect
						v-model="form.isDefault"
						name="isDefault"
						label="联系人"
						:attr="{ required: true }"
						placeholder="请选择是否默认联系人"
						:columns="yn"
						:rules="[{ required: true, message: '请选择是否默认联系人' }]"
					/>
					<CRMTextarea
						label1="备注"
						:rows="3"
						required
						:attr="{ maxlength: 1000, 'show-word-limit': true ,required: true}"
						v-model="form.remark"
						name="remark"
						:rules="[{ required: true, message: '请填写备注' }, ,]"
						placeholder="备注"
					/>
				</div>
			</div>
		</van-form>
		<van-button class="crm-btn mb-80" :loading="loading" block @click="submit">提交</van-button>
	</div>
</template>

<script>
import api from "@/request/api";
import TYPES from "@/constant/types";
import RULES from "@/constant/rules";
import CRMDate from "@/components/CRM/SelectDate";
import CRMSelect from "@/components/CRM/Select";
import { Notify } from "vant";
import crmMIxins from "@/utils/crm-mixins";
import CRMTextarea from "@/components/CRM/Textarea";
export default {
	name: "crm-create-history",
	mixins: [crmMIxins],
	components: { CRMDate, CRMSelect ,CRMTextarea},
	data() {
		return {
			loading: false,
			form: {
				name: "",
				cellphone: "",
				relation: "",
				isDefault: "",
				remark: "",
			},
            RULES,
		};
	},
	mounted() {
		this.form.patientId = this.$route.params.id;
	},
	watch: {

		'form.cellphone'(newVal) {
      this.form.cellphone = newVal.replace(/\s/g, '');

    },
	},
	methods: {

		submit() {
			this.$refs.formRef
				.validate()
				.then(() => {
					this.loading = true;
					if (this.form.id) {
						api.crmContactEdit(this.form).then((res) => {
							if (res.success) {
								this.loading = false;
								this.$router.back();
							} else {
								Notify({ type: "danger", message: "修改联系人失败！" });
							}
						});
					} else {
						api.crmContactAdd(this.form).then((res) => {
							if (res.success) {
								this.loading = false;
								this.$router.back();
							} else {
								Notify({ type: "danger", message: res.message||"添加联系人失败！" });
                                this.loading = false;
							}
						});
					}
				})
				.catch();
		},
	},
};
</script>

<style lang="less"></style>

<template>
	<div class="crm-message-card">
		<div class="crm-message-card-top">
			<div class="crm-message-content">
				<p>
					<strong class="username">{{ info.patient.name }}</strong>
					<span class="sex">{{ info.patient.sex_dictText }}</span> <span class="age">{{ info.patient.age }}岁</span>
					<span class="date">{{ info.patient.payType_dictText }}</span>
				</p>
			</div>
		</div>
		<div class="crm-message-card-bottom">
			<div class="crm-message-msg">
				<span class="dian"></span>
				患者的入院申请待审批
			</div>
			<a class="crm-message-btn"
				@click="handleDetails">详情</a>
		</div>
		<van-dialog v-model:show="showDetails"
			cancel-button-text="关闭"
			confirm-button-text="前往患者主页"
			@confirm="handleUpdate"
			@cancel="changeRouter"
			show-cancel-button>
			<div class="crm-message-dialog">
				<div class="crm-message-card-top">
					<div class="crm-message-content">
						<p>
							<strong class="username">{{ info.patient.name }}</strong>
							<span class="sex">{{ info.patient.sex_dictText }}</span>
							<span class="age">{{ info.patient.age }}岁</span>
							<span class="date">{{ info.patient.payType_dictText }}</span>
						</p>
						<p>转诊医院：{{info.apply.zzHospital_dictText}}</p>
						<p>拟入院时间：{{info.apply.zzPlanInDate}}</p>
						<p>拟入病区：{{info.apply.zzPlanInArea_dictText}}</p>
						<p>申请人：{{info.apply.updateBy}}</p>
					</div>
				</div>
			</div>
		</van-dialog>
	</div>
</template>
<script>
	import crmMixins from "@/utils/crm-mixins";
	export default {
		name: "msg4",
		props: ["item"],
		mixins: [crmMixins],
		data () {
			return {
				showDetails: false,
				info: {
					patient: {},
					apply: {}
				}
			};
		},
		mounted () {
			this.info = JSON.parse(this.item.msgContent || "{msgContent:{}}");
		},
		methods: {
			handleDetails () {
				this.showDetails = true
				this.$emit("read", this.item.anntId);
			},
			handleUpdate () {
				this.$emit('update')
			},
			changeRouter () {
				if (this.info.patient && this.info.patient.id) {
					this.$router.push(`/crm/profile/${this.info.patient.id}`);
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.crm-message-dialog {
		text-align: left;
		background: #ffffff;
		padding: 32px;
		margin: 32px 0;
		.crm-message-card-top {
			display: flex;
			.crm-message-photo {
				display: inline-block;
				width: 144px;
				height: 144px;
				border-radius: 50%;
				overflow: hidden;
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			.crm-message-content {
				padding-left: 34px;
				text-align: left;
				display: block;
				width: 100%;
				p {
					font-style: normal;
					font-weight: 400;
					font-size: 28px;
					color: #777;
					margin-bottom: 9px;
				}
				.username {
					font-weight: 600;
					color: #333;
					margin-right: 24px;
				}
				.sex {
					margin-right: 24px;
					color: #333;
				}
				.age {
					margin-right: 58px;
					color: #333;
				}
			}
		}
		.crm-message-card-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.crm-message-msg {
				text-align: left;
			}
			.dian {
				display: inline-block;
				width: 16px;
				height: 16px;
				background: #ff8bb5;
				margin-right: 16px;
				border-radius: 50%;
			}
			.crm-message-btn {
				display: inline-block;
				padding: 10px 32px;
				color: #fff;
				background: linear-gradient(180deg, #ff8bb5 0%, #f16991 60.94%, #e3466c 100%);
				border-radius: 10px;
			}
			.crm-message-tag {
				font-size: 28px;
				color: #b0b0b0;
				padding-left: 10px;
				padding-right: 10px;
				&.ml-16 {
					margin-left: 16px;
				}
			}
		}
	}
</style>

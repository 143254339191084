<template>
	<van-dialog v-model="show"
		title="验证手机"
		confirm-button-text="验证"
		:before-close="handleSubmit"
		show-cancel-button>
		<van-form ref="vanFormRef"
			class="form-box">
			<van-cell-group>
				<van-field v-model="params.mobile"
					required
					label="手机号"
					:rules="[{ validator: phoneValidator, message: '手机号格式错误',trigger:'onChange' }]"
					placeholder="请输入手机号" />
				<van-field v-model="params.code"
					center
					clearable
					label="短信验证码"
					:maxlength="6"
					ref="vanCodeRef"
					placeholder="验证码">
					<template #button>
						<van-button size="small"
							@click.stop="getCaptcha"
							type="primary">
							<span v-if="time <= 0">获取验证码</span>
							<span v-else>{{ time }}s 后重发</span>
						</van-button>
					</template>
				</van-field>
			</van-cell-group>
		</van-form>
	</van-dialog>
</template>

<script>
	import api from "@/request/api";
	import crmMixins from "@/utils/crm-mixins";
	import TYPES from "@/constant/types";
	import { mapGetters } from "vuex";
	export default {
		name: "login-form",
		mixins: [crmMixins],
		data () {
			return {
				show: false,
				loading: false,
				time: 0,
				timer: null,
				params: {
					mobile: "",
					code: ""
				}
			};
		},
		watch: {
			show: function (val) {
				if (!val && this.timer) {
					clearInterval(this.timer)
					this.timer = null;
					this.time = 0;
				}
			}
		},
		computed: {
			...mapGetters(["userInfo"]),
		},
		methods: {
			phoneValidator (val) {
				return /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
			},
			codeValidator (val) {
				return val.length === 6
			},
			handleSubmit (action, done) {
				if (action === 'confirm') {
					this.$refs.vanFormRef.validate().then(val => {
						if (this.codeValidator(this.params.code)) {
							this.onSubmit(done)
						} else {
							this.$toast('请输入6位验证码');
							done(false)
						}
					}).catch(() => {
						done(false)
					})
				} else {
					done()
				}
			},
			getCaptcha () {
				if (this.timer) return false;
				if (this.phoneValidator(this.params.mobile)) {
					this.showLoading();
					api.getMobileCode({
						mobile: this.params.mobile
					}).then(() => {
						this.showLoading("发送成功", 3);
						this.time = 59;
						this.setTime();
						this.$refs.vanCodeRef.focus()
						this.hideLoading();
					})
				} else {
					this.$toast('手机号格式错误');
				}
			},
			onSubmit (done) {
				api.mobileLogin(this.params).then(res => {
					done()
					if (res.success && res.code === 200) {
						this.$store.dispatch('set_user_info', {
							...this.userInfo,
							phone: this.params.mobile,
							token: res.result.token,
						});
						localStorage.setItem(TYPES.TOKEN_KEY_HEALTH, res.result.token)
						done()
						this.$emit('success')
					} else {
						this.$toast({
							type: "danger",
							message: res.message
						});
						done(false)
					}
				}).catch(() => {
					done(false)
					this.clearTimer();
				});
			},
			setTime () {
				if (this.timer) return false;
				this.timer = setInterval(() => {
					if (this.time === 1) {
						this.clearTimer();
					} else {
						this.runTime();
					}
				}, 1000);
			},
			runTime () {
				this.time--;
			},
			clearTimer () {
				clearInterval(this.timer);
				this.timer = null;
				this.time = 0;
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/crm_variables.less");
	.form-box {
		margin: 30px 0;
	}
	.submit-box {
		display: flex;
		justify-content: space-around;
		margin: 30px 0;
	}
</style>
<style lang="less">
	.form-box .van-field__button,
	.form-box .van-cell,
	.form-box input {
		font-size: 26px;
	}
</style>

<template>
	<div class="crm-message">
		<van-tabs v-model="active"
			color="#8d85ff"
			@change="selectTab">
			<van-tab title="通知"></van-tab>
			<van-tab title="提醒"></van-tab>
			<van-tab title="系统"></van-tab>
		</van-tabs>
		<van-pull-refresh v-model="scroll.refreshing"
			@refresh="refreshScroll">
			<van-list v-model="scroll.loading"
				:finished="scroll.finished"
				:finished-text="list.length > 0 ? '没有更多了' : ''"
				@load="loadScroll">
				<van-empty description="暂无消息"
					v-if="list.length == 0 && !scroll.loading" />
				<template v-else>
					<component v-for="(item, index) in list"
						:key="index"
						:is="`msg${item.msgCategory}`"
						:item="item"
						@update="handleUpdate($event,index)"
						@read="toRead" />
				</template>
				<div class="footer-empty"></div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import crmMixins from "@/utils/crm-mixins";
	import api from "@/request/api";
	import { msg1, msg2, msg3, msg4, msg401, msg402, msg403, msg5, msg6, msg404, msg7, msg8 } from "./components/index";
	import { Notify } from "vant";
	export default {
		mixins: [crmMixins],
		components: { msg1, msg2, msg3, msg4, msg401, msg402, msg403, msg404, msg5, msg6, msg7, msg8 },
		data () {
			return {
				isLoading: false,
				loading: true,
				active: 1,
				url: {
					list: "/crm-system/sys/sysAnnouncementSend/getMyAnnouncementSend"
				},
				queryParams: {
					readFlag: 0,
					msgCategoryStrs: "3,4,401,402,403,404,5,6,7,8"
				},
				msgCode: {
					0: [1],
					1: [3, 4, 401, 402, 404, 403, 5, 6, 7, 8],
					2: [2]
				}
			};
		},
		mounted () { },
		methods: {
			toRead (id) {
				api.readMsg(id).then(res => {
					this.$store.dispatch("update_msg");
					Notify({ type: "success", message: "消息已读!" });
				});
			},
			selectTab () {
				// this.queryParams.msgCategory = this.msgCode[this.active].join("");
				this.queryParams.msgCategoryStrs = this.msgCode[this.active].join(",");
				this.refreshScroll();
			},
			handleUpdate (event, index) {
				// 更新消息列表
				if (event) {
					// TODO
				} else {
					this.list.splice(index, 1)
				}
			}
		}
	};
</script>

<style lang="less">
	.crm-message-skeleton {
		.van-skeleton__row,
		.van-skeleton__title,
		.van-skeleton__avatar {
			background-color: #fff;
		}
	}
	.crm-message {
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
		.van-pull-refresh {
			width: 100%;
			height: 100%;
			padding: 32px;
			position: relative;
			overflow: auto;
			box-sizing: border-box;
		}
	}
	.crm-message-card {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		border-radius: 32px;
		padding: 32px;
		margin-bottom: 32px;
		.crm-message-card-top {
			display: flex;
			.crm-message-photo {
				display: inline-block;
				width: 144px;
				height: 144px;
				border-radius: 50%;
				overflow: hidden;
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			.crm-message-content {
				padding-left: 34px;
				text-align: left;
				display: block;
				width: 100%;
				p {
					font-style: normal;
					font-weight: 400;
					font-size: 28px;
					color: #777;
					margin-bottom: 9px;
				}
				.username {
					font-weight: 600;
					color: #333;
					margin-right: 24px;
				}
				.sex {
					margin-right: 24px;
					color: #333;
				}
				.age {
					margin-right: 58px;
					color: #333;
				}
			}
		}
		.crm-message-card-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.crm-message-msg {
				text-align: left;
			}
			.dian {
				display: inline-block;
				width: 16px;
				height: 16px;
				background: #ff8bb5;
				margin-right: 16px;
				border-radius: 50%;
			}
			.crm-message-btn {
				display: inline-block;
				padding: 10px 32px;
				color: #fff;
				background: linear-gradient(180deg, #ff8bb5 0%, #f16991 60.94%, #e3466c 100%);
				border-radius: 10px;
			}
			.crm-message-tag {
				font-size: 28px;
				color: #b0b0b0;
				padding-left: 10px;
				padding-right: 10px;
				&.ml-16 {
					margin-left: 16px;
				}
			}
		}
	}
	.footer-empty {
		height: 16px;
		width: 100%;
	}
</style>

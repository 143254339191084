<template>
	<div class="status-icon">
		<img v-if="status === 'IN-HOSPITAL'" src="@/assets/crm/icon/icon-IN-HOSPITAL.png" alt="" />
		<img v-if="status === 'OUT'" src="@/assets/crm/icon/icon-OUT.png" alt="" />
		<img v-if="status === 'WAIT-IN'" src="@/assets/crm/icon/icon-WAIT-IN.png" alt="" />
		<img v-if="status === 'WAIT-AUDIT'" src="@/assets/crm/icon/icon-WAIT-AUDIT.png" alt="" />
		<img v-if="status === 'CREATED'" src="@/assets/crm/icon/icon-CREATED.png" alt="" />
		<img v-if="status === 'FIRST'" src="@/assets/crm/icon/icon-FIRST.png" alt="" />
	</div>
</template>
<script>
export default {
	name: "sex-icon",
	props: {
		status: {
			type: String,
			default: ""
		}
	}
};
</script>
<style lang="less" scoped>
.status-icon {
	width: 90%;
	border-radius: 8px;
	padding: 4px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100%;
		display: inline-block;
	}
}
</style>

<template>
	<div class="crm-message-card">
		<div class="crm-message-card-top">
			<div class="crm-message-content">
				<p>
					<strong class="username">{{ item.titile }}</strong>
					<span v-if="item.priority"
						:class="[item.priority,'rich_media_meta']">{{priorityMap[item.priority]}}</span>
				</p>
				<p>
					{{ item.msgAbstract }}
				</p>
			</div>
		</div>
		<div class="crm-message-card-bottom">
			<div class="crm-message-msg">
				发布时间：<span class="date">{{ item.sendTime | formatDate }}</span>
			</div>
			<a class="crm-message-btn"
				@click="handleDetails">{{item.msgContent ? '查看':'标记已读'}}</a>
		</div>
		<van-dialog v-model:show="showDetails"
			:title="item.titile"
			:show-confirm-button="false"
			cancel-button-text="关闭"
			@cancel="handleChange"
			show-cancel-button>
			<p class="system-msg">
				<span v-html="item.msgContent"></span>
			</p>
		</van-dialog>
	</div>
</template>
<script>
	import crmMixins from "@/utils/crm-mixins";
	export default {
		name: "msg2",
		props: ["item"],
		mixins: [crmMixins],
		data () {
			return {
				priorityMap: {
					L: '低',
					M: '中',
					H: '高',
				},
				showDetails: false,
				info: {
					patient: {},
					apply: {}
				}
			};
		},
		mounted () { },
		methods: {
			handleDetails () {
				if (item.msgContent) {
					this.showDetails = true
				}
				this.$emit("read", this.item.anntId);
			},
			handleChange () {
				this.$emit("update");
			}
		}
	};
</script>
<style lang="less" scoped>
	.system-msg {
		text-align: left;
		padding: 1rem;
		max-height: 15rem;
		overflow: auto;
	}
	.crm-message-msg {
		padding-left: 34px;
	}
	.rich_media_meta {
		padding: 0 4px;
		font-size: 12px;
		line-height: 1.67;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		width: auto;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		max-width: 70%;
		font-style: normal;
		letter-spacing: normal;
		margin-right: 8px;
		&.L {
			background: rgba(0, 0, 0, 0.05);
			color: rgba(0, 0, 0, 0.3);
			border-color: #d9d9d9;
		}
		&.M {
			color: #fa8c16;
			background: #fff7e6;
			border-color: #ffe59a;
		}
		&.H {
			color: #f5222d;
			background: #fff1f0;
			border-color: #ffcfbf;
		}
	}
</style>

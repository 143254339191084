<template>
	<div class="custom-form custom-radio">
		<div class="form-label">{{ model.label }}</div>
		<div class="form-option">
			<div :class="['option-item', inputValue === item.value ? 'active' : '']"
				v-for="item in model.options"
				:key="item.value">
				<img class="option-item-img"
					:src="item.img ? item.img : item.value === '男' ? IconMan : IconWman"
					@click="changeValue(item)"
					:alt="item.label" />
				<img v-if="inputValue === item.value"
					:src="IconChecked"
					class="active-item" />
			</div>
		</div>
	</div>
</template>

<script>
	import IconMan from "@/assets/images/icon-man.png";
	import IconWman from "@/assets/images/icon-wman.png";
	import IconChecked from "@/assets/images/icon-checked.png";
	export default {
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: "",
						options: []
					};
				}
			},
			value: [String, Number, null]
		},
		data () {
			return {
				IconMan,
				IconWman,
				IconChecked,
				inputValue: null
			};
		},
		created () {
			this.init();
		},
		methods: {
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = null;
				}
			},
			changeValue (item) {
				this.inputValue = item.value;
				this.$emit("input", this.inputValue, this.model, item);
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/custom-form.less");

	.form-option {
		display: flex;
		justify-content: space-around;

		.option-item {
			position: relative;
			opacity: 0.6;
			&.active {
				opacity: 1;
			}
			.option-item-img {
				width: 230px;
			}
			.active-item {
				position: absolute;
				width: 48px;
				right: 19px;
			}
		}
	}
	.custom-radio {
		padding-top: 50px;
	}
</style>

<template>
	<div class="home-page analysis-page completed-box">
		<div class="page-content">
			<div class="analysis-info"></div>
			<div class="analysis-card card-after">
				<div class="card-content">
					<div class="content-header">
						<span v-html="resultInfo.desc"></span>
					</div>
					<div class="content-tag">{{ resultInfo.label }}</div>
					<div class="content-label-tip">{{ resultInfo.labelTip }}</div>
					<template v-if="resultInfo.moreLabel">
						<van-divider />
						<div class="check-label" @click="checkLabel">
							<div class="content-tag-tip" v-html="resultInfo.moreLabel"></div>
							<van-icon name="arrow" />
						</div>
					</template>
					<div class="content-desc">{{ resultInfo.content }}</div>
				</div>
			</div>
		</div>
		<a target="_blank" :href="item.link ? item.link : 'javascript:;'" class="card-list-box" v-for="(item, index) in card1" :key="index">
			<div class="card-list-left">
				<h1>{{ item.title }}</h1>
				<p>{{ item.content }}</p>
			</div>
			<div class="card-list-img" v-if="item.img">
				<img :src="item.img" />
			</div>
		</a>
		<a target="_blank" :href="item.link ? item.link : 'javascript:;'" class="card-list-box" v-for="(item, index) in card2" :key="index">
			<div class="card-list-left">
				<h1>{{ item.title }}</h1>
				<p>{{ item.content }}</p>
			</div>
			<div class="card-list-img" v-if="item.img">
				<img :src="item.img" />
			</div>
		</a>
		<a target="_blank" :href="item.link ? item.link : 'javascript:;'" class="card-list-box black-title" v-for="(item, index) in card3" :key="index">
			<div class="card-list-header">
				<h1>{{ item.title }}</h1>
			</div>
			<div class="card-list-img" v-if="item.img">
				<img :src="item.img" />
			</div>
			<div class="card-list-desc">
				<p>{{ item.content }}</p>
			</div>
		</a>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
// import { resultFc } from "./result_function";
export default {
	computed: {
		...mapGetters(["userInfo", "formValueData", "formValueScore", "currentSurvey", "source"])
	},
	data() {
		return {
			resultInfo: {
				label: "高危体质",
				labelTip: "",
				desc: "多数人的体质特征不是典型的，兼夹体质为多，根据您的本次检测结果分析，主要体质结果如下：",
				content: "平和质是指阴阳平和，脏腑气血功能正常，先天禀赋良好，后天调养得当之人，对四时寒暑及地理环境适应能力强，患病少"
			},
			card1: [],
			card2: [],
			card3: []
		};
	},
	mounted() {
		this.computedResult.call(this, this);
	},
	methods: {
		computedResult(that) {
			eval(this.currentSurvey.result_function);
		}
	}
};
</script>

<style scoped lang="less">
@import url("~@/variables.less");
.analysis-page {
	width: calc(100% + 8px);
	position: relative;
	box-sizing: border-box;
	color: @font4;
	text-align: left;
	overflow-x: hidden;
	font-size: 26px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&.completed-box {
		padding: 40px;
		.page-content {
			width: 100%;
		}
	}

	.analysis-info {
		display: flex;
		justify-content: flex-start;
		background-color: @bg7;
		margin-top: 40px;
		border-radius: 20px 20px 0 0;
		padding: 20px;
		width: 100%;
		text-align: center;
		align-items: center;
		.info-item {
			display: inline-block;
			padding: 0 10px;
			color: #333333;
			font-size: 26px;
			.info-item-label {
				.desc-item;
				padding: 6px 0;
				color: @font6;
			}
			.info-item-value {
				.desc-title;
				font-size: 20px;
				color: @font6;
			}
		}
	}
	.analysis-card {
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		background-color: @font9;
		margin-bottom: 20px;
		border-radius: 0 0 20px 20px;
		position: relative;
		&.tac {
			text-align: center;
		}
		.card-header {
			display: flex;
			justify-content: space-between;
			.card-date {
				.desc-item;
				padding: 9px;
				background-color: @bg1;
				color: @font9;
				border-radius: 10px;
				margin-bottom: 20px;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					border-top: 20px solid rgba(0, 0, 0, 0);
					border-right: 20px solid rgba(0, 0, 0, 0);
					border-bottom: 20px solid rgba(0, 0, 0, 0);
					border-left: 20px solid @bg1;
					left: 0;
					bottom: -10px;
					transform: rotate(360deg);
				}
			}
			.card-label {
				padding: 9px;
				.desc-item;
				color: @font6;
				img {
					width: 22px;
					vertical-align: inherit;
				}
			}
		}

		.card-content {
			.content-header {
				.desc;
				color: @font3;
				font-size: 28px;
				line-height: 46px;
			}
			.content-tag {
				margin: 30px 0 10px 0;
				text-align: center;
				color: @bg3;
				font-size: 50px;
				font-weight: 500;
			}
			.content-label-tip {
				text-align: center;
				margin-bottom: 40px;
				line-height: 40px;
			}
			.content-tag-tip span {
				display: inline-block;
				padding: 0 5px;
				color: @color3;
				font-size: 28px;
			}
			.content-desc {
				.desc;
				color: @font4;
				line-height: 46px;
			}
		}

		.analysis-card-img {
			width: 390px;
		}

		.chart-box {
			.chart-content {
				.chart-progress {
					width: 70%;
					height: 34px;
					background: #7b92fc;
					border-radius: 10px;
					border-radius: 50%;
					&::-webkit-progress-bar {
						background-color: #7b92fc;
						border-radius: 10px;
					}
					&::-webkit-progress-value {
						background: linear-gradient(270deg, #ff9302 0%, #fde959 100%);
						border-radius: 10px;
					}
				}
			}
		}

		.content-des {
			.desc-result;
			color: @font4;
		}
	}

	.analysis-tabs {
		box-sizing: border-box;
		width: 100%;
		background-color: @font9;
		border-radius: 30px 30px 0 0;
		min-height: 600px;
		position: relative;
		overflow: hidden;
		.item-card-content {
			font-size: 28px;
			margin: 10px 0;
		}
		.tab-content {
			padding: 0 40px 0;
			.tab-item-card {
				padding: 20px;
				margin: 10px 0;
				background-color: @font13;
				border-radius: 10px;
				font-size: 26px;
				.item-card-title {
					font-size: 28px;
					margin-bottom: 10px;
					color: @font1;
					font-weight: 800;
				}
			}
			.dietTyerapy-title {
				font-size: 30px;
				margin: 20px 0;
				color: @font1;
				position: relative;
				padding-left: 20px;
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 10px;
					bottom: 0;
					z-index: 2;
					display: inline-block;
					border-radius: 3px;
					background-color: @font7;
				}
			}
			.dietTyerapy-tip {
				margin: 40px 0;
				font-size: 28px;
				text-indent: 2em;
				line-height: 50px;
			}
		}
	}
	.next-btn {
		position: inherit;
		margin: 50px 0;
	}
}
.progress-circle {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	img {
		display: inline-block;
		margin: 0 auto;
	}
	.progress-circle-box {
		width: 310px;
		height: 310px;
		background-color: #f8fbff;
		border-radius: 50%;
		position: absolute;
		top: 10%;
	}
	.progress-circle-box-center {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.progress-circle-box-center-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 198px;
		height: 198px;
		background: #ffffff;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
		border-radius: 50%;
		font-weight: 500;
		color: #333333;
		font-size: 46px;
		letter-spacing: 3px;
	}
	.van-circle {
		position: absolute;
		transform: scale(1.78) rotate(-120deg);
	}
}
.submit-list {
	padding: 20px 40px;
	color: @font9;
	display: flex;
	justify-content: space-between;
}
.foot-links {
	padding: 20px 40px;
	background-color: #ffff;
	a {
		margin-top: 10px;
		display: block;
	}
}
.register-card {
	background-color: @font9;
	padding: 20px 40px;
	.register-content {
		color: @font1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: @font13;
		padding: 20px;
		border-radius: 10px;
		.register-right {
			padding-left: 30px;
			.right-title {
				.desc-big;
				color: @font1;
				font-weight: 800;
				> span {
					display: inline-block;
					color: @font1;
					font-weight: 400;
				}
			}
			.right-subTitle {
				.desc;
				color: @font1;
			}
			.right-desc {
				color: @font1;
			}
		}
	}
}
.check-label {
	display: flex;
	justify-content: space-between;
}
.link-title {
	margin: 0 0 20px 0;
	font-size: 28px;
	font-weight: 800;
	color: #000;
}
.card-list-box {
	display: flex;
	width: 100%;
	margin: 10px 0;
	border-radius: 20px;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	font-size: 20px;
	&.black-title {
		flex-direction: column;
		.card-list-header {
			padding: 10px 30px;
			border-radius: 30px 30px 0 0;
			background-color: rgb(242, 242, 244);
			color: #333;
		}
		.card-list-img {
			width: 100%;
			max-height: 260px;
			img {
				width: 100%;
			}
		}
		.card-list-desc {
			color: #333;
			padding: 20px 30px;
			border-radius: 0 0 30px 30px;
			font-size: 26px;
			font-family: @Regular-family;
			font-weight: 400;
			line-height: 40px;
			color: #888888;
		}
	}
	.card-list-left {
		flex: 1;
		padding: 30px;
		color: #333;
	}
	.card-list-img {
		width: 40%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0 20px 20px 0;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	h1 {
		font-weight: bold;
		margin-bottom: 10px;
		font-size: 30px;
	}
	p {
		line-height: 1.5;
		font-size: 26px;
		font-family: @Regular-family;
		font-weight: 400;
		line-height: 40px;
		color: #888888;
	}
}
</style>

<style lang="less">
@import url("~@/variables.less");
.tab-content {
	.tongue {
		margin: 20px 0;
		background-color: @bg8;
		color: @font9;
		border-radius: 5px;
		.van-cell__right-icon {
			color: @font9;
		}
	}
}
</style>

<template>
	<div class="crm-message-card">
		<div class="crm-message-card-top">
			<div class="crm-message-content">
				<p>
					<strong class="username">{{ info.patient.name }}</strong>
					<span class="sex">{{ info.patient.sex_dictText }}</span> <span class="age">{{ info.patient.age }}岁</span><span class="date">{{ info.patient.createTime | formatDate }}</span>
				</p>
				<p>病区：{{info.apply.nsWard_dictText}}</p>
				<p>入院时间：{{info.apply.nsInDate}} {{info.apply.nsInTime}}</p>
			</div>
		</div>
		<div class="crm-message-card-bottom">
			<div class="crm-message-msg">
				<span class="dian"></span>患者已入院
			</div>
			<a class="crm-message-btn"
				@click="handleDetails">详情</a>
			<van-dialog v-model:show="showDetails"
				cancel-button-text="关闭"
				confirm-button-text="前往患者主页"
				@cancel="handleUpdate"
				@confirm="changeRouter"
				show-cancel-button>
				<div class="crm-message-dialog">
					<div class="crm-message-card-top">
						<div class="crm-message-content">
							<p>
								<strong class="username">{{ info.patient.name }}</strong>
								<span class="sex">{{ info.patient.sex_dictText }}</span>
								<span class="age">{{ info.patient.age }}岁</span>
								<span class="date">{{ info.patient.payType_dictText }}</span>
							</p>
							<p>入院日期：{{info.apply.nsInDate}} {{info.apply.nsInTime}}</p>
							<p>入院院区：{{info.apply.nsWard_dictText}}</p>
							<p>入院病区：{{info.apply.zzHospital_dictText}}</p>
							<p>床号：{{info.apply.inBed}}</p>
							<p>主诊医生：{{info.apply.inChargeDirector}}</p>
							<p>管床医生：{{info.apply.inNurse}}</p>
						</div>
					</div>
				</div>
			</van-dialog>
		</div>
	</div>
</template>
<script>
	import crmMixins from "@/utils/crm-mixins";
	export default {
		name: "msg403",
		props: ["item"],
		mixins: [crmMixins],
		data () {
			return {
				showDetails: false,
				info: {
					patient: {},
					apply: {}
				}
			};
		},
		mounted () {
			this.info = JSON.parse(this.item.msgContent || "{}");
		},
		methods: {
			handleDetails () {
				this.showDetails = true
				this.$emit("read", this.item.anntId);
			},
			handleUpdate () {
				this.$emit('update')
			},
			changeRouter () {
				if (this.info.patient && this.info.patient.id) {
					this.$router.push(`/crm/profile/${this.info.patient.id}`);
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.crm-message-dialog {
		text-align: left;
		background: #ffffff;
		padding: 32px;
		margin: 32px 0;
	}
</style>

// 流调问卷
export const archivesQuery = {
	title: "快速自建档",
	info: "<div style='text-indent: 2em;'>上海好望角医院患者快速自主创建档案。</div>",
	hideInfo: true,
	id: 3,
	time: 3,
	joinCount: 3,
	start_btn: "立即建档",
	effective: 72,
	result_function: "",
	statement: {
		title: "患者快速自主建档",
		content:
			"您好！欢迎您使用患者快速自主建档，通过建档表单我们可能会收集和使用您的相关信息，我们知道任何用户的个人信息安全都是至关重要的，我们将高度重视并竭力保护好您的个人信息隐私的安全。您的个人信息将仅用于我院建档，院内其他服务。"
	},
	questions: [
		{
			title: "基本信息",
			type: "baseinfo",
			questions: []
		},
		{
			title: "其他信息",
			type: "content",
			showIndex: true,
			questions: []
		}
	]
};

export const ProvinceCity = [
	{
		text: "上海市",
		children: [
			{ text: "黄浦区" },
			{ text: "徐汇区" },
			{ text: "长宁区" },
			{ text: "静安区" },
			{ text: "普陀区" },
			{ text: "虹口区" },
			{ text: "杨浦区" },
			{ text: "闵行区" },
			{ text: "宝山区" },
			{ text: "嘉定区" },
			{ text: "浦东新区" },
			{ text: "金山区" },
			{ text: "松江区" },
			{ text: "青浦区" },
			{ text: "奉贤区" },
			{ text: "崇明区" }
		]
	},
	{
		text: "浙江省",
		children: [
			{ text: "杭州市" },
			{ text: "宁波市" },
			{ text: "温州市" },
			{ text: "嘉兴市" },
			{ text: "湖州市" },
			{ text: "绍兴市" },
			{ text: "金华市" },
			{ text: "衢州市" },
			{ text: "舟山市" },
			{ text: "台州市" },
			{ text: "丽水市" }
		]
	},
	{
		text: "江苏省",
		children: [
			{ text: "南京市" },
			{ text: "无锡市" },
			{ text: "徐州市" },
			{ text: "常州市" },
			{ text: "苏州市" },
			{ text: "南通市" },
			{ text: "连云港市" },
			{ text: "淮安市" },
			{ text: "盐城市" },
			{ text: "扬州市" },
			{ text: "镇江市" },
			{ text: "泰州市" },
			{ text: "宿迁市" }
		]
	},
	{
		text: "安徽省",
		children: [
			{ text: "合肥市" },
			{ text: "芜湖市" },
			{ text: "蚌埠市" },
			{ text: "淮南市" },
			{ text: "马鞍山市" },
			{ text: "淮北市" },
			{ text: "铜陵市" },
			{ text: "安庆市" },
			{ text: "黄山市" },
			{ text: "滁州市" },
			{ text: "阜阳市" },
			{ text: "宿州市" },
			{ text: "六安市" },
			{ text: "亳州市" },
			{ text: "池州市" },
			{ text: "宣城市" }
		]
	},
	{
		text: "北京市",
		children: [
			{ text: "东城区" },
			{ text: "西城区" },
			{ text: "朝阳区" },
			{ text: "丰台区" },
			{ text: "石景山区" },
			{ text: "海淀区" },
			{ text: "门头沟区" },
			{ text: "房山区" },
			{ text: "通州区" },
			{ text: "顺义区" },
			{ text: "昌平区" },
			{ text: "大兴区" },
			{ text: "怀柔区" },
			{ text: "平谷区" },
			{ text: "密云区" },
			{ text: "延庆区" }
		]
	},
	{
		text: "天津市",
		children: [
			{ text: "和平区" },
			{ text: "河东区" },
			{ text: "河西区" },
			{ text: "南开区" },
			{ text: "河北区" },
			{ text: "红桥区" },
			{ text: "东丽区" },
			{ text: "西青区" },
			{ text: "津南区" },
			{ text: "北辰区" },
			{ text: "武清区" },
			{ text: "宝坻区" },
			{ text: "滨海新区" },
			{ text: "宁河区" },
			{ text: "静海区" },
			{ text: "蓟州区" }
		]
	},
	{
		text: "河北省",
		children: [
			{ text: "石家庄市" },
			{ text: "唐山市" },
			{ text: "秦皇岛市" },
			{ text: "邯郸市" },
			{ text: "邢台市" },
			{ text: "保定市" },
			{ text: "张家口市" },
			{ text: "承德市" },
			{ text: "沧州市" },
			{ text: "廊坊市" },
			{ text: "衡水市" }
		]
	},
	{
		text: "山西省",
		children: [
			{ text: "太原市" },
			{ text: "大同市" },
			{ text: "阳泉市" },
			{ text: "长治市" },
			{ text: "晋城市" },
			{ text: "朔州市" },
			{ text: "晋中市" },
			{ text: "运城市" },
			{ text: "忻州市" },
			{ text: "临汾市" },
			{ text: "吕梁市" }
		]
	},
	{
		text: "内蒙古自治区",
		children: [
			{ text: "呼和浩特市" },
			{ text: "包头市" },
			{ text: "乌海市" },
			{ text: "赤峰市" },
			{ text: "通辽市" },
			{ text: "鄂尔多斯市" },
			{ text: "呼伦贝尔市" },
			{ text: "巴彦淖尔市" },
			{ text: "乌兰察布市" },
			{ text: "兴安盟" },
			{ text: "锡林郭勒盟" },
			{ text: "阿拉善盟" }
		]
	},
	{
		text: "辽宁省",
		children: [
			{ text: "沈阳市" },
			{ text: "大连市" },
			{ text: "鞍山市" },
			{ text: "抚顺市" },
			{ text: "本溪市" },
			{ text: "丹东市" },
			{ text: "锦州市" },
			{ text: "营口市" },
			{ text: "阜新市" },
			{ text: "辽阳市" },
			{ text: "盘锦市" },
			{ text: "铁岭市" },
			{ text: "朝阳市" },
			{ text: "葫芦岛市" }
		]
	},
	{
		text: "吉林省",
		children: [
			{ text: "长春市" },
			{ text: "吉林市" },
			{ text: "四平市" },
			{ text: "辽源市" },
			{ text: "通化市" },
			{ text: "白山市" },
			{ text: "松原市" },
			{ text: "白城市" },
			{ text: "延边朝鲜族自治州" }
		]
	},
	{
		text: "黑龙江省",
		children: [
			{ text: "哈尔滨市" },
			{ text: "齐齐哈尔市" },
			{ text: "鸡西市" },
			{ text: "鹤岗市" },
			{ text: "双鸭山市" },
			{ text: "大庆市" },
			{ text: "伊春市" },
			{ text: "佳木斯市" },
			{ text: "七台河市" },
			{ text: "牡丹江市" },
			{ text: "黑河市" },
			{ text: "绥化市" },
			{ text: "大兴安岭地区" }
		]
	},
	{
		text: "福建省",
		children: [
			{ text: "福州市" },
			{ text: "厦门市" },
			{ text: "莆田市" },
			{ text: "三明市" },
			{ text: "泉州市" },
			{ text: "漳州市" },
			{ text: "南平市" },
			{ text: "龙岩市" },
			{ text: "宁德市" }
		]
	},
	{
		text: "江西省",
		children: [
			{ text: "南昌市" },
			{ text: "景德镇市" },
			{ text: "萍乡市" },
			{ text: "九江市" },
			{ text: "新余市" },
			{ text: "鹰潭市" },
			{ text: "赣州市" },
			{ text: "吉安市" },
			{ text: "宜春市" },
			{ text: "抚州市" },
			{ text: "上饶市" }
		]
	},
	{
		text: "山东省",
		children: [
			{ text: "济南市" },
			{ text: "青岛市" },
			{ text: "淄博市" },
			{ text: "枣庄市" },
			{ text: "东营市" },
			{ text: "烟台市" },
			{ text: "潍坊市" },
			{ text: "济宁市" },
			{ text: "泰安市" },
			{ text: "威海市" },
			{ text: "日照市" },
			{ text: "临沂市" },
			{ text: "德州市" },
			{ text: "聊城市" },
			{ text: "滨州市" },
			{ text: "菏泽市" }
		]
	},
	{
		text: "河南省",
		children: [
			{ text: "郑州市" },
			{ text: "开封市" },
			{ text: "洛阳市" },
			{ text: "平顶山市" },
			{ text: "安阳市" },
			{ text: "鹤壁市" },
			{ text: "新乡市" },
			{ text: "焦作市" },
			{ text: "濮阳市" },
			{ text: "许昌市" },
			{ text: "漯河市" },
			{ text: "三门峡市" },
			{ text: "南阳市" },
			{ text: "商丘市" },
			{ text: "信阳市" },
			{ text: "周口市" },
			{ text: "驻马店市" },
			{ text: "济源市" }
		]
	},
	{
		text: "湖北省",
		children: [
			{ text: "武汉市" },
			{ text: "黄石市" },
			{ text: "十堰市" },
			{ text: "宜昌市" },
			{ text: "襄阳市" },
			{ text: "鄂州市" },
			{ text: "荆门市" },
			{ text: "孝感市" },
			{ text: "荆州市" },
			{ text: "黄冈市" },
			{ text: "咸宁市" },
			{ text: "随州市" },
			{ text: "恩施土家族苗族自治州" },
			{ text: "仙桃市" },
			{ text: "潜江市" },
			{ text: "天门市" },
			{ text: "神农架林区" }
		]
	},
	{
		text: "湖南省",
		children: [
			{ text: "长沙市" },
			{ text: "株洲市" },
			{ text: "湘潭市" },
			{ text: "衡阳市" },
			{ text: "邵阳市" },
			{ text: "岳阳市" },
			{ text: "常德市" },
			{ text: "张家界市" },
			{ text: "益阳市" },
			{ text: "郴州市" },
			{ text: "永州市" },
			{ text: "怀化市" },
			{ text: "娄底市" },
			{ text: "湘西土家族苗族自治州" }
		]
	},
	{
		text: "广东省",
		children: [
			{ text: "云浮市" },
			{ text: "揭阳市" },
			{ text: "潮州市" },
			{ text: "清远市" },
			{ text: "肇庆市" },
			{ text: "茂名市" },
			{ text: "湛江市" },
			{ text: "阳江市" },
			{ text: "江门市" },
			{ text: "中山市" },
			{ text: "东莞市" },
			{ text: "汕尾市" },
			{ text: "惠州市" },
			{ text: "梅州市" },
			{ text: "河源市" },
			{ text: "韶关市" },
			{ text: "佛山市" },
			{ text: "汕头市" },
			{ text: "广州市" },
			{ text: "深圳市" },
			{ text: "珠海市" },
		]
	},
	{
		text: "广西壮族自治区",
		children: [
			{ text: "南宁市" },
			{ text: "柳州市" },
			{ text: "桂林市" },
			{ text: "梧州市" },
			{ text: "北海市" },
			{ text: "防城港市" },
			{ text: "钦州市" },
			{ text: "贵港市" },
			{ text: "玉林市" },
			{ text: "百色市" },
			{ text: "贺州市" },
			{ text: "河池市" },
			{ text: "来宾市" },
			{ text: "崇左市" }
		]
	},
	{
		text: "海南省",
		children: [
			{ text: "海口市" },
			{ text: "三亚市" },
			{ text: "三沙市" },
			{ text: "儋州市" },
			{ text: "五指山市" },
			{ text: "琼海市" },
			{ text: "文昌市" },
			{ text: "万宁市" },
			{ text: "东方市" },
			{ text: "定安县" },
			{ text: "屯昌县" },
			{ text: "澄迈县" },
			{ text: "临高县" },
			{ text: "白沙黎族自治县" },
			{ text: "昌江黎族自治县" },
			{ text: "乐东黎族自治县" },
			{ text: "陵水黎族自治县" },
			{ text: "保亭黎族苗族自治县" },
			{ text: "琼中黎族苗族自治县" }
		]
	},
	{
		text: "重庆市",
		children: [
			{ text: "万州区" },
			{ text: "涪陵区" },
			{ text: "渝中区" },
			{ text: "大渡口区" },
			{ text: "江北区" },
			{ text: "沙坪坝区" },
			{ text: "九龙坡区" },
			{ text: "南岸区" },
			{ text: "北碚区" },
			{ text: "綦江区" },
			{ text: "大足区" },
			{ text: "渝北区" },
			{ text: "巴南区" },
			{ text: "黔江区" },
			{ text: "长寿区" },
			{ text: "江津区" },
			{ text: "合川区" },
			{ text: "永川区" },
			{ text: "南川区" },
			{ text: "璧山区" },
			{ text: "铜梁区" },
			{ text: "潼南区" },
			{ text: "荣昌区" },
			{ text: "开州区" },
			{ text: "梁平区" },
			{ text: "武隆区" },
			{ text: "城口县" },
			{ text: "丰都县" },
			{ text: "垫江县" },
			{ text: "忠县" },
			{ text: "云阳县" },
			{ text: "奉节县" },
			{ text: "巫山县" },
			{ text: "巫溪县" },
			{ text: "石柱土家族自治县" },
			{ text: "秀山土家族苗族自治县" },
			{ text: "酉阳土家族苗族自治县" },
			{ text: "彭水苗族土家族自治县" }
		]
	},
	{
		text: "四川省",
		children: [
			{ text: "成都市" },
			{ text: "自贡市" },
			{ text: "攀枝花市" },
			{ text: "泸州市" },
			{ text: "德阳市" },
			{ text: "绵阳市" },
			{ text: "广元市" },
			{ text: "遂宁市" },
			{ text: "内江市" },
			{ text: "乐山市" },
			{ text: "南充市" },
			{ text: "眉山市" },
			{ text: "宜宾市" },
			{ text: "广安市" },
			{ text: "达州市" },
			{ text: "雅安市" },
			{ text: "巴中市" },
			{ text: "资阳市" },
			{ text: "阿坝藏族羌族自治州" },
			{ text: "甘孜藏族自治州" },
			{ text: "凉山彝族自治州" }
		]
	},
	{
		text: "贵州省",
		children: [
			{ text: "贵阳市" },
			{ text: "六盘水市" },
			{ text: "遵义市" },
			{ text: "安顺市" },
			{ text: "毕节市" },
			{ text: "铜仁市" },
			{ text: "黔西南布依族苗族自治州" },
			{ text: "黔东南苗族侗族自治州" },
			{ text: "黔南布依族苗族自治州" }
		]
	},
	{
		text: "云南省",
		children: [
			{ text: "昆明市" },
			{ text: "曲靖市" },
			{ text: "玉溪市" },
			{ text: "保山市" },
			{ text: "昭通市" },
			{ text: "丽江市" },
			{ text: "普洱市" },
			{ text: "临沧市" },
			{ text: "楚雄彝族自治州" },
			{ text: "红河哈尼族彝族自治州" },
			{ text: "文山壮族苗族自治州" },
			{ text: "西双版纳傣族自治州" },
			{ text: "大理白族自治州" },
			{ text: "德宏傣族景颇族自治州" },
			{ text: "怒江傈僳族自治州" },
			{ text: "迪庆藏族自治州" }
		]
	},
	{
		text: "西藏自治区",
		children: [
			{ text: "拉萨市" },
			{ text: "日喀则市" },
			{ text: "昌都市" },
			{ text: "林芝市" },
			{ text: "山南市" },
			{ text: "那曲市" },
			{ text: "阿里地区" }
		]
	},
	{
		text: "陕西省",
		children: [
			{ text: "西安市" },
			{ text: "铜川市" },
			{ text: "宝鸡市" },
			{ text: "咸阳市" },
			{ text: "渭南市" },
			{ text: "延安市" },
			{ text: "汉中市" },
			{ text: "榆林市" },
			{ text: "安康市" },
			{ text: "商洛市" }
		]
	},
	{
		text: "甘肃省",
		children: [
			{ text: "兰州市" },
			{ text: "嘉峪关市" },
			{ text: "金昌市" },
			{ text: "白银市" },
			{ text: "天水市" },
			{ text: "武威市" },
			{ text: "张掖市" },
			{ text: "平凉市" },
			{ text: "酒泉市" },
			{ text: "庆阳市" },
			{ text: "定西市" },
			{ text: "陇南市" },
			{ text: "临夏回族自治州" },
			{ text: "甘南藏族自治州" }
		]
	},
	{
		text: "青海省",
		children: [
			{ text: "西宁市" },
			{ text: "海东市" },
			{ text: "海北藏族自治州" },
			{ text: "黄南藏族自治州" },
			{ text: "海南藏族自治州" },
			{ text: "果洛藏族自治州" },
			{ text: "玉树藏族自治州" },
			{ text: "海西蒙古族藏族自治州" }
		]
	},
	{ text: "宁夏回族自治区", children: [{ text: "银川市" }, { text: "石嘴山市" }, { text: "吴忠市" }, { text: "固原市" }, { text: "中卫市" }] },
	{
		text: "新疆维吾尔自治区",
		children: [
			{ text: "乌鲁木齐市" },
			{ text: "克拉玛依市" },
			{ text: "吐鲁番市" },
			{ text: "哈密市" },
			{ text: "昌吉回族自治州" },
			{ text: "博尔塔拉蒙古自治州" },
			{ text: "巴音郭楞蒙古自治州" },
			{ text: "阿克苏地区" },
			{ text: "克孜勒苏柯尔克孜自治州" },
			{ text: "喀什地区" },
			{ text: "和田地区" },
			{ text: "伊犁哈萨克自治州" },
			{ text: "塔城地区" },
			{ text: "阿勒泰地区" },
			{ text: "石河子市" },
			{ text: "阿拉尔市" },
			{ text: "图木舒克市" },
			{ text: "五家渠市" },
			{ text: "北屯市" },
			{ text: "铁门关市" },
			{ text: "双河市" },
			{ text: "可克达拉市" },
			{ text: "昆玉市" },
			{ text: "胡杨河市" },
			{ text: "新星市" }
		]
	}
];

export const card_type = [
	{ value: "身份证", text: "身份证", title: "身份证" },
	{ value: "医保卡", text: "医保卡", title: "医保卡" },
	{ value: "护照", text: "护照", title: "护照" },
	{ value: "other", text: "其他", title: "其他" }
];
export const sex = [
	{ value: "1", text: "男", title: "男" },
	{ value: "2", text: "女", title: "女" },
	{ value: "3", text: "未知", title: "未知" }
];

export const decision_type = [
	{ value: "2", text: "家属", title: "家属" },
	{ value: "1", text: "本人", title: "本人" }
];

export const patient_relation = [
	{ value: "zinv", text: "子女", title: "子女" },
	{ value: "qy", text: "亲友", title: "亲友" },
	{ value: "fumu", text: "父母", title: "父母" },
	{ value: "xdjm", text: "兄弟姐妹", title: "兄弟姐妹" },
	{ value: "夫妻", text: "夫妻", title: "夫妻" },
	{ value: "朋友", text: "朋友", title: "朋友" },
	{ value: "other", text: "其他", title: "其他" }
];

export const religion = [
	{ value: "fojiao", text: "佛教", title: "佛教" },
	{ value: "tianzhu", text: "天主教", title: "天主教" },
	{ value: "yisilanjiao", text: "伊斯兰教", title: "伊斯兰教" },
	{ value: "无", text: "无", title: "无" },
	{ value: "other", text: "其他", title: "其他" }
];

export const pay_type = [
	{ value: "bendi", text: "上海医保", title: "上海医保" },
	{ value: "zifei", text: "自费", title: "自费" },
	{ value: "yidi", text: "异地医保", title: "异地医保" }
];

export const sourceData = {
	all: [
		{
			id: "1540218087976996866",
			pid: "1539197003230171137",
			name: "上海市",
			code: "B08A04",
			createBy: "admin",
			createTime: "2022-06-24 14:19:43",
			updateBy: "admin",
			updateTime: "2022-06-24 15:38:36",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218415136903170",
			pid: "1539197003230171137",
			name: "浙江省",
			code: "B08A10",
			createBy: "admin",
			createTime: "2022-06-24 14:21:01",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:49",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1539446085286699009",
			pid: "1539197003230171137",
			name: "江苏省",
			code: "B08A02",
			createBy: "admin",
			createTime: "2022-06-22 11:12:03",
			updateBy: "admin",
			updateTime: "2022-06-22 11:12:12",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1539446124302114818",
			pid: "1539446085286699009",
			name: "南京市",
			code: "B08A02A01",
			createBy: "admin",
			createTime: "2022-06-22 11:12:12",
			updateBy: "admin",
			updateTime: "2022-06-22 11:12:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1539439845236088833",
			pid: "1539197003230171137",
			name: "四川省",
			code: "B08A01",
			createBy: "admin",
			createTime: "2022-06-22 10:47:15",
			updateBy: "admin",
			updateTime: "2022-06-22 10:47:28",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218377044234241",
			pid: "1539197003230171137",
			name: "安徽省",
			code: "B08A09",
			createBy: "admin",
			createTime: "2022-06-24 14:20:52",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:10",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1539439897132212225",
			pid: "1539439845236088833",
			name: "成都市",
			code: "B08A01A01",
			createBy: "admin",
			createTime: "2022-06-22 10:47:28",
			updateBy: "admin",
			updateTime: "2022-06-22 10:47:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540218200698916866",
			pid: "1539197003230171137",
			name: "福建省",
			code: "B08A05",
			createBy: "admin",
			createTime: "2022-06-24 14:20:10",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:38",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218252859281409",
			pid: "1539197003230171137",
			name: "内蒙古自治区",
			code: "B08A06",
			createBy: "admin",
			createTime: "2022-06-24 14:20:22",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:00",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218296815587329",
			pid: "1539197003230171137",
			name: "山东省",
			code: "B08A07",
			createBy: "admin",
			createTime: "2022-06-24 14:20:32",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:50",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218338540523522",
			pid: "1539197003230171137",
			name: "新疆维吾尔自治区",
			code: "B08A08",
			createBy: "admin",
			createTime: "2022-06-24 14:20:42",
			updateBy: "admin",
			updateTime: "2022-06-24 15:59:47",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218453837746177",
			pid: "1539197003230171137",
			name: "甘肃省",
			code: "B08A11",
			createBy: "admin",
			createTime: "2022-06-24 14:21:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:10:57",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218496086970370",
			pid: "1539197003230171137",
			name: "辽宁省",
			code: "B08A12",
			createBy: "admin",
			createTime: "2022-06-24 14:21:20",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:30",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218553993531394",
			pid: "1539197003230171137",
			name: "黑龙江省",
			code: "B08A13",
			createBy: "admin",
			createTime: "2022-06-24 14:21:34",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:40",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218593763921922",
			pid: "1539197003230171137",
			name: "河南省",
			code: "B08A14",
			createBy: "admin",
			createTime: "2022-06-24 14:21:43",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:46",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218634159263745",
			pid: "1539197003230171137",
			name: "江西省",
			code: "B08A15",
			createBy: "admin",
			createTime: "2022-06-24 14:21:53",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:55",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218673325674497",
			pid: "1539197003230171137",
			name: "广西壮族自治区",
			code: "B08A16",
			createBy: "admin",
			createTime: "2022-06-24 14:22:02",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:45",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218713704239105",
			pid: "1539197003230171137",
			name: "河北省",
			code: "B08A17",
			createBy: "admin",
			createTime: "2022-06-24 14:22:12",
			updateBy: "admin",
			updateTime: "2022-06-24 16:26:35",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{

			id: "1592326566728814593",
			pid: "1539197003230171137",
			name: "山西省",
			code: "B08A26",
			createBy: "admin",
			createTime: "2022-11-15 09:20:12",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:16:59",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{

			id: "1539446252358410242",
			pid: "1539197003230171137",
			name: "云南省",
			code: "B08A03",
			createBy: "admin",
			createTime: "2022-06-22 11:12:43",
			updateBy: "13818285743",
			updateTime: "2022-06-22 11:12:55",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1623541043194544130",
			pid: "1539446252358410242",
			name: "西双版纳傣族自治州",
			code: "B08A03A16",
			createBy: "13818285743",
			createTime: "2023-02-09 12:35:23",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:35:23",
			sysOrgCode: "A01",
			hasChild: null
		},

		{
			id: "1623541012962000897",
			pid: "1539446252358410242",
			name: "文山壮族苗族自治州",
			code: "B08A03A15",
			createBy: "13818285743",
			createTime: "2023-02-09 12:35:16",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:35:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540957249060866",
			pid: "1539446252358410242",
			name: "楚雄彝族自治州",
			code: "B08A03A13",
			createBy: "13818285743",
			createTime: "2023-02-09 12:35:03",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:35:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540984973410306",
			pid: "1539446252358410242",
			name: "红河哈尼族彝族自治州",
			code: "B08A03A14",
			createBy: "13818285743",
			createTime: "2023-02-09 12:35:09",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:35:09",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540928480329729",
			pid: "1539446252358410242",
			name: "大理白族自治州",
			code: "B08A03A12",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:56",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:56",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540903419363330",
			pid: "1539446252358410242",
			name: "迪庆藏族自治州",
			code: "B08A03A11",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:50",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540876370296833",
			pid: "1539446252358410242",
			name: "怒江傈僳族自治州",
			code: "B08A03A10",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:44",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:44",
			sysOrgCode: "A01",
			hasChild: null
		}, {
			id: "1623540848591421442",
			pid: "1539446252358410242",
			name: "德宏傣族景颇族自治州",
			code: "B08A03A09",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:37",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540802726707202",
			pid: "1539446252358410242",
			name: "临沧市",
			code: "B08A03A08",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:26",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540776495529986",
			pid: "1539446252358410242",
			name: "普洱市",
			code: "B08A03A07",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:20",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:20",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540746959241217",
			pid: "1539446252358410242",
			name: "丽江市",
			code: "B08A03A06",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:13",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540720757424129",
			pid: "1539446252358410242",
			name: "保山市",
			code: "B08A03A05",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:06",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540693083406337",
			pid: "1539446252358410242",
			name: "昭通市",
			code: "B08A03A04",
			createBy: "13818285743",
			createTime: "2023-02-09 12:34:00",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:34:00",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540658971131906",
			pid: "1539446252358410242",
			name: "玉溪市",
			code: "B08A03A03",
			createBy: "13818285743",
			createTime: "2023-02-09 12:33:52",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:33:52",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623540614792527873",
			pid: "1539446252358410242",
			name: "曲靖市",
			code: "B08A03A02",
			createBy: "13818285743",
			createTime: "2023-02-09 12:33:41",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:33:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1539446304212590593",
			pid: "1539446252358410242",
			name: "昆明市",
			code: "B08A03A01",
			createBy: "admin",
			createTime: "2022-06-22 11:12:55",
			updateBy: "admin",
			updateTime: "2022-06-22 11:12:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540218751427809281",
			pid: "1539197003230171137",
			name: "吉林省",
			code: "B08A18",
			createBy: "admin",
			createTime: "2022-06-24 14:22:21",
			updateBy: "admin",
			updateTime: "2022-06-24 16:28:58",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218789260431362",
			pid: "1539197003230171137",
			name: "湖北省",
			code: "B08A19",
			createBy: "admin",
			createTime: "2022-06-24 14:22:30",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:06",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218827801890817",
			pid: "1539197003230171137",
			name: "宁夏回族自治区",
			code: "B08A20",
			createBy: "admin",
			createTime: "2022-06-24 14:22:39",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:45",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540218867702304769",
			pid: "1539197003230171137",
			name: "湖南省",
			code: "B08A21",
			createBy: "admin",
			createTime: "2022-06-24 14:22:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:31",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1579641088916852738",
			pid: "1539197003230171137",
			name: "广东省",
			code: "B08A25",
			createBy: "admin",
			createTime: "2022-10-11 09:12:39",
			updateBy: "admin",
			updateTime: "2022-10-11 09:12:39",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1623539855841607682",
			pid: "1579641088916852738",
			name: "云浮市",
			code: "B08A25A21",
			createBy: "13818285743",
			createTime: "2023-02-09 12:30:40",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:30:40",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539824602431490",
			pid: "1579641088916852738",
			name: "揭阳市",
			code: "B08A25A20",
			createBy: "13818285743",
			createTime: "2023-02-09 12:30:33",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:30:33",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539792943824897",
			pid: "1579641088916852738",
			name: "潮州市",
			code: "B08A25A19",
			createBy: "13818285743",
			createTime: "2023-02-09 12:30:25",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:30:25",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			code: "B08A25A18",
			createBy: "13818285743",
			createTime: "2023-02-09 12:30:18",
			hasChild: null,
			id: "1623539761243275265",
			name: "清远市",
			pid: "1579641088916852738",
			sysOrgCode: "A01",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:30:18"
		},
		{
			id: "1623539720357199873",
			pid: "1579641088916852738",
			name: "肇庆市",
			code: "B08A25A17",
			createBy: "13818285743",
			createTime: "2023-02-09 12:30:08",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:30:08",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539683552182273",
			pid: "1579641088916852738",
			name: "茂名市",
			code: "B08A25A16",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:59",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:59",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539648827539458",
			pid: "1579641088916852738",
			name: "湛江市",
			code: "B08A25A15",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:51",
			updateBy: "13818285743",
			updateTime: "22023-02-09 12:29:51",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539614501355521",
			pid: "1579641088916852738",
			name: "阳江市",
			code: "B08A25A14",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:43",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:43",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539586105917441",
			pid: "1579641088916852738",
			name: "江门市",
			code: "B08A25A13",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:36",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:36",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539552719257602",
			pid: "1579641088916852738",
			name: "中山市",
			code: "B08A25A12",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:28",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:28",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539522843230209",
			pid: "1579641088916852738",
			name: "东莞市",
			code: "B08A25A11",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:21",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:21",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539488886145026",
			pid: "1579641088916852738",
			name: "汕尾市",
			code: "B08A25A10",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:13",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:13",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539452768993282",
			pid: "1579641088916852738",
			name: "惠州市",
			code: "B08A25A09",
			createBy: "13818285743",
			createTime: "2023-02-09 12:29:04",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:29:04",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539413506113538",
			pid: "1579641088916852738",
			name: "梅州市",
			code: "B08A25A08",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:55",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:55",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539383521034241",
			pid: "1579641088916852738",
			name: "河源市",
			code: "B08A25A07",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:48",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:48",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539349526200321",
			pid: "1579641088916852738",
			name: "韶关市",
			code: "B08A25A06",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:39",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:39",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539317997617153",
			pid: "1579641088916852738",
			name: "佛山市",
			code: "B08A25A05",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:32",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:32",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539284493516802",
			pid: "1579641088916852738",
			name: "汕头市",
			code: "B08A25A04",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:24",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:24",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1623539225085394945",
			pid: "1579641088916852738",
			name: "珠海市",
			code: "B08A25A03",
			createBy: "13818285743",
			createTime: "2023-02-09 12:28:10",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:28:10",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1613706919998455809",
			pid: "1579641088916852738",
			name: "广州市",
			code: "B08A25A02",
			createBy: "13818285743",
			createTime: "2023-01-13 09:18:06",
			updateBy: "13818285743",
			updateTime: "2023-01-13 09:18:06",
			sysOrgCode: "A01",
			hasChild: "null"
		},
		{
			id: "1579641138422222849",
			pid: "1579641088916852738",
			name: "深圳市",
			code: "B08A25A01",
			createBy: "13818285743",
			createTime: "2022-10-11 09:12:50",
			updateBy: "13818285743",
			updateTime: "2022-10-11 09:12:50",
			sysOrgCode: "A01",
			hasChild: "null"
		},





		{
			id: "1540218955807854593",
			pid: "1539197003230171137",
			name: "重庆市",
			code: "B08A22",
			createBy: "admin",
			createTime: "2022-06-24 14:23:10",
			updateBy: "admin",
			updateTime: "2022-06-24 14:46:20",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540219005904621570",
			pid: "1539197003230171137",
			name: "天津市",
			code: "B08A23",
			createBy: "admin",
			createTime: "2022-06-24 14:23:22",
			updateBy: "admin",
			updateTime: "2022-06-24 14:23:36",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1540219066159992834",
			pid: "1540219005904621570",
			name: "和平区",
			code: "B08A23A01",
			createBy: "admin",
			createTime: "2022-06-24 14:23:36",
			updateBy: "admin",
			updateTime: "2022-06-24 14:23:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219104663703554",
			pid: "1540219005904621570",
			name: "河东区",
			code: "B08A23A02",
			createBy: "admin",
			createTime: "2022-06-24 14:23:45",
			updateBy: "admin",
			updateTime: "2022-06-24 14:23:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219146023735297",
			pid: "1540219005904621570",
			name: "河西区",
			code: "B08A23A03",
			createBy: "admin",
			createTime: "2022-06-24 14:23:55",
			updateBy: "admin",
			updateTime: "2022-06-24 14:23:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219432175931394",
			pid: "1540219005904621570",
			name: "南开区",
			code: "B08A23A04",
			createBy: "admin",
			createTime: "2022-06-24 14:25:03",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219467940761602",
			pid: "1540219005904621570",
			name: "河北区",
			code: "B08A23A05",
			createBy: "admin",
			createTime: "2022-06-24 14:25:12",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219506633216002",
			pid: "1540219005904621570",
			name: "红桥区",
			code: "B08A23A06",
			createBy: "admin",
			createTime: "2022-06-24 14:25:21",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219550157508609",
			pid: "1540219005904621570",
			name: "东丽区",
			code: "B08A23A07",
			createBy: "admin",
			createTime: "2022-06-24 14:25:31",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219589600743426",
			pid: "1540219005904621570",
			name: "西青区",
			code: "B08A23A08",
			createBy: "admin",
			createTime: "2022-06-24 14:25:41",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219622928683009",
			pid: "1540219005904621570",
			name: "津南区",
			code: "B08A23A09",
			createBy: "admin",
			createTime: "2022-06-24 14:25:49",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219666092265474",
			pid: "1540219005904621570",
			name: "北辰区",
			code: "B08A23A10",
			createBy: "admin",
			createTime: "2022-06-24 14:25:59",
			updateBy: "admin",
			updateTime: "2022-06-24 14:25:59",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219697264332801",
			pid: "1540219005904621570",
			name: "武清区",
			code: "B08A23A11",
			createBy: "admin",
			createTime: "2022-06-24 14:26:06",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219726251167745",
			pid: "1540219005904621570",
			name: "宝坻区",
			code: "B08A23A12",
			createBy: "admin",
			createTime: "2022-06-24 14:26:13",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219759675576322",
			pid: "1540219005904621570",
			name: "滨海新区",
			code: "B08A23A13",
			createBy: "admin",
			createTime: "2022-06-24 14:26:21",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219789706792961",
			pid: "1540219005904621570",
			name: "宁河区",
			code: "B08A23A14",
			createBy: "admin",
			createTime: "2022-06-24 14:26:28",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219821986156546",
			pid: "1540219005904621570",
			name: "静海区",
			code: "B08A23A15",
			createBy: "admin",
			createTime: "2022-06-24 14:26:36",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540219854244548609",
			pid: "1540219005904621570",
			name: "蓟州区",
			code: "B08A23A16",
			createBy: "admin",
			createTime: "2022-06-24 14:26:44",
			updateBy: "admin",
			updateTime: "2022-06-24 14:26:44",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540224785655533569",
			pid: "1540218955807854593",
			name: "万州区",
			code: "B08A22A01",
			createBy: "admin",
			createTime: "2022-06-24 14:46:20",
			updateBy: "admin",
			updateTime: "2022-06-24 14:46:20",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227222214807554",
			pid: "1540218955807854593",
			name: "涪陵区",
			code: "B08A22A02",
			createBy: "admin",
			createTime: "2022-06-24 14:56:00",
			updateBy: "admin",
			updateTime: "2022-06-24 14:56:00",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227267131609090",
			pid: "1540218955807854593",
			name: "渝中区",
			code: "B08A22A03",
			createBy: "admin",
			createTime: "2022-06-24 14:56:11",
			updateBy: "admin",
			updateTime: "2022-06-24 14:56:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227303508807681",
			pid: "1540218955807854593",
			name: "大渡口区",
			code: "B08A22A04",
			createBy: "admin",
			createTime: "2022-06-24 14:56:20",
			updateBy: "admin",
			updateTime: "2022-06-24 14:56:20",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227336656392193",
			pid: "1540218955807854593",
			name: "江北区",
			code: "B08A22A05",
			createBy: "admin",
			createTime: "2022-06-24 14:56:28",
			updateBy: "admin",
			updateTime: "2022-06-24 14:56:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227865516183554",
			pid: "1540218955807854593",
			name: "沙坪坝区",
			code: "B08A22A06",
			createBy: "admin",
			createTime: "2022-06-24 14:58:34",
			updateBy: "admin",
			updateTime: "2022-06-24 14:58:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227893538328578",
			pid: "1540218955807854593",
			name: "九龙坡区",
			code: "B08A22A07",
			createBy: "admin",
			createTime: "2022-06-24 14:58:41",
			updateBy: "admin",
			updateTime: "2022-06-24 14:58:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227923884118017",
			pid: "1540218955807854593",
			name: "南岸区",
			code: "B08A22A08",
			createBy: "admin",
			createTime: "2022-06-24 14:58:48",
			updateBy: "admin",
			updateTime: "2022-06-24 14:58:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540227960559112193",
			pid: "1540218955807854593",
			name: "北碚区",
			code: "B08A22A09",
			createBy: "admin",
			createTime: "2022-06-24 14:58:57",
			updateBy: "admin",
			updateTime: "2022-06-24 14:58:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231067590463490",
			pid: "1540218955807854593",
			name: "綦江区",
			code: "B08A22A10",
			createBy: "admin",
			createTime: "2022-06-24 15:11:17",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:17",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231101815984130",
			pid: "1540218955807854593",
			name: "大足区",
			code: "B08A22A11",
			createBy: "admin",
			createTime: "2022-06-24 15:11:25",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231142345543681",
			pid: "1540218955807854593",
			name: "渝北区",
			code: "B08A22A12",
			createBy: "admin",
			createTime: "2022-06-24 15:11:35",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231171969912833",
			pid: "1540218955807854593",
			name: "巴南区",
			code: "B08A22A13",
			createBy: "admin",
			createTime: "2022-06-24 15:11:42",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:42",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231199459381249",
			pid: "1540218955807854593",
			name: "黔江区",
			code: "B08A22A14",
			createBy: "admin",
			createTime: "2022-06-24 15:11:49",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231232195923969",
			pid: "1540218955807854593",
			name: "长寿区",
			code: "B08A22A15",
			createBy: "admin",
			createTime: "2022-06-24 15:11:57",
			updateBy: "admin",
			updateTime: "2022-06-24 15:11:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231264861163522",
			pid: "1540218955807854593",
			name: "江津区",
			code: "B08A22A16",
			createBy: "admin",
			createTime: "2022-06-24 15:12:04",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231297262161921",
			pid: "1540218955807854593",
			name: "合川区",
			code: "B08A22A17",
			createBy: "admin",
			createTime: "2022-06-24 15:12:12",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231325535965185",
			pid: "1540218955807854593",
			name: "永川区",
			code: "B08A22A18",
			createBy: "admin",
			createTime: "2022-06-24 15:12:19",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231360952668162",
			pid: "1540218955807854593",
			name: "南川区",
			code: "B08A22A19",
			createBy: "admin",
			createTime: "2022-06-24 15:12:27",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231390660923394",
			pid: "1540218955807854593",
			name: "璧山区",
			code: "B08A22A20",
			createBy: "admin",
			createTime: "2022-06-24 15:12:34",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231422470524929",
			pid: "1540218955807854593",
			name: "铜梁区",
			code: "B08A22A21",
			createBy: "admin",
			createTime: "2022-06-24 15:12:42",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:42",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231484273594369",
			pid: "1540218955807854593",
			name: "潼南区",
			code: "B08A22A22",
			createBy: "admin",
			createTime: "2022-06-24 15:12:57",
			updateBy: "admin",
			updateTime: "2022-06-24 15:12:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231524299837442",
			pid: "1540218955807854593",
			name: "荣昌区",
			code: "B08A22A23",
			createBy: "admin",
			createTime: "2022-06-24 15:13:06",
			updateBy: "admin",
			updateTime: "2022-06-24 15:13:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231557397090305",
			pid: "1540218955807854593",
			name: "开州区",
			code: "B08A22A24",
			createBy: "admin",
			createTime: "2022-06-24 15:13:14",
			updateBy: "admin",
			updateTime: "2022-06-24 15:13:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231596227956737",
			pid: "1540218955807854593",
			name: "梁平区",
			code: "B08A22A25",
			createBy: "admin",
			createTime: "2022-06-24 15:13:23",
			updateBy: "admin",
			updateTime: "2022-06-24 15:13:23",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540231628335353857",
			pid: "1540218955807854593",
			name: "武隆区",
			code: "B08A22A26",
			createBy: "admin",
			createTime: "2022-06-24 15:13:31",
			updateBy: "admin",
			updateTime: "2022-06-24 15:13:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540237942050525186",
			pid: "1540218087976996866",
			name: "黄浦区",
			code: "B08A04A01",
			createBy: "admin",
			createTime: "2022-06-24 15:38:36",
			updateBy: "admin",
			updateTime: "2022-06-24 15:38:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540237987311259650",
			pid: "1540218087976996866",
			name: "徐汇区",
			code: "B08A04A02",
			createBy: "admin",
			createTime: "2022-06-24 15:38:47",
			updateBy: "admin",
			updateTime: "2022-06-24 15:38:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238019942944770",
			pid: "1540218087976996866",
			name: "长宁区",
			code: "B08A04A03",
			createBy: "admin",
			createTime: "2022-06-24 15:38:55",
			updateBy: "admin",
			updateTime: "2022-06-24 15:38:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238052306194433",
			pid: "1540218087976996866",
			name: "静安区",
			code: "B08A04A04",
			createBy: "admin",
			createTime: "2022-06-24 15:39:03",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238085432807425",
			pid: "1540218087976996866",
			name: "普陀区",
			code: "B08A04A05",
			createBy: "admin",
			createTime: "2022-06-24 15:39:10",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238119087902721",
			pid: "1540218087976996866",
			name: "虹口区",
			code: "B08A04A06",
			createBy: "admin",
			createTime: "2022-06-24 15:39:18",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:18",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238151765725186",
			pid: "1540218087976996866",
			name: "杨浦区",
			code: "B08A04A07",
			createBy: "admin",
			createTime: "2022-06-24 15:39:26",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238183831179265",
			pid: "1540218087976996866",
			name: "闵行区",
			code: "B08A04A08",
			createBy: "admin",
			createTime: "2022-06-24 15:39:34",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238220942381058",
			pid: "1540218087976996866",
			name: "宝山区",
			code: "B08A04A09",
			createBy: "admin",
			createTime: "2022-06-24 15:39:43",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:43",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238259106353154",
			pid: "1540218087976996866",
			name: "嘉定区",
			code: "B08A04A10",
			createBy: "admin",
			createTime: "2022-06-24 15:39:52",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:52",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238289397616642",
			pid: "1540218087976996866",
			name: "浦东新区",
			code: "B08A04A11",
			createBy: "admin",
			createTime: "2022-06-24 15:39:59",
			updateBy: "admin",
			updateTime: "2022-06-24 15:39:59",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238318497697794",
			pid: "1540218087976996866",
			name: "金山区",
			code: "B08A04A12",
			createBy: "admin",
			createTime: "2022-06-24 15:40:06",
			updateBy: "admin",
			updateTime: "2022-06-24 15:40:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238345265745921",
			pid: "1540218087976996866",
			name: "松江区",
			code: "B08A04A13",
			createBy: "admin",
			createTime: "2022-06-24 15:40:12",
			updateBy: "admin",
			updateTime: "2022-06-24 15:40:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238373170450434",
			pid: "1540218087976996866",
			name: "青浦区",
			code: "B08A04A14",
			createBy: "admin",
			createTime: "2022-06-24 15:40:19",
			updateBy: "admin",
			updateTime: "2022-06-24 15:40:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238401473613825",
			pid: "1540218087976996866",
			name: "奉贤区",
			code: "B08A04A15",
			createBy: "admin",
			createTime: "2022-06-24 15:40:26",
			updateBy: "admin",
			updateTime: "2022-06-24 15:40:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238439440453634",
			pid: "1540218087976996866",
			name: "崇明区",
			code: "B08A04A16",
			createBy: "admin",
			createTime: "2022-06-24 15:40:35",
			updateBy: "admin",
			updateTime: "2022-06-24 15:40:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238549343801345",
			pid: "1539446085286699009",
			name: "无锡市",
			code: "B08A02A02",
			createBy: "admin",
			createTime: "2022-06-24 15:41:01",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238591756603394",
			pid: "1539446085286699009",
			name: "徐州市",
			code: "B08A02A03",
			createBy: "admin",
			createTime: "2022-06-24 15:41:11",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238624195350529",
			pid: "1539446085286699009",
			name: "常州市",
			code: "B08A02A04",
			createBy: "admin",
			createTime: "2022-06-24 15:41:19",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238652217495554",
			pid: "1539446085286699009",
			name: "苏州市",
			code: "B08A02A05",
			createBy: "admin",
			createTime: "2022-06-24 15:41:26",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238678125711362",
			pid: "1539446085286699009",
			name: "南通市",
			code: "B08A02A06",
			createBy: "admin",
			createTime: "2022-06-24 15:41:32",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:32",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238702234570754",
			pid: "1539446085286699009",
			name: "连云港市",
			code: "B08A02A07",
			createBy: "admin",
			createTime: "2022-06-24 15:41:38",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238728541245441",
			pid: "1539446085286699009",
			name: "淮安市",
			code: "B08A02A08",
			createBy: "admin",
			createTime: "2022-06-24 15:41:44",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:44",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238754147471362",
			pid: "1539446085286699009",
			name: "盐城市",
			code: "B08A02A09",
			createBy: "admin",
			createTime: "2022-06-24 15:41:50",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238780714192897",
			pid: "1539446085286699009",
			name: "扬州市",
			code: "B08A02A10",
			createBy: "admin",
			createTime: "2022-06-24 15:41:56",
			updateBy: "admin",
			updateTime: "2022-06-24 15:41:56",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238811768819714",
			pid: "1539446085286699009",
			name: "镇江市",
			code: "B08A02A11",
			createBy: "admin",
			createTime: "2022-06-24 15:42:04",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238839916793857",
			pid: "1539446085286699009",
			name: "泰州市",
			code: "B08A02A12",
			createBy: "admin",
			createTime: "2022-06-24 15:42:10",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238872242294786",
			pid: "1539446085286699009",
			name: "宿迁市",
			code: "B08A02A13",
			createBy: "admin",
			createTime: "2022-06-24 15:42:18",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:18",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238955826384898",
			pid: "1540218200698916866",
			name: "福州市",
			code: "B08A05A01",
			createBy: "admin",
			createTime: "2022-06-24 15:42:38",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540238988692951042",
			pid: "1540218200698916866",
			name: "厦门市",
			code: "B08A05A02",
			createBy: "admin",
			createTime: "2022-06-24 15:42:46",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:46",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239020640964610",
			pid: "1540218200698916866",
			name: "莆田市",
			code: "B08A05A03",
			createBy: "admin",
			createTime: "2022-06-24 15:42:53",
			updateBy: "admin",
			updateTime: "2022-06-24 15:42:53",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239054719684609",
			pid: "1540218200698916866",
			name: "三明市",
			code: "B08A05A04",
			createBy: "admin",
			createTime: "2022-06-24 15:43:02",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:02",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239083270311937",
			pid: "1540218200698916866",
			name: "泉州市",
			code: "B08A05A05",
			createBy: "admin",
			createTime: "2022-06-24 15:43:08",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:08",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239111690915841",
			pid: "1540218200698916866",
			name: "漳州市",
			code: "B08A05A06",
			createBy: "admin",
			createTime: "2022-06-24 15:43:15",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239137825624066",
			pid: "1540218200698916866",
			name: "南平市",
			code: "B08A05A07",
			createBy: "admin",
			createTime: "2022-06-24 15:43:21",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239164081967105",
			pid: "1540218200698916866",
			name: "龙岩市",
			code: "B08A05A08",
			createBy: "admin",
			createTime: "2022-06-24 15:43:28",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540239191810510850",
			pid: "1540218200698916866",
			name: "宁德市",
			code: "B08A05A09",
			createBy: "admin",
			createTime: "2022-06-24 15:43:34",
			updateBy: "admin",
			updateTime: "2022-06-24 15:43:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241314853949442",
			pid: "1540218252859281409",
			name: "呼和浩特市",
			code: "B08A06A01",
			createBy: "admin",
			createTime: "2022-06-24 15:52:00",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:00",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241358604734466",
			pid: "1540218252859281409",
			name: "包头市",
			code: "B08A06A02",
			createBy: "admin",
			createTime: "2022-06-24 15:52:11",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241396500271105",
			pid: "1540218252859281409",
			name: "乌海市",
			code: "B08A06A03",
			createBy: "admin",
			createTime: "2022-06-24 15:52:20",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:20",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241428230180865",
			pid: "1540218252859281409",
			name: "赤峰市",
			code: "B08A06A04",
			createBy: "admin",
			createTime: "2022-06-24 15:52:27",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241459934924801",
			pid: "1540218252859281409",
			name: "通辽市",
			code: "B08A06A05",
			createBy: "admin",
			createTime: "2022-06-24 15:52:35",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241488347140098",
			pid: "1540218252859281409",
			name: "鄂尔多斯市",
			code: "B08A06A06",
			createBy: "admin",
			createTime: "2022-06-24 15:52:42",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:42",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241516012769281",
			pid: "1540218252859281409",
			name: "呼伦贝尔市",
			code: "B08A06A07",
			createBy: "admin",
			createTime: "2022-06-24 15:52:48",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241552473853954",
			pid: "1540218252859281409",
			name: "巴彦淖尔市",
			code: "B08A06A08",
			createBy: "admin",
			createTime: "2022-06-24 15:52:57",
			updateBy: "admin",
			updateTime: "2022-06-24 15:52:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241590491025410",
			pid: "1540218252859281409",
			name: "乌兰察布市",
			code: "B08A06A09",
			createBy: "admin",
			createTime: "2022-06-24 15:53:06",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241626494930946",
			pid: "1540218252859281409",
			name: "兴安盟",
			code: "B08A06A10",
			createBy: "admin",
			createTime: "2022-06-24 15:53:15",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241654286389250",
			pid: "1540218252859281409",
			name: "锡林郭勒盟",
			code: "B08A06A11",
			createBy: "admin",
			createTime: "2022-06-24 15:53:21",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241687991816194",
			pid: "1540218252859281409",
			name: "阿拉善盟",
			code: "B08A06A12",
			createBy: "admin",
			createTime: "2022-06-24 15:53:29",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:29",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241775526940673",
			pid: "1540218296815587329",
			name: "济南市",
			code: "B08A07A01",
			createBy: "admin",
			createTime: "2022-06-24 15:53:50",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241805931450369",
			pid: "1540218296815587329",
			name: "青岛市",
			code: "B08A07A02",
			createBy: "admin",
			createTime: "2022-06-24 15:53:58",
			updateBy: "admin",
			updateTime: "2022-06-24 15:53:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241832724664322",
			pid: "1540218296815587329",
			name: "淄博市",
			code: "B08A07A03",
			createBy: "admin",
			createTime: "2022-06-24 15:54:04",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241863217254401",
			pid: "1540218296815587329",
			name: "枣庄市",
			code: "B08A07A04",
			createBy: "admin",
			createTime: "2022-06-24 15:54:11",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241891545583618",
			pid: "1540218296815587329",
			name: "东营市",
			code: "B08A07A05",
			createBy: "admin",
			createTime: "2022-06-24 15:54:18",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:18",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241919257350145",
			pid: "1540218296815587329",
			name: "烟台市",
			code: "B08A07A06",
			createBy: "admin",
			createTime: "2022-06-24 15:54:25",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241948508426242",
			pid: "1540218296815587329",
			name: "潍坊市",
			code: "B08A07A07",
			createBy: "admin",
			createTime: "2022-06-24 15:54:31",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540241993521696769",
			pid: "1540218296815587329",
			name: "济宁市",
			code: "B08A07A08",
			createBy: "admin",
			createTime: "2022-06-24 15:54:42",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:42",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242021720002561",
			pid: "1540218296815587329",
			name: "泰安市",
			code: "B08A07A09",
			createBy: "admin",
			createTime: "2022-06-24 15:54:49",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242046638362626",
			pid: "1540218296815587329",
			name: "威海市",
			code: "B08A07A10",
			createBy: "admin",
			createTime: "2022-06-24 15:54:55",
			updateBy: "admin",
			updateTime: "2022-06-24 15:54:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242077298724865",
			pid: "1540218296815587329",
			name: "日照市",
			code: "B08A07A11",
			createBy: "admin",
			createTime: "2022-06-24 15:55:02",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:02",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242105190846465",
			pid: "1540218296815587329",
			name: "临沂市",
			code: "B08A07A12",
			createBy: "admin",
			createTime: "2022-06-24 15:55:09",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:09",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242133946994689",
			pid: "1540218296815587329",
			name: "德州市",
			code: "B08A07A13",
			createBy: "admin",
			createTime: "2022-06-24 15:55:16",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242173281177602",
			pid: "1540218296815587329",
			name: "聊城市",
			code: "B08A07A14",
			createBy: "admin",
			createTime: "2022-06-24 15:55:25",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242200246358017",
			pid: "1540218296815587329",
			name: "滨州市",
			code: "B08A07A15",
			createBy: "admin",
			createTime: "2022-06-24 15:55:32",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:32",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540242227031183361",
			pid: "1540218296815587329",
			name: "菏泽市",
			code: "B08A07A16",
			createBy: "admin",
			createTime: "2022-06-24 15:55:38",
			updateBy: "admin",
			updateTime: "2022-06-24 15:55:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243270121033729",
			pid: "1540218338540523522",
			name: "乌鲁木齐市",
			code: "B08A08A01",
			createBy: "admin",
			createTime: "2022-06-24 15:59:47",
			updateBy: "admin",
			updateTime: "2022-06-24 15:59:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243325477457922",
			pid: "1540218338540523522",
			name: "克拉玛依市",
			code: "B08A08A02",
			createBy: "admin",
			createTime: "2022-06-24 16:00:00",
			updateBy: "admin",
			updateTime: "2022-06-24 16:00:00",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243370008383490",
			pid: "1540218338540523522",
			name: "吐鲁番市",
			code: "B08A08A03",
			createBy: "admin",
			createTime: "2022-06-24 16:00:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:00:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243417500487682",
			pid: "1540218338540523522",
			name: "哈密市",
			code: "B08A08A04",
			createBy: "admin",
			createTime: "2022-06-24 16:00:22",
			updateBy: "admin",
			updateTime: "2022-06-24 16:00:22",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243470835257345",
			pid: "1540218338540523522",
			name: "昌吉回族自治州",
			code: "B08A08A05",
			createBy: "admin",
			createTime: "2022-06-24 16:00:34",
			updateBy: "admin",
			updateTime: "2022-06-24 16:00:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243538145447938",
			pid: "1540218338540523522",
			name: "博尔塔拉蒙古自治州",
			code: "B08A08A06",
			createBy: "admin",
			createTime: "2022-06-24 16:00:50",
			updateBy: "admin",
			updateTime: "2022-06-24 16:00:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243580998651905",
			pid: "1540218338540523522",
			name: "巴音郭楞蒙古自治州",
			code: "B08A08A07",
			createBy: "admin",
			createTime: "2022-06-24 16:01:01",
			updateBy: "admin",
			updateTime: "2022-06-24 16:01:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243619447836674",
			pid: "1540218338540523522",
			name: "阿克苏地区",
			code: "B08A08A08",
			createBy: "admin",
			createTime: "2022-06-24 16:01:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:01:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243666105274369",
			pid: "1540218338540523522",
			name: "克孜勒苏柯尔克孜自治州",
			code: "B08A08A09",
			createBy: "admin",
			createTime: "2022-06-24 16:01:21",
			updateBy: "admin",
			updateTime: "2022-06-24 16:01:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243722808070145",
			pid: "1540218338540523522",
			name: "喀什地区",
			code: "B08A08A10",
			createBy: "admin",
			createTime: "2022-06-24 16:01:35",
			updateBy: "admin",
			updateTime: "2022-06-24 16:01:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243775001989121",
			pid: "1540218338540523522",
			name: "和田地区",
			code: "B08A08A11",
			createBy: "admin",
			createTime: "2022-06-24 16:01:47",
			updateBy: "admin",
			updateTime: "2022-06-24 16:01:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243831809642498",
			pid: "1540218338540523522",
			name: "伊犁哈萨克自治州",
			code: "B08A08A12",
			createBy: "admin",
			createTime: "2022-06-24 16:02:01",
			updateBy: "admin",
			updateTime: "2022-06-24 16:02:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243888365637634",
			pid: "1540218338540523522",
			name: "塔城地区",
			code: "B08A08A13",
			createBy: "admin",
			createTime: "2022-06-24 16:02:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:02:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243940760883201",
			pid: "1540218338540523522",
			name: "阿勒泰地区",
			code: "B08A08A14",
			createBy: "admin",
			createTime: "2022-06-24 16:02:26",
			updateBy: "admin",
			updateTime: "2022-06-24 16:02:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540243992355016705",
			pid: "1540218338540523522",
			name: "石河子市",
			code: "B08A08A15",
			createBy: "admin",
			createTime: "2022-06-24 16:02:39",
			updateBy: "admin",
			updateTime: "2022-06-24 16:02:39",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244033316589570",
			pid: "1540218338540523522",
			name: "阿拉尔市",
			code: "B08A08A16",
			createBy: "admin",
			createTime: "2022-06-24 16:02:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:02:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244198756716545",
			pid: "1540218338540523522",
			name: "图木舒克市",
			code: "B08A08A17",
			createBy: "admin",
			createTime: "2022-06-24 16:03:28",
			updateBy: "admin",
			updateTime: "2022-06-24 16:03:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244248614408193",
			pid: "1540218338540523522",
			name: "五家渠市",
			code: "B08A08A18",
			createBy: "admin",
			createTime: "2022-06-24 16:03:40",
			updateBy: "admin",
			updateTime: "2022-06-24 16:03:40",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244287743070210",
			pid: "1540218338540523522",
			name: "北屯市",
			code: "B08A08A19",
			createBy: "admin",
			createTime: "2022-06-24 16:03:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:03:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244322954252289",
			pid: "1540218338540523522",
			name: "铁门关市",
			code: "B08A08A20",
			createBy: "admin",
			createTime: "2022-06-24 16:03:58",
			updateBy: "admin",
			updateTime: "2022-06-24 16:03:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244373533364226",
			pid: "1540218338540523522",
			name: "双河市",
			code: "B08A08A21",
			createBy: "admin",
			createTime: "2022-06-24 16:04:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:04:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244414239084545",
			pid: "1540218338540523522",
			name: "可克达拉市",
			code: "B08A08A22",
			createBy: "admin",
			createTime: "2022-06-24 16:04:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:04:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244462666518529",
			pid: "1540218338540523522",
			name: "昆玉市",
			code: "B08A08A23",
			createBy: "admin",
			createTime: "2022-06-24 16:04:31",
			updateBy: "admin",
			updateTime: "2022-06-24 16:04:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244502084587521",
			pid: "1540218338540523522",
			name: "胡杨河市",
			code: "B08A08A24",
			createBy: "admin",
			createTime: "2022-06-24 16:04:40",
			updateBy: "admin",
			updateTime: "2022-06-24 16:04:40",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244543633362946",
			pid: "1540218338540523522",
			name: "新星市",
			code: "B08A08A25",
			createBy: "admin",
			createTime: "2022-06-24 16:04:50",
			updateBy: "admin",
			updateTime: "2022-06-24 16:04:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244628769345537",
			pid: "1540218377044234241",
			name: "合肥市",
			code: "B08A09A01",
			createBy: "admin",
			createTime: "2022-06-24 16:05:11",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244664735502337",
			pid: "1540218377044234241",
			name: "芜湖市",
			code: "B08A09A02",
			createBy: "admin",
			createTime: "2022-06-24 16:05:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244699887964161",
			pid: "1540218377044234241",
			name: "蚌埠市",
			code: "B08A09A03",
			createBy: "admin",
			createTime: "2022-06-24 16:05:27",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244739188592642",
			pid: "1540218377044234241",
			name: "淮南市",
			code: "B08A09A04",
			createBy: "admin",
			createTime: "2022-06-24 16:05:37",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244774374608898",
			pid: "1540218377044234241",
			name: "马鞍山市",
			code: "B08A09A05",
			createBy: "admin",
			createTime: "2022-06-24 16:05:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244818809065474",
			pid: "1540218377044234241",
			name: "淮北市",
			code: "B08A09A06",
			createBy: "admin",
			createTime: "2022-06-24 16:05:56",
			updateBy: "admin",
			updateTime: "2022-06-24 16:05:56",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244857996447746",
			pid: "1540218377044234241",
			name: "铜陵市",
			code: "B08A09A07",
			createBy: "admin",
			createTime: "2022-06-24 16:06:05",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244897628426241",
			pid: "1540218377044234241",
			name: "安庆市",
			code: "B08A09A08",
			createBy: "admin",
			createTime: "2022-06-24 16:06:15",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244938720022530",
			pid: "1540218377044234241",
			name: "黄山市",
			code: "B08A09A09",
			createBy: "admin",
			createTime: "2022-06-24 16:06:24",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:24",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540244993636044801",
			pid: "1540218377044234241",
			name: "滁州市",
			code: "B08A09A10",
			createBy: "admin",
			createTime: "2022-06-24 16:06:38",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245026691354625",
			pid: "1540218377044234241",
			name: "阜阳市",
			code: "B08A09A11",
			createBy: "admin",
			createTime: "2022-06-24 16:06:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245060480667649",
			pid: "1540218377044234241",
			name: "宿州市",
			code: "B08A09A12",
			createBy: "admin",
			createTime: "2022-06-24 16:06:53",
			updateBy: "admin",
			updateTime: "2022-06-24 16:06:53",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245093565337601",
			pid: "1540218377044234241",
			name: "六安市",
			code: "B08A09A13",
			createBy: "admin",
			createTime: "2022-06-24 16:07:01",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245124829679618",
			pid: "1540218377044234241",
			name: "亳州市",
			code: "B08A09A14",
			createBy: "admin",
			createTime: "2022-06-24 16:07:09",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:09",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245159818563586",
			pid: "1540218377044234241",
			name: "池州市",
			code: "B08A09A15",
			createBy: "admin",
			createTime: "2022-06-24 16:07:17",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:17",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245201824518146",
			pid: "1540218377044234241",
			name: "宣城市",
			code: "B08A09A16",
			createBy: "admin",
			createTime: "2022-06-24 16:07:27",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245291624566785",
			pid: "1540218415136903170",
			name: "杭州市",
			code: "B08A10A01",
			createBy: "admin",
			createTime: "2022-06-24 16:07:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245326126911490",
			pid: "1540218415136903170",
			name: "宁波市",
			code: "B08A10A02",
			createBy: "admin",
			createTime: "2022-06-24 16:07:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:07:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245359714897921",
			pid: "1540218415136903170",
			name: "温州市",
			code: "B08A10A03",
			createBy: "admin",
			createTime: "2022-06-24 16:08:05",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245392505966594",
			pid: "1540218415136903170",
			name: "嘉兴市",
			code: "B08A10A04",
			createBy: "admin",
			createTime: "2022-06-24 16:08:13",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245425561276418",
			pid: "1540218415136903170",
			name: "湖州市",
			code: "B08A10A05",
			createBy: "admin",
			createTime: "2022-06-24 16:08:20",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:20",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245460097175554",
			pid: "1540218415136903170",
			name: "绍兴市",
			code: "B08A10A06",
			createBy: "admin",
			createTime: "2022-06-24 16:08:29",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:29",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245496533094401",
			pid: "1540218415136903170",
			name: "金华市",
			code: "B08A10A07",
			createBy: "admin",
			createTime: "2022-06-24 16:08:37",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245535959552001",
			pid: "1540218415136903170",
			name: "衢州市",
			code: "B08A10A08",
			createBy: "admin",
			createTime: "2022-06-24 16:08:47",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245580779884546",
			pid: "1540218415136903170",
			name: "舟山市",
			code: "B08A10A09",
			createBy: "admin",
			createTime: "2022-06-24 16:08:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:08:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245617979166721",
			pid: "1540218415136903170",
			name: "台州市",
			code: "B08A10A10",
			createBy: "admin",
			createTime: "2022-06-24 16:09:06",
			updateBy: "admin",
			updateTime: "2022-06-24 16:09:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540245655555936257",
			pid: "1540218415136903170",
			name: "丽水市",
			code: "B08A10A11",
			createBy: "admin",
			createTime: "2022-06-24 16:09:15",
			updateBy: "admin",
			updateTime: "2022-06-24 16:09:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246083454636034",
			pid: "1540218453837746177",
			name: "兰州市",
			code: "B08A11A01",
			createBy: "admin",
			createTime: "2022-06-24 16:10:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:10:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246125762580482",
			pid: "1540218453837746177",
			name: "嘉峪关市",
			code: "B08A11A02",
			createBy: "admin",
			createTime: "2022-06-24 16:11:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:11:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246168414457858",
			pid: "1540218453837746177",
			name: "金昌市",
			code: "B08A11A03",
			createBy: "admin",
			createTime: "2022-06-24 16:11:18",
			updateBy: "admin",
			updateTime: "2022-06-24 16:11:18",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246213889101825",
			pid: "1540218453837746177",
			name: "白银市",
			code: "B08A11A04",
			createBy: "admin",
			createTime: "2022-06-24 16:11:28",
			updateBy: "admin",
			updateTime: "2022-06-24 16:11:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246250769616898",
			pid: "1540218453837746177",
			name: "天水市",
			code: "B08A11A05",
			createBy: "admin",
			createTime: "2022-06-24 16:11:37",
			updateBy: "admin",
			updateTime: "2022-06-24 16:11:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246298177835009",
			pid: "1540218453837746177",
			name: "武威市",
			code: "B08A11A06",
			createBy: "admin",
			createTime: "2022-06-24 16:11:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:11:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246360022847490",
			pid: "1540218453837746177",
			name: "张掖市",
			code: "B08A11A07",
			createBy: "admin",
			createTime: "2022-06-24 16:12:03",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246397045968897",
			pid: "1540218453837746177",
			name: "平凉市",
			code: "B08A11A08",
			createBy: "admin",
			createTime: "2022-06-24 16:12:12",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246437298704386",
			pid: "1540218453837746177",
			name: "酒泉市",
			code: "B08A11A09",
			createBy: "admin",
			createTime: "2022-06-24 16:12:22",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:22",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246474187608065",
			pid: "1540218453837746177",
			name: "庆阳市",
			code: "B08A11A10",
			createBy: "admin",
			createTime: "2022-06-24 16:12:31",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246510673858562",
			pid: "1540218453837746177",
			name: "定西市",
			code: "B08A11A11",
			createBy: "admin",
			createTime: "2022-06-24 16:12:39",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:39",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246546153476097",
			pid: "1540218453837746177",
			name: "陇南市",
			code: "B08A11A12",
			createBy: "admin",
			createTime: "2022-06-24 16:12:48",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246583600222209",
			pid: "1540218453837746177",
			name: "临夏回族自治州",
			code: "B08A11A13",
			createBy: "admin",
			createTime: "2022-06-24 16:12:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:12:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246620375879682",
			pid: "1540218453837746177",
			name: "甘南藏族自治州",
			code: "B08A11A14",
			createBy: "admin",
			createTime: "2022-06-24 16:13:05",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246721743818754",
			pid: "1540218496086970370",
			name: "沈阳市",
			code: "B08A12A01",
			createBy: "admin",
			createTime: "2022-06-24 16:13:30",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:30",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246752941051905",
			pid: "1540218496086970370",
			name: "大连市",
			code: "B08A12A02",
			createBy: "admin",
			createTime: "2022-06-24 16:13:37",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246788986900482",
			pid: "1540218496086970370",
			name: "鞍山市",
			code: "B08A12A03",
			createBy: "admin",
			createTime: "2022-06-24 16:13:46",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:46",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246823355027457",
			pid: "1540218496086970370",
			name: "抚顺市",
			code: "B08A12A04",
			createBy: "admin",
			createTime: "2022-06-24 16:13:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:13:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246855667945474",
			pid: "1540218496086970370",
			name: "本溪市",
			code: "B08A12A05",
			createBy: "admin",
			createTime: "2022-06-24 16:14:01",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246892011589633",
			pid: "1540218496086970370",
			name: "丹东市",
			code: "B08A12A06",
			createBy: "admin",
			createTime: "2022-06-24 16:14:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246928590114818",
			pid: "1540218496086970370",
			name: "锦州市",
			code: "B08A12A07",
			createBy: "admin",
			createTime: "2022-06-24 16:14:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540246962517839873",
			pid: "1540218496086970370",
			name: "营口市",
			code: "B08A12A08",
			createBy: "admin",
			createTime: "2022-06-24 16:14:27",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247004343439361",
			pid: "1540218496086970370",
			name: "阜新市",
			code: "B08A12A09",
			createBy: "admin",
			createTime: "2022-06-24 16:14:37",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:37",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247038371827713",
			pid: "1540218496086970370",
			name: "辽阳市",
			code: "B08A12A10",
			createBy: "admin",
			createTime: "2022-06-24 16:14:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247075772436481",
			pid: "1540218496086970370",
			name: "盘锦市",
			code: "B08A12A11",
			createBy: "admin",
			createTime: "2022-06-24 16:14:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:14:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247113047216129",
			pid: "1540218496086970370",
			name: "铁岭市",
			code: "B08A12A12",
			createBy: "admin",
			createTime: "2022-06-24 16:15:03",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247143917293570",
			pid: "1540218496086970370",
			name: "朝阳市",
			code: "B08A12A13",
			createBy: "admin",
			createTime: "2022-06-24 16:15:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247179401105410",
			pid: "1540218496086970370",
			name: "葫芦岛市",
			code: "B08A12A14",
			createBy: "admin",
			createTime: "2022-06-24 16:15:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247268676866050",
			pid: "1540218553993531394",
			name: "哈尔滨市",
			code: "B08A13A01",
			createBy: "admin",
			createTime: "2022-06-24 16:15:40",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:40",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247300629073921",
			pid: "1540218553993531394",
			name: "齐齐哈尔市",
			code: "B08A13A02",
			createBy: "admin",
			createTime: "2022-06-24 16:15:48",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247331780169729",
			pid: "1540218553993531394",
			name: "鸡西市",
			code: "B08A13A03",
			createBy: "admin",
			createTime: "2022-06-24 16:15:55",
			updateBy: "admin",
			updateTime: "2022-06-24 16:15:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247361354207233",
			pid: "1540218553993531394",
			name: "鹤岗市",
			code: "B08A13A04",
			createBy: "admin",
			createTime: "2022-06-24 16:16:02",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:02",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247394224967681",
			pid: "1540218553993531394",
			name: "双鸭山市",
			code: "B08A13A05",
			createBy: "admin",
			createTime: "2022-06-24 16:16:10",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247432271499265",
			pid: "1540218553993531394",
			name: "大庆市",
			code: "B08A13A06",
			createBy: "admin",
			createTime: "2022-06-24 16:16:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247468082466817",
			pid: "1540218553993531394",
			name: "伊春市",
			code: "B08A13A07",
			createBy: "admin",
			createTime: "2022-06-24 16:16:27",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247503004241922",
			pid: "1540218553993531394",
			name: "佳木斯市",
			code: "B08A13A08",
			createBy: "admin",
			createTime: "2022-06-24 16:16:36",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247541038190593",
			pid: "1540218553993531394",
			name: "七台河市",
			code: "B08A13A09",
			createBy: "admin",
			createTime: "2022-06-24 16:16:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247580724695042",
			pid: "1540218553993531394",
			name: "牡丹江市",
			code: "B08A13A10",
			createBy: "admin",
			createTime: "2022-06-24 16:16:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:16:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247623208800257",
			pid: "1540218553993531394",
			name: "黑河市",
			code: "B08A13A11",
			createBy: "admin",
			createTime: "2022-06-24 16:17:04",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247664627552257",
			pid: "1540218553993531394",
			name: "绥化市",
			code: "B08A13A12",
			createBy: "admin",
			createTime: "2022-06-24 16:17:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247708642578434",
			pid: "1540218553993531394",
			name: "大兴安岭地区",
			code: "B08A13A13",
			createBy: "admin",
			createTime: "2022-06-24 16:17:25",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247799277293570",
			pid: "1540218593763921922",
			name: "郑州市",
			code: "B08A14A01",
			createBy: "admin",
			createTime: "2022-06-24 16:17:46",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:46",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247842285686786",
			pid: "1540218593763921922",
			name: "开封市",
			code: "B08A14A02",
			createBy: "admin",
			createTime: "2022-06-24 16:17:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:17:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247883612164098",
			pid: "1540218593763921922",
			name: "洛阳市",
			code: "B08A14A03",
			createBy: "admin",
			createTime: "2022-06-24 16:18:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247922409476098",
			pid: "1540218593763921922",
			name: "平顶山市",
			code: "B08A14A04",
			createBy: "admin",
			createTime: "2022-06-24 16:18:16",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540247971633827841",
			pid: "1540218593763921922",
			name: "安阳市",
			code: "B08A14A05",
			createBy: "admin",
			createTime: "2022-06-24 16:18:28",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248008384319489",
			pid: "1540218593763921922",
			name: "鹤壁市",
			code: "B08A14A06",
			createBy: "admin",
			createTime: "2022-06-24 16:18:36",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248046435045377",
			pid: "1540218593763921922",
			name: "新乡市",
			code: "B08A14A07",
			createBy: "admin",
			createTime: "2022-06-24 16:18:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248096028495873",
			pid: "1540218593763921922",
			name: "焦作市",
			code: "B08A14A08",
			createBy: "admin",
			createTime: "2022-06-24 16:18:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:18:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248133580099585",
			pid: "1540218593763921922",
			name: "濮阳市",
			code: "B08A14A09",
			createBy: "admin",
			createTime: "2022-06-24 16:19:06",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248171429498882",
			pid: "1540218593763921922",
			name: "许昌市",
			code: "B08A14A10",
			createBy: "admin",
			createTime: "2022-06-24 16:19:15",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248216270802945",
			pid: "1540218593763921922",
			name: "漯河市",
			code: "B08A14A11",
			createBy: "admin",
			createTime: "2022-06-24 16:19:26",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248252471840770",
			pid: "1540218593763921922",
			name: "三门峡市",
			code: "B08A14A12",
			createBy: "admin",
			createTime: "2022-06-24 16:19:34",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248292414197761",
			pid: "1540218593763921922",
			name: "南阳市",
			code: "B08A14A13",
			createBy: "admin",
			createTime: "2022-06-24 16:19:44",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:44",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248332868259841",
			pid: "1540218593763921922",
			name: "商丘市",
			code: "B08A14A14",
			createBy: "admin",
			createTime: "2022-06-24 16:19:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:19:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248379659915265",
			pid: "1540218593763921922",
			name: "信阳市",
			code: "B08A14A15",
			createBy: "admin",
			createTime: "2022-06-24 16:20:05",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248415500242946",
			pid: "1540218593763921922",
			name: "周口市",
			code: "B08A14A16",
			createBy: "admin",
			createTime: "2022-06-24 16:20:13",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248450690453506",
			pid: "1540218593763921922",
			name: "驻马店市",
			code: "B08A14A17",
			createBy: "admin",
			createTime: "2022-06-24 16:20:22",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:22",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248489856864257",
			pid: "1540218593763921922",
			name: "济源市",
			code: "B08A14A18",
			createBy: "admin",
			createTime: "2022-06-24 16:20:31",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248589660327938",
			pid: "1540218634159263745",
			name: "南昌市",
			code: "B08A15A01",
			createBy: "admin",
			createTime: "2022-06-24 16:20:55",
			updateBy: "admin",
			updateTime: "2022-06-24 16:20:55",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248625714565122",
			pid: "1540218634159263745",
			name: "景德镇市",
			code: "B08A15A02",
			createBy: "admin",
			createTime: "2022-06-24 16:21:03",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248662146289665",
			pid: "1540218634159263745",
			name: "萍乡市",
			code: "B08A15A03",
			createBy: "admin",
			createTime: "2022-06-24 16:21:12",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:12",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248698821283841",
			pid: "1540218634159263745",
			name: "九江市",
			code: "B08A15A04",
			createBy: "admin",
			createTime: "2022-06-24 16:21:21",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248735341088769",
			pid: "1540218634159263745",
			name: "新余市",
			code: "B08A15A05",
			createBy: "admin",
			createTime: "2022-06-24 16:21:30",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:30",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248770032177153",
			pid: "1540218634159263745",
			name: "鹰潭市",
			code: "B08A15A06",
			createBy: "admin",
			createTime: "2022-06-24 16:21:38",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248810461073410",
			pid: "1540218634159263745",
			name: "赣州市",
			code: "B08A15A07",
			createBy: "admin",
			createTime: "2022-06-24 16:21:48",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248852810960897",
			pid: "1540218634159263745",
			name: "吉安市",
			code: "B08A15A08",
			createBy: "admin",
			createTime: "2022-06-24 16:21:58",
			updateBy: "admin",
			updateTime: "2022-06-24 16:21:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248891562135554",
			pid: "1540218634159263745",
			name: "宜春市",
			code: "B08A15A09",
			createBy: "admin",
			createTime: "2022-06-24 16:22:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248931114422273",
			pid: "1540218634159263745",
			name: "抚州市",
			code: "B08A15A10",
			createBy: "admin",
			createTime: "2022-06-24 16:22:16",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540248967890079745",
			pid: "1540218634159263745",
			name: "上饶市",
			code: "B08A15A11",
			createBy: "admin",
			createTime: "2022-06-24 16:22:25",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249051675496450",
			pid: "1540218673325674497",
			name: "南宁市",
			code: "B08A16A01",
			createBy: "admin",
			createTime: "2022-06-24 16:22:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249087993974785",
			pid: "1540218673325674497",
			name: "柳州市",
			code: "B08A16A02",
			createBy: "admin",
			createTime: "2022-06-24 16:22:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:22:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249122957692929",
			pid: "1540218673325674497",
			name: "桂林市",
			code: "B08A16A03",
			createBy: "admin",
			createTime: "2022-06-24 16:23:02",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:02",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249168000323586",
			pid: "1540218673325674497",
			name: "梧州市",
			code: "B08A16A04",
			createBy: "admin",
			createTime: "2022-06-24 16:23:13",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249205312851969",
			pid: "1540218673325674497",
			name: "北海市",
			code: "B08A16A05",
			createBy: "admin",
			createTime: "2022-06-24 16:23:22",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:22",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249247398498306",
			pid: "1540218673325674497",
			name: "防城港市",
			code: "B08A16A06",
			createBy: "admin",
			createTime: "2022-06-24 16:23:32",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:32",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249280692883457",
			pid: "1540218673325674497",
			name: "钦州市",
			code: "B08A16A07",
			createBy: "admin",
			createTime: "2022-06-24 16:23:40",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:40",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249313437814785",
			pid: "1540218673325674497",
			name: "贵港市",
			code: "B08A16A08",
			createBy: "admin",
			createTime: "2022-06-24 16:23:47",
			updateBy: "admin",
			updateTime: "2022-06-24 16:23:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249540139945985",
			pid: "1540218673325674497",
			name: "玉林市",
			code: "B08A16A09",
			createBy: "admin",
			createTime: "2022-06-24 16:24:41",
			updateBy: "admin",
			updateTime: "2022-06-24 16:24:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249600378540033",
			pid: "1540218673325674497",
			name: "百色市",
			code: "B08A16A10",
			createBy: "admin",
			createTime: "2022-06-24 16:24:56",
			updateBy: "admin",
			updateTime: "2022-06-24 16:24:56",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249635308703746",
			pid: "1540218673325674497",
			name: "贺州市",
			code: "B08A16A11",
			createBy: "admin",
			createTime: "2022-06-24 16:25:04",
			updateBy: "admin",
			updateTime: "2022-06-24 16:25:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249677121720321",
			pid: "1540218673325674497",
			name: "河池市",
			code: "B08A16A12",
			createBy: "admin",
			createTime: "2022-06-24 16:25:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:25:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249713373089794",
			pid: "1540218673325674497",
			name: "来宾市",
			code: "B08A16A13",
			createBy: "admin",
			createTime: "2022-06-24 16:25:23",
			updateBy: "admin",
			updateTime: "2022-06-24 16:25:23",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540249748122898433",
			pid: "1540218673325674497",
			name: "崇左市",
			code: "B08A16A14",
			createBy: "admin",
			createTime: "2022-06-24 16:25:31",
			updateBy: "admin",
			updateTime: "2022-06-24 16:25:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250015698522113",
			pid: "1540218713704239105",
			name: "石家庄市",
			code: "B08A17A01",
			createBy: "admin",
			createTime: "2022-06-24 16:26:35",
			updateBy: "admin",
			updateTime: "2022-06-24 16:26:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250051253637121",
			pid: "1540218713704239105",
			name: "唐山市",
			code: "B08A17A02",
			createBy: "admin",
			createTime: "2022-06-24 16:26:43",
			updateBy: "admin",
			updateTime: "2022-06-24 16:26:43",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250089409220609",
			pid: "1540218713704239105",
			name: "秦皇岛市",
			code: "B08A17A03",
			createBy: "admin",
			createTime: "2022-06-24 16:26:52",
			updateBy: "admin",
			updateTime: "2022-06-24 16:26:52",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250143054368769",
			pid: "1540218713704239105",
			name: "邯郸市",
			code: "B08A17A04",
			createBy: "admin",
			createTime: "2022-06-24 16:27:05",
			updateBy: "admin",
			updateTime: "2022-06-24 16:27:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250181461610498",
			pid: "1540218713704239105",
			name: "邢台市",
			code: "B08A17A05",
			createBy: "admin",
			createTime: "2022-06-24 16:27:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:27:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250231294136322",
			pid: "1540218713704239105",
			name: "保定市",
			code: "B08A17A06",
			createBy: "admin",
			createTime: "2022-06-24 16:27:26",
			updateBy: "admin",
			updateTime: "2022-06-24 16:27:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250308158951425",
			pid: "1540218713704239105",
			name: "张家口市",
			code: "B08A17A07",
			createBy: "admin",
			createTime: "2022-06-24 16:27:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:27:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250365335703553",
			pid: "1540218713704239105",
			name: "承德市",
			code: "B08A17A08",
			createBy: "admin",
			createTime: "2022-06-24 16:27:58",
			updateBy: "admin",
			updateTime: "2022-06-24 16:27:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250402937638913",
			pid: "1540218713704239105",
			name: "沧州市",
			code: "B08A17A09",
			createBy: "admin",
			createTime: "2022-06-24 16:28:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:28:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250440828981249",
			pid: "1540218713704239105",
			name: "廊坊市",
			code: "B08A17A10",
			createBy: "admin",
			createTime: "2022-06-24 16:28:16",
			updateBy: "admin",
			updateTime: "2022-06-24 16:28:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250478640631810",
			pid: "1540218713704239105",
			name: "衡水市",
			code: "B08A17A11",
			createBy: "admin",
			createTime: "2022-06-24 16:28:25",
			updateBy: "admin",
			updateTime: "2022-06-24 16:28:25",
			sysOrgCode: "A01",
			hasChild: null
		},



		{
			id: "1623536874723262466",
			pid: "1592326566728814593",
			name: "运城市",
			code: "B08A26A11",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:49",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536845132447746",
			pid: "1592326566728814593",
			name: "临汾市",
			code: "B08A26A10",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:42",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:42",
			sysOrgCode: "A01",
			hasChild: null
		},

		{
			id: "1623536806272221186",
			pid: "1592326566728814593",
			name: "晋城市",
			code: "B08A26A09",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:33",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:33",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536771702767618",
			pid: "1592326566728814593",
			name: "长治市",
			code: "B08A26A08",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:25",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536739993829378",
			pid: "1592326566728814593",
			name: "晋中市",
			code: "B08A26A07",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:17",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:17",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536689758650370",
			pid: "1592326566728814593",
			name: "吕梁市",
			code: "B08A26A06",
			createBy: "13818285743",
			createTime: "2023-02-09 12:18:05",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:18:05",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536657961631745",
			pid: "1592326566728814593",
			name: "阳泉市",
			code: "B08A26A05",
			createBy: "13818285743",
			createTime: "2023-02-09 12:17:58",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:17:58",
			sysOrgCode: "A01",
			hasChild: null
		},

		{
			id: "1623536616719040514",
			pid: "1592326566728814593",
			name: "忻州市",
			code: "B08A26A04",
			createBy: "13818285743",
			createTime: "2023-02-09 12:17:48",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:17:48",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536576982204418",
			pid: "1592326566728814593",
			name: "朔州市",
			code: "B08A26A03",
			createBy: "13818285743",
			createTime: "2023-02-09 12:17:38",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:17:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536510989025281",
			pid: "1592326566728814593",
			name: "大同市",
			code: "B08A26A02",
			createBy: "13818285743",
			createTime: "2023-02-09 12:17:23",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:17:23",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1623536414171906049",
			pid: "1592326566728814593",
			name: "太原市",
			code: "B08A26A01",
			createBy: "13818285743",
			createTime: "2023-02-09 12:17:00",
			updateBy: "13818285743",
			updateTime: "2023-02-09 12:17:00",
			sysOrgCode: "A01",
			hasChild: null
		},













		{
			id: "1540250615148449794",
			pid: "1540218751427809281",
			name: "长春市",
			code: "B08A18A01",
			createBy: "admin",
			createTime: "2022-06-24 16:28:58",
			updateBy: "admin",
			updateTime: "2022-06-24 16:28:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250654017064961",
			pid: "1540218751427809281",
			name: "吉林市",
			code: "B08A18A02",
			createBy: "admin",
			createTime: "2022-06-24 16:29:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250688888508418",
			pid: "1540218751427809281",
			name: "四平市",
			code: "B08A18A03",
			createBy: "admin",
			createTime: "2022-06-24 16:29:15",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250732429578242",
			pid: "1540218751427809281",
			name: "辽源市",
			code: "B08A18A04",
			createBy: "admin",
			createTime: "2022-06-24 16:29:26",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250768756445185",
			pid: "1540218751427809281",
			name: "通化市",
			code: "B08A18A05",
			createBy: "admin",
			createTime: "2022-06-24 16:29:34",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250809952899074",
			pid: "1540218751427809281",
			name: "白山市",
			code: "B08A18A06",
			createBy: "admin",
			createTime: "2022-06-24 16:29:44",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:44",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250847085072385",
			pid: "1540218751427809281",
			name: "松原市",
			code: "B08A18A07",
			createBy: "admin",
			createTime: "2022-06-24 16:29:53",
			updateBy: "admin",
			updateTime: "2022-06-24 16:29:53",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250891607609346",
			pid: "1540218751427809281",
			name: "白城市",
			code: "B08A18A08",
			createBy: "admin",
			createTime: "2022-06-24 16:30:04",
			updateBy: "admin",
			updateTime: "2022-06-24 16:30:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540250934834106369",
			pid: "1540218751427809281",
			name: "延边朝鲜族自治州",
			code: "B08A18A09",
			createBy: "admin",
			createTime: "2022-06-24 16:30:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:30:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251153609003010",
			pid: "1540218789260431362",
			name: "武汉市",
			code: "B08A19A01",
			createBy: "admin",
			createTime: "2022-06-24 16:31:06",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251184919482370",
			pid: "1540218789260431362",
			name: "黄石市",
			code: "B08A19A02",
			createBy: "admin",
			createTime: "2022-06-24 16:31:14",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:14",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251217555361793",
			pid: "1540218789260431362",
			name: "十堰市",
			code: "B08A19A03",
			createBy: "admin",
			createTime: "2022-06-24 16:31:21",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251248748400641",
			pid: "1540218789260431362",
			name: "宜昌市",
			code: "B08A19A04",
			createBy: "admin",
			createTime: "2022-06-24 16:31:29",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:29",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251286945927170",
			pid: "1540218789260431362",
			name: "襄阳市",
			code: "B08A19A05",
			createBy: "admin",
			createTime: "2022-06-24 16:31:38",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:38",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251326485630977",
			pid: "1540218789260431362",
			name: "鄂州市",
			code: "B08A19A06",
			createBy: "admin",
			createTime: "2022-06-24 16:31:47",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:47",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251365119365122",
			pid: "1540218789260431362",
			name: "荆门市",
			code: "B08A19A07",
			createBy: "admin",
			createTime: "2022-06-24 16:31:57",
			updateBy: "admin",
			updateTime: "2022-06-24 16:31:57",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251397830742018",
			pid: "1540218789260431362",
			name: "孝感市",
			code: "B08A19A08",
			createBy: "admin",
			createTime: "2022-06-24 16:32:04",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:04",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251449819140097",
			pid: "1540218789260431362",
			name: "荆州市",
			code: "B08A19A09",
			createBy: "admin",
			createTime: "2022-06-24 16:32:17",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:17",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251483667173378",
			pid: "1540218789260431362",
			name: "黄冈市",
			code: "B08A19A10",
			createBy: "admin",
			createTime: "2022-06-24 16:32:25",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:25",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251516785397762",
			pid: "1540218789260431362",
			name: "咸宁市",
			code: "B08A19A11",
			createBy: "admin",
			createTime: "2022-06-24 16:32:33",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:33",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251551879139329",
			pid: "1540218789260431362",
			name: "随州市",
			code: "B08A19A12",
			createBy: "admin",
			createTime: "2022-06-24 16:32:41",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251597861294082",
			pid: "1540218789260431362",
			name: "恩施土家族苗族自治州",
			code: "B08A19A13",
			createBy: "admin",
			createTime: "2022-06-24 16:32:52",
			updateBy: "admin",
			updateTime: "2022-06-24 16:32:52",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251638827061249",
			pid: "1540218789260431362",
			name: "仙桃市",
			code: "B08A19A14",
			createBy: "admin",
			createTime: "2022-06-24 16:33:02",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:02",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251677121056769",
			pid: "1540218789260431362",
			name: "潜江市",
			code: "B08A19A15",
			createBy: "admin",
			createTime: "2022-06-24 16:33:11",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251718355259394",
			pid: "1540218789260431362",
			name: "天门市",
			code: "B08A19A16",
			createBy: "admin",
			createTime: "2022-06-24 16:33:21",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:21",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251751716753410",
			pid: "1540218789260431362",
			name: "神农架林区",
			code: "B08A19A17",
			createBy: "admin",
			createTime: "2022-06-24 16:33:29",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:29",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251820293623810",
			pid: "1540218827801890817",
			name: "银川市",
			code: "B08A20A01",
			createBy: "admin",
			createTime: "2022-06-24 16:33:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251850752659458",
			pid: "1540218827801890817",
			name: "石嘴山市",
			code: "B08A20A02",
			createBy: "admin",
			createTime: "2022-06-24 16:33:52",
			updateBy: "admin",
			updateTime: "2022-06-24 16:33:52",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251881383661570",
			pid: "1540218827801890817",
			name: "吴忠市",
			code: "B08A20A03",
			createBy: "admin",
			createTime: "2022-06-24 16:34:00",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:00",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251912845135874",
			pid: "1540218827801890817",
			name: "固原市",
			code: "B08A20A04",
			createBy: "admin",
			createTime: "2022-06-24 16:34:07",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540251944084312065",
			pid: "1540218827801890817",
			name: "中卫市",
			code: "B08A20A05",
			createBy: "admin",
			createTime: "2022-06-24 16:34:15",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:15",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252014095634434",
			pid: "1540218867702304769",
			name: "长沙市",
			code: "B08A21A01",
			createBy: "admin",
			createTime: "2022-06-24 16:34:31",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:31",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252053001998337",
			pid: "1540218867702304769",
			name: "株洲市",
			code: "B08A21A02",
			createBy: "admin",
			createTime: "2022-06-24 16:34:41",
			updateBy: "admin",
			updateTime: "2022-06-24 16:34:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252179611258882",
			pid: "1540218867702304769",
			name: "湘潭市",
			code: "B08A21A03",
			createBy: "admin",
			createTime: "2022-06-24 16:35:11",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:11",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252214197489665",
			pid: "1540218867702304769",
			name: "衡阳市",
			code: "B08A21A04",
			createBy: "admin",
			createTime: "2022-06-24 16:35:19",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252250830540801",
			pid: "1540218867702304769",
			name: "邵阳市",
			code: "B08A21A05",
			createBy: "admin",
			createTime: "2022-06-24 16:35:28",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:28",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252286188523522",
			pid: "1540218867702304769",
			name: "岳阳市",
			code: "B08A21A06",
			createBy: "admin",
			createTime: "2022-06-24 16:35:36",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:36",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252322527973377",
			pid: "1540218867702304769",
			name: "常德市",
			code: "B08A21A07",
			createBy: "admin",
			createTime: "2022-06-24 16:35:45",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:45",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252360633225217",
			pid: "1540218867702304769",
			name: "张家界市",
			code: "B08A21A08",
			createBy: "admin",
			createTime: "2022-06-24 16:35:54",
			updateBy: "admin",
			updateTime: "2022-06-24 16:35:54",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252398281297921",
			pid: "1540218867702304769",
			name: "益阳市",
			code: "B08A21A09",
			createBy: "admin",
			createTime: "2022-06-24 16:36:03",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:03",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252439230287874",
			pid: "1540218867702304769",
			name: "郴州市",
			code: "B08A21A10",
			createBy: "admin",
			createTime: "2022-06-24 16:36:13",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252477130018818",
			pid: "1540218867702304769",
			name: "永州市",
			code: "B08A21A11",
			createBy: "admin",
			createTime: "2022-06-24 16:36:22",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:22",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252525150605314",
			pid: "1540218867702304769",
			name: "怀化市",
			code: "B08A21A12",
			createBy: "admin",
			createTime: "2022-06-24 16:36:33",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:33",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252557564186625",
			pid: "1540218867702304769",
			name: "娄底市",
			code: "B08A21A13",
			createBy: "admin",
			createTime: "2022-06-24 16:36:41",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540252591865204738",
			pid: "1540218867702304769",
			name: "湘西土家族苗族自治州",
			code: "B08A21A14",
			createBy: "admin",
			createTime: "2022-06-24 16:36:49",
			updateBy: "admin",
			updateTime: "2022-06-24 16:36:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540892811539255298",
			pid: "1539439845236088833",
			name: "自贡市",
			code: "B08A01A02",
			createBy: "admin",
			createTime: "2022-06-26 11:00:49",
			updateBy: "admin",
			updateTime: "2022-06-26 11:00:49",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540892861610856449",
			pid: "1539439845236088833",
			name: "攀枝花市",
			code: "B08A01A03",
			createBy: "admin",
			createTime: "2022-06-26 11:01:01",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:01",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540892896817844225",
			pid: "1539439845236088833",
			name: "泸州市",
			code: "B08A01A04",
			createBy: "admin",
			createTime: "2022-06-26 11:01:10",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:10",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540892927125884930",
			pid: "1539439845236088833",
			name: "德阳市",
			code: "B08A01A05",
			createBy: "admin",
			createTime: "2022-06-26 11:01:17",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:17",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893001688027138",
			pid: "1539439845236088833",
			name: "绵阳市",
			code: "B08A01A06",
			createBy: "admin",
			createTime: "2022-06-26 11:01:35",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:35",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893034697199617",
			pid: "1539439845236088833",
			name: "广元市",
			code: "B08A01A07",
			createBy: "admin",
			createTime: "2022-06-26 11:01:43",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:43",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893069463785473",
			pid: "1539439845236088833",
			name: "遂宁市",
			code: "B08A01A08",
			createBy: "admin",
			createTime: "2022-06-26 11:01:51",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:51",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893100874928129",
			pid: "1539439845236088833",
			name: "内江市",
			code: "B08A01A09",
			createBy: "admin",
			createTime: "2022-06-26 11:01:58",
			updateBy: "admin",
			updateTime: "2022-06-26 11:01:58",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893131292020738",
			pid: "1539439845236088833",
			name: "乐山市",
			code: "B08A01A10",
			createBy: "admin",
			createTime: "2022-06-26 11:02:06",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:06",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893161251934209",
			pid: "1539439845236088833",
			name: "南充市",
			code: "B08A01A11",
			createBy: "admin",
			createTime: "2022-06-26 11:02:13",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893189144055809",
			pid: "1539439845236088833",
			name: "眉山市",
			code: "B08A01A12",
			createBy: "admin",
			createTime: "2022-06-26 11:02:19",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:19",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893220630695937",
			pid: "1539439845236088833",
			name: "宜宾市",
			code: "B08A01A13",
			createBy: "admin",
			createTime: "2022-06-26 11:02:27",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:27",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893251282669569",
			pid: "1539439845236088833",
			name: "广安市",
			code: "B08A01A14",
			createBy: "admin",
			createTime: "2022-06-26 11:02:34",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:34",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893280345001986",
			pid: "1539439845236088833",
			name: "达州市",
			code: "B08A01A15",
			createBy: "admin",
			createTime: "2022-06-26 11:02:41",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:41",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893316118220801",
			pid: "1539439845236088833",
			name: "雅安市",
			code: "B08A01A16",
			createBy: "admin",
			createTime: "2022-06-26 11:02:50",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:50",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893354038923265",
			pid: "1539439845236088833",
			name: "巴中市",
			code: "B08A01A17",
			createBy: "admin",
			createTime: "2022-06-26 11:02:59",
			updateBy: "admin",
			updateTime: "2022-06-26 11:02:59",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893389694701570",
			pid: "1539439845236088833",
			name: "资阳市",
			code: "B08A01A18",
			createBy: "admin",
			createTime: "2022-06-26 11:03:07",
			updateBy: "admin",
			updateTime: "2022-06-26 11:03:07",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893425048489985",
			pid: "1539439845236088833",
			name: "阿坝藏族羌族自治州",
			code: "B08A01A19",
			createBy: "admin",
			createTime: "2022-06-26 11:03:16",
			updateBy: "admin",
			updateTime: "2022-06-26 11:03:16",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893468019134466",
			pid: "1539439845236088833",
			name: "甘孜藏族自治州",
			code: "B08A01A20",
			createBy: "admin",
			createTime: "2022-06-26 11:03:26",
			updateBy: "admin",
			updateTime: "2022-06-26 11:03:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1540893497056301058",
			pid: "1539439845236088833",
			name: "凉山彝族自治州",
			code: "B08A01A21",
			createBy: "admin",
			createTime: "2022-06-26 11:03:33",
			updateBy: "admin",
			updateTime: "2022-06-26 11:03:33",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1542338182807932930",
			pid: "1540219005904621570",
			name: "天津市",
			code: "B08A23A17",
			createBy: "admin",
			createTime: "2022-06-30 10:44:13",
			updateBy: "admin",
			updateTime: "2022-06-30 10:44:13",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1542338238151774210",
			pid: "1540218955807854593",
			name: "重庆市",
			code: "B08A22A27",
			createBy: "admin",
			createTime: "2022-06-30 10:44:26",
			updateBy: "admin",
			updateTime: "2022-06-30 10:44:26",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1542338352018739202",
			pid: "1540218087976996866",
			name: "上海市",
			code: "B08A04A17",
			createBy: "admin",
			createTime: "2022-06-30 10:44:53",
			updateBy: "admin",
			updateTime: "2022-06-30 10:44:53",
			sysOrgCode: "A01",
			hasChild: null
		},
		{
			id: "1542339757488066561",
			pid: "1539197003230171137",
			name: "北京市",
			code: "B08A24",
			createBy: "admin",
			createTime: "2022-06-30 10:50:28",
			updateBy: "admin",
			updateTime: "2022-07-01 10:02:40",
			sysOrgCode: "A01",
			hasChild: "1"
		},
		{
			id: "1542690117296631809",
			pid: "1542339757488066561",
			name: "北京市",
			code: "B08A24A01",
			createBy: "admin",
			createTime: "2022-07-01 10:02:40",
			updateBy: "admin",
			updateTime: "2022-07-01 10:02:40",
			sysOrgCode: "A01",
			hasChild: null
		}
	],
	tree: [
		{
			text: "上海市",
			value: "1540218087976996866",
			children: [
				{ text: "黄浦区", value: "1540237942050525186" },
				{ text: "徐汇区", value: "1540237987311259650" },
				{ text: "长宁区", value: "1540238019942944770" },
				{ text: "静安区", value: "1540238052306194433" },
				{ text: "普陀区", value: "1540238085432807425" },
				{ text: "虹口区", value: "1540238119087902721" },
				{ text: "杨浦区", value: "1540238151765725186" },
				{ text: "闵行区", value: "1540238183831179265" },
				{ text: "宝山区", value: "1540238220942381058" },
				{ text: "嘉定区", value: "1540238259106353154" },
				{ text: "浦东新区", value: "1540238289397616642" },
				{ text: "金山区", value: "1540238318497697794" },
				{ text: "松江区", value: "1540238345265745921" },
				{ text: "青浦区", value: "1540238373170450434" },
				{ text: "奉贤区", value: "1540238401473613825" },
				{ text: "崇明区", value: "1540238439440453634" }
			]
		},
		{
			text: "浙江省",
			value: "1540218415136903170",
			children: [
				{ text: "杭州市", value: "1540245291624566785" },
				{ text: "宁波市", value: "1540245326126911490" },
				{ text: "温州市", value: "1540245359714897921" },
				{ text: "嘉兴市", value: "1540245392505966594" },
				{ text: "湖州市", value: "1540245425561276418" },
				{ text: "绍兴市", value: "1540245460097175554" },
				{ text: "金华市", value: "1540245496533094401" },
				{ text: "衢州市", value: "1540245535959552001" },
				{ text: "舟山市", value: "1540245580779884546" },
				{ text: "台州市", value: "1540245617979166721" },
				{ text: "丽水市", value: "1540245655555936257" }
			]
		},
		{
			text: "安徽省",
			value: "1540218377044234241",
			children: [
				{ text: "合肥市", value: "1540244628769345537" },
				{ text: "芜湖市", value: "1540244664735502337" },
				{ text: "蚌埠市", value: "1540244699887964161" },
				{ text: "淮南市", value: "1540244739188592642" },
				{ text: "马鞍山市", value: "1540244774374608898" },
				{ text: "淮北市", value: "1540244818809065474" },
				{ text: "铜陵市", value: "1540244857996447746" },
				{ text: "安庆市", value: "1540244897628426241" },
				{ text: "黄山市", value: "1540244938720022530" },
				{ text: "滁州市", value: "1540244993636044801" },
				{ text: "阜阳市", value: "1540245026691354625" },
				{ text: "宿州市", value: "1540245060480667649" },
				{ text: "六安市", value: "1540245093565337601" },
				{ text: "亳州市", value: "1540245124829679618" },
				{ text: "池州市", value: "1540245159818563586" },
				{ text: "宣城市", value: "1540245201824518146" }
			]
		},
		{
			text: "江苏省",
			value: "1539446085286699009",
			children: [
				{ text: "南京市", value: "1539446124302114818" },
				{ text: "无锡市", value: "1540238549343801345" },
				{ text: "徐州市", value: "1540238591756603394" },
				{ text: "常州市", value: "1540238624195350529" },
				{ text: "苏州市", value: "1540238652217495554" },
				{ text: "南通市", value: "1540238678125711362" },
				{ text: "连云港市", value: "1540238702234570754" },
				{ text: "淮安市", value: "1540238728541245441" },
				{ text: "盐城市", value: "1540238754147471362" },
				{ text: "扬州市", value: "1540238780714192897" },
				{ text: "镇江市", value: "1540238811768819714" },
				{ text: "泰州市", value: "1540238839916793857" },
				{ text: "宿迁市", value: "1540238872242294786" }
			]
		},
		{
			text: "四川省",
			value: "1539439845236088833",
			children: [
				{ text: "成都市", value: "1539439897132212225" },
				{ text: "自贡市", value: "1540892811539255298" },
				{ text: "攀枝花市", value: "1540892861610856449" },
				{ text: "泸州市", value: "1540892896817844225" },
				{ text: "德阳市", value: "1540892927125884930" },
				{ text: "绵阳市", value: "1540893001688027138" },
				{ text: "广元市", value: "1540893034697199617" },
				{ text: "遂宁市", value: "1540893069463785473" },
				{ text: "内江市", value: "1540893100874928129" },
				{ text: "乐山市", value: "1540893131292020738" },
				{ text: "南充市", value: "1540893161251934209" },
				{ text: "眉山市", value: "1540893189144055809" },
				{ text: "宜宾市", value: "1540893220630695937" },
				{ text: "广安市", value: "1540893251282669569" },
				{ text: "达州市", value: "1540893280345001986" },
				{ text: "雅安市", value: "1540893316118220801" },
				{ text: "巴中市", value: "1540893354038923265" },
				{ text: "资阳市", value: "1540893389694701570" },
				{ text: "阿坝藏族羌族自治州", value: "1540893425048489985" },
				{ text: "甘孜藏族自治州", value: "1540893468019134466" },
				{ text: "凉山彝族自治州", value: "1540893497056301058" }
			]
		},
		{
			text: "福建省",
			value: "1540218200698916866",
			children: [
				{ text: "福州市", value: "1540238955826384898" },
				{ text: "厦门市", value: "1540238988692951042" },
				{ text: "莆田市", value: "1540239020640964610" },
				{ text: "三明市", value: "1540239054719684609" },
				{ text: "泉州市", value: "1540239083270311937" },
				{ text: "漳州市", value: "1540239111690915841" },
				{ text: "南平市", value: "1540239137825624066" },
				{ text: "龙岩市", value: "1540239164081967105" },
				{ text: "宁德市", value: "1540239191810510850" }
			]
		},
		{
			text: "山东省",
			value: "1540218296815587329",
			children: [
				{ text: "济南市", value: "1540241775526940673" },
				{ text: "青岛市", value: "1540241805931450369" },
				{ text: "淄博市", value: "1540241832724664322" },
				{ text: "枣庄市", value: "1540241863217254401" },
				{ text: "东营市", value: "1540241891545583618" },
				{ text: "烟台市", value: "1540241919257350145" },
				{ text: "潍坊市", value: "1540241948508426242" },
				{ text: "济宁市", value: "1540241993521696769" },
				{ text: "泰安市", value: "1540242021720002561" },
				{ text: "威海市", value: "1540242046638362626" },
				{ text: "日照市", value: "1540242077298724865" },
				{ text: "临沂市", value: "1540242105190846465" },
				{ text: "德州市", value: "1540242133946994689" },
				{ text: "聊城市", value: "1540242173281177602" },
				{ text: "滨州市", value: "1540242200246358017" },
				{ text: "菏泽市", value: "1540242227031183361" }
			]
		},
		{
			text: "湖南省",
			value: "1540218867702304769",
			children: [
				{ text: "长沙市", value: "1540252014095634434" },
				{ text: "株洲市", value: "1540252053001998337" },
				{ text: "湘潭市", value: "1540252179611258882" },
				{ text: "衡阳市", value: "1540252214197489665" },
				{ text: "邵阳市", value: "1540252250830540801" },
				{ text: "岳阳市", value: "1540252286188523522" },
				{ text: "常德市", value: "1540252322527973377" },
				{ text: "张家界市", value: "1540252360633225217" },
				{ text: "益阳市", value: "1540252398281297921" },
				{ text: "郴州市", value: "1540252439230287874" },
				{ text: "永州市", value: "1540252477130018818" },
				{ text: "怀化市", value: "1540252525150605314" },
				{ text: "娄底市", value: "1540252557564186625" },
				{ text: "湘西土家族苗族自治州", value: "1540252591865204738" }
			]
		},
		{
			text: "广东省",
			value: "1579641088916852738",
			children: [
				{ text: "云浮市", value: "1623539855841607682" },
				{ text: "揭阳市", value: "1623539824602431490" },
				{ text: "潮州市", value: "1623539792943824897" },
				{ text: "清远市", value: "1623539761243275265" },
				{ text: "肇庆市", value: "1623539720357199873" },
				{ text: "茂名市", value: "1623539683552182273" },
				{ text: "湛江市", value: "1623539648827539458" },
				{ text: "阳江市", value: "1623539614501355521" },
				{ text: "江门市", value: "1623539586105917441" },
				{ text: "中山市", value: "1623539552719257602" },
				{ text: "东莞市", value: "1623539522843230209" },
				{ text: "汕尾市", value: "1623539488886145026" },
				{ text: "惠州市", value: "1623539452768993282" },
				{ text: "梅州市", value: "1623539413506113538" },
				{ text: "河源市", value: "1623539383521034241" },
				{ text: "韶关市", value: "1623539349526200321" },
				{ text: "佛山市", value: "1623539317997617153" },
				{ text: "汕头市", value: "1623539284493516802" },
				{ text: "珠海市", value: "1623539225085394945" },
				{ text: "广州市", value: "1613706919998455809" },
				{ text: "深圳市", value: "1579641138422222849" },
			]
		},
		{
			text: "重庆市",
			value: "1540218955807854593",
			children: [
				{ text: "重庆市", value: "1542338238151774210" },
				{ text: "万州区", value: "1540224785655533569" },
				{ text: "涪陵区", value: "1540227222214807554" },
				{ text: "渝中区", value: "1540227267131609090" },
				{ text: "大渡口区", value: "1540227303508807681" },
				{ text: "江北区", value: "1540227336656392193" },
				{ text: "沙坪坝区", value: "1540227865516183554" },
				{ text: "九龙坡区", value: "1540227893538328578" },
				{ text: "南岸区", value: "1540227923884118017" },
				{ text: "北碚区", value: "1540227960559112193" },
				{ text: "綦江区", value: "1540231067590463490" },
				{ text: "大足区", value: "1540231101815984130" },
				{ text: "渝北区", value: "1540231142345543681" },
				{ text: "巴南区", value: "1540231171969912833" },
				{ text: "黔江区", value: "1540231199459381249" },
				{ text: "长寿区", value: "1540231232195923969" },
				{ text: "江津区", value: "1540231264861163522" },
				{ text: "合川区", value: "1540231297262161921" },
				{ text: "永川区", value: "1540231325535965185" },
				{ text: "南川区", value: "1540231360952668162" },
				{ text: "璧山区", value: "1540231390660923394" },
				{ text: "铜梁区", value: "1540231422470524929" },
				{ text: "潼南区", value: "1540231484273594369" },
				{ text: "荣昌区", value: "1540231524299837442" },
				{ text: "开州区", value: "1540231557397090305" },
				{ text: "梁平区", value: "1540231596227956737" },
				{ text: "武隆区", value: "1540231628335353857" }
			]
		},
		{
			text: "天津市",
			value: "1540219005904621570",
			children: [
				{ text: "天津市", value: "1542338182807932930" },
				{ text: "和平区", value: "1540219066159992834" },
				{ text: "河东区", value: "1540219104663703554" },
				{ text: "河西区", value: "1540219146023735297" },
				{ text: "南开区", value: "1540219432175931394" },
				{ text: "河北区", value: "1540219467940761602" },
				{ text: "红桥区", value: "1540219506633216002" },
				{ text: "东丽区", value: "1540219550157508609" },
				{ text: "西青区", value: "1540219589600743426" },
				{ text: "津南区", value: "1540219622928683009" },
				{ text: "北辰区", value: "1540219666092265474" },
				{ text: "武清区", value: "1540219697264332801" },
				{ text: "宝坻区", value: "1540219726251167745" },
				{ text: "滨海新区", value: "1540219759675576322" },
				{ text: "宁河区", value: "1540219789706792961" },
				{ text: "静海区", value: "1540219821986156546" },
				{ text: "蓟州区", value: "1540219854244548609" }
			]
		},
		{ text: "北京市", value: "1542339757488066561", children: [{ text: "北京市", value: "1542690117296631809" }] },
		// { text: "云南省", value: "1539446252358410242", children: [{ text: "昆明市", value: "1539446304212590593" }] },
		{
			text: "内蒙古自治区",
			value: "1540218252859281409",
			children: [
				{ text: "呼和浩特市", value: "1540241314853949442" },
				{ text: "包头市", value: "1540241358604734466" },
				{ text: "乌海市", value: "1540241396500271105" },
				{ text: "赤峰市", value: "1540241428230180865" },
				{ text: "通辽市", value: "1540241459934924801" },
				{ text: "鄂尔多斯市", value: "1540241488347140098" },
				{ text: "呼伦贝尔市", value: "1540241516012769281" },
				{ text: "巴彦淖尔市", value: "1540241552473853954" },
				{ text: "乌兰察布市", value: "1540241590491025410" },
				{ text: "兴安盟", value: "1540241626494930946" },
				{ text: "锡林郭勒盟", value: "1540241654286389250" },
				{ text: "阿拉善盟", value: "1540241687991816194" }
			]
		},
		{
			text: "新疆维吾尔自治区",
			value: "1540218338540523522",
			children: [
				{ text: "乌鲁木齐市", value: "1540243270121033729" },
				{ text: "克拉玛依市", value: "1540243325477457922" },
				{ text: "吐鲁番市", value: "1540243370008383490" },
				{ text: "哈密市", value: "1540243417500487682" },
				{ text: "昌吉回族自治州", value: "1540243470835257345" },
				{ text: "博尔塔拉蒙古自治州", value: "1540243538145447938" },
				{ text: "巴音郭楞蒙古自治州", value: "1540243580998651905" },
				{ text: "阿克苏地区", value: "1540243619447836674" },
				{ text: "克孜勒苏柯尔克孜自治州", value: "1540243666105274369" },
				{ text: "喀什地区", value: "1540243722808070145" },
				{ text: "和田地区", value: "1540243775001989121" },
				{ text: "伊犁哈萨克自治州", value: "1540243831809642498" },
				{ text: "塔城地区", value: "1540243888365637634" },
				{ text: "阿勒泰地区", value: "1540243940760883201" },
				{ text: "石河子市", value: "1540243992355016705" },
				{ text: "阿拉尔市", value: "1540244033316589570" },
				{ text: "图木舒克市", value: "1540244198756716545" },
				{ text: "五家渠市", value: "1540244248614408193" },
				{ text: "北屯市", value: "1540244287743070210" },
				{ text: "铁门关市", value: "1540244322954252289" },
				{ text: "双河市", value: "1540244373533364226" },
				{ text: "可克达拉市", value: "1540244414239084545" },
				{ text: "昆玉市", value: "1540244462666518529" },
				{ text: "胡杨河市", value: "1540244502084587521" },
				{ text: "新星市", value: "1540244543633362946" }
			]
		},
		{
			text: "甘肃省",
			value: "1540218453837746177",
			children: [
				{ text: "兰州市", value: "1540246083454636034" },
				{ text: "嘉峪关市", value: "1540246125762580482" },
				{ text: "金昌市", value: "1540246168414457858" },
				{ text: "白银市", value: "1540246213889101825" },
				{ text: "天水市", value: "1540246250769616898" },
				{ text: "武威市", value: "1540246298177835009" },
				{ text: "张掖市", value: "1540246360022847490" },
				{ text: "平凉市", value: "1540246397045968897" },
				{ text: "酒泉市", value: "1540246437298704386" },
				{ text: "庆阳市", value: "1540246474187608065" },
				{ text: "定西市", value: "1540246510673858562" },
				{ text: "陇南市", value: "1540246546153476097" },
				{ text: "临夏回族自治州", value: "1540246583600222209" },
				{ text: "甘南藏族自治州", value: "1540246620375879682" }
			]
		},
		{
			text: "辽宁省",
			value: "1540218496086970370",
			children: [
				{ text: "沈阳市", value: "1540246721743818754" },
				{ text: "大连市", value: "1540246752941051905" },
				{ text: "鞍山市", value: "1540246788986900482" },
				{ text: "抚顺市", value: "1540246823355027457" },
				{ text: "本溪市", value: "1540246855667945474" },
				{ text: "丹东市", value: "1540246892011589633" },
				{ text: "锦州市", value: "1540246928590114818" },
				{ text: "营口市", value: "1540246962517839873" },
				{ text: "阜新市", value: "1540247004343439361" },
				{ text: "辽阳市", value: "1540247038371827713" },
				{ text: "盘锦市", value: "1540247075772436481" },
				{ text: "铁岭市", value: "1540247113047216129" },
				{ text: "朝阳市", value: "1540247143917293570" },
				{ text: "葫芦岛市", value: "1540247179401105410" }
			]
		},
		{
			text: "黑龙江省",
			value: "1540218553993531394",
			children: [
				{ text: "哈尔滨市", value: "1540247268676866050" },
				{ text: "齐齐哈尔市", value: "1540247300629073921" },
				{ text: "鸡西市", value: "1540247331780169729" },
				{ text: "鹤岗市", value: "1540247361354207233" },
				{ text: "双鸭山市", value: "1540247394224967681" },
				{ text: "大庆市", value: "1540247432271499265" },
				{ text: "伊春市", value: "1540247468082466817" },
				{ text: "佳木斯市", value: "1540247503004241922" },
				{ text: "七台河市", value: "1540247541038190593" },
				{ text: "牡丹江市", value: "1540247580724695042" },
				{ text: "黑河市", value: "1540247623208800257" },
				{ text: "绥化市", value: "1540247664627552257" },
				{ text: "大兴安岭地区", value: "1540247708642578434" }
			]
		},
		{
			text: "河南省",
			value: "1540218593763921922",
			children: [
				{ text: "郑州市", value: "1540247799277293570" },
				{ text: "开封市", value: "1540247842285686786" },
				{ text: "洛阳市", value: "1540247883612164098" },
				{ text: "平顶山市", value: "1540247922409476098" },
				{ text: "安阳市", value: "1540247971633827841" },
				{ text: "鹤壁市", value: "1540248008384319489" },
				{ text: "新乡市", value: "1540248046435045377" },
				{ text: "焦作市", value: "1540248096028495873" },
				{ text: "濮阳市", value: "1540248133580099585" },
				{ text: "许昌市", value: "1540248171429498882" },
				{ text: "漯河市", value: "1540248216270802945" },
				{ text: "三门峡市", value: "1540248252471840770" },
				{ text: "南阳市", value: "1540248292414197761" },
				{ text: "商丘市", value: "1540248332868259841" },
				{ text: "信阳市", value: "1540248379659915265" },
				{ text: "周口市", value: "1540248415500242946" },
				{ text: "驻马店市", value: "1540248450690453506" },
				{ text: "济源市", value: "1540248489856864257" }
			]
		},
		{
			text: "江西省",
			value: "1540218634159263745",
			children: [
				{ text: "南昌市", value: "1540248589660327938" },
				{ text: "景德镇市", value: "1540248625714565122" },
				{ text: "萍乡市", value: "1540248662146289665" },
				{ text: "九江市", value: "1540248698821283841" },
				{ text: "新余市", value: "1540248735341088769" },
				{ text: "鹰潭市", value: "1540248770032177153" },
				{ text: "赣州市", value: "1540248810461073410" },
				{ text: "吉安市", value: "1540248852810960897" },
				{ text: "宜春市", value: "1540248891562135554" },
				{ text: "抚州市", value: "1540248931114422273" },
				{ text: "上饶市", value: "1540248967890079745" }
			]
		},
		{
			text: "广西壮族自治区",
			value: "1540218673325674497",
			children: [
				{ text: "南宁市", value: "1540249051675496450" },
				{ text: "柳州市", value: "1540249087993974785" },
				{ text: "桂林市", value: "1540249122957692929" },
				{ text: "梧州市", value: "1540249168000323586" },
				{ text: "北海市", value: "1540249205312851969" },
				{ text: "防城港市", value: "1540249247398498306" },
				{ text: "钦州市", value: "1540249280692883457" },
				{ text: "贵港市", value: "1540249313437814785" },
				{ text: "玉林市", value: "1540249540139945985" },
				{ text: "百色市", value: "1540249600378540033" },
				{ text: "贺州市", value: "1540249635308703746" },
				{ text: "河池市", value: "1540249677121720321" },
				{ text: "来宾市", value: "1540249713373089794" },
				{ text: "崇左市", value: "1540249748122898433" }
			]
		},
		{
			text: "河北省",
			value: "1540218713704239105",
			children: [
				{ text: "石家庄市", value: "1540250015698522113" },
				{ text: "唐山市", value: "1540250051253637121" },
				{ text: "秦皇岛市", value: "1540250089409220609" },
				{ text: "邯郸市", value: "1540250143054368769" },
				{ text: "邢台市", value: "1540250181461610498" },
				{ text: "保定市", value: "1540250231294136322" },
				{ text: "张家口市", value: "1540250308158951425" },
				{ text: "承德市", value: "1540250365335703553" },
				{ text: "沧州市", value: "1540250402937638913" },
				{ text: "廊坊市", value: "1540250440828981249" },
				{ text: "衡水市", value: "1540250478640631810" }
			]
		},
		{
			text: "山西省",
			value: "1592326566728814593",
			children: [
				{ text: "运城市", value: "1623536874723262466" },
				{ text: "临汾市", value: "1623536845132447746" },
				{ text: "晋城市", value: "1623536806272221186" },
				{ text: "长治市", value: "1623536771702767618" },
				{ text: "晋中市", value: "1623536739993829378" },
				{ text: "吕梁市", value: "1623536689758650370" },
				{ text: "阳泉市", value: "1623536657961631745" },
				{ text: "忻州市", value: "1623536616719040514" },
				{ text: "朔州市", value: "1623536576982204418" },
				{ text: "大同市", value: "1623536510989025281" },
				{ text: "太原市", value: "1623536414171906049" },
			]
		},
		{
			text: "云南省",
			value: "1539446252358410242",
			children: [
				{ text: "西双版纳傣族自治州", value: "1623541043194544130" },
				{ text: "文山壮族苗族自治州", value: "1623541012962000897" },
				{ text: "红河哈尼族彝族自治州", value: "1623540984973410306" },
				{ text: "楚雄彝族自治州", value: "1623540957249060866" },
				{ text: "大理白族自治州", value: "1623540928480329729" },
				{ text: "迪庆藏族自治州", value: "1623540903419363330" },
				{ text: "怒江傈僳族自治州", value: "1623540876370296833" },
				{ text: "德宏傣族景颇族自治州", value: "1623540848591421442" },
				{ text: "临沧市", value: "1623540802726707202" },
				{ text: "普洱市", value: "1623540776495529986" },
				{ text: "丽江市", value: "1623540746959241217" },
				{ text: "保山市", value: "1623540720757424129" },
				{ text: "昭通市", value: "1623540693083406337" },
				{ text: "玉溪市", value: "1623540658971131906" },
				{ text: "曲靖市", value: "1623540614792527873" },
				{ text: "昆明市", value: "1539446304212590593" },
			]
		},
		{
			text: "吉林省",
			value: "1540218751427809281",
			children: [
				{ text: "长春市", value: "1540250615148449794" },
				{ text: "吉林市", value: "1540250654017064961" },
				{ text: "四平市", value: "1540250688888508418" },
				{ text: "辽源市", value: "1540250732429578242" },
				{ text: "通化市", value: "1540250768756445185" },
				{ text: "白山市", value: "1540250809952899074" },
				{ text: "松原市", value: "1540250847085072385" },
				{ text: "白城市", value: "1540250891607609346" },
				{ text: "延边朝鲜族自治州", value: "1540250934834106369" }
			]
		},
		{
			text: "湖北省",
			value: "1540218789260431362",
			children: [
				{ text: "武汉市", value: "1540251153609003010" },
				{ text: "黄石市", value: "1540251184919482370" },
				{ text: "十堰市", value: "1540251217555361793" },
				{ text: "宜昌市", value: "1540251248748400641" },
				{ text: "襄阳市", value: "1540251286945927170" },
				{ text: "鄂州市", value: "1540251326485630977" },
				{ text: "荆门市", value: "1540251365119365122" },
				{ text: "孝感市", value: "1540251397830742018" },
				{ text: "荆州市", value: "1540251449819140097" },
				{ text: "黄冈市", value: "1540251483667173378" },
				{ text: "咸宁市", value: "1540251516785397762" },
				{ text: "随州市", value: "1540251551879139329" },
				{ text: "恩施土家族苗族自治州", value: "1540251597861294082" },
				{ text: "仙桃市", value: "1540251638827061249" },
				{ text: "潜江市", value: "1540251677121056769" },
				{ text: "天门市", value: "1540251718355259394" },
				{ text: "神农架林区", value: "1540251751716753410" }
			]
		},
		{
			text: "宁夏回族自治区",
			value: "1540218827801890817",
			children: [
				{ text: "银川市", value: "1540251820293623810" },
				{ text: "石嘴山市", value: "1540251850752659458" },
				{ text: "吴忠市", value: "1540251881383661570" },
				{ text: "固原市", value: "1540251912845135874" },
				{ text: "中卫市", value: "1540251944084312065" }
			]
		}
	]
};

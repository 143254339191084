<template>
	<van-uploader  :fileList="fileList"  :after-read="afterRead" multiple :max-count="maxCount" v-bind="$attrs" v-on="$listeners" />
</template>

  <script>
  import api from "@/request/api";
import crmMIxins from "@/utils/crm-mixins";
import { Notify } from "vant";
export default {
    mixins: [crmMIxins],
	components: {},
	name: "compress-uploader",
	props: {
        childEvent:Array,
		value: Array,
		quality: {
			type: Number,
			default: 0.1,
		},
		compressSwitch: {
			type: Boolean,
			default: false,
		},
        biz:{
type:String,
        },
		threshold: {
			type: Number,
			default: 500,
		},
		maxCount: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		fileList: {
			get() {
                console.log("this.value",this.value);
				return this.value;

			},
			set(n) {
                console.log("this.n",n);
                console.log("this.valuen",this.value);
				this.$emit("input", n);
			},
		},
	},
	methods: {
		afterRead(file, detail) {
            console.log("file, detail",file, detail);
			file.status = "uploading";
			file.message = "上传中.....";
			this.imgPreview(file, detail.index);
			return true;
		},
		// 处理图片
		async imgPreview(myFile, index) {
            let self=this;
			const file = myFile.file;
			if (!file || !window.FileReader) {
				// 看支持不支持FileReader
				return;
			}
			const size = file.size / 1024;
			console.log(`图片大小 ===> ${size}kb`);
			console.log("图片压缩：", this.compressSwitch ? "开" : "关");
			console.log("图片压缩阈值：", this.threshold + "kb");
			console.log("图片压缩降帧值：", this.quality);
            console.log("1",/^image/.test(file.type));
            console.log("2", size >= this.threshold);
            console.log("3", size >= this.compressSwitch);
            console.log("4",/^image/.test(file.type) && size >= this.threshold && this.compressSwitch);
			if (/^image/.test(file.type) && size >= this.threshold && this.compressSwitch) {
                console.log("11111",file);
				const img = new Image();
				img.src = await this.getBase64(file); // 将图片将转成base64格式

				img.onload = () => {
                    console.log("22222",);
					const data = this.compress(img, file.name, file.type);
					console.log(`压缩后 ===> ${data.fileData.size / 1024}kb`);
                    console.log("3333",this.fileList[index]);
                    console.log("5555",data);
					this.fileList[index] = {

						content: data.base64Data,
						file: data.fileData,
					};
					myFile.status = "done";
                    console.log("myFile,",myFile);
                    console.log("666",this.fileList[index]);    console.log("this.fileList[index]",this.fileList[index]);
            self.runUpload(this.fileList[index],this.biz);
				};

			} else {
				myFile.status = "done";
                console.log("111",file);
                console.log("myFile,myFile",myFile);
            this.runUpload(myFile,this.biz);
			}

		},
        runUpload(fd,biz) {
            console.log("a",fd);
            let self =this;
			this.showLoading("上传中...");
			// fd.status = "uploading";
			// fd.message = "上传中....";
			console.log("f11d",fd);
			// this.showLoading("上传中...");
			api.upload(fd,biz).then((res) => {
                console.log("222",fd);
				this.hideLoading();
                this.$toast.clear();
				console.log(res);
				if (res.success) {
					if (res.message) {
						fd.status = "done";
					fd.message = "上传成功";
                    this.$emit('getShow',res.message);
                    console.log("1112222",fd);
					// this.imgList.push(res.message)
                    ;}else{
						Notify({ type: "danger", message: "图片上传失败！" });
					}

				} else {
					Notify({ type: "danger", message: "图片上传失败！" });
				}
			});
		},
		// 压缩图片
		compress(img, name, type) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			// 瓦片canvas
			const tCanvas = document.createElement("canvas");
			const tctx = tCanvas.getContext("2d");
			let width = img.width;
			let height = img.height;
			// 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
			let ratio;
			if ((ratio = (width * height) / 4000000) > 1) {
				ratio = Math.sqrt(ratio);
				width /= ratio;
				height /= ratio;
			} else {
				ratio = 1;
			}
			canvas.width = width;
			canvas.height = height;
			// 铺底色
			ctx.fillStyle = "#fff";
			ctx.fillRect(0, 0, canvas.width, canvas.height);
			// 如果图片像素大于100万则使用瓦片绘制
			let count;
			if ((count = (width * height) / 1000000) > 1) {
				count = ~~(Math.sqrt(count) + 1); // 计算要分成多少块瓦片
				// 计算每块瓦片的宽和高
				const nw = ~~(width / count);
				const nh = ~~(height / count);
				tCanvas.width = nw;
				tCanvas.height = nh;
				for (let i = 0; i < count; i++) {
					for (let j = 0; j < count; j++) {
						tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
						ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
					}
				}
			} else {
				ctx.drawImage(img, 0, 0, width, height);
			}
			// 进行压缩
			const ndata = canvas.toDataURL("image/jpeg", this.quality);
			tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
			return { base64Data: ndata, fileData: this.dataURLtoFile(ndata, name, type) };
		},
		// 获取图片base64格式
		getBase64(data) {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					resolve(e.target.result);
				};
				fileReader.readAsDataURL(data);
				fileReader.onerror = () => {
					reject(new Error("文件流异常"));
				};
			});
		},
		// 将base64转换为文件
		dataURLtoFile(dataurl, name, type) {
			name = name || "图片";
			type = type || "jpg";
			const arr = dataurl.split(",");
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], name, {
				type: type,
			});
		},
	},
};
</script>
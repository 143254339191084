<template>
	<div class="crm-report">
		<van-nav-bar @click-left="onClickLeft">
			<template #title><span class="bar-title"> {{ reportModel.hzxm }} - 报告详情</span> </template>
			<template #left>
				<van-icon name="arrow-left" size="18" />
			</template>
		</van-nav-bar>
		<div class="crm-report-body">
			<div>
				<van-cell>

					<van-row class="crm-basic crm-basic-report">
						<!-- <van-col class="crm-report-title" :span="24">检查结果</van-col> -->
						<van-col class="crm-report-text" :span="24"><div class="ds-content" v-html="reportModel.wbnr"></div></van-col>
					</van-row>

				</van-cell>
			</div>
		</div>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-report",
	mixins: [crmMixins],
	components: { SexIcon },
	data() {
		return {
			reportModel: {},
		};
	},
	mounted() {
		this.reportModel = this.$route.params.params;
	},
	methods: {
		onClickLeft() {
			this.$router.go(-1);
		},


	},
};
</script>

<style lang="less">
.ds-content{
	white-space: break-spaces;
}
.crm-report-body {
	width: 100vw;
    height: 100vh;
    overflow: auto;
    padding-bottom: 10vh;
	.crm-report-title {
		font-weight: 600;
		font-size: 0.853rem;
		line-height: 1.8rem;
		text-align: left;
		margin-bottom: 0.2rem;
	}
	.crm-report-text {
		font-weight: 400;
		font-size: 0.747rem;
		line-height: 1.04rem;
		color: #333333;
		margin-bottom: 0.24rem;
	}
	.crm-basic {
		margin: 0.533rem;
		// padding: 0.748rem;
	}
	.crm-basic-report {
		.crm-report-text {
			margin-bottom: 0.54rem;
		}
	}
	.crm-report-btn {
		height: 2.56rem;
		background: #5f5aa3;
		border-radius: 0.23rem;
		color: #fff;
		font-size: 0.853rem;
		line-height: 1.067rem;
		white-space: nowrap;
	}
	.crm-report-time{
		margin-bottom: 1rem;
	}
}
.bar-title{
		font-weight: 600;
	}
</style>

<template>
	<div class="normal-form">
		<div class="form-label">
			<span v-if="model.required"
				class="label-required">*</span>
			<span class="label-index"
				v-if="showIndex">
				{{childIndex+1}}.
			</span>
			{{ model.label }}
		</div>
		<div :class="['input-value', , error ? 'error' : '']">
			<van-field v-model="inputValue"
				rows="1"
				@input="changeValue"
				autosize
				label-width="0"
				style="padding:0;"
				type="textarea"
				:placeholder="model.placeholder" />
		</div>
		<div class="error-tip"
			v-if="errorTip && error">{{ errorTip }}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	import normalSelect from "@/components/form/normal-select.vue";
	export default {
		mixins: [validateMIxins],
		components: {
			normalSelect
		},
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			childIndex: Number,
			showIndex: Boolean,
			value: [String, Number, null]
		},
		data () {
			return {
				updateKey: 1,
				residentialModel: {
					name: "residence_address",
					placeholder: "请选择您的居住地",
					rules: [
						{
							required: true,
							type: "required",
							message: "该项为必填项"
						}
					]
				},
				errorTip: "",
				error: false,
				residenceInput: "",
				inputValue: null
			};
		},
		mounted () {
			this.init();
		},
		methods: {
			init () {
				const list = this.value ? this.value.split("_") : [];
				this.residenceInput = list[0];
				if (this.value) {
					this.inputValue = list[1] || list[0] || this.value;
				} else {
					this.inputValue = null;
				}
				this.updateKey++;
			},
			residenceChange (val) {
				this.residenceInput = val;
				this.changeValue();
			},
			changeValue () {
				let updateValue = this.inputValue;
				if (this.model.name === "residential_address" && this.residenceInput) {
					updateValue = `${this.residenceInput}_${this.inputValue}`;
				}
				this.resetValidate();
				this.$emit("input", updateValue, this.model);
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");
	.normal-form {
		.input-value {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0;
			input[type="textarea"] {
				background: none;
				border: none;
				box-shadow: none;
				border-bottom: 1px solid @font11;
				text-align: left;
				display: block;
				width: 100%;
				min-width: 240px;
				font-size: 32px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				line-height: 60px;
				color: @font1;
				caret-color: @font1;
				background-color: @bg6;
				padding: 10px;
				border-radius: 3px;
				&::placeholder {
					.placeholder-font;
					color: @font3;
					font-size: 26px;
					opacity: 0.7;
					text-align: left;
				}
				&:focus {
					border-bottom: 1px solid @font7;
				}
			}
		}
		.item-group {
			margin-bottom: 10px;
		}
	}
</style>

<template>
	<div class="constitution">
		<div class="question-title"
				 v-if="model.title">{{ model.title }}</div>
		<div v-for="(item, index) in model.children"
				 class="question-item"
				 :key="index">
			<component :is="`custom-${item.type}`"
								 :model="item"
								 @input="valueChange"
								 :value="fd[item.name]" />
		</div>
	</div>
</template>

<script>
import { customInput, customNumberSelect, customRadioImg, customRadio, customSelectScore } from "./index";
import { mapGetters } from "vuex";
export default {
	components: {
		customInput,
		customNumberSelect,
		customRadioImg,
		customRadio,
		customSelectScore
	},
	props: {
		model: {
			type: Object,
			default: () => {
				return {
					title: "",
					children: []
				};
			}
		},
		value: [String, null]
	},
	data () {
		return {
			fd: {}
		};
	},
	computed: {
		...mapGetters(["formValueData", "userInfo"])
	},
	mounted () {
		this.fd = { ...this.formValueData, ...this.userInfo };
	},
	methods: {
		valueChange (value, item, option) {
			if (this.constant.USER_INFO.indexOf(item.name) > -1) {
				this.$store.dispatch("set_info", { ...this.userInfo, [item.name]: value });
			} else {
				this.$store.dispatch("set_info_item", { value, key: item.name || item.label });
				if (!!option) {
					this.$store.dispatch("set_score_item", { value: option.score, key: item.name || item.label });
				}
			}
		}
	}
};
</script>

<style scoped lang="less">
.constitution {
	width: 100%;
	padding: 0 2% 10%;
	box-sizing: border-box;

	.constitution-item {
		padding: 0 70px;
		margin: auto;
	}

	.question-item {
		display: block;
	}
}
</style>

<template>
	<div class="normal-form normal-radio">
		<div class="form-label">
			<span v-if="model.required"
				class="label-required">*</span>
			<span v-if="showIndex">
				{{childIndex+1}}
			</span>
			{{ model.label }}
		</div>
		<div class="form-option">
			<div class="left-tip"
				v-if="model.leftText">{{model.leftText}}</div>
			<van-rate v-model="inputValue"
				:size="25"
				@change="changeValue"
				:color="model.color||'#5f5aa3'"
				:count="model.count||1"
				void-icon="star"
				:void-color="model.voidColor||'#eee'" />
			<div class="right-tip"
				v-if="model.rightText">{{model.rightText}}</div>
		</div>
		<div class="error-tip"
			v-if="errorTip&&error">{{errorTip}}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: "",
						option: []
					};
				}
			},
			childIndex: Number,
			showIndex: Boolean,
			value: [String, Number, null]
		},
		data () {
			return {
				error: false,
				errorTip: "",
				inputValue: 0
			};
		},
		created () {
			this.init();
		},
		methods: {
			init () {
				if (this.value) {
					this.inputValue = +this.value;
				} else {
					this.inputValue = null;
				}
			},
			changeValue (item) {
				this.resetValidate();
				this.inputValue = item ? item : '';
				this.$emit("input", this.inputValue);
				this.$emit("change", this.inputValue);
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");
	.label-index {
		display: inline-block;
		background-color: @bg5;
		border-radius: 10px;
		padding: 2px 5px;
		vertical-align: middle;
		color: @font9;
		font-size: 26px;
		letter-spacing: 2px;
	}
	.form-option {
		display: flex;
		justify-content: space-around;

		.option-item {
			position: relative;
			opacity: 0.6;
			width: 200px;
			text-align: center;
			height: 60px;
			line-height: 60px;
			background-color: @font12;
			border-radius: 60px;
			font-size: 32px;
			&.active {
				opacity: 1;
				color: @font9;
			}
			.option-item-img {
				width: 230px;
			}
			.active-item {
				position: absolute;
				width: 48px;
				right: 19px;
			}
		}
	}
	.custom-radio {
		padding-top: 50px;
	}
	.left-tip,
	.right-tip {
		font-size: 28px;
	}
</style>

<template>
	<div class="crm-patient crm-bg">
		<div class="crm-patient-search">
			<div class="crm-search-input">
                <van-search v-model="queryParams.name"
									shape="round"
									background="transparent"
									placeholder="请输入联系人姓名"
									@keydown.enter="search" />
			</div>
		</div>
		<div class="crm-patient-body">
			<div class="crm-patient-body-scroll">
				<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->
						<div class="crm-message-card" @click="jumpPage('/crm/contact-detail/' + item.id)" v-for="(item, index) in list" :key="index">
							<div class="crm-message-card-top">
								<div class="crm-message-content">
									<p>
										<strong class="username">{{ item.name }}</strong
										><span class="date d-fr">{{ item.createTime | formatDate("YYYY-MM-DD") }}</span>
									</p>
									<p>
										<van-row>
											<van-col :span="12">电话：{{ item.cellphone }}</van-col>
											<van-col :span="12">关系：{{ item.relation | convertDict(patient_relation) }}</van-col>
											<van-col :span="12">默认联系人：{{ item.isDefault | convertDict(yn) }}</van-col>
											<van-col class="text-ellipsis" :span="12">备注：{{ item.remark }}</van-col>
										</van-row>
									</p>
								</div>
							</div>
							<div class="crm-message-card-bottom">
								<div class="crm-message-msg"></div>
								<div></div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
			<div class="fixed-footer">
				<van-button class="crm-btn mb-80" :loading="loading" block @click="jumpPage(`/crm/create-contact/${$route.params.id}`)">添加联系人 </van-button>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import TYPES from "@/constant/types";
import crmMixins from "@/utils/crm-mixins";
import CRMSelect from "@/components/CRM/Select";
import CRMSelectDate from "@/components/CRM/SelectDate";

export default {
	name: "crm-history-list",
	mixins: [crmMixins],
	components: { CRMSelect, CRMSelectDate },
	data() {
		return {
			loading: false,
			url: {
				list: "/crm-service/crm/crmPatientContact/list",
			},
		};
	},
	mounted() {
		this.queryParams.patientId = this.$route.params.id;
	},
	methods: {
		search() {
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-search-group {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 60px;
	margin-bottom: 24px;
	padding: 0 20px;
	.crm-search-group-item {
		flex: 1;
		border-right: 2px solid rgba(176, 176, 176, 0.6);
		&:last-child {
			border-right: none;
		}
		input {
			display: block;
			width: 100%;
			font-size: 28px;
		}
	}
}
.crm-search-input {
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 60px;
	.van-cell {
		padding: 16px 40px;
		.icon-down {
			background-image: url("~@/assets/crm/icon/icon-search.png");
		}
	}
}
.fixed-footer {
	padding: 0 60px;
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
.relation {
	font-weight: normal;
}
.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}
</style>

<template>
	<div class="crm-bg crm-apply">
		<van-form ref="formRef1">
			<div class="crm-card mb-80" >
				<div class="crm-card-title">
					<div class="crm-card-title__flex">个人信息</div>
				</div>
				<div class="crm-card-body">
					<van-form validate-first @failed="onFailed">
						<van-field
						v-model="personalFrom.username"
						required
						:rules="[{ required: true, message: '请填写用户账号' }]"
						name="username"
						label="用户账号"
						readonly
						placeholder="请填写用户账号"
					/>
                    <van-field
						v-model="personalFrom.password"
						required
						:rules="[{ required: true,pattern, message: '密码由8位数字、大小写字母和特殊符号组成!' }]"
						name="password"
						label="新密码"
						placeholder="请输入密码!"
					/>
                    <van-field
						v-model="personalFrom.confirmpassword"
						required
						:rules="[{ required: true,validator, message: '两次输入的密码不一样！' }]"
						name="confirmpassword"
						label="确认密码"
						@focus="onChange"
						:readonly="edit==false"
						placeholder="请再次输入新密码!"
					/>
      </van-form>
					
				</div>
			</div>
		</van-form>
		<van-button  class="crm-btn mb-80" :loading="loading" block @click="submitPersonal">
			<span>提交</span>
		</van-button>

	</div>
</template>

<script>
import api from "@/request/api";
import crmMIxins from "@/utils/crm-mixins";
import ImageCompressor from "@/utils/imageCompressor";
import CRMSelect from "@/components/CRM/Select";
import CRMRadio from "@/components/CRM/Radio";
import CRMDate from "@/components/CRM/SelectDate";
import CRMCheckbox from "@/components/CRM/Checkbox";
import ImgField from "@/components/CRM/ImgField";
import CRMTextarea from "@/components/CRM/Textarea";
import RULES from "@/constant/rules";
import { mapState } from "vuex";
import { Dialog, Notify } from "vant";

const gen = () => {
	return {
		zzHospital: "",
		zzHospitalOther: "",
		zzDepartment: "",
		zzDepartmentOther: "",
		consultationDoctor: "",
		zzInType: "",
		zzPlanInArea: "",
		zzBedReq: "",
	};
};


export default {
	name: "crm-personal-create",
	mixins: [crmMIxins],
	components: { CRMSelect, ImgField,CRMRadio, CRMDate, CRMCheckbox, CRMTextarea },
	data() {
		return {
			RULES,
			loading: false,
			edit: true,
			personalFrom: {
				username: "",
				password: "",
				confirmpassword: "",
			},
			pattern:/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
		};
	},
    computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),
        // const userId = this.info.id;
	
	},
	mounted() {
	this.personalFrom.username=this.info.username;
		this.getInformation();
		
	},
	methods: {
		onChange(){
this.edit=/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/.test(this.personalFrom.password);
		},
	 //   此处使用函数校验
	 validator(val) {
		// 
		return this.personalFrom.password===this.personalFrom.confirmpassword;
    //   return /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/.test(val);
    },
     onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

		getInformation() {
			let vm = this;
			this.showLoading();
			api.crmGetUserInfo()
				.then((res) => {
					this.hideLoading();
					if (res.success) {
                        vm.personalFrom=res.result.userInfo;
					} else {
						Notify({ type: "danger", message: res.message });
					}
				})
				.catch(() => {
					this.hideLoading();
				});
		},
		editPersonal(){	
			Dialog.confirm({ title: "提示", message: "修改基础信息时，只允许修改头像！", confirmButtonText: "知晓" }).then(res => {
				this.editType=true;});
		},
		submitPersonal() {
			this.$refs.formRef1.validate().then((res) => {
				if(this.personalFrom.confirmpassword&&this.personalFrom.password&&this.personalFrom.password===this.personalFrom.confirmpassword){
					console.log("测试验证");
						api.changePassword(this.personalFrom).then((res) => {
				if (res.success) {
					Notify({ type: "success", message: res.message });
					this.$router.back(-2);
				}
			});
				}else{

				}
				
			
			});
		},
		
	},
};
</script>

<style lang="less">
.crm-apply {
	padding: 32px;
	.van-checkbox__label {
		font-size: 28px;
	}
}
.crm-upload {
	display: block;
	text-align: left;
	background: #f2f2f2;
	border-radius: 15px;
	padding: 20px 25px 0px 25px;
	margin-bottom: 20px;
	.crm-upload-title {
		color: #333;
		font-size: 32px;
	}
	.van-uploader {
		padding-left: 100px;
	}
	.van-uploader__preview-image,
	.van-uploader__upload {
		background: #fff;
	}
	.van-uploader__preview {
		background: #f2f2f2;
		border-radius: 12px;
	}
	.van-uploader__preview-delete {
		border-radius: 16px;
		top: -6px;
		right: -6px;
		width: 32px;
		height: 32px;
		.van-uploader__preview-delete-icon {
			top: 0;
			right: 0;
		}
	}
}
.user-know-box {
	padding: 20px 20px 20px 30px;
	p {
		padding: 10px;
	}
}
.jh-cell-group {
	padding: 10px 20px;
	.van-cell__title {
		text-align: left;
		font-weight: bold;
	}
}
</style>

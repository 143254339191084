<template>
	<div class="crm-login">

		<div class="crm-login-box">
			<div class="crm-field">
				<span class="crm-field-icon icon-phone"></span>
				<input type="tel" v-model="params.mobile" placeholder="请输入手机号" />
			</div>
			<div class="crm-field">
				<span class="crm-field-icon icon-code"></span>
				<input type="tel" v-model="params.captcha" :maxlength="6" placeholder="请输入手机验证码" />
				<a href="javascript:;" class="crm-get-code" @click="getCaptcha">
					<span v-if="time <= 0">获取验证码</span>
					<span v-else>{{ time }}s 后重发</span>
				</a>
			</div>

			<van-button class="crm-field-submit" :loading="loading" loading-text="登录中..." @click="submit">登录</van-button>

			<router-link :to="{path: $route.query.redirectUri? `/crm/plogin?redirectUri=${$route.query.redirectUri}&responseType=${$route.query.responseType}`: '/crm/plogin'}" >	<span class="crm-login-passward"  >密码登录</span> </router-link>
		</div>

	</div>
</template>

<script>
import { Notify } from "vant";
import api from "@/request/api";
import crmMixins from "@/utils/crm-mixins";
export default {
	name: "crm-login-page",
	mixins: [crmMixins],
	data() {
		return {
			loading: false,
			time: 0,
			timer: null,
			params: {
				mobile: "",
				captcha: ""
			}
		};
	},
  created() {
	  console.log(this.$route.fullPath, this.$route.query)
  },
  methods: {
		getCaptcha() {
			let smsParams = {
				mobile: this.params.mobile,
				smsmode: 2
			};
			if (this.timer) return false;
			if (smsParams.mobile.length == 0) return false;
			if (/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(smsParams.mobile)) {
				this.showLoading();
				api.crmSysSms(smsParams).then(res => {
					if (res.success) {
						this.showLoading("发送成功", 3);
						this.time = 59;
						this.setTime();
					} else {
						this.hideLoading();
						Notify({ type: "danger", message: res.message });
						this.clearTime();
					}
				});
			} else {
				this.hideLoading();
				Notify({ type: "danger", message: "手机号格式错误!" });
			}
		},
		setTime() {
			if (this.timer) return false;
			this.timer = setInterval(() => {
				if (this.time === 1) {
					this.clearTimer();
				} else {
					this.runTime();
				}
			}, 1000);
		},
		runTime() {
			this.time--;
		},
		clearTime() {
			clearInterval(this.timer);
			this.timer = null;
			this.time = 0;
		},
		submit() {
			if (/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(this.params.mobile) && this.params.captcha.length == 6) {
			  const params = { ...this.params, ...this.$route.query }
				api.phoneLogin(params).then(res => {
					if (res.success && res.code === 200) {
					  if (res.result.code) {
              window.location.href = `${params.redirectUri}?code=${res.result.code}`
					    return false
            }
						this.$store.dispatch("save_staff_info", res.result);
						this.$router.push("/crm/home");
						const userInfoId = res.result.userInfo.id;
						const realname =res.result.userInfo.realname;
						const username =res.result.userInfo.username;
            sessionStorage.setItem('userId', JSON.stringify(userInfoId));
			      sessionStorage.setItem('realname', JSON.stringify(realname));
			      sessionStorage.setItem('username', JSON.stringify(username));
					} else {
						Notify({ type: "danger", message: res.message });
					}
				});
			}
		}
	}
};
</script>

<style lang="less">
@import url("~@/crm_variables.less");

.crm-login {
	width: 100%;
	height: 100%;
	position: relative;
	background: url("~@/assets/crm/login-bg.png") no-repeat top center;
	background-size: 100%;
	background-color: @bg_color;
}
.crm-login-box {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 100px 30px;
	background: url("~@/assets/crm/box-bg.png") repeat-y top center;
	background-size: 100%;
}
.crm-field {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 32px;
	background: #ffffff;
	height: 112px;
	margin-bottom: 40px;
	box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	border-radius: 16px 0px 16px 16px;
	.crm-field-icon {
		display: inline-block;
		width: 48px;
		height: 48px;
		min-width: 48px;
		margin-right: 40px;
	}
	input {
		width: 80%;
		height: 48px;
		line-height: 48px;
		color: @font_color;
		font-size: 38px;
	}
	.crm-get-code {
		display: inline-block;
		background: #ffffff;
		border: 2px solid #b8b4f2;
		box-sizing: border-box;
		border-radius: 10px;
		span {
			white-space: nowrap;
			font-size: 32px;
			line-height: 1.5;
			padding: 10px 16px;
			color: @main_color;
		}
	}
}
.icon-phone {
	background: url("~@/assets/crm/icon/icon-phone.png") no-repeat center center/contain;
}
.icon-code {
	background: url("~@/assets/crm/icon/icon-code.png") no-repeat center center/contain;
}
.van-button.crm-field-submit {
	display: block;
	width: 100%;
	height: 96px;
	background: @main_color;
	box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.25);
	border-radius: 32px 0px 32px 32px;
	margin-top: 60px;
	color: #fff;
	font-size: 40px;
}
.crm-login-passward{
	float: right;
    font-size: 28px;
    color:rgb(93, 92, 92);
    padding: 20px 10px 0 0;
}
</style>

<template>
	<div class="crm-select-cell">
		<van-field
			readonly
			clickable
			name="picker"
			:value="value"
			:label="label"
			:placeholder="placeholder"
			:rules="rules"
			v-bind="attr"
		>
			<template #input>
				<div class="crm-select-cell-body" @click="open">
					<input type="text" readonly :value="text" :placeholder="placeholder" />
					<span class="icon-down"></span>
				</div>
			</template>
      <template #button>
        <slot name="button"></slot>
      </template>
		</van-field>
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" :visible-item-count="columns.length > 10 ? 10 : 4" @confirm="onConfirm" @cancel="showPicker = false" />
		</van-popup>
	</div>
</template>

<script>
export default {
	name: "crm-select",
	props: {
		name: {
			type: String,
			default: "picker"
		},
		value: {
			type: String | Number,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		deep: {
			type: Number,
			default: 1
		},
		readonly: {
			type: Boolean,
			default: false
		},
		all: {
			type: Array,
			default() {
				return [];
			}
		},
		rules: {
			type: Array,
			default() {
				return [];
			}
		},
		// {value, text}
		columns: {
			type: Array,
			default() {
				return [];
			}
		},
		attr: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			text: "",
			showPicker: false
		};
	},
	watch: {
		value: {
			handler: function(val) {
				let list = this.columns;
				if (this.deep > 1) {
					list = this.all || [];
					const arr = list.filter(v => v.id === val);
					this.text = arr.length > 0 ? arr[0].name : "";
				} else {
					const arr = list.filter(v => v.value === val);
					this.text = arr.length > 0 ? arr[0].text : "";
				}
			},
			immediate: true
		}
	},
	methods: {
		open() {
			if (!this.readonly) {
				this.showPicker = true;
			}
		},
		onConfirm (e, index) {
			let text = "";
			let value = "";
			if (this.deep > 1) {
				let item = {};
				index.map(v => {
					if (!!item.children) {
						item = item.children[v];
					} else {
						item = this.columns[v];
					}
				});
				text = item.text;
				value = item.value;
			} else {
				text = e.text;
				value = e.value;
			}
			console.log(e,'------',value)
			this.text = text;
			this.$emit("input", value);
			this.$emit("change", value);
			this.showPicker = false;
		}
	}
};
</script>

<style lang="less">
.crm-select-cell {
	.van-cell {
		background-color: transparent;
	}
	.crm-select-cell-body {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 28px;
	}
	.icon-down {
		display: inline-block;
		width: 32px;
		height: 32px;
		background: url("~@/assets/crm/icon/icon-down.png") no-repeat center center/contain;
	}
}
</style>

import CommonForm from "@/views/WJ/CommonForm/index.vue";

const CommonFormRouter = [
	{
		path: "/CommonForm",
		name: "CommonForm",
		mate: {
			title: "公共问卷"
		},
		component: CommonForm,
	},
	// {
	//     path: "/archives/question",
	//     name: "archives-question",
	//     mate: {
	//         title: "患者建档"
	//     },
	//     component: ArchivesQuestion,
	// },
]

export default CommonFormRouter;
<template>
	<div id="app" v-watermark="text">
		<!-- <router-view /> -->

		<!-- <keep-alive :include="['crm-in-hospital','crm-detail-hospital']"> -->
			<keep-alive >
<router-view v-if="$route.meta.keepAlive"  />
		</keep-alive>

		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
	data() {
		return {
			user_text: "",
			text: "健恒医疗 CMS 系统",
		};
	},
	computed: {
		...mapState({
			info: (state) => state.staffInfo,
		}),
	},
	watch: {
		info: function (n) {
			this.updateWatermark();
		},
		$route: {
			handler: function (to) {
				if (to.path.includes("crm")) {
					if (this.user_text.length > 0) {
						this.text = this.user_text + " - " + dayjs().format("YYYYMMDD HH:mm:ss");
					} else {
						this.text = "健恒医疗 CMS 系统";
					}
				} else {
					this.text = "";
				}
			},
			immediate: true,
		},
	},
	// created(){this.updateWatermark();},
	mounted() {
		const loadingEl = document.getElementById("body-loading");
		if (loadingEl) {
			setTimeout(() => {
				loadingEl.style.opacity = 0;
				setTimeout(() => {
					loadingEl.remove();
				}, 1500);
			}, 300);
		}
		this.updateScreenSize();
		window.addEventListener("resize", this.updateScreenSize, false);
	},

	methods: {
		//水印
		updateWatermark() {
			if (this.info.username) {
				this.user_text = `${this.info.username} (${this.info.realname})`;
			} else {
				this.user_text = "";
			}
			if (this.user_text.length > 0) {
				this.text = this.user_text + " - " + dayjs().format("YYYYMMDD HH:mm:ss");
			} else {
				this.text = "健恒医疗 CRM 系统";
			}
		},
		//水印结束
		updateScreenSize() {
			const self = this;
			if (self.flag != null) {
				clearTimeout(self.flag);
			}
			self.flag = setTimeout(() => {
				let w = window.innerWidth;
				// 设置 body font-size
			}, 30);
		},
	},
};
</script>

<style lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000;
	width: 100%;
	height: 100%;
}
body {
	-webkit-text-size-adjust: 100% !important;
}
</style>

<template>
	<div class="history-list">
		<van-sticky>
			<div class="list-header">我的登记记录</div>
		</van-sticky>
		<van-cell-group>
			<template v-for="item in list">
				<van-cell :key="item.id"
					@click="linkTo(item)"
					is-link>
					<template #title>
						<van-tag type="danger"
							class="archives-status"
							plain
							v-if="+item.status === 2">驳回</van-tag>
						<van-tag type="success"
							class="archives-status"
							plain
							v-else-if="+item.status === 3">通过</van-tag>
						<van-tag type="success"
							class="archives-status"
							plain
							v-else-if="+item.status === 4">通过</van-tag>
						<van-tag type="primary"
							class="archives-status"
							plain
							v-else>确认中</van-tag>
						<span class="item-name">
							{{ item.name }}
						</span>
					</template>
				</van-cell>
			</template>
		</van-cell-group>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import dayjs from "dayjs";
	import api from "@/request/api";
	import crmMixins from "@/utils/crm-mixins";
	export default {
		mixins: [crmMixins],
		computed: {
			...mapState(["archivesSurvey", "archivesValueData", "userInfo"])
		},
		data () {
			return {
				list: []
			};
		},
		created () {
			if (this.userInfo && this.userInfo.phone) {
				this.init();
			} else {
				this.$router.push({
					path: "/archives",
					query: {
						...this.$route.query
					}
				});
			}
		},
		methods: {
			init () {
				this.showLoading();
				api.listByUserId().then(resp => {
						this.list = resp.result || [];
						this.hideLoading();
					}).finally(() => this.hideLoading());
			},
			linkTo (item) {
				this.$store.dispatch("set_archives_value", {
					...item
				});
				this.$router.push({
					path: "/archives/result",
					query: {
						...this.$route.query,
						archivesId: item.id
					}
				});
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");
	.history-list {
		text-align: left;
		.list-header {
			text-align: center;
			box-shadow: 0px 1px 8px 1px #e1dfdf;
			height: 100px;
			font-size: 32px;
			border-bottom: 2px solid @bg5;
			color: @bg5;
			line-height: 100px;
		}
		> .van-cell {
			font-size: 26px;
			// padding: 0;
		}
		.van-cell__title {
			display: flex;
			align-content: center;
			.item-name {
				margin-left: 10px;
				font-size: 28px;
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.archives-status {
			width: 90px;
			justify-content: center;
		}
	}
</style>

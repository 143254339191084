import axios from "axios";
import TYPES from "@/constant/types";
import store from "@/store";
import {Dialog, Notify } from "vant";
const baseURL = "";

const instance = axios.create({
  baseURL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json"
  }
});

const errorCode = (code,msg) => {

  const codeMessage = {
    200: "服务器成功返回请求的数据。",
    201: "新建或修改数据成功。",
    202: "一个请求已经进入后台排队（异步任务）。",
    204: "删除数据成功。",
    400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
    401: "用户没有权限（令牌、用户名、密码错误）。",
    403: "用户得到授权，但是访问是被禁止的。",
    404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
    406: "请求的格式不可得。",
    410: "请求的资源被永久删除，且不会再得到的。",
    422: "当创建一个对象时，发生一个验证错误。",
    500: "服务器发生错误，请检查服务器。",
    502: "网关错误。",
    503: "服务不可用，服务器暂时过载或维护。",
    504: "网关超时。"
  };
  if (msg || codeMessage[code]) {
    Notify({ type: 'danger', message: msg || codeMessage[code] });
  }
};

instance.interceptors.request.use(
  function (config) {
    const CRMTOKEN = location.pathname.indexOf('/crm/') > -1
    const _token = CRMTOKEN ? (store.getters.token || localStorage.getItem(TYPES.TOKEN_KEY)) : localStorage.getItem(TYPES.TOKEN_KEY);
    const _token_health = store.getters.userInfo ? (store.getters.userInfo.token || localStorage.getItem(TYPES.TOKEN_KEY_HEALTH)) : localStorage.getItem(TYPES.TOKEN_KEY_HEALTH);
    if (_token) {
      config.headers["X-Access-Token"] = _token;
    }
    if (config.url.includes('jeecg-healthhome')) {
      config.headers["healthhome-token"] = _token;
    }
    if (_token_health) {
      config.headers["healthhome-token"] = _token_health || '';
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

instance.interceptors.response.use(function (resp) {
  if (resp.status != 200 || (resp.data.code != 500 &&resp.data.code != 200 && resp.data.code != 0 && resp.data.code != undefined)) {
    // if (resp.status != 200 || (resp.data.code != 200 && resp.data.code != 0 && resp.data.code != undefined)) {
      errorCode(resp.status, resp.data.message);
      return Promise.reject(resp.data);
    }
    return resp.data;
  },
  error => {
    const resp = error.response;
    console.log(resp)
    // errorCode(resp.status);
    if (resp) {  if (resp.status === 401) {
      // localStorage.clear();
      // window.location.href = '/crm'
      Dialog.alert({ title: "提示", message: resp.data.message, confirmButtonText: "退出系统" }).then((res) => {
        localStorage.clear();
        window.location.href = process.env.NODE_ENV == 'development' ? '/crm/pLogin' : '/crm/login'

      });

    }  }

    Promise.reject(error)
  }
);

export default instance;

<template>
	<div class="result-page">
		<div :class="isLose ? 'result-card' : `${dangerOption.length > 0 ? 'danger' : 'success'} result-card`">
			<p class="card-title">{{ isLose ? '已过期' : '提交成功' }}</p>

			<p class="card-tip"
				v-if="isLose">请重新填写</p>
			<template v-else>
				<p class="card-tip">请勿关闭此页面，并出示给预检人员</p>
				<p class="card-tip">请在来院前重新打开次页面，并出示给预检人员含有本人照片的有效证件（如身份证、医保卡）和该页面给工作人员查验后进入医院。 谢谢您的配合！</p>
			</template>

			<div class="card-tip">
				<div class="bold-text"
					v-if="investValueData.name">
					<label>姓名：</label>
					<span class="bold-text-value">{{ investValueData.name }}</span>
				</div>
				<div class="bold-text"
					v-if="investValueData.id_card">
					<label>身份证号：</label>
					<span class="bold-text-value">{{ investValueData.id_card }}</span>
				</div>
				<div class="bold-text"
					v-if="investValueData.family_name">
					<label>家属的姓名：</label>
					<span class="bold-text-value">{{ investValueData.family_name }}</span>
				</div>
				<div class="bold-text"
					v-if="investValueData.family_id_card">
					<label>家属身份证号：</label>
					<span class="bold-text-value">{{ investValueData.family_id_card }}</span>
				</div>
				<div class="bold-text">
					<label>有效期：</label>
					<span class="bold-text-value">
						{{ investSurvey.effective }}
					</span>
					小时
				</div>
				<!-- <div class="bold-text"
						 v-if="investValueData.submitDate">
					<label>失效时间：</label>
					<span class="effective">
						{{ loseEfficacy }}
					</span>
				</div> -->
			</div>
		</div>

		<div class="result-link">
			<van-cell title="我的提交历史"
				is-link
				:value="list.length ? `${list.length}条` : ''"
				@click="linkToList">
				<template v-if="errList.length"
					#label>
					<div class="error-tip">{{ errList.length }}条异常记录</div>
				</template>
			</van-cell>
		</div>

		<div class="result-card danger"
			:style="isLose ? 'background-color:#A0A0A0' : ''"
			v-if="dangerOption.length">
			<p class="card-title">
				流行病学异常
				<van-icon name="warning"
					style="margin-left: 10px;"
					size="1.5em" />
			</p>
			<div class="card-option"
				v-for="(item, index) in dangerOption"
				:key="index">
				<div class="card-option-label">{{ item.label }}</div>
				<div class="card-option-value">{{ item.value }}</div>
			</div>
		</div>
		<div class="result-card success"
			:style="isLose ? 'background-color:#A0A0A0' : ''"
			v-else>
			<p class="card-title">
				流行病学无殊
				<van-icon name="checked"
					style="margin-left: 10px;"
					size="1.5em" />
			</p>
		</div>

		<div class="info-card"
			style="border-top:none;padding: 0 20px;">
			<div class="info-item">
				<div class="item-label">采集时间：</div>
				<div class="item-value">
					{{ submitDate }}
				</div>
			</div>
			<div class="info-item">
				<div class="item-label">失效时间：</div>
				<div class="item-value">{{ loseEfficacy }}</div>
			</div>
		</div>
		<div class="submit-box">
			<van-button type="primary"
				color="#5f5aa3"
				@click="submit"
				block>重新填写</van-button>
		</div>

		<div class="info-card">
			<template v-for="item in baseinfo.questions">
				<template v-if="!noBase.includes(item.name)">
					<div class="info-item"
						:key="item.name">
						<div class="item-label">{{ item.label }}</div>
						<div class="item-value">
							<template v-if="item.name === 'residential_address'">
								{{ investValueData[item.name].replace("_", "") }}
							</template>
							<template v-else>
								{{ investValueData[item.name] && investValueData[item.name] != '0' ? (investValueData[item.name] || ''):'-' }}
							</template>
						</div>
					</div>
				</template>
				<template v-if="investValueData.enter_reason === '预约接待' && item.name === 'receptionist'">
					<div class="info-item"
						:key="item.name">
						<div class="item-label">{{ item.label }}</div>
						<div class="item-value">
							<template v-if="item.name === 'residential_address'">
								{{ investValueData[item.name].replace("_", "") }}
							</template>
							<template v-else>
								{{ investValueData[item.name] }}
							</template>
						</div>
					</div>
				</template>
				<template v-else-if="investValueData[item.name] && signList.includes(item.name)">
					<div class="info-item"
						:key="item.name">
						<div class="item-label">{{ item.label }}</div>
						<img :src="investValueData[item.name]" />
					</div>
				</template>
			</template>
			<template v-if="investValueData.health_code">
				<div class="item-label">患者最新的健康码与核酸记录截图</div>
				<div class="info-item">
					<img class="item-black-img"
						v-for="i in investValueData.health_code.split(',')"
						:key="i"
						@click="handleShowImage(i)"
						:src="i" />
				</div>
			</template>
			<template v-if="investValueData.family_health_code">
				<div class="item-label">陪护家属最新的健康码与核酸记录截图</div>
				<div class="info-item">
					<img class="item-black-img"
						v-for="i in investValueData.family_health_code.split(',')"
						:key="i"
						@click="handleShowImage(i)"
						:src="i" />
				</div>
			</template>
		</div>
		<van-dialog v-model="show"
			close-on-click-overlay
			:show-confirm-button="false">
			<img :src="showImage" />
		</van-dialog>
		<wj-login :model="investValueData"
			@success="sendForm"
			ref="WjLoginRef" />
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import dayjs from "dayjs";
	import crmMixins from "@/utils/crm-mixins";
	import api from "@/request/api";
	import WjLogin from "@/views/WJ/login.vue";
	export default {
		mixins: [crmMixins],
		components: {
			WjLogin
		},
		data () {
			return {
				signList: ['sign', 'family_auth_sign', 'family_accompany_sign'],
				noBase: ['sign', 'family_auth_sign', 'family_accompany_sign', 'health_code', 'hesuan_code', 'receptionist'],
				show: false,
				showImage: '',
				list: [],
				errList: [],
			};
		},
		computed: {
			...mapGetters(["investSurvey", "investValueData", "userInfo"]),
			loseEfficacy: function () {
				const dayNow = this.investValueData.expire_date ? dayjs(this.investValueData.expire_date) : dayjs();
				return dayNow.add(this.investSurvey.effective || 24, "hour").format("YYYY-MM-DD HH:mm");
			},
			baseinfo: function () {
				return (this.investSurvey.questions || []).find(i => i.type === "baseinfo") || { questions: [] };
			},
			isLose: function () {
				const dayNow = this.investValueData.expire_date ? dayjs(this.investValueData.expire_date) : dayjs();
				const lose = dayNow.add(this.investSurvey.effective || 24, "hour").valueOf();
				const now = dayjs().valueOf();
				return lose < now;
			},
			dangerOption: function () {
				return this.investValueData.content.filter(i => i.danger > 0);
			},
			submitDate: function () {
				return this.investValueData.expire_date ? dayjs(this.investValueData.expire_date).format("YYYY-MM-DD HH:mm") : dayjs().format("YYYY-MM-DD HH:mm")
			}
		},
		created () {
			if (this.userInfo && Object.keys(this.userInfo).length) {
				this.init();
			}
		},
		methods: {
			handleShowImage (url) {
				this.showImage = url;
				this.show = true
			},
			init () {
				this.showLoading();
				api.epidemiologicalIndex()
					.then(resp => {
						this.list = resp.result || [];
						// 异常数据
						this.errList = (resp.result || []).filter(i => !i.status);
						this.hideLoading();
					}).finally(() => this.hideLoading());
			},
			sendForm () {
				const params = {
					...this.investValueData
				};
				delete params.submitDate;
				delete params.phone;
				delete params.token;
				api.epidemiologicalSave(params).then(() => {
					this.linkToList();
				});
			},
			linkToList () {
				if (this.userInfo && this.userInfo.phone) {
					this.$router.push({
						path: "/investigation/list",
						query: {
							...this.$route.query
						}
					});
				} else {
					this.$dialog
						.confirm({
							title: "提示",
							message: `您还未验证手机，您是否要验证手机，验证之后可以查询您的流行病学调查记录，且${this.investSurvey.effective}小时只能无需重新填写，直接出示有效期内的流行病学调查记录即可。`,
							messageAlign: "left",
							cancelButtonText: "暂不验证",
							confirmButtonText: "立即验证"
						})
						.then(() => {
							this.$refs.WjLoginRef.show = true;
							this.$refs.WjLoginRef.params.mobile = this.investValueData.mobile;
							this.$refs.WjLoginRef.params.code = "";
						});
				}
			},
			submit () {
				this.$router.push({
					path: "/investigation/question",
					query: {
						...this.$route.query
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	@import url("~@/variables.less");

	.result-page {
		text-align: left;

		.result-link {
			margin: 20px;
			padding: 20px 0;

			> .van-cell {
				font-size: 26px;
				padding: 0;
			}
		}

		.result-card {
			background-color: #a0a0a0;
			margin: 30px 20px;
			padding: 20px;
			border-radius: 5px;
			color: @font9;
			font-size: 26px;

			&.isLose {
				background-color: #22223385;
			}

			&.success {
				background-color: @color1;
			}

			&.danger {
				background-color: @danger;
			}

			.card-title {
				font-size: 32px;
				padding: 10px 0 20px;
				display: flex;
				align-items: center;
			}

			.card-tip {
				margin-bottom: 20px;

				.bold-text {
					line-height: 55px;
					display: flex;
					align-items: center;

					label {
						font-size: 28px;
						font-weight: 100;
						display: inline-block;
						width: 220px;
					}

					.bold-text-value {
						font-size: 36px;
						font-weight: 600;
						margin-right: 10px;

						&.effective {
							color: @success;
							font-weight: 800;
						}

						&.lose {
							color: @color4;
							font-weight: 800;
						}
					}

					> span {
						&.effective {
							color: @success;
						}

						&.lose {
							color: @color4;
						}
					}
				}
			}

			.card-option {
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid @font11;
				padding-bottom: 10px;

				.card-option-label {
					flex: auto;
				}

				.card-option-value {
					padding: 10px 20px;
					margin: 0 20px;
					background-color: @bg5;
					border-radius: 5px;
				}

				&:last-child {
					border: none;
				}
			}
		}

		.info-card {
			border-top: 4px dashed @bg5;
			padding: 30px 20px;
			font-size: 26px;

			.info-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px;

				img {
					width: 30%;
					height: auto;
				}

				.item-black-img {
					width: 45%;
					margin: auto;
				}
			}
		}

		.submit-box {
			font-size: 26px;
			padding: 30px 30px 40px;
		}
	}
</style>

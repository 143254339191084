<template>
	<div class="crm-dslist">
		<div class="crm-dslist-body-scroll">
			<div class="showTitle">
				<p>共{{ list.length }}条</p>
				<!-- <p @click="showPopup">筛选<van-icon name="brush-o" /></p> -->
			</div>
			<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
				<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
					<van-cell
						:title="item.blmc"
						:label="dayjs(item.ryrq).format('YYYY-MM-DD HH:mm')"
						@click="jumpParamsPages('crm-dsreport-hospital', item,list)"
						is-link
						v-for="(item, index) in list"
						:key="index"
					></van-cell>
				</van-list>
			</van-pull-refresh>
		</div>
		<van-popup class="crm-jy-popup" v-model="show">
			<van-row>
				<van-col class="popup-title">类型筛选</van-col>
			</van-row>
			<van-row class="popup-row">
				<van-col class="popup-col" v-for="item in jyList" :key="item.value"
					><van-tag @click="select(item.value)" color="#5f5aa3" size="large" :plain="item.jyRadio" type="primary">{{ item.title }}</van-tag></van-col
				>
			</van-row>
			<van-row class="popup_ok"> <van-button color="#5f5aa3" size="small" @click="onSearch()">确定</van-button></van-row>
		</van-popup>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-patient",
	mixins: [crmMixins],
	components: { SexIcon },
	props: {
		inWardFrom: Object,
	},
	data() {
		return {
			dayjs,
			show: false,
			applyList: [],
			url: {
				list: "/crm-service/crm/crmCyxj/list",
			},
			queryParams: {
				column: "create_time",
				order: "desc",
				patientId: this.inWardFrom.patientId,
			},
			statusList: [],
			applyId: "",
			zjh: "",
			jyList: [
				{
					text: "全部",
					title: "全部",
					value: null,
					jyRadio: true,
				},
			],
			isScrollTop:0,num:0,
		};
	},
	mounted() {
		this.$nextTick(() => {

const listInfo=	sessionStorage.getItem("listInfo")
const tabScrollTop=	sessionStorage.getItem("tabScrollTop")
this.isScrollTop=tabScrollTop
if(!!listInfo&&listInfo.length!=0){
this.list=JSON.parse(listInfo);
const $wrapper = document.querySelector('.crm-dslist');
$wrapper.scrollTop = tabScrollTop;
}
})
	},
	watch: {
    upData(newVal, oldVal) {
      console.log('myData 改变了: ', newVal, '（之前是：', oldVal, '）');
	  if(newVal!=oldVal){
		this.refreshData();
	  }

    },
	list(newVal, oldVal) {

if(newVal!=oldVal&&!!this.isScrollTop){
  const tabScrollTop=sessionStorage.getItem("tabScrollTop")
  const $wrapper = document.querySelector('.crm-dslist');
  $wrapper.scrollTop = tabScrollTop;

this.num=this.num+1
console.log("wrapper2222",);

}
}
  },
	methods: {
		jumpParamsPages(path, params,listInfo) {

const $wrapper = document.querySelector('.crm-dslist');
console.log(path,params,$wrapper);
const detailPageNo=Math.ceil(listInfo.length / 15);
sessionStorage.setItem('detailPageNo',detailPageNo)
sessionStorage.setItem("listInfo",JSON.stringify(listInfo))
sessionStorage.setItem("tabScrollTop",$wrapper.scrollTop)
this.$router.push({name: path,params: {params:params}});
},
		refreshData(a) {
		this.loadPage();
		},
		showPopup() {
			this.show = true;
		},
		select(value) {
			let that = this;
			that.jyList.map((i) => {
				if (value == i.value) {
					i.jyRadio = !i.jyRadio;
					that.queryParams.checkType = i.value;
				} else {
					i.jyRadio = true;
				}
			});
		},
		onSearch() {
			this.show = false;
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-dslist {
	text-align: left;
	background-color: #f2f2f2;
    width: 100vw;
    height: 100vh;
    overflow: auto;
	padding-bottom: 6rem;
	.showTitle {
		// margin-top: 2px;
		background-color: #fff;
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 0.3rem 0.533rem 0.1rem;
		p {
			color: #969799;
			text-align: left;
			font-weight: 400;
			font-size: 0.647rem;
			line-height: 1.04rem;
			margin-bottom: 0rem;
		}
	}
}

.crm-jy-popup {
	.popup_ok {
		text-align: right;
		margin-top: 20px;
	}
	padding: 80px 50px;
	width: 80vw;
	border-radius: 10px;
	.popup-title {
		font-size: 32px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 20px;
	}
}
.crm-dslist .showTitle {
	// margin-top: 2px;
	background-color: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.533rem 0.1rem;
	p {
		color: #969799;
		text-align: left;
		font-weight: 400;
		font-size: 0.647rem;
		line-height: 1.04rem;
		margin-bottom: 0rem;
	}
}
.popup-row {
	.popup-col {
		width: 33%;
		padding-top: 40px;

		span {
			width: 100%;
			justify-content: center;
		}
		&:nth-child(3n + 1) {
			padding-right: 10px;
		}
		&:nth-child(3n + 2) {
			padding-left: 5px;
			padding-right: 5px;
		}

		&:nth-child(3n + 3) {
			padding-left: 10px;
		}
	}
}
</style>

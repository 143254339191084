<template>
	<div :class="`custom-form agreen-checkbox ${error?'error':''}`">
		<van-checkbox v-model="inputValue"
			@change="error = false"
			:checked-color="model.color"
			shape="square">
			{{ model.label }}
		</van-checkbox>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	export default {
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			value: [String, Number, null]
		},
		data () {
			return {
				inputValue: null,
				error: false
			};
		},
		created () {
			this.init();
		},
		methods: {
			validate () {
				if (this.inputValue) {
					return true
				} else {
					this.$toast.fail(this.model.message || '请勾选');
					this.error = true
					throw (this.model.message || '请勾选')
				}
			},
			init () {
				if (this.value) {
					this.inputValue = this.value;
				} else {
					this.inputValue = this.model.default || null;
				}
			},
			changeValue () {
				this.$emit("input", this.inputValue, this.model);
			}
		}
	};
</script>

<style lang="less">
	@import url("~@/variables.less");
	@import url("~@/custom-form.less");

	.agreen-checkbox {
		font-size: 26px;
		&.error {
			.van-checkbox__label {
				color: red;
			}
		}
	}
</style>

/**
 * 用户授权
 * 安博重定向通过get参数提供用户信息
 * 验证手机号码
 * 微信授权
*/
import api from "@/request/api";
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters(["redirectUrl", "wechatToken"]),
	},
	created () {
		if (!this.wechatToken) {
			this.changeToLogin()
		}
	},
	methods: {
		// 获取地址栏参数
		changeToLogin () {
			this.$store.dispatch('set_redirect_url', window.location.href)
			const HREF = `${window.location.origin}/wechatLogin`;
			window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WECHAT_APPID}&redirect_uri=${HREF}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;

		},
		// 判断客户端环境
		checkBrower () { },
		// 获得地址上用户参数
		getURLInfo () { },
		// 获得微信授权登录
		getWechatAuth () { },
	}
}
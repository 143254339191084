<template>
	<div class="question-box common-form">
		<div class="hold-height">
			<div v-for="(item, index) in questions"
				:key="index"
				class="question-group">
				<p class="question-group-title"
					v-if="item.title"
					:id="item.type">{{ item.title }}</p>
				<template v-for="(child, childIndex) in item.questions">
					<div class="question-item"
						v-if="!child.hide"
						:key="`${child.name}-${childIndex}-${index}`">
						<component :key="`${childIndex}-${updateKey}`"
							:is="`normal-${child.type}`"
							:model="child"
							:index="index"
							ref="questionItemRef"
							:childIndex="childIndex"
							:showIndex="!!item.showIndex"
							@input="valueChange($event, item, child)"
							:value="submitData[child.name]" />
					</div>
				</template>
			</div>
		</div>
		<div class="submit-box" v-if="questions.length >0">
			<van-button type="primary"
				color="#5f5aa3"
				@click="submit"
				block>提交</van-button>
		</div>
	</div>
</template>
<script>
	import { mapState } from "vuex";
  import moment from 'moment'
	import {
		normalInput,
		normalInputNumber,
		normalRadio,
		normalRadioBlock,
		normalSelect,
		normalUpload,
		normalSign,
		normalAgreenCheckbox,
		normalTextarea,
		normalDescText,
		normalRate,
		normalDate
	} from "@/components/form/index";
	import api from "@/request/api";
	import crmMixins from "@/utils/crm-mixins";
	import myMixins from "@/utils/mixins";
	// import wechatMixins from "@/utils/wechat-mixins";
	// import { formQueryNew } from "@/constant/formQuery"
	export default {
		components: {
			normalInputNumber,
			normalInput,
			normalTextarea,
			normalSelect,
			normalRadio,
			normalSign,
			normalRate,
			normalUpload,
			normalAgreenCheckbox,
			normalDescText,
			normalRadioBlock,
			normalDate
		},
		mixins: [
			// wechatMixins,
			crmMixins,
			myMixins
		],
		data () {
			return {
				updateKey: 1,
				questions: [],
				submitData: {},
			};
		},
		mounted () {
			document.title = "上海好望角医院";
			const surveyId = this.$route.query.id;
			if (!surveyId) {
				this.$router.replace("/survey/404");
				console.log("跳转到landing page， 因为没有问卷id");
				return false;
			} else {
				this.autoLogin().then(() => {
					this.getSurvey();
				});
			}
		},
		methods: {
			getSurvey () {
				const surveyId = this.$route.query.id;
				if (!surveyId) {
					this.$router.replace("/survey/404");
					console.log("跳转到landing page， 因为没有问卷id");
					return false;
				} else {
					this.showLoading();
					api.querySurvey(surveyId)
						.then(res => {
							if (res.success) {
								try {
									const contentJson = JSON.parse(res.result.contentJson);
									this.initQuery(contentJson);
								} catch (error) {
									console.log("问卷信息设置失败", error);
								}
							} else {
								this.$router.replace("/404");
							}
						})
						.catch(() => {
							this.$router.replace("/404");
						}).finally(() => this.hideLoading());
				}
			},
			initQuery (content) {
        this.$store.dispatch("setInfo", content)
				let contentJson = {};
				contentJson.title = content.title;
				document.title = content.title
				contentJson.duration = content.duration;
				contentJson.time = content.time;
				contentJson.id = content.id;
				contentJson.effective = content.effective;
				contentJson.total = content.joinCount;
				contentJson.statement = content.statement;
				contentJson.result_function = content.answerJson;
				contentJson.questions = content.questions;
				this.resultSurveyInfo = content;
				this.questions = content.questions || [];
				this.surveyInfo = content;
				this.statement = contentJson.statement;
			},
			valueChange (value, val, ITEM) {
				if (val.type === "content") {
					const OPTION = (ITEM.option || []).find(i => i.value === value) || {};
					if (this.submitData[val.type]) {
						const Index = this.submitData[val.type].findIndex(i => i.name === ITEM.name);
						if (Index === -1) {
							this.submitData[val.type].push({
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							});
						} else {
							this.$set(this.submitData[val.type], Index, {
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							});
						}
					} else {
						this.submitData[val.type] = [
							{
								danger: OPTION.danger || 0,
								name: ITEM.name,
								value: value,
								label: ITEM.label
							}
						];
					}
				} else {
					this.submitData[ITEM.name] = value;
				}
				// 显隐规则
				if (ITEM.changeLink && ITEM.link) {
					this.questions.some(item => {
						const questionItem = item.questions.find(i => i.name === ITEM.link);
						if (questionItem) {
							let flag = true;
							questionItem.showRule.forEach(i => {
								flag = flag && this.submitData[i.filed] === i.value;
							});
							questionItem.hide = !flag;
							// 清空隐藏字段值
							if (!flag) {
								this.submitData[questionItem.name] = "";
							}
							return true;
						}
					});
				}
			},
			submit () {
				this.validate().then(val => {
					if (val) {
						this.showLoading();
						if (this.resultSurveyInfo.submitType === 'common') {
							// 导出列表
							const columns = [];
							this.questions.map(item => {
								columns.push(...item.questions.map(i => {
									return {
										name: i.name,
										label: i.label
									}
								}))
							})
							api.submitAnswer({
								answers: JSON.stringify(this.submitData),
								questionId: this.$route.query.id,
								columns: JSON.stringify(columns)
								// ...this.submitData,
							}).then(resp => {
								if (resp.success) {
									this.$store.dispatch('set_success_tip', this.surveyInfo.successTip || '感谢您的支持与配合')
									this.$router.push({
										path: "/survey/success",
									});
								}
							}).finally(() => this.hideLoading())
						} else {
              this.submitData.pid = this.$route.query.id
              this.submitData.created_at = moment().unix()
              this.$store.dispatch("setSubmitData", this.submitData)
							api.portraitStore({
								...this.submitData,
							}).then(resp => {
								if (resp.success) {
									this.$store.dispatch('set_success_tip', this.surveyInfo.successTip || '感谢您的支持与配合')
									this.$router.push({
										path: "/survey/success",
									});
								}
							}).finally(() => this.hideLoading())
						}
					} else {
						this.$toast.fail("必填项未完善或填写错误！");
					}
				});
			},
			// 校验
			validate () {
				return new Promise(resolve => {
					let flag = true;
					this.$refs.questionItemRef.forEach(item => {
						flag = item.validate() && flag;
					});
					resolve(flag);
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/variables.less");
	.question-box.common-form {
		height: calc(100vh - 50px);
		overflow: auto;
		text-align: left;
		.hold-height {
			min-height: 80vh;
		}
		.question-group-title {
			font-size: 32px;
			padding: 30px 20px 20px;
			font-weight: 600;
			color: @bg5;
		}
		.question-item {
			padding: 30px 30px 40px;
			border-bottom: 1px solid @font11;
			&:last-child {
				border: none;
			}
		}
		.question-group {
			padding: 0 0 20px 0;
			border: none;
			.question-item {
				border: none;
			}
			&:first-child {
				padding: 0 0 60px 0;
				border-bottom: 4px dashed @bg5;
			}
			&:last-child {
				border-bottom: none !important;
			}
		}
		.question-date {
			font-size: 26px;
			padding: 30px 30px 40px;
			> div {
				line-height: 30px;
				margin-top: 20px;
			}
		}
		.submit-box {
			font-size: 26px;
			padding: 30px 30px 40px;
		}
	}
</style>

<style lang="less">
	@import url("~@/variables.less");
	.error-tip {
		float: left;
		color: @color4;
		font-size: 16px;
	}
	.normal-form .input-value.error .input-value-cell-body,
	.normal-form .input-value.error input {
		border-color: @color4;
	}
	.desc-list{
		padding-left: 20px;
		li{
			padding-bottom: 10px;
		}
	}
</style>

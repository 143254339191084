<template>
	<van-dialog v-model="show" class-name="custom-dialog" :showConfirmButton="false">
		<div class="kj-modal">
			<div class="kj-modal-body">
				<div class="kj-top">
					<img src="~@/assets/images/icon-tip.png" alt="" />
				</div>
				<h1>{{ statement.title }}</h1>
				<div class="kj-modal-desc">
					<span v-html="statement.content"></span>
				</div>
				<div class="kj-modal-footer">
					<a href="javascript:;" class="kj-btn" @click="handlerAgree">同意</a>
					<a href="javascript:;" class="kj-close" @click="close">不同意并退出</a>
				</div>
			</div>
		</div>
	</van-dialog>
</template>

<script>
export default {
	data() {
		return {
			show: false
		};
	},
	props: {
		statement: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	methods: {
		showModal() {
			this.show = true;
		},
		close() {
			this.show = false;
		},
		handlerAgree() {
			this.$emit("agree");
			this.show = false;
		}
	}
};
</script>

<style lang="less">
@import url("~@/variables.less");
.kj-modal {
	p {
		.desc;
		color: @font2;
		text-align: left;
	}
}
.kj-modal-body {
	background: @font9;
	border-radius: 20px;
	padding: 40px 0 0;
	h1 {
		.btn-font;
		color: @font1;
		padding: 36px 0 24px;
	}
	a {
		display: inline-block;
	}
}
.kj-top {
	img {
		display: inline-block;
		height: 192px;
		width: auto;
	}
}
.kj-modal-desc {
	max-height: 286px;
	padding: 0 40px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: left;
	text-indent: 2em;
}
.kj-modal-footer {
	padding: 27px 0 0;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	align-items: center;
	.kj-btn {
		width: 340px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90px;
		background: @bg3;
		border-radius: 45px;
		color: #fff;
	}
	.kj-close {
		font-size: 26px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		line-height: 39px;
		padding: 30px 0;
		letter-spacing: 1px;
	}
}
</style>

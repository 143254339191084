<template>
	<div class="crm-login">
		<div class="crm-login-box">
			<div class="crm-field">
				<span class="crm-field-icon icon-phone"></span>
				<input type="text" v-model.trim="params.username" placeholder="请输入账号" />
			</div>
			<div class="crm-field">
				<span class="crm-field-icon icon-phone"></span>
				<input type="password" v-model.trim="params.password" placeholder="请输入密码" />
			</div>
			<div class="crm-field">
				<span class="crm-field-icon icon-code"></span>
				<input type="text" v-model.trim="params.captcha" placeholder="请输入验证码" />
				<img @click="updateImg" class="crm-get-code crm-img-code" :src="codeImg" />
			</div>
			<van-button class="crm-field-submit" :loading="loading" loading-text="登录中..." @click="submit">登录</van-button>
			<router-link :to="{path: $route.query.redirectUri? `/crm/login?redirectUri=${$route.query.redirectUri}&responseType=${$route.query.responseType}`: '/crm/login'}" >	<span class="crm-login-passward"  >手机号码登录</span> </router-link>
		</div>
	</div>
</template>

<script>
import api from "@/request/api";
import { Notify } from "vant";
export default {
	name: "crm-login-page",
	data() {
		return {
			loading: false,
			time: 0,
			codeImg: "",
			params: {
				captcha: "",
				checkKey: "",
				password: "",
				username: ""
			}
		};
	},
	mounted() {
		this.updateImg();
	},
	methods: {
		updateImg() {
			this.params.checkKey = Math.random() * new Date().getTime();
			api.randomImage(this.params.checkKey).then(res => {
				if (res.success) {
					this.codeImg = res.result;
				}
			});
		},
		submit() {
			if (!!this.params.captcha && !!this.params.password && !!this.params.username) {
				this.loading = true;
        const params = { ...this.params, ...this.$route.query }
				api.crmLogin(params)
					.then(res => {
						console.log(res);
						this.loading = false;
						if (res.success && res.code === 200) {
              if (res.result.code) {
                window.location.href = `${params.redirectUri}?code=${res.result.code}`
                return false
              }
							this.$store.dispatch("save_staff_info", res.result);
							this.$router.push("/crm/home");
						const userInfoId = res.result.userInfo.id;
						const realname =res.result.userInfo.realname;
						const username =res.result.userInfo.username;
            sessionStorage.setItem('userId', JSON.stringify(userInfoId));
			sessionStorage.setItem('realname', JSON.stringify(realname));
			sessionStorage.setItem('username', JSON.stringify(username));
						} else {
							Notify({ type: "danger", message: res.message });
							this.updateImg();
						}
					})
					.catch(e => {
						setTimeout(() => {
							this.loading = false;
						}, 500);
					});
			}
		}
	}
};
</script>

<style lang="less">
.crm-img-code {
	width: 210px;
	height: 70px;
	border: 0 !important;
}
.crm-login-passward{
	float: right;
    font-size: 28px;
    color:rgb(93, 92, 92);
    padding: 20px 10px 0 0;
}
</style>

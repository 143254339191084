import request from "./index.js";
import TYPES from "../constant/types.js";
import Vue from 'vue';

const API_URL = {
    crmPatientListURL: '/crm-service/crm/crmPatient/list', // 患者列表
    crmInHospitalListURL: "/crm-service/crm/crmInHospitalApply/inHospitalList"//住院列表
};

const CRM_API = {
    /**获取外院医生列表*/
    externalDoctorList: (params) => {
        return request.get('/crm-service/wechat/external-doctor/list', { params })
    },
    /**
    * 文件上传
    * @param {FormData} fd = { file biz}
    * @returns
    */
    upload: fd => {
        return request({
            url: '/crm-system/sys/common/upload',
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: fd
        })
    },
    /**
     * file upload
     * @param {File} file
     * @param {Service Path} biz
     * @returns
     */
    /**
     * file upload
     * @param {File} file
     * @param {Service Path} biz
     * @returns
     */
    uploads: (file, biz) => {
        const fd = new FormData();
        fd.append("file", file.file);
        fd.append('biz', biz);
        return request({
            url: '/crm-system/sys/common/upload',
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: fd
        })
    },
    getImage: (name) => {
        return '/crm-system/sys/common/static/' + name;
    },
    /**
     * 获取验证码图片
     * @param {String} key 验证码key
     * @returns
     */
    randomImage: key => {
        return request.get('/crm-system/sys/randomImage/' + key)
    },
    /**
     * 账号密码登录
     * @param {Object} params
     * @returns
     */
    crmLogin: params => {
        return request.post('/crm-system/sys/login', params)
    },
    /**
     * 获取用户基本信息
     * @returns
     */
    crmGetUserInfo: () => {
        return request.get('/crm-system/sys/user/getUserInfo');
    },
    /**
     * 发送手机验证码
     * @param {Object} params { mobile, smsmode: 登录模式: 2 注册模式: 1}
     * @returns
     */
    crmSysSms: (params = { mobile: '', smsmode: 0 }) => {
        return request.post('/crm-system/sys/sms', params)
    },
    /**
     * 手机号登录
     * @param {Object} params
     * @returns
     */
    phoneLogin: (params = { captcha: '', mobile: '' }) => {
        return request.post('/crm-system/sys/phoneLogin', params);
    },
    /**
    * 统计报表-统计患者数据
    * @returns
    */
    crmCountNums: () => {
        return request.get('/crm-service/crm/report/countPatientData');
    },

        /**
    * 通过 用户名 获取相同姓名患者
    * @param {Object} params
    * @returns
    */
        listMobile: params => request.get('/crm-service/crm/crmPatient/listMobile', { params }),


    /**
     * 创建患者
     * @param {Object} params
     * @returns
     */
    crmPatientAdd: params => request.post('/crm-service/crm/crmPatient/add', params),
    /**
    * 通过 用户名 获取疑似用户
    * @param {Object} params
    * @returns
    */
    crmSuspectedList: params => request.get('/crm-service/crm/crmInHospitalApply/inHospitalList', { params }),
    /**
 * 通过 用户名 获取疑似用户 无数据权限
 * @param {Object} params
 * @returns
 */
    listWithoutPermission: params => request.get('/crm-service/crm/crmInHospitalApply/listWithoutPermission', { params }),
    /**
    * 患者存在，分配给其他用户
    * @param {Object} params
    * @returns
    */
    CrmPatientAssign: params => request.post('/crm-service/crm/CrmPatientAssign/assign', params),
    /**
     * 患者存在，分配给当前用户患者
     * @param {Object} params
     * @returns
     */
    assignAndUpdateCreate: params => request.post('/crm-service/crm/crmPatient/assignAndUpdateCreate', params),
    /**
   * 编辑信息并重新匹配
   * @param {Object} params
   * @returns
   */
    crmReMatch: params => request.put('/crm-service/crm/crmInHospitalApply/editAndReMatch', params),
    /**
 * 获取用户基本信息
 * @param {Object} params
 * @returns
 */
    crmGetUserInfo: params => request.get('/crm-system/sys/user/getUserInfo', { params }),

    /**
   * 编辑个人信息
   * @param {Object} params
   * @returns
   */
    crmEditUserInfo: params => request.put('/crm-system/sys/editUserInfo', params),
    /**
* 修改密码
* @param {Object} params
* @returns
*/
    changePassword: params => request.put('/crm-system/sys/user/updatePassword', params),
    /**
     * 修改患者
     * @param {Object} params
     * @returns
     */
    crmPatientEdit: params => request.put('/crm-service/crm/crmPatient/edit', params),
    /**
     * ORC 识别
     * @param {FormData} fd = { file type: idcard|medical}
     * @returns
     */
    crmOCR: fd => {
        return request({
            url: '/crm-service/crm/crmPatient/ocr',
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: fd
        })
    },
    /**
     * 通过 ID 获取用户详情
     * @param {String} id
     * @returns
     */
    crmPatientQueryById: id => request.get('/crm-service/crm/crmPatient/queryById', { params: { id } }),
    /**
     * 查询最新住院数据
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    hospitalLastByPatientId: id => request.get('/crm-service/crm/crmInHospitalApply/queryLastByPatientId', { params: { patientId: id } }),
    /**
      * 通过 ID 获取用户解密信息
      * @param {String} id
      * @returns
      */
    crmDecodeById: id => request.get('/crm-service/crm/crmPatient/decodeById', { params: { id } }),
    // /crm-system/sys/mobileMenu/authMenuList
    /**
     * 通过 roleId 获取用户菜单权限
     * @param {String} roleId
     * @returns
     */
    authMenuList: roleId => request.get('/crm-system/sys/mobileMenu/authMenuList', { params: { roleId } }),
    /**
    * 联系人添加
    * @param {Object} data
    * @returns
    */
    crmContactAdd: data => request.post('/crm-service/crm/crmPatientContact/add', data),
    /**
   * 修改联系人
   * @param {Object} data
   * @returns
   */

    crmContactEdit: data => request.put('/crm-service/crm/crmPatientContact/edit', data),
    /**
        * 联系人添加
        * @param {Object} data
        * @returns
        */
    crmContactDelete: id => request.delete('/crm-service/crm/crmPatientContact/delete', { params: { id } }),
    /**
    * 检验列表
    * @param {Object} params
    * @returns
    */
    crmCmsJyreportlist: params => request.get('/crm-service/crm/crmCmsJyreportlist/', { params }),
    /**
     * 患者入院申请记录创建
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    patientApplyRecordsCreate: params => request.post('/crm-service/crm/patientApplyRecords/create', params),
   /**
     * 会诊医生校验
     * @param {*} params
     * @returns
     */
   queryOneBy: params => request.get('/crm-service/crm/crmInHospitalApply/queryOneBy', { params}),
   /**
     * 同步
     * @param {*} id
     * @returns
     */
   crmPatientHomeHis: sfzh => request.get('/crm-service/crm/crmSyjbk/sysHisBysfzh', { params: { sfzh } }),

    /**
     * 病史详情
     * @param {*} id
     * @returns
     */
    crmContactDetail: id => request.get('/crm-service/crm/crmPatientContact/queryById', { params: { id } }),

    /**
     * 病史添加
     * @param {Object} data
     * @returns
     */
    crmMedicalHistoryAdd: data => request.post('/crm-service/crm/crmMedicalHistory/add', data),
    /**
     * 修改病史
     * @param {Object} data
     * @returns
     */
    crmMedicalHistoryEdit: data => request.post('/crm-service/crm/crmMedicalHistory/edit', data),
    /**
     * 病史详情
     * @param {*} id
     * @returns
     */
    crmMedicalHistoryDetail: id => request.get('/crm-service/crm/crmMedicalHistory/queryById', { params: { id } }),
    /**
     * 病史列表
     * @param {Object} params
     * @returns
     */
    crmMedicalHistoryList: params => request.get('/crm-service/crm/crmMedicalHistory/list', { params }),
    /**
     * 自填病史列表
     * @param {Object} params
     * @returns
     */
    crmMedicalRecordList: params => request.get('/crm-service/crm/crmMedicalRecord/list', { params }),

    /**
     * 入院申请列表
     * @param {Object} params
     * @returns
     */
    crmInHospitalApplyList: params => request.get('/crm-service/crm/crmInHospitalApply/list', { params }),
    /**
     * 创建入院申请
     * @param {Object} data
     * @returns
     */
    crmInHospitalApplyAdd: data => request.post('/crm-service/crm/crmInHospitalApply/addFromPatientAudit', data),
    /**
     * 入院申请编辑
     * @param {Object} data
     * @returns
     */
    crmInHospitalApplyEdit: data => request.put('/crm-service/crm/crmInHospitalApply/edit', data),
    /**
     * 入院申请详情
     * @param {ID} id
     * @returns
     */
    crmInHospitalApplyDetail: id => request.get('/crm-service/crm/crmInHospitalApply/queryById', { params: { id } }),
    /**
     * 取消入院
     * @param {*} id
     * @returns
     */
    crmInHospitalApplyCancel: id => request.post('/crm-service/crm/crmInHospitalApply/cancel', { id }),
    /**
     * 我的消息列表
     * @param {*} params
     * @returns
     */
    getMyAnnouncementSendList: params => request.get('/crm-system/sys/sysAnnouncementSend/getMyAnnouncementSend', { params }),
    /**
     * 消息已读
     * @param {String} anntId
     * @returns
     */
    readMsg: anntId => request.put('/crm-system/sys/sysAnnouncementSend/editByAnntIdAndUserId', { anntId }),
    /**
     * 患者备注-添加
     * @param {Object} params
     * @returns
     */
    crmRemarksAdd: params => request.post('/crm-service/crm/crmRemarks/add', params),
    /**
* 获取患者备注列表
* @param {Object} params
* @returns
*/
    crmRemarksList: params => request.get('/crm-service/crm/crmRemarks/list', { params }),
    /**
   * 患者备注-处理
   * @param {Object} data
   * @returns
   */
    crmProcessEdit: data => request.put('/crm-service/crm/crmRemarks/process', data),
    /**
* 获取某个dict
* @param {*} dictCodeList
* @returns
*/
    dictItemList: params => request.get('/crm-system/sys/dictItem/list', { params }),

    /**
     * 批量获取dict
     * @param {*} dictCodeList
     * @returns
     */
    ajaxGetAllDictItems: dictCodeList => request.get('/crm-system/sys/api/getManyDictItems?dictCodeList=' + dictCodeList.join(',')),
    /**
     * 获取远端字典库
     * @param {dict code} code
     * @param {*} params
     * @returns
     */
    ajaxGetDictItems: (code, params) => request.get(`/crm-system/sys/api/dictCodeList?dictCode=${code}`),
    /**
     * 获取本地字典库
     * @param {dict code} dictCode
     * @returns
     */

    crmSmsTemplateByOrgCodeList ( params ) {
        return request({
            url: '/crm-service/crm/crmSmsTemplate/listByOrgCode',
            method: 'GET',
            params: params
        })
    },


    /**
     *短信模板选择
     */
    crmSmsTemplateInfo: id => request.get(`/crm-service/crm/crmSmsTemplate/queryById?id=${id}`),
    /**
     *短信模板内容
     */

    getDictItemsFromCache(dictCode) {
        const that = this;
        let newItems = [];
        let dictItems = [];
        if (Vue.ls.get(TYPES.UI_CACHE_DB_DICT_DATA) && Vue.ls.get(TYPES.UI_CACHE_DB_DICT_DATA)[dictCode]) {
            if (dictCode == 'history_hospital') {
                that.newItems = Vue.ls.get(TYPES.UI_CACHE_DB_DICT_DATA)[dictCode];
                that.dictItems = that.newItems.filter(item => item.value != "上海好望角医院");
            } else {
                that.dictItems = Vue.ls.get(TYPES.UI_CACHE_DB_DICT_DATA)[dictCode];
            }

            return that.dictItems;
        }
    },
    /** 通过code获取字典数组 */
    async getDictItems(dictCode, params) {
        //优先从缓存中读取字典配置
        if (CRM_API.getDictItemsFromCache(dictCode)) {
            let desformDictItems = CRM_API.getDictItemsFromCache(dictCode).map(item => ({ ...item, label: item.text }))
            return desformDictItems;
        }

        //缓存中没有，就请求后台
        return await CRM_API.ajaxGetDictItems(dictCode, params).then(({ success, result }) => {
            if (success) {
                let res = result.map(item => ({ ...item, label: item.text }))
                return Promise.resolve(res)
            } else {
                console.error('getDictItems error: : ', res)
                return Promise.resolve([])
            }
        }).catch((res) => {
            console.error('getDictItems error: ', res)
            return Promise.resolve([])
        })
    },
    /**
     * 获取分类字典
     * @param {String} code
     * @returns
     */
    async loadCategoryDict(code) {
        const all = (await request.get(`/crm-system/sys/category/childList?code=` + code)).result;
        const allKey = all.map(item => item.id);
        let rootList = all.filter(v => !allKey.includes(v.pid));
        const arr = []
        rootList.map(f => {
            if (["上海市", "江苏省", "浙江省", "安徽省", "北京市", "山东省", "福建省", "四川省", "湖南省", "天津市", "重庆市"].includes(f.name)) {
                arr.unshift(f);
            } else {
                arr.push(f);
            }
        });
        const tree = await CRM_API.buildTreeData(arr, all);

        Vue.ls.set(`${TYPES.UI_CACHE_DB_DICT_DATA}_${code}`, { all, tree }, 7 * 24 * 60 * 60 * 1000)
        return {
            all,
            tree
        }
    },
    getCategoryDictItemsFromCache(dictCode) {
        const res = Vue.ls.get(`${TYPES.UI_CACHE_DB_DICT_DATA}_${dictCode}`)
        if (!!res && !!res.all) {
            return res;
        }
    },
    buildTreeData: function (root, all) {
        let list = [];
        root.map(v => {
            let children = this.buildTreeData(all.filter(item => item.pid == v.id), all);
            // const arr = []
            // children.map(f => {
            //     if (["上海市", "江苏省", "浙江省", "安徽省", "北京市", "山东省", "福建省", "四川省", "湖南省", "天津市", "重庆市"].includes(f.text)) {
            //         arr.unshift(f);
            //     } else {
            //         arr.push(f);
            //     }
            // });
            const info = { text: v.name, value: v.id }
            if (children.length > 0) {
                info.children = children;
            }
            if (["上海市", "江苏省", "浙江省", "安徽省", "北京市", "山东省", "福建省", "四川省", "湖南省", "天津市", "重庆市"].includes(info.text)) {
                list.unshift(info)
            } else {
                list.push(info)
            }

        })
        return list;
    },
    async getCategoryDict(dictCode) {
        //缓存中没有，就请求后台
        return CRM_API.loadCategoryDict(dictCode)
    },
    // 患者自建档管理
    async getArchiveList(params) {
        return request.get('/jeecg-healthhome/archive/patient/csm-list', { params: params })
    },
    // 删除 患者自建档
    async cmsDeleteArchive(params) {
        return request.posst('/jeecg-healthhome/archive/patient/csm-delete', params)
    },
    // 患者自建档详情
    async cmsInfo(params) {
        return request.get('/jeecg-healthhome/archive/patient/cms-info', { params: params })
    },
    // 患者自建档修改
    async cmsUpdate(params) {
        return request.post('/jeecg-healthhome/archive/patient/csm-update', params)
    }
}

export {
    CRM_API,
    API_URL
}

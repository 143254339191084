<template>
	<div class="normal-form">
		<div class="form-label"
			v-if="model.label">
			<span v-if="model.required"
				class="label-required">*</span>
			{{ model.label }}
		</div>
		<div :class="['input-value normal-upload', error ? 'error' : '']">
			<van-uploader preview-size="120"
				accept="image/*"
				:max-count="model.limit||1"
				:after-read="afterRead"
				v-model="fileList"
				@delete="removeImg" />
		</div>
		<div class="error-tip"
			v-if="errorTip && error">{{ errorTip }}</div>
	</div>
</template>

<script>
	import validateMIxins from "@/utils/validate-mixins.js";
	import api from "@/request/api";
	import { mapState } from "vuex";
	import ImageCompressor from "@/utils/imageCompressor";
	import heic2any from "heic2any";
	const FILEURL = "/jeecg-healthhome/healthhome-common/static/";
	export default {
		name: "normal-upload",
		mixins: [validateMIxins],
		props: {
			model: {
				type: Object,
				default: () => {
					return {
						label: "",
						placeholder: ""
					};
				}
			},
			value: [String, Number, Array, null]
		},
		computed: {
			...mapState(["userInfo"])
		},
		watch: {
			inputValue: {
				handler: function (val) {
					console.log('inputValue', val, this.fileList)
					if (val.length < this.fileList.length) {
						this.inputValue = this.fileList.map(i => i.url)
					}
				},
				deep: true
			}
		},
		data () {
			return {
				fileList: [],
				errorTip: "",
				error: false,
				showPicker: false,
				inputValue: []
			};
		},
		created () {
			console.log('created')
		},
		mounted () {
			this.init();
		},
		methods: {
			getJpgFile (file) {
				return new Promise((resolve, reject) => {
					const fileName = file.name;
					if (fileName.toLowerCase().endsWith(`.heic`)) {
						heic2any({
							blob: file,
							toType: "image/jpg"
						}).then(resultBlob => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime()
							});
							resolve(file);
						}).catch(e => {
							reject(e);
						});
					} else {
						resolve(file);
					}
				});
			},
			uploadYBK (targetFile) {
				const self = this;
				this.getJpgFile(targetFile.file).then(file => {
					const options = {
						file: file,
						quality: 1,
						mimeType: "image/jpeg",
						maxWidth: 2000,
						maxHeight: 2000,
						width: 1000,
						height: 1000,
						minWidth: 500,
						minHeight: 500,
						convertSize: Infinity,
						loose: true,
						redressOrientation: true,

						// 压缩前回调
						beforeCompress: function (result) {
							console.log("压缩之前图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
						},

						// 压缩成功回调
						success: function (result) {
							targetFile.status = "uploading";
							targetFile.message = "上传中...";
							api.uploadFile({
								file: new File([result], result.name + ".jpg", {
									type: "image/jpg",
									lastModified: new Date().getTime()
								})
							}, self.userInfo ? self.userInfo.phone || "images" : "images").then(resp => {
								self.inputValue = [
									...self.inputValue,
									`${window.location.origin}${FILEURL}${resp.message}`
								];
								self.fileList = self.inputValue.map(i => {
									return {
										url: i
									}
								})
								targetFile.status = "done";
								targetFile.message = "上传成功";
								self.changeValue();
							}).catch(e => {
								file.status = "failed";
								file.message = "上传失败";
							});
						},

						// 发生错误
						error: function (msg) {
							console.error(msg);
						}
					};
					new ImageCompressor(options);
				});
			},
			afterRead (file) {
				if (file.file.size > 10 * 1024 * 1024) {
					this.$toast.fail("您选择的图片过大，请选择10Mb已内的图片上传。");
				} else if (file.file.size > 2 * 1024 * 1024) {
					this.uploadYBK(file);
				} else {
					file.status = "uploading";
					file.message = "上传中...";
					api.uploadFile(file, this.userInfo ? this.userInfo.phone || "images" : "images")
						.then(resp => {
							this.inputValue = [
								...this.inputValue,
								`${window.location.origin}${FILEURL}${resp.message}`
							];
							this.fileList = this.inputValue.map(i => {
								return {
									url: i
								}
							})
							file.status = "done";
							file.message = "上传成功";
							this.changeValue();
						})
						.catch(e => {
							file.status = "failed";
							file.message = "上传失败";
						});
				}
			},
			init () {
				console.log('upload-init', this.value)
				if (this.value) {
					this.inputValue = Array.isArray(this.value) ? this.value : [this.value];
				} else {
					this.inputValue = [];
				}
			},
			changeValue () {
				this.resetValidate();
				this.$emit("input", Array.isArray(this.inputValue) ? this.inputValue.join(",") : this.inputValue, this.model);
			},
			removeImg (file, detail) {
				this.inputValue.splice(detail.index, 1);
				this.fileList = this.inputValue.map(i => {
					return {
						url: i
					}
				})
				this.changeValue();
			},
			handleClick () {
				this.resetValidate();
				this.showPicker = true;
				this.$nextTick(() => {
					if (this.model.name === "residence_address") {
						if (!Array.isArray(this.inputValue) && this.inputValue) {
							this.$refs.vanPickerRef.setValues(this.inputValue.split(","));
						}
					}
				});
			}
		}
	};
</script>

<style scoped lang="less">
	@import url("~@/variables.less");
	@import url("~@/normal-form.less");

	.normal-form {
		.input-value {
			display: flex;
			justify-content: center;
			width: 100%;
			padding: 0;

			&.normal-upload {
				justify-content: flex-start;
			}

			.input-value-cell-body {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid @font11;
				padding-left: 0;
				padding-right: 0;
				background-color: @bg6;
				padding: 0 10px;
				border-radius: 3px;

				&:focus {
					border-bottom: 1px solid @font7;
				}
			}

			.icon-down {
				display: inline-block;
				width: 32px;
				height: 32px;
				background: url("~@/assets/crm/icon/icon-down.png") no-repeat center center/contain;
			}

			input {
				background: none;
				border: none;
				box-shadow: none;
				padding: 10px;
				text-align: left;
				display: block;
				width: 100%;
				min-width: 240px;
				font-size: 32px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				line-height: 60px;
				color: @font1;
				caret-color: @font1;

				&::placeholder {
					.placeholder-font;
					color: @font3;
					font-size: 26px;
					opacity: 0.7;
					text-align: left;
				}
			}
		}
	}
</style>

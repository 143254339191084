<template>
	<div class="history-list">
		<van-sticky>
			<div class="list-header">我的提交记录</div>
		</van-sticky>
		<van-cell-group>
			<template v-for="item in list">
				<van-cell :key="item.id"
									@click="linkTo(item)"
									:is-link="dayjs(item.submitDate).add(effective, 'hour').valueOf() >= dayjs().valueOf()">
					<template #title>
						<van-tag type="danger"
										 plain
										 v-if="+item.status === 0">异常</van-tag>
						<van-tag type="success"
										 plain
										 v-else>正常</van-tag>
						<span class="item-name">
							{{ item.name }}
						</span>
					</template>
					<template #default>
						<template v-if="dayjs(item.submitDate).add(effective, 'hour').valueOf() >= dayjs().valueOf()">
							失效时间:
							<span class="lose">
								{{ dayjs(item.submitDate).add(effective, 'hour').format('MM-DD HH:mm') }}
							</span>
						</template>
						<template v-else>过期</template>
					</template>
				</van-cell>
			</template>
		</van-cell-group>
	</div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import api from "@/request/api";
import crmMixins from "@/utils/crm-mixins";
export default {
	mixins: [crmMixins],
	computed: {
		...mapState(["investSurvey", "userInfo"]),
		baseinfo: function () {
			return (this.investSurvey.questions || []).find(i => i.type === 'baseinfo') || { questions: [] }
		},
		effective: function () {
			return this.investSurvey.effective || 72
		}
	},
	data () {
		return {
			dayjs,
			list: []
		}
	},
	created () {
		if (this.investSurvey && this.investSurvey.questions.length) {
			this.init()
		} else {
			this.$router.push({
				path: "/investigation",
				query: {
					...this.$route.query,
				}
			})
		}
	},
	methods: {
		init () {
			this.showLoading();
			api.epidemiologicalIndex().then(resp => {
				this.list = (resp.result || []).reverse();
				this.hideLoading();
			}).finally(() => this.hideLoading())
		},
		linkTo (item) {
			if (dayjs(item.submitDate).add(this.effective, 'hour').valueOf() >= dayjs().valueOf()) {
				this.$store.dispatch('set_invest_value', {
					...item,
				});
				this.$router.push({
					path: '/investigation/result',
					query: {
						...this.$route.query,
						investigationId: item.id
					}
				})
			}
		}
	}
};
</script>

<style lang="less">
@import url("~@/variables.less");

.history-list {
	text-align: left;

	.list-header {
		text-align: center;
		box-shadow: 0px 1px 8px 1px #e1dfdf;
		height: 100px;
		font-size: 32px;
		border-bottom: 2px solid @bg5;
		color: @bg5;
		line-height: 100px;
		background-color: #ffffff;
	}

	>.van-cell {
		font-size: 26px;
		// padding: 0;
	}

	.van-cell__title {
		display: flex;
		align-content: center;

		.item-name {
			margin-left: 10px;
			font-size: 28px;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>

<template>
    <div>

        <van-dialog v-model="localDialog" title="相同姓名患者信息" confirm-button-text="关闭" @confirm="submitHistory">

<div class="patientInfo">
    <div v-if="nameInfoList.length == 0" class="pb-16">
        <van-empty description="暂无任何相同姓名患者信息"> </van-empty>
    </div>
    <div class="crm-card pb-0 mb-16 crm-ys" v-for="(item, index) in nameInfoList" :key="index">
        <div class="crm-card-title">
            {{ item.name }}
            <span class="sex"><SexIcon :sex="item.sex" /></span>
            <span class="age">{{ item.age }}岁</span>
            <span class="age"> <van-button @click="onImport(item)" plain type="info" round size="mini">一键建档</van-button> </span>
        </div>
        <div class="crm-card-body">
            <div class="crm-card-info">
                <p><strong>电话号码：</strong>{{ item.cellphone | arrPhone }}</p>
                <p><strong>转诊医院</strong>{{ item.zzHospital == "other" ? item.zzHospitalOther : item.zzHospital }}</p>

                <p><strong>身份证号码：</strong>{{ desensitizeIdentityCard( item.idCard) }}&nbsp;</p>
            </div>
        </div>
    </div>
</div>
</van-dialog>
    </div>
</template>
<script>
import crmMixins from "@/utils/crm-mixins";
import SexIcon from "@/components/CRM/SexIcon";
export default {
	name: "crm-select",
    mixins: [crmMixins],
    components: { SexIcon },
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
        NameInfo:{
            type: Array,
			default() {
				return [];
			}
        }

	},
	data() {
		return {
			text: "",
			showPicker: false,
            localDialog: this.showDialog

		};
	},
    computed: {
    nameInfoList() {
      // 计算属性会返回 props 中的 NameInfo
      return this.NameInfo;
    }
  },
  watch: {
    // 监听 prop 的变化，并更新本地数据
    showDialog(newVal) {
      this.localDialog = newVal;
    }
  },
	methods: {
        desensitizeIdentityCard(card) {
      // 判断是否为空或长度不正确
      if (!card || card.length !== 18) {
        return card || '无效身份证号码';
      }

      // 脱敏处理，保留前6位和后4位，中间用星号代替
      const maskedCard = card.substr(0, 6) + '********' + card.substr(14);
      return maskedCard;
    },
        submitHistory() {
			// Notify({ type: "success", message: "关闭" });
		},
        onImport(info) {
			let vm = this;
			//将ysFrom.inHosTime传给患者
			//弹窗
			this.$dialog
				.confirm({
					title: "确认获取患者管理权限",
                    allowHtml:true,
                    messageAlign:"left",
					message:'<p class="name-dialog">1、该途径建档的权限同自己建档请确认患者已转至您负责的院区。\n2、系统会记录该次操作</p>',
				})
				.then(() => {
                    this.$emit('data-sent', info);
				})
				.catch(() => {
					// on cancel
				});
		},
	}
};
</script>
<style lang="less">
.name-dialog{
    line-height: 1.5rem;
}
</style>
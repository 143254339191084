<template>
	<div class="crm-bg crm-apply crm-apply-show">
		<van-form ref="formRef1">
			<div class="crm-card mb-80" v-if="step === 1">
				<div class="crm-card-title">
					<div class="crm-card-title__flex">转诊信息</div>
					<span class="crm-card-cancel" v-if="type != 'edit'" @click="loadZZInfo">填充上一次信息</span>
				</div>
				<div class="crm-card-body">
					<CRMRadio
						v-model="apply.applyType"
						name="applyType"
						label="申请形式"
						:options="apply_type"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择申请形式' }]"
					/>
					<CRMSelect
						v-if="!(apply.applyType == 'oneself')"
						v-model="apply.zzHospital"
						name="zzHospital"
						:label="`${apply.applyType == 'again' ? '(原)' : ''}转诊医院`"
						:attr="{ required: true }"
						placeholder="请选择转诊医院"
						:readonly="type == 'edit' && zzName!= '转诊信息'"
						:columns="history_hospital"
						:rules="[{ required: true, message: '请选择转诊医院' }]"
					/>
					<van-field
						v-if="apply.zzHospital === 'other' && !(apply.applyType == 'oneself')"
						v-model="apply.zzHospitalOther"
						name="zzHospitalOther"
						label="其他转诊医院"
						:readonly="type == 'edit' && zzName!= '转诊信息'"
						required
						placeholder="请输入其他转诊医院"
					/>
					<CRMSelect
						v-if="!(apply.applyType == 'oneself')"
						v-model="apply.zzDepartment"
						name="zzDepartment"
						:label="`${apply.applyType == 'again' ? '(原)' : ''}转诊科室`"
						:attr="{ required: true }"
						placeholder="请选择科室"
						:columns="history_department"
						:readonly="type == 'edit' && zzName!= '转诊信息'"
						:rules="[{ required: true, message: '请选择科室' }]"
					/>
					<van-field
						v-if="apply.zzDepartment === 'other' && !(apply.applyType == 'oneself')"
						v-model="apply.zzDepartmentOther"
						name="zzDepartmentOther"
						:readonly="type == 'edit' && zzName!= '转诊信息'"
						label="其他转诊科室"
						placeholder="请输入其他转诊科室"
					/>
					<van-field
						v-if="!(apply.applyType == 'oneself')"
						v-model="apply.consultationDoctor"
						required
						:rules="[{ required: true, message: '请填写会诊医生' }]"
						@blur="getConsultationDoctor()"
						name="consultationDoctor"
						label="会诊医生"
						placeholder="请输入会诊医生"
					/>

					<CRMSelect
						v-model="apply.zzDiagnosisCategory"
						name="zzDiagnosisCategory"
						label="入院目的"
						:attr="{ required: true }"
						placeholder="请选择入院目的"
						:columns="diagnosis_category"
						:rules="[{ required: true, message: '请选择入院目的' }]"
					/>
					<van-field
						v-if="apply.zzDiagnosisCategory === 'other'"
						v-model="apply.zzDiagnosisCategoryOther"
						name="zzDiagnosisCategoryOther"
						label="其他入院目的"
						placeholder="请输入其他入院目的"
					/>
					<CRMSelect
						v-model="apply.nsInTime"
						name="zzDiagnosisCategory"
						label="入院时间"
						:attr="{ required: true }"
						placeholder="请选择入院时间"
						:columns="inHospital_time"
						:rules="[{ required: true, message: '请选择入院时间' }]"
					/>
					<CRMSelect v-model="apply.nsWard" label="入院病区" placeholder="请选择入院病区" :columns="ns_ward" />
					<CRMDate
						v-model="apply.zzPlanInDate"
						name="zzPlanInDate"
						label="拟入院日"
						:attr="{ required: true }"
						placeholder="请选择拟入院日"
						:readonly="type == 'edit' && apply.status !== 'WAIT-IN'"
						:rules="[{ required: true, message: '请选择拟入院日' }]"
            @change="changeZzPlanInDate"
					/>
					<CRMRadio
						v-model="apply.zzInType"
						name="zzInType"
						label="入院方式"
						:options="in_hos_type"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择入院方式' }]"
					/>
					<CRMRadio
						v-model="apply.zzPlanInArea"
						name="zzPlanInArea"
						label="拟入院区"
						:options="crm_hospital_area"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择拟入院区' }]"
					/>
					<CRMRadio
						v-model="apply.zzBedReq"
						name="zzBedReq"
						label="床位要求"
						:options="bed_req"
						direction="vertical"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择床位要求' }]"
					/>
				</div>
			</div>
		</van-form>
		<van-button v-if="step === 1" class="crm-btn mb-80" :loading="loading" block @click="submitApply">
			下一步<span class="btn-icon icon-next"></span>
		</van-button>
		<van-form ref="formRef2">
			<div class="crm-card" v-if="step === 2">
				<div class="crm-card-title">
					<div class="crm-card-title__flex">病史概要</div>
					<span class="crm-card-cancel" v-if="type != 'edit'" @click="loadMedicalInfo">填充患者病史</span>
				</div>
				<div class="crm-card-body">
					<CRMCheckbox
						v-model="history.withGuan"
						name="withGuan"
						:isRequired="false"
						label="带管情况（多选）"
						:options="history_guan"
						:rules="[{ required: true, message: '请选择转带管情况' }]"
					/>
					<van-field
						v-if="(history.withGuan || '').indexOf('other') > -1"
						v-model="history.withGuanOther"
						name="withGuanOther"
						class="mt-10"
						label="其他"
						placeholder="请输入其他带管情况"
					/>
				</div>
			</div>
			<div class="crm-card" v-if="step === 2">
				<div class="crm-card-body" style="margin-bottom: 8px">
					<CRMTextarea
						label="出院诊断"
						:rows="3"
						:attr="{ maxlength: 1000, 'show-word-limit': true }"
						v-model="history.outRemarks"
						placeholder="请输入诊断内容......"
					>
						<van-icon slot="label" class="ml-10" name="add-o" @click="addOutRemarks" />
					</CRMTextarea>
					<CRMTextarea
						v-model="outRemarks[item]"
						type="tel"
						class="mt-20"
						:name="`outRemarks-${item}`"
						:label="`出院诊断${index + 1}`"
						placeholder="请输入出院诊断"
						:attr="{ maxlength: 1000, 'show-word-limit': true }"
						:rows="4"
						@blur="outRemarksBlur($event, item)"
						v-for="(item, index) in Object.keys(outRemarks)"
						:key="index"
					>
						<van-icon slot="label" name="close" @click="subOutRemarks(item)" />
					</CRMTextarea>
				</div>
			</div>
			<div class="crm-card" v-if="step === 2">
				<div class="crm-card-body">
					<div class="mb-24">
						<van-checkbox-group v-model="picType" direction="horizontal" @change="selectCheckbox">
							<van-checkbox checked-color="#5f5aa3" name="1">出院小结</van-checkbox>
							<van-checkbox checked-color="#5f5aa3" name="2">门诊信息</van-checkbox>
						</van-checkbox-group>
					</div>
					<!-- <div class="crm-upload"> -->
					<!-- afterRead -->
					<!-- <van-uploader v-model="fileList" :after-read="afterRead" @delete="removeImg" /> -->
					<!-- </div> -->
					<div class="crm-upload">
						<!-- <van-uploader v-model="fileList" :after-read="afterRead" @delete="removeImg" /> -->
						<van-uploader multiple v-model="fileList" :after-read="uploadSFZ" @delete="removeImg" />
					</div>
				</div>
			</div>
			<div class="crm-card" v-if="step === 2 && apply.id == undefined">
				<div class="crm-remark-show">
					<div class="crm-card-title">是否有特别注意事项？</div>
					<CRMRadio
						@change="selectRadio"
						v-model="remarksRadio"
						name="remarksRadio"
						label1="测试"
						style="width: unset"
						:options="remarks_show"
						direction="horizontal"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择是否' }]"
					/>
				</div>

				<!-- <div class="mb-24">
					<CRMRadio
					@change="selectRadio"
						v-model="remarksRadio"
						name="remarksRadio"
						label1="测试"
						:options="remarks_show"
						direction="horizontal"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '请选择是否' }]"
					/>
				</div> -->
				<div class="crm-card-body" v-if="remarksShow">
					<CRMSelect
						v-model="form.emergencyLevel"
						name="emergencyLevel"
						label="紧急程度"
						:readonly="false"
						:attr="{ required: true }"
						placeholder="选择紧急程度"
						:columns="emergency_level"
						:rules="[{ required: true, message: '请选择紧急程度' }]"
					/>

					<van-field
						v-if="form.contentType == '4'"
						v-model="form.contact"
						required
						name="contact"
						label1="最新联系方式"
						placeholder="最新联系方式"
						:rules="[
							{ required: true, message: '请填写联系电话' },
							{ pattern: RULES.contactDr, message: '请输入正确的联系电话' },
						]"
					/>

					<CRMTextarea
						label1="内容详细描述"
						:rows="3"
						required
						:attr="{ maxlength: 1000, 'show-word-limit': true, required: true }"
						v-model="form.remarks"
						name="remarks"
						:rules="[{ required: true, message: '请填写内容详细描述' }, ,]"
						placeholder="内容详细描述"
					/>
					<div class="crm-upload">
						<span class="crm-upload-title">照片：</span>
						<van-uploader multiple :max-count="6" v-model="remarksList" :after-read="uploadRemarks" @delete="removeRemarks" />
					</div>
				</div>
			</div>

			<div class="crm-card" v-if="step === 2 && apply.id == undefined">
				<div class="crm-remark-show" v-if="this.apply.zzPlanInDate != 0">
					<div class="crm-card-title">发送入院短信提醒</div>
					<CRMRadio
						v-model="apply.isSendSms"
						name="isSendSms"
						:options="[
							{ value: 1, text: '是' },
							{ value: 2, text: '否' },
						]"
						style="width: unset"
						direction="horizontal"
						:attr="{ required: true }"
						:rules="[{ required: true, message: '发送入院短信提醒' }]"
					/>
				</div>
				<div class="crm-message-body" v-if="apply.isSendSms == 1">
					<CRMSelect
						v-model="form.messModel"
						name="messModel"
						label="模板选择"
						:readonly="false"
						:attr="{ required: true }"
						placeholder="选择模板"
						:columns="objiectList"
						@change="getTemplateInfo"
					/>
					<CRMTextarea :rows="3" v-model="textarea" @input="setSmsContentFn" />
				</div>
			</div>

			<!--
			<div class="crm-card" v-if="step === 2 && remarksShow && apply.id == undefined">
				<div class="crm-card-title">照片信息</div>
				<div class="crm-card-body">
					<div class="crm-upload">
						<span class="crm-upload-title">头像：</span>
						<van-uploader v-model="remarksList" :after-read="uploadRemarks" @delete="removeRemarks" />
					</div>
				</div>
			</div> -->
			<!-- 患者备注 -->
		</van-form>
		<div class="crm-btn-inline mb-32" v-if="step == 2">
			<van-button class="crm-round-btn" :loading="loading" block @click="prev"> 上一步<span class="btn-icon icon-prev"></span> </van-button>
			<van-button class="crm-btn" :loading="loading" block @click="openDialog">提交</van-button>
		</div>
		<van-dialog
			v-model="show"
			title="告知书确认"
			confirm-button-text="立即提交入院申请"
			:showConfirmButton="to_user_know.length == know_list.length"
			:show-cancel-button="to_user_know.length != know_list.length"
			@confirm="submitHistory"
		>
			<div class="user-know-box">
				<van-checkbox-group v-model="know_list">
					<p v-for="(item, index) in to_user_know" :key="index">
						<van-checkbox checked-color="#5f5aa3" :name="item.title">{{ item.title }}</van-checkbox>
					</p>
				</van-checkbox-group>
			</div>
		</van-dialog>
		<van-dialog v-model="showOldInfo" title="上一次转诊信息" confirm-button-text="填充" show-cancel-button @confirm="oldInfoOk">
			<van-cell-group class="jh-cell-group">
				<van-cell title="转诊医院">
					{{ oldApplyInfo.zzHospital == "other" ? "其他" : oldApplyInfo.zzHospital_dictText }}
					{{ oldApplyInfo.zzHospitalOther ? `（${oldApplyInfo.zzHospitalOther}）` : "" }}
				</van-cell>
				<van-cell title="转诊科室">
					{{ (oldApplyInfo.zzDepartment == "other" ? "其他" : oldApplyInfo.zzDepartment_dictText) || "-" }}
					{{ oldApplyInfo.zzDepartmentOther ? `（${oldApplyInfo.zzDepartmentOther}）` : "" }}
				</van-cell>
				<van-cell title="会诊医生">{{ oldApplyInfo.consultationDoctor || "-" }}</van-cell>

				<van-cell title="入院方式">{{ oldApplyInfo.zzInType_dictText || "-" }}</van-cell>
				<van-cell title="拟入院区">{{ oldApplyInfo.zzPlanInArea_dictText || "-" }}</van-cell>
				<van-cell title="床位要求">{{ oldApplyInfo.zzBedReq_dictText || "-" }}</van-cell>
			</van-cell-group>
		</van-dialog>
		<CustomMedical ref="crmMedicalRecordModal" @ok="medicalRecordOk"></CustomMedical>
	</div>
</template>

<script>
import api from "@/request/api";
import crmMIxins from "@/utils/crm-mixins";
import { Notify, Dialog } from "vant";
import ImageCompressor from "@/utils/imageCompressor";
import CRMSelect from "@/components/CRM/Select";
import CRMRadio from "@/components/CRM/Radio";
import CRMDate from "@/components/CRM/SelectDate";
import CRMCheckbox from "@/components/CRM/Checkbox";
import CRMTextarea from "@/components/CRM/Textarea";
import ImgField from "@/components/CRM/ImgField";
import compressUploader from "@/components/CRM/imgUpload";
import customUpload from "@/components/form/custom-upload";
import CustomMedical from "@/views/CRM/Apply/medical";
import RULES from "@/constant/rules";
import apply from "@/views/CRM/Patient/apply";
import axios from "axios";
import moment from 'moment'

const gen = () => {
	return {
		zzHospital: "",
		zzHospitalOther: "",
		zzDepartment: "",
		zzDepartmentOther: "",
		consultationDoctor: "",
		zzInType: "",
		zzPlanInArea: "",
		zzBedReq: "",
	};
};

export default {
	name: "crm-apply-create",
	mixins: [crmMIxins],
	components: { CustomMedical, customUpload, compressUploader, CRMSelect, ImgField, CRMRadio, CRMDate, CRMCheckbox, CRMTextarea },
	data() {
		return {
			RULES,
			form: {
				contentType: "",
				emergencyLevel: "9",
				contact: "",
				images: "",
				remarks: "",
				patientId: "",
				messModel: "",
			},
			step: 1,
			remarksShow: false,
			messShow: false,
			loading: false,
			know_list: [],
			show: false,
			apply: {
				applyType: "normal",
				patientId: "",
				zzTreatmentCategory: "",
				zzPlanInDate: "",
				zzPlanInArea: "haowangjiao",
				zzInType: "",
				zzHospital: "",
				zzDiagnosisCategory: "",
				zzDepartment: "",
				zzBedReq: "",
				remarks: "",
				consultationDoctor: "",
				isSendSms: 2,
				smsContent: "",
				nsWard: "",
			},
			history: {
				pic: "",
				picType: "",
				outRemarks: "",
				withGuan: [],
			},
			remarksList: [],
			fileList: [],
			imgList: [],
			picList: [],
			outRemarks: {},
			applyId: "",
			picType: [],
			remarksRadio: "",
			type: "create",
			loadingBtn: false,
			showOldInfo: false,
			oldApplyInfo: gen(),
			objiectList: [],
			item: [],
			textarea: "",
			id: "",
			zzName:'',
		};
	},
	mounted() {
		this.apply.patientId = this.$route.params.id;
		this.applyId = this.$route.query.applyId;
		this.type = this.$route.query.type || "create";
		this.form.patientId = this.$route.params.id;
		if (!!this.applyId) {
			this.applyDetail(this.applyId);
		}
		this.zzName=this.$route.query.zzName
	},

	created() {
		console.log(this.$route.query, "打印参数");
		this.lodaData();
	},
	methods: {
    changeZzPlanInDate(val) {
      api.hospitalLastByPatientId(this.apply.patientId).then((res) => {
        const oldUnix = moment(res.result.outHosTime, 'YYYY-MM-DD HH:mm:ss').unix()
        const nowUnix = moment(val, 'YYYY-MM-DD').unix()

        if(res.result.outHosTime && nowUnix - oldUnix < 86400 * 7) {
          Dialog.confirm({
            title: '',
            message: '该患者距上次出院时间不足7天，请与医教部、个案部沟通后提交',
            confirmButtonText: '继续申请',
            cancelButtonText: '取消'
          }).then(() => {
            api.patientApplyRecordsCreate({type: 2, patientId: this.apply.patientId})
          }).catch(() => {
            this.apply.zzPlanInDate = ""
          })
        }
      })
    },
		medicalRecordOk(info) {
			let stringValue = info.withGuan;
			info.withGuan = stringValue.split(","); // ['PICC', 'AnotherValue']
			// 然后将这个数组传递给组件
			this.history = info;
		},
		loadMedicalInfo() {
			const vm = this;
			if (vm.apply.patientId) {
				api.crmDecodeById(vm.apply.patientId).then((res) => {
					if (res.success) {
						this.$refs.crmMedicalRecordModal.showHide(res.result.idCard);
					}
				});
			}
		},
		setSmsContentFn(value) {
			console.log(value, "123333");
			this.apply.smsContent = value;
		},
		lodaData() {
			api.crmSmsTemplateByOrgCodeList({ pageSize: 1000 }).then((res) => {
				console.log(res.result.records, "+++++++");
				this.objiectList = res.result.records.map((item) => {
					return { value: item.id, text: item.categroy_dictText };
				});
			});
		},

		getConsultationDoctor(val) {
			const that = this;
			api.queryOneBy({ consultationDoctor: that.apply.consultationDoctor }).then((res) => {
				if (res.success && !res.result) {
					Notify({ type: "danger", message: "您填写的医生不在数据库中，将作为新医生添加" });
				}
			});
		},
    getTemplateInfo(id){
      const that = this
      api.crmSmsTemplateInfo(id).then(res =>{
        let smsContent = res.result.content
        smsContent = smsContent.replaceAll('[NAME]', that.$route.query.patientName)
        smsContent = smsContent.replaceAll('[APPT_DATE]', that.apply.zzPlanInDate)
        smsContent = smsContent.replaceAll('[WARD]',that.apply.nsWard)
        that.setSmsContent = smsContent
        that.textarea = smsContent
        that.setSmsContentFn(smsContent)
      })
      // const data = {
      //   isSendSms: this.apply.isSendSms,
      //   content:this.textarea
      // };
      // axios.post('crmMedicalHistoryList',data).then(response =>{
      //   console.log("上传成功111")
      // })
      // .catch(error =>{
      //   console.error("上传失败11111",error)
      // })
    },
		getRemarks() {
			let vm = this;
			const fd = { ...this.form };
			fd.images = vm.picList.join(",");
			fd.contentType != "4" ? (fd.contact = null) : fd.contact;
			this.showLoading();
			api.crmRemarksAdd(fd)
				.then((res) => {
					this.hideLoading();
					if (res.success) {
						Notify({ type: "success", message: "患者备注添加成功！" });
					} else {
						Notify({ type: "danger", message: res.message });
					}
				})
				.catch(() => {
					this.hideLoading();
				});
		},
		selectRadio(e) {
			if (e == 1) {
				this.remarksShow = false;
			} else {
				this.remarksShow = true;
			}
		},

		selectMess(e) {
			if (e == 1) {
				this.messShow = true;
			} else {
				this.messShow = false;
			}
		},

		removeRemarks(file, detail) {
			this.picList.splice(detail.index, 1);
		},
		runRemarksUpload(fd) {
			fd.status = "uploading";
			fd.message = "上传中...";
			this.showLoading("上传中...");
			api.upload(fd).then((res) => {
				this.hideLoading();
				if (res.success) {
					fd.status = "done";
					fd.message = "上传成功";
					this.picList.push(res.message);
				} else {
					fd.status = "failed";
					fd.message = "上传失败";
				}
			});
		},
		uploadRemarks(files) {
			const self = this;
			if (files.length) {
				for (const k in files) {
					this.getJpgFile(files[k].file).then((file) => {
						const options = {
							file: file,
							quality: 1,
							mimeType: "image/jpeg",

							minWidth: 500,
							minHeight: 500,
							loose: true,

							// 压缩前回调
							beforeCompress: function (result) {
								console.log("压缩之前图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
							},

							// 压缩成功回调
							success: function (result) {
								var fd = new FormData();
								console.log("转换后:" + result.size);
								console.log("this.apply.patientId", self.apply.patientId);
								// fd.append("type", "idcard");
								fd.append("file", result);
								// fd.append("file", file.file);
								fd.append("biz", self.apply.patientId);
								console.log(result, "1");
								console.log("1fd", fd);
								console.log("fd.file", file);
								self.runRemarksUpload(fd);

								console.log("压缩之后图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
								console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
							},

							// 发生错误
							error: function (msg) {
								console.error(msg);
							},
						};
						new ImageCompressor(options);
					});
				}
			} else {
				this.getJpgFile(files.file).then((file) => {
					const options = {
						file: file,
						quality: 1,
						mimeType: "image/jpeg",

						loose: true,

						// 压缩前回调
						beforeCompress: function (result) {
							console.log("压缩之前图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
						},

						// 压缩成功回调
						success: function (result) {
							var fd = new FormData();
							console.log("转换后:" + result.size);
							console.log("this.apply.patientId", self.apply.patientId);
							// fd.append("type", "idcard");
							fd.append("file", result);
							// fd.append("file", file.file);
							fd.append("biz", self.apply.patientId);
							console.log(result, "1");
							console.log("1fd", fd);
							console.log("fd.file", file);
							self.runRemarksUpload(fd);

							console.log("压缩之后图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
							console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
						},

						// 发生错误
						error: function (msg) {
							console.error(msg);
						},
					};
					new ImageCompressor(options);
				});
			}
		},
		oldInfoOk() {
			const info = {
				zzHospital: this.oldApplyInfo.zzHospital,
				zzHospitalOther: this.oldApplyInfo.zzHospitalOther,
				zzDepartment: this.oldApplyInfo.zzDepartment,
				zzDepartmentOther: this.oldApplyInfo.zzDepartmentOther,
				consultationDoctor: this.oldApplyInfo.consultationDoctor,
				zzInType: this.oldApplyInfo.zzInType,
				zzPlanInArea: this.oldApplyInfo.zzPlanInArea,
				zzBedReq: this.oldApplyInfo.zzBedReq,
				content: this.oldApplyInfo.textarea,
        nsWard: this.oldApplyInfo.nsWard,
			};
			this.apply = { ...this.apply, ...info };

			this.oldInfoCancel();
		},
		oldInfoCancel() {
			this.oldApplyInfo = gen();
			this.showOldInfo = false;
		},
		loadZZInfo() {
			api.crmInHospitalApplyList({ patientId: this.apply.patientId, notHis: "true", column: "create_time", order: "desc" }).then((res) => {
				if (res.success && res.result.records.length > 0) {
					const d = res.result.records[0];
					this.oldApplyInfo = d;
					console.log("this.oldApplyInfo", this.oldApplyInfo);
					this.showOldInfo = true;
				}
			});
		},
		outRemarksBlur(e, item) {
			this.$forceUpdate();
		},
		addOutRemarks() {
			const len = Object.keys(this.outRemarks).length;
			if (len >= 4) {
				this.$toast.fail("最多允许5条出院诊断记录");
				return false;
			}
			this.outRemarks[(len + 1).toString()] = "";
			this.$forceUpdate();
		},
		subOutRemarks(key) {
			delete this.outRemarks[key];
			this.$forceUpdate();
		},
		selectCheckbox(e) {
			if (e.length > 1) {
				this.picType = e.slice(-1);
			} else {
				this.picType = e;
				this.history.picType = e[0] || "";
			}
		},
		prev() {
			this.step = 1;
		},
		applyDetail(id) {
			api.crmInHospitalApplyDetail(id).then((res) => {
				if (res.success) {
					const apply = { ...this.apply };
					const copyApply = {};
					for (let i in apply) {
						copyApply[i] = res.result[i];
					}
					if (this.type == "edit") {
						copyApply.status = res.result.status;
						copyApply.id = res.result.id;
					}
					this.apply = copyApply;
					if (this.type == "create") {
						delete this.apply.outHosTime;
						delete this.apply.outType;
						delete this.apply.outType_dictText;
					}

					this.$forceUpdate();
				}
			});
			api.crmMedicalHistoryList({ relationId: id, relationType: "hospital" }).then((res) => {
				if (res.success && res.result.records.length > 0) {
					const record = res.result.records[0];
					this.history.pic = record.pic;
					if (!!record.pic) {
						this.imgList = record.pic.split(",").map((v) => v);
						record.pic.split(",").map((v) => {
							this.fileList.push({
								url: api.getImage(v),
								isImage: true,
							});
							api.getImage(v);
						});
					}
					this.history.picType = record.picType;
					this.history.withGuan = record.withGuan.split(",");
					if (!!record.outRemarks && record.outRemarks.indexOf("[") === 0) {
						const outRemarks = JSON.parse(record.outRemarks);
						this.history.outRemarks = outRemarks[0];
						this.outRemarks = outRemarks.slice(1);
					} else {
						this.history.outRemarks = record.outRemarks;
					}

					if (record.picType) {
						this.picType = [record.picType];
					}

					if (this.type == "edit") {
						this.history.id = record.id;
					}

					this.$forceUpdate();
				}
			});
		},
		convertImgToBase64(url, callback, outputFormat) {
			var canvas = document.createElement("CANVAS"),
				ctx = canvas.getContext("2d"),
				img = new Image();
			img.crossOrigin = "Anonymous";
			img.onload = function () {
				canvas.height = img.height;
				canvas.width = img.width;
				ctx.drawImage(img, 0, 0);
				var dataURL = canvas.toDataURL(outputFormat || "image/png");
				callback.call(this, dataURL);
				canvas = null;
			};
			img.src = url;
		},
		removeImg(file, detail) {
			this.imgList.splice(detail.index, 1);
		},
		uploadSFZ(files) {
			const self = this;
			if (files.length) {
				for (const k in files) {
					this.getJpgFile(files[k].file).then((file) => {
						const options = {
							file: file,
							quality: 1,
							mimeType: "image/jpeg",

							minWidth: 500,
							minHeight: 500,

							loose: true,

							// 压缩前回调
							beforeCompress: function (result) {
								console.log("压缩之前图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
							},

							// 压缩成功回调
							success: function (result) {
								var fd = new FormData();
								console.log("转换后:" + result.size);
								console.log("this.apply.patientId", self.apply.patientId);
								fd.append("file", result);
								fd.append("biz", self.apply.patientId);
								self.runUpload(fd);
								console.log("压缩之后图片尺寸大小: ", result.size);
								console.log("mime 类型: ", result.type);
								console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
							},

							// 发生错误
							error: function (msg) {
								console.error(msg);
							},
						};
						new ImageCompressor(options);
					});
				}
			} else {
				this.getJpgFile(files.file).then((file) => {
					const options = {
						file: file,
						quality: 100,
						mimeType: "image/jpeg",
						// maxWidth: 2000,
						// maxHeight: 2000,
						// width: 1000,
						// height: 1000,

						// convertSize: Infinity,
						// loose: true,
						// redressOrientation: true,

						// 压缩前回调
						beforeCompress: function (result) {
							console.log("压缩之前图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
						},

						// 压缩成功回调
						success: function (result) {
							var fd = new FormData();
							console.log("转换后:" + result.size);
							fd.append("file", result);
							fd.append("biz", self.apply.patientId);
							self.runUpload(fd);
							console.log("压缩之后图片尺寸大小: ", result.size);
							console.log("mime 类型: ", result.type);
							console.log("实际压缩率： ", (((file.size - result.size) / file.size) * 100).toFixed(2) + "%");
						},

						// 发生错误
						error: function (msg) {
							console.error(msg);
						},
					};
					new ImageCompressor(options);
				});
			}
		},
		getJpgFile(file) {
			return new Promise((resolve, reject) => {
				const fileName = file.name;
				if (fileName.toLowerCase().endsWith(`.heic`)) {
					heic2any({
						blob: file,
						toType: "image/jpg",
					})
						.then((resultBlob) => {
							file = new File([resultBlob], fileName + ".jpg", {
								type: "image/jpg",
								lastModified: new Date().getTime(),
							});
							resolve(file);
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve(file);
				}
			});
		},
		runUpload(fd) {
			this.showLoading("上传中...");
			fd.status = "uploading";
			fd.message = "上传中...";
			api.upload(fd).then((res) => {
				this.hideLoading();
				if (res.success) {
					if (res.message) {
						fd.status = "done";
						fd.message = "上传成功";
						this.imgList.push(res.message);
					} else {
						Notify({ type: "danger", message: "图片上传失败！" });
					}
				} else {
					fd.status = "done";
					fd.message = "上传失败.";
					Notify({ type: "danger", message: "图片上传失败！" });
				}
			});
		},
		//sfz
		afterRead(file) {
			file.status = "uploading";
			file.message = "上传中...";
			api.uploads(file, this.apply.patientId).then((res) => {
				if (res.success) {
					file.status = "done";
					file.message = "上传成功";
					this.imgList.push(res.message);
				} else {
					file.status = "failed";
					file.message = "上传失败";
				}
			});
		},
		submitApply() {
			this.$refs.formRef1.validate().then((res) => {
				this.step = 2;
			});
		},
		openDialog() {
			this.$refs.formRef2.validate().then((res) => {
				// if (this.history.withGuan.length <= 0) {
				// 	this.$toast.fail("请选择带管情况");
				// 	return false;
				// }
				//
				if (!!this.history.picType && this.history.picType.length > 0 && this.imgList.length == 0) {
					this.$toast.fail("请上传照片");
					return false;
				}

				if (this.type == "edit") {
					this.submitHistory();
				} else {
          if (this.apply.isSendSms === 1 && this.form.messModel === '') {
            this.$toast.fail("请选择短信发送模版");
            return false;
          }
					// 提交申请
					this.show = true;
				}
			});
		},
		submitHistory() {
			let that = this;
			if (!this.apply.patientId) {
				this.$toast.fail("患者标识丢失！");
				return false;
			}
			this.$refs.formRef2.validate().then((res) => {
				// if (this.history.withGuan.length <= 0) {
				// 	this.$toast.fail("请选择带管情况");
				// 	return false;
				// }
				if (!!this.history.picType && this.history.picType.length > 0 && this.imgList.length == 0) {
					this.$toast.fail("请上传照片");
					return false;
				}
				this.showLoading();
				this.history.pic = this.imgList.join(",");
				const params = {
					apply: { ...this.apply, isForceSubmit: 2 },
					history: { ...this.history },
				};
				params.history.withGuan = this.history.withGuan.join(",");
				if (Object.keys(this.outRemarks).length > 0) {
					const outRemarks = [this.history.outRemarks];
					Object.keys(this.outRemarks).map((v) => {
						if (p.length > 0) {
							outRemarks.push(p);
							const p = this.outRemarks[v];
						}
					});
					params.history.outRemarks = JSON.stringify(outRemarks);
				}
				// 如果申请形式为复诊则清空如下内容
				if (params.applyType == "oneself") {
					params.zzHospital = "";
					params.zzHospitalOther = "";
					params.zzDepartment = "";
					params.zzDepartmentOther = "";
					params.consultationDoctor = "";
				}

				if (params.apply.id != undefined) {
					params.apply.isEditSms = 2;
					// 提交申请
					api.crmInHospitalApplyEdit(params).then((res) => {
						this.hideLoading();
						if (res.success) {
							this.$router.back(-2);
						} else {
							if (res.message == "未匹配到his数据!") {
								this.$router.back(-2);
							} else {
								Notify({ type: "danger", message: res.message });
							}
						}
					});
				} else {
					// 提交申请
					api.crmInHospitalApplyAdd(params).then((res) => {
						this.hideLoading();
						if (res.success) {
							if (!!this.applyId) {
								this.$router.back(-2);
							} else {
								this.$router.back();
							}
							if (that.remarksShow == true) {
								this.getRemarks();
							}
						} else {
							if (res.message.indexOf("入院申请记录") != -1) {
								// start
								this.$dialog
									.confirm({
										title: "提示",
										message: `${res.message} 是否强制提交?`,
										messageAlign: "left",
										cancelButtonText: "否",
										confirmButtonText: "是",
									})
									.then(() => {
										params.apply.isForceSubmit = 1;
										api.crmInHospitalApplyAdd(params).then((res) => {
											//
											this.hideLoading();
											if (res.success) {
												if (!!this.applyId) {
													this.$router.back(-2);
												} else {
													this.$router.back();
												}
												if (that.remarksShow == true) {
													this.getRemarks();
												}
											} else {
												Notify({ type: "danger", message: res.message });
											}
											//
										});
									});
								// end
							} else {
								Notify({ type: "danger", message: res.message });
							}
						}
					});
				}
			});
		},
	},
};
</script>

<style lang="less">
.crm-apply-show {
	.crm-card {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
		border-radius: 32px;
		padding: 14px 28px;
		margin-bottom: 20px;
	}
	.crm-card-title {
		margin-bottom: 4px;
	}
	.crm-textarea-title {
		margin-bottom: 8px;
	}
	.crm-card-body {
		.crm-select-cell {
			margin-bottom: 16px;
		}
	}
	.crm-upload {
		margin-top: 16px;
	}
}
.crm-remark-show {
	display: flex;

	.crm-card-title {
		margin-bottom: 0px;
		font-size: 28px;
	}
}
.crm-apply {
	padding: 32px;
	.van-checkbox__label {
		font-size: 28px;
	}
}
.crm-upload {
	display: block;
	text-align: left;
	.van-uploader__preview-image,
	.van-uploader__upload,
	.van-uploader__preview {
		background: #f2f2f2;
		border-radius: 12px;
	}
	.van-uploader__preview-delete {
		border-radius: 16px;
		top: -6px;
		right: -6px;
		width: 32px;
		height: 32px;
		.van-uploader__preview-delete-icon {
			top: 0;
			right: 0;
		}
	}
}
.user-know-box {
	padding: 20px 20px 20px 30px;
	p {
		padding: 10px;
	}
}
.jh-cell-group {
	padding: 10px 20px;
	.van-cell__title {
		text-align: left;
		font-weight: bold;
	}
}
</style>

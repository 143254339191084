<template>
	<div class="page-404">
		<div class="img-box">
			<img src="~@/assets/images/jh_404.png" alt="" />
			<p>您访问的问卷不存在！</p>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less">
.page-404 {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.img-box {
		display: inline-block;
		position: relative;
		p {
			position: absolute;
			bottom: 30%;
			width: 100%;
			left: 0;
			font-size: 28px;
			color: #999;
			font-weight: bold;
		}
	}
	img {
		display: inline-block;
	}
}
</style>
